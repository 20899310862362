import React from 'react';
import PropTypes from 'prop-types';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames/bind';

// eslint-disable-next-line import/extensions
import 'react-big-calendar/lib/css/react-big-calendar.css?external';

import { Notification } from 'components/rfs-ui';
import { Modal } from 'components/ui';
import { compareDates, makeDateString } from 'magic/date';
import dateFormats from 'constants/dateFormats';

import Toolbar from './Components/Toolbar';
import WeekEventWrapper from './Components/WeekEventContainer';
import MonthEventWrapper from './Components/MonthEventContainer';
import DateRange from './Components/DateRange';
import ConrolButtons from './Components/ControlButtons';

import css from './index.scss';

const cn = classnames.bind(css);
const localizer = BigCalendar.momentLocalizer(moment);
const calendarViews = {
  month: BigCalendar.Views.MONTH,
  week: BigCalendar.Views.WEEK,
};
const dateWarnings = {
  earlyEndError: 'Дата окончания события не может быть раньше текущего времени',
  earlyStartError: 'Дата начала события не может быть раньше текущего времени',
  emptyOrNotEqualsError: 'Даты не могут быть пустыми или различаться',
  endError: 'Дата окончания должна быть позже даты начала',
  pastCreateError: 'Нельзя создавать события раньше текущего времени',
  startEditActiveError: 'Нельзя изменять дату начала активного события',
  startError: 'Дата начала должна быть раньше даты окончания',
};
const eventWrappers = {
  month: MonthEventWrapper,
  week: WeekEventWrapper,
};

class Calendar extends React.PureComponent {
  static propTypes = {
    EventEditRenderer: PropTypes.func,
    EventRenderer: PropTypes.func,
    EventViewRenderer: PropTypes.func,
    defaultView: PropTypes.oneOf(Object.keys(calendarViews)),
    events: PropTypes.arrayOf(),
    onEventRemove: PropTypes.func,
    onEventUpdate: PropTypes.func,
    onPageChange: PropTypes.func,
    selectable: PropTypes.bool,
    views: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(calendarViews))),
  };

  static defaultProps = {
    EventEditRenderer: undefined,
    EventRenderer: undefined,
    EventViewRenderer: undefined,
    defaultView: undefined,
    events: undefined,
    onEventRemove: undefined,
    onEventUpdate: undefined,
    onPageChange: undefined,
    selectable: false,
    views: ['week', 'month'],
  };

  constructor(props) {
    super(props);
    const { defaultView } = props;

    const view = defaultView ? calendarViews[defaultView] : calendarViews.week;

    this.state = {
      date: new Date(),
      selectedEvent: {},
      view,
      watchMode: false,
    };
  }

  setValidEventDate = (validDate, warningMessage) => {
    Notification.warning({ children: { message: warningMessage } });
    this.updateSelectedEventField(validDate);
  };

  resetSelectedEvent = () => {
    this.setState({
      selectedEvent: {},
      watchMode: false,
    });
  };

  updateSelectedEventField = (field, callback) => {
    this.setState(
      ({ selectedEvent }) => ({
        selectedEvent: {
          ...selectedEvent,
          ...field,
        },
      }),
      callback,
    );
  };

  switchMode = () => {
    this.setState(({ watchMode }) => ({ watchMode: !watchMode }));
  };

  checkEventDate = (key) => (value) => {
    const { selectedEvent = {} } = this.state;
    const { start, end, type } = selectedEvent;
    const now = new Date().getTime();
    const dateStart = new Date(moment.utc(start)).getTime();
    const dateEnd = new Date(moment.utc(end)).getTime();
    let warning;

    this.updateSelectedEventField({ [key]: value }, () => {
      // валидация дат события для разных отображений календаря
      if (type === 'time') {
        if (key === 'start' && end && value) {
          const lowerDate = compareDates(value, dateEnd);
          const lowerTime = compareDates(value, dateEnd, true);

          if (dateStart < now && now < dateEnd) {
            warning = dateWarnings.startEditActiveError;
          } else if (now > value) {
            warning = dateWarnings.earlyStartError;
          } else if (lowerDate) {
            warning = dateWarnings.emptyOrNotEqualsError;
          } else if (lowerTime === dateEnd || value === dateEnd) {
            warning = dateWarnings.startError;
          }

          if (warning) {
            this.setValidEventDate({ start: dateStart }, warning);
          }
        } else if (key === 'end' && start && value) {
          const lowerDate = compareDates(dateStart, value);
          const lowerTime = compareDates(dateStart, value, true);

          if (value < now) {
            warning = dateWarnings.earlyEndError;
          } else if (lowerDate) {
            warning = dateWarnings.emptyOrNotEqualsError;
          } else if (lowerTime === value || value === dateStart) {
            warning = dateWarnings.endError;
          }

          if (warning) {
            this.setValidEventDate({ end: dateEnd }, warning);
          }
        }
      } else if (type === 'date') {
        if (key === 'start' && end && value) {
          const lowerDate = compareDates(value, dateEnd);

          if (dateStart <= now && now <= dateEnd) {
            warning = dateWarnings.startEditActiveError;
          } else if (now >= value) {
            warning = dateWarnings.earlyStartError;
          } else if (lowerDate && lowerDate === dateEnd) {
            warning = dateWarnings.startError;
          }

          if (warning) {
            this.setValidEventDate({ start: dateStart }, warning);
          } else if (!lowerDate) {
            const startDate = new Date(value);
            startDate.setHours(0, 0, 0);
            end.setHours(0, 0, 0);
            this.updateSelectedEventField({ start: startDate, end });
          }
        } else if (key === 'end' && start && value) {
          const lowerDate = compareDates(dateStart, value);

          if (value <= now) {
            warning = dateWarnings.earlyEndError;
          } else if (lowerDate && lowerDate === value) {
            warning = dateWarnings.endError;
          }

          if (warning) {
            this.setValidEventDate({ end: dateEnd }, warning);
          } else if (!lowerDate) {
            const endDate = new Date(value);
            endDate.setHours(0, 0, 0);
            this.updateSelectedEventField({ end: endDate });
          }
        }
      }
    });
  };

  handleEventUpdate = (event) => {
    this.props.onEventUpdate(event);
    this.resetSelectedEvent();
  };

  handleEventRemove = async () => {
    this.props.onEventRemove(this.state.selectedEvent.eventId);
    this.resetSelectedEvent();
  };

  handleViewChange = (view) => {
    this.setState({ view });
  };

  handleShowMore = (_events, date) => {
    this.setState({ date, view: calendarViews.week });
  };

  handleDateChange = (date) => {
    this.setState({ date });
  };

  handleEventDataChange = (field) => {
    this.updateSelectedEventField(field);
  };

  handleEventLoad = (event = {}) => {
    const { selectedEvent = {} } = this.state;

    if (!isEmpty(selectedEvent)) {
      this.setState({
        selectedEvent: {
          ...event,
          ...selectedEvent,
        },
      });
    }
  };

  handleEventSelect = ({ id: eventId, start, end, type }) => {
    this.setState({
      selectedEvent: {
        end,
        eventId,
        start,
        type,
      },
      watchMode: true,
    });
  };

  handleSlotSelect = (event = {}) => {
    const { start, end, type, action, bounds, resourceId, slots = [], box, ...eventAttr } = event;

    // этот кейс при нажатии на ячейку в отображении месяца (проваливаемся в неделю)
    if (action === 'click' && slots[0] && this.state.view === calendarViews.month) {
      this.setState({
        date: slots[0],
        view: calendarViews.week,
      });

      return;
    }

    const startDate = new Date(start.getTime());
    const endDate = new Date(end.getTime());
    const now = new Date();
    const generatedType = bounds || box ? 'time' : 'date';
    const eventType = type || generatedType;
    const lowerDate = compareDates(start, now);

    // проверяем, что дата выбранного события не раньше текущего времени
    const canCreate = (eventType === 'time' && start.getTime() >= now.getTime()) || (eventType === 'date' && lowerDate === start);

    if (!canCreate) {
      Notification.warning({ children: { message: dateWarnings.pastCreateError } });
    } else {
      if (eventType === 'date' && slots.length > 1) {
        endDate.setHours(23, 59, 59);
      }

      this.setState({
        selectedEvent: {
          ...eventAttr,
          end: endDate,
          start: startDate,
          type: eventType,
        },
      });
    }
  };

  handleRangeChange = (dates = []) => {
    this.props.onPageChange(dates[0], dates[dates.length - 1]);
  };

  renderEventView = () => {
    const { selectedEvent = {} } = this.state;
    const { EventViewRenderer, selectable } = this.props;
    const { start, end, type, ...eventProps } = selectedEvent;
    const isTimeFormat = type === 'time';
    const dateStartFormat = isTimeFormat ? 'dddd, D MMMM, HH:mm' : dateFormats.APP_DATE_FORMAT;
    const dateEndFormat = isTimeFormat ? 'HH:mm' : dateFormats.APP_DATE_FORMAT;
    const dateStart = start && makeDateString(start, true, dateStartFormat);
    const dateEnd = end && makeDateString(end, true, dateEndFormat);
    const now = new Date().getTime();
    const canEdit = selectable && new Date(start).getTime() > now && new Date(end).getTime() > now;

    return (
      <EventViewRenderer
        {...eventProps}
        date={dateStart && dateEnd && `${dateStart} - ${dateEnd}`}
        onRemove={this.handleEventRemove}
        onLoad={this.handleEventLoad}
        onClose={this.resetSelectedEvent}
        EditComponent={canEdit ? (props) => <ConrolButtons {...props} onEdit={this.switchMode} /> : null}
      />
    );
  };

  renderEventEdit = () => {
    const { selectedEvent = {} } = this.state;
    const { EventEditRenderer } = this.props;
    const { start, end, type } = selectedEvent;

    return (
      <EventEditRenderer
        event={selectedEvent}
        RangeComponent={() => <DateRange onChange={this.checkEventDate} start={start} end={end} withTime={type === 'time'} />}
        onClose={selectedEvent.id ? this.switchMode : this.resetSelectedEvent}
        onChange={this.handleEventDataChange}
        onUpdate={this.handleEventUpdate}
      />
    );
  };

  render() {
    const { selectedEvent, view, date, watchMode } = this.state;
    const { EventRenderer, events, selectable, views } = this.props;
    const EventWrapper = eventWrappers[view];
    const hideAllDayRow = views.length === 1 || view !== 'week';

    return (
      <React.Fragment>
        <BigCalendar
          className={cn({ hide: hideAllDayRow })}
          date={date}
          selectable={selectable}
          localizer={localizer}
          events={events}
          views={views}
          view={view}
          onShowMore={this.handleShowMore}
          dayPropGetter={() => ({ className: css.day })}
          slotPropGetter={() => ({ className: css.slot })}
          messages={{ showMore: (count) => `+ показать еще ${count}` }}
          components={{
            eventWrapper: (props) => <EventWrapper {...props}>{EventRenderer && <EventRenderer {...props.event} />}</EventWrapper>,
            toolbar: (props) => <Toolbar {...props} view={view} />,
          }}
          onSelectEvent={this.handleEventSelect}
          onSelectSlot={this.handleSlotSelect}
          onNavigate={this.handleDateChange}
          onRangeChange={this.handleRangeChange}
          onView={this.handleViewChange}
        />
        {!isEmpty(selectedEvent) && (
          <Modal onClose={this.resetSelectedEvent} className={css.eventModal}>
            <div className={css.modalContent}>{watchMode ? this.renderEventView() : this.renderEventEdit()}</div>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default Calendar;
