/**
 * Application provider
 */
import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import 'moment/locale/ru';

import { ConfirmModal } from 'components/rfs-ui';
import createAppStore from 'redux/createAppStore';

import App from 'components/App';

const history = createBrowserHistory({ getUserConfirmation: ConfirmModal.routerConfirm });
const store = createAppStore(history);

export default function AppProvider() {
  return (
    <Provider store={store}>
      <App history={history} />
    </Provider>
  );
}
