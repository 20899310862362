import actionsFactory from 'magic/actionsFactory';

const createAction = actionsFactory('transfers/user/');

export const setPlayer = createAction('setPlayer');
export const setTransferData = createAction('setTransferData');
export const setTypes = createAction('setTypes');
export const changeType = createAction('changeType');
export const changeForm = createAction('changeForm');
export const clearFormData = createAction('clearFormData');
export const clearData = createAction('clearData');
