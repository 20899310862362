import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';

const createAction = actionsFactory('payConstructor/threshold/');

export const setThresholdData = createAction('setThresholdData');
export const setThresholdSum = createAction('setThresholdSum');
export const clearData = createAction('clearData');

export const getThresholdData = () => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get('/api/rest/costcalculation/payoutthreshold/all');
    dispatch(setThresholdData(data));
  } catch (err) {
    handleError(err);
    dispatch(setThresholdData([]));
  }
};

export const saveThresholdData = (data) => async (dispatch) => {
  try {
    const response = await axios.post('/api/rest/costcalculation/payoutthreshold/save', data);
    handleSuccess('Пороговые выплаты успешно изменены!');

    dispatch(setThresholdData(response.data.data));
  } catch (err) {
    handleError(err);
  }
};

export const getThresholdSum = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/costcalculation/payoutthresholdsumm');
    dispatch(setThresholdSum(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setThresholdSum({}));
  }
};

export const saveThresholdSum = (data) => async (dispatch) => {
  try {
    const response = await axios.post('/api/rest/costcalculation/payoutthresholdsumm/save', data);
    handleSuccess('Пороговая сумма успешно обновлена!');
    dispatch(setThresholdSum(response.data.data));
  } catch (err) {
    handleError(err);
  }
};
