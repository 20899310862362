import { createReducer } from 'redux-act';

import {
  clearData,
  setAppealData,
  setAppealLoading,
  setAppealTemplatesData,
  setAppealTemplatesLoading,
  setAppealTypesData,
  setAppealTypesLoading,
} from 'redux/actions/Appeals/creationActions';

const initialState = {
  appeal: {
    data: null,
    isLoading: false,
  },
  templates: {
    data: [],
    isLoading: false,
  },
  types: {
    data: [],
    isLoading: false,
  },
};

const creationReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setAppealData]: ({ appeal, ...state }, data) => ({
      ...state,
      appeal: {
        ...appeal,
        data,
      },
    }),
    [setAppealLoading]: ({ appeal, ...state }, isLoading) => ({
      ...state,
      appeal: {
        ...appeal,
        isLoading,
      },
    }),
    [setAppealTemplatesData]: ({ templates, ...state }, data) => ({
      ...state,
      templates: {
        ...templates,
        data,
      },
    }),
    [setAppealTemplatesLoading]: ({ templates, ...state }, isLoading) => ({
      ...state,
      templates: {
        ...templates,
        isLoading,
      },
    }),
    [setAppealTypesData]: ({ types, ...state }, data) => ({
      ...state,
      types: {
        ...types,
        data,
      },
    }),
    [setAppealTypesLoading]: ({ types, ...state }, isLoading) => ({
      ...state,
      types: {
        ...types,
        isLoading,
      },
    }),
  },
  initialState,
);

export default creationReducer;
