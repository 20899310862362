import { createReducer } from 'redux-act';
import { setData, setLoading, cleanData } from 'redux/actions/Organization/contactsActions';

const initialState = {
  data: [],
  loading: false,
};

const decisionsReducer = createReducer(
  {
    [cleanData]: () => ({ ...initialState }),
    [setLoading]: (state, payload) => ({
      ...state,
      isLoading: payload,
    }),
    [setData]: (state, payload) => ({
      ...state,
      data: payload,
    }),
  },
  initialState,
);

export default decisionsReducer;
