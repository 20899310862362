import { DEFAULT_PAGINATION } from 'constants/pagination';
import axios from 'axios';
import { handleError, handleSuccess } from 'magic/handlers';
import actionsFactory from 'magic/actionsFactory';
import { FILTERS_FIELDS_WITH_IDS } from 'components/pages/Cases/List/constants';
import { omit } from '../../../utils/lodash';

const createAction = actionsFactory('cases/view/');

export const clearData = createAction('clearData');
export const setCasesList = createAction('setCasesList');
export const setCase = createAction('setCase');
export const setCasesLoading = createAction('setCasesLoading');
export const setUpdateCasesLoading = createAction('setUpdateCasesLoading');

const filtersIdsBuilder = (filters) =>
  Object.fromEntries(
    Object.entries(filters || {}).map(([key, value]) =>
      FILTERS_FIELDS_WITH_IDS.includes(key) ? [[`${key}Id`], value?.id || value?.name] : [key, value]),
  );

export const getCasesList = (filters = {}, pagination = DEFAULT_PAGINATION) => async (dispatch) => {
  try {
    dispatch(setCasesLoading(true));
    const url = '/api/rest/judiciary/case/list';
    const { page, perPage } = pagination;

    const requestFilters = filtersIdsBuilder(omit(filters, 'participantType', 'relationType'));

    const params = {
      ...omit(requestFilters, 'federations'),
      page,
      perPage,
      status: requestFilters.status?.name,
    };

    const {
      data: { data, pageData = {} },
    } = await axios.get(url, { params });

    const newPagination = {
      itemsCount: pageData.size,
      page,
      pageCount: pageData.countPage,
      perPage,
    };

    dispatch(setCasesList({ data, filters, pagination: newPagination }));
  } catch (err) {
    handleError(err);
    dispatch(setCasesLoading(false));
  }
};

export const onRemoveCase = (caseId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/judiciary/case/${caseId}`);
    dispatch(setCase(caseId));
    handleSuccess('Дело успешно удалено!');
  } catch (err) {
    handleError(err);
  }
};

export const onUpdateCases = (body, successCallback) => async (dispatch) => {
  try {
    dispatch(setUpdateCasesLoading(true));
    await axios.post(`/api/rest/judiciary/case/auto/reload?committeeId=${body.committeeId}&tournamentId=${body.tournamentId}`);
    handleSuccess('Автодела успешно обновлены!');
    if (successCallback) successCallback();
    dispatch(setUpdateCasesLoading(false));
  } catch (err) {
    handleError(err);
    dispatch(setUpdateCasesLoading(false));
  }
};
