/* eslint-disable no-console */
import axios from 'axios';
import { push } from 'connected-react-router';
import actionsFactory from 'magic/actionsFactory';
import { handleSuccess, handleError } from 'magic/handlers';
import { ROUTES_WAYS } from 'constants/routesWays';
import { sortByKey } from 'utils/arrays';

const createAction = actionsFactory('documentConstructor');

export const clearData = createAction('clearData');
export const setDocumentCategories = createAction('setDocumentCategories');
export const setDocumentTypes = createAction('setDocumentTypes');

export const clearSelectedDocumentType = createAction('clearSelectedDocumentType');
export const setSelectedDocumentTypeData = createAction('setSelectedDocumentTypeData');
export const setSelectedDocumentTypeLoading = createAction('setSelectedDocumentTypeLoading');

export const setFieldTypesData = createAction('setFieldTypesData');
export const setFieldTypesLoading = createAction('setFieldTypesLoading');

export const setFieldCollectionData = createAction('setFieldCollectionData');
export const setFieldCollectionLoading = createAction('setFieldCollectionLoading');

export const getDocumentCategories = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/document/categories');
    dispatch(setDocumentCategories(response.data.data));
  } catch (err) {
    handleError(err);
  }
};

export const getDocumentTypes = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/document/types');
    dispatch(setDocumentTypes(response.data.data));
  } catch (err) {
    handleError(err);
  }
};

export const getDocumentTypesByCategories = () => async (dispatch) => {
  await Promise.all([dispatch(getDocumentCategories()), dispatch(getDocumentTypes())]);
};

export const postDocumentType = (body) => async () => {
  try {
    await axios.post('/api/rest/document/types/new', body);
    handleSuccess('Тип документа успешно сохранен', 'file-check');
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const getTypeById = (id) => async (dispatch) => {
  try {
    dispatch(setSelectedDocumentTypeLoading(true));
    const {
      data: { data },
    } = await axios.get(`/api/rest/document/types/${id}`);

    const docType = { ...data, fieldsList: data.fieldsList ? sortByKey(data.fieldsList, 'fieldOrder') : [] };

    dispatch(setSelectedDocumentTypeData(docType));
  } catch (err) {
    handleError(err);
    dispatch(setSelectedDocumentTypeData(null));
    dispatch(push(ROUTES_WAYS.DOCUMENT_CONSTRUCTOR));
  } finally {
    dispatch(setSelectedDocumentTypeLoading(false));
  }
};

export const getFieldTypes = () => async (dispatch) => {
  try {
    dispatch(setFieldTypesLoading(true));
    const {
      data: { data },
    } = await axios.get('/api/rest/fieldTypes/all');

    dispatch(setFieldTypesData(data));
  } catch (err) {
    handleError(err);
    dispatch(setFieldTypesData([]));
  } finally {
    dispatch(setFieldTypesLoading(false));
  }
};

export const getFieldCollection = () => async (dispatch) => {
  try {
    dispatch(setFieldCollectionLoading(true));
    const {
      data: { data },
    } = await axios.get('/api/rest/fields/all?owner=PERSON');

    dispatch(setFieldCollectionData(sortByKey(data, 'name')));
  } catch (err) {
    handleError(err);
    dispatch(setFieldCollectionData([]));
  } finally {
    dispatch(setFieldCollectionLoading(false));
  }
};

export const deleteDocumentType = (documentTypeId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/document/types/del/${documentTypeId}`);
    dispatch(getDocumentTypes());
  } catch (err) {
    handleError(err);
  }
};
