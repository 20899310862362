import { createReducer } from 'redux-act';
import axios from 'axios';

import { handleError } from 'magic/handlers';
import actionsFactory from 'magic/actionsFactory';
import { isFunction } from 'utils/lodash';

const createAction = actionsFactory('profile/transfersHistory/');
const initialState = {
  form: {
    action: null,
    club: null,
    initdate: null,
    status: false,
  },
  items: [],
  transfersHistoryInfo: null,
  transfersHistoryInfoLoading: true,
};

// Очистка всего стора
export const clearData = createAction('clearData');
export const fillTransfersHistory = createAction('fillTransfersHistory');

// История переходов
export const addTransfersHistoryItem = createAction('addTransfersHistoryItem');
export const deleteTransfersHistoryItem = createAction('deleteTransfersHistoryItem');
export const updateTransfersHistoryItem = createAction('updateTransfersHistoryItem');
export const fillTransfersHistoryData = createAction('fillTransfersHistoryData');
export const setTransfersHistoryDataByKey = createAction('setTransfersHistoryDataByKey');
export const clearTransfersHistoryData = createAction('clearTransfersHistoryData');
const setTransfersHistoryInfoLoading = createAction('transfersHistoryInfoLoading');

const _setTransfersHistoryInfo = createAction('setTransfersHistoryInfo');
export const purgeTransfersHistoryInfo = createAction('purgeTransfersHistoryInfo');

export const getTransfersHistoryInfo = ({ eventId, onError }) => async (dispatch) => {
  dispatch(setTransfersHistoryInfoLoading(true));
  try {
    const {
      data: { data },
    } = await axios.get(`/api/rest/audit/transfer/view/${eventId}`);
    dispatch(_setTransfersHistoryInfo(data));
  } catch (error) {
    handleError(error);
    if (isFunction(onError)) {
      onError(error);
    }
  } finally {
    dispatch(setTransfersHistoryInfoLoading(false));
  }
};

const reducer = createReducer(
  {
    [_setTransfersHistoryInfo]: (state, payload) => ({
      ...state,
      transfersHistoryInfo: payload,
    }),
    [addTransfersHistoryItem]: (state, { item }) => ({
      ...state,
      items: [...state.items, item],
    }),
    [clearData]: () => initialState,
    [clearTransfersHistoryData]: (state) => ({
      ...state,
      form: {
        action: null,
        club: null,
        initdate: null,
        status: false,
      },
    }),
    [deleteTransfersHistoryItem]: (state, { id }) => ({
      ...state,
      items: state.items.filter((item) => (item.tempId ? item.tempId !== id : item.id !== id)),
    }),
    [fillTransfersHistory]: (state, payload) => ({
      ...state,
      items: payload,
    }),
    [fillTransfersHistoryData]: (state, item) => ({
      ...state,
      form: item,
    }),
    [purgeTransfersHistoryInfo]: (state) => ({
      ...state,
      transfersHistoryInfo: initialState.transfersHistoryInfo,
    }),
    [setTransfersHistoryDataByKey]: (state, { key, value }) => ({
      ...state,
      form: {
        ...state.form,
        [key]: value,
      },
    }),
    [setTransfersHistoryInfoLoading]: (state, payload) => ({
      ...state,
      transfersHistoryInfoLoading: payload,
    }),
    [updateTransfersHistoryItem]: (state, { item }) => ({
      ...state,
      items: state.items.map((oldItem) => {
        // У каждого элемента может быть либо id в случае, если он пришел с бэка, либо tempId, если элемент был создан на фронте.
        // Здесь просто проверяется, не совпадает ли id (tempId) у элемента массива с входящим элементом.
        if ((item.tempId && oldItem.tempId === item.tempId) || (item.id && oldItem.id === item.id)) {
          return item;
        }
        return oldItem;
      }),
    }),
  },
  initialState,
);

export default reducer;
