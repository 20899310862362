import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import cn from 'classnames';

import { PermissionsConsumer } from 'components/composed/Permissions';
import { appConfig } from 'constants/appConfig';
import { ROUTES_WAYS } from 'constants/routesWays';
import { PTMatch } from 'propTypes';

import { ACCESS_PERMISSIONS } from '../constants';

import { checkPermissions } from 'magic/permissions';
import { useSelector } from 'react-redux';
import PermissionsContext from 'components/composed/Permissions/PermissionsContext';
import { PERMISSIONS } from 'constants/permissions';

import styles from './Links.scss';

const Links = ({ match }) => {
  const isControlIndicators = match.path === ROUTES_WAYS.CONTROL_INDICATORS;

  const userPermissions = useContext(PermissionsContext);
  const hasNewCompetitionsAccess = checkPermissions(false, false, 'global', userPermissions, [PERMISSIONS.NEW_COMPETITION])

  const competitionsAddress = useSelector((state) => state.competitionsIntegrationReducer?.address);

  return (
    <React.Fragment>
      <Link to={ROUTES_WAYS.MANAGEMENT} className={cn(styles.navigationLink, !isControlIndicators && styles.active)}>
        Паспортизация
      </Link>
      <PermissionsConsumer permissions={ACCESS_PERMISSIONS.COMPETITION}>
        <a href={hasNewCompetitionsAccess ? `${competitionsAddress}/competition-management/` : "/ords/f?p=1:1"} className={styles.navigationLink}>
          Соревнования
        </a>
      </PermissionsConsumer>
      <PermissionsConsumer permissions={ACCESS_PERMISSIONS.STRATEGY}>
        <Link to={ROUTES_WAYS.CONTROL_INDICATORS} className={cn(styles.navigationLink, isControlIndicators && styles.active)}>
          Стратегия 2030
        </Link>
      </PermissionsConsumer>

      <a href={appConfig.TALENTS_HOST} rel="noopener noreferrer" className={styles.navigationLink}>
        Наша смена
      </a>
      {appConfig.REFEREE_HOST ? (
        <a href={appConfig.REFEREE_HOST} rel="noopener noreferrer" className={styles.navigationLink}>
          Арбитры
        </a>
      ) : null}

      {appConfig.TRAVEL_HOST ? (
        <a href={appConfig.TRAVEL_HOST} rel="noopener noreferrer" className={styles.navigationLink}>
          Выезды ЮФЛ
        </a>
      ) : null}
    </React.Fragment>
  );
};

Links.propTypes = {
  match: PropTypes.shape(PTMatch).isRequired,
};

export default withRouter(Links);
