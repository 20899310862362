import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'components/ui/Button/Button';
import cn from 'classnames';
import styles from './LinkButton.scss';

/**
 * @type {React.FC<any>}
 */
const LinkButton = (props) => {
  const { to, className, buttonClassName, children, target, ...buttonProps } = props;

  return (
    <Link to={to} className={cn(styles.linkButton, className)} target={target}>
      <Button {...buttonProps} className={cn(buttonClassName, styles.inheritClass)}>
        {children}
      </Button>
    </Link>
  );
};

LinkButton.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  target: PropTypes.string
};

export default LinkButton;
