import { string, number, array } from 'prop-types';

/**
 * @description Навыки
 */
export const PTSkill = {
  /**
   * @description Описание
   */
  description: string,
  /**
   * @description Идентификатор
   */
  id: number,
  /**
   * @description Наименование
   */
  name: string.isRequired,
  persons: array,
  /**
   * @description Системеное наименование
   */
  sysName: string,
  /**
   * @description Идентификатор с фронта
   */
  tempId: string,
};
