import { createReducer } from 'redux-act';

import actionsFactory from 'magic/actionsFactory';

const createAction = actionsFactory('@@TotalTrainingGroupForm/');

const initialState = {
  baseYearBirths: [],
  curator: undefined,
  groupName: undefined,
  season: undefined,
  stage: undefined,
  stageYear: undefined,
};

export const purgeTotalTrainingGroupFormReducer = createAction('purgeTotalTrainingGroupFormReducer');

export const changeFormElement = createAction('changeFormElement');

const reducer = createReducer(
  {
    [changeFormElement]: (state, payload) => {
      const { field, value } = payload;

      return {
        ...state,
        [field]: value,
      };
    },
    [purgeTotalTrainingGroupFormReducer]: () => ({ ...initialState }),
  },
  initialState,
);

export default reducer;
