import { createReducer } from 'redux-act';

import { clearData, setRatingAmpluaData, setRatingAmpluaLoading } from 'redux/actions/Rating';

const initialState = {
  data: [],
  isLoading: false,
};

const ampluaReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setRatingAmpluaData]: (state, data) => ({
      ...state,
      data,
    }),
    [setRatingAmpluaLoading]: (state, isLoading) => ({
      ...state,
      isLoading,
    }),
  },
  initialState,
);

export default ampluaReducer;
