import { DEFAULT_PAGINATION } from 'constants/pagination';
import { createReducer } from 'redux-act';
import {
  setFederationsList,
  setCasesLoading,
  clearData,
  setCasesList,
  setFederationsLoading,
  setCase, setUpdateCasesLoading,
} from 'redux/actions/Cases/viewActions';

const initState = {
  filters: {},
  list: [],
  loading: false,
  pagination: DEFAULT_PAGINATION,
  updateLoading: false,
};

const viewReducer = createReducer(
  {
    [clearData]: () => initState,
    [setCase]: (state, caseId) => ({
      ...state,
      list: state.list.filter((item) => item.id !== caseId),
    }),
    [setCasesList]: (state, { data, pagination, filters }) => ({
      ...state,
      filters,
      list: data,
      loading: false,
      pagination,
    }),
    [setCasesLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
    [setUpdateCasesLoading]: (state, updateLoading) => ({
      ...state,
      updateLoading,
    }),
  },
  initState,
);

export default viewReducer;
