import axios from 'axios';
import { createReducer } from 'redux-act';
import { push, replace } from 'connected-react-router';

import { get } from 'utils/lodash';

import { Auth2faService, AuthService, CareerBonusLogoutService, LogoutService, SessionService, SsoService } from 'api/services';
import { ROUTES_WAYS } from 'constants/routesWays';
import { UNAUTHORIZED } from 'constants/statusCodes';

import actionsFactory from 'magic/actionsFactory';
import { handleError } from 'magic/handlers';
import { getCurrentOrganization } from 'redux/modules/personalData/reducer';

// import WSMessage from 'magic/WSMessage';
// import { ws } from 'magic/WebSocketService';

// import { clearWsPersonDoubleFromCollection, setWsPersonDoublesCollection } from 'redux/actions/personDoublesActions';

const CONFIRMATION_CODE_IS_NEEDED = 333005;

const SMS_CONFIRMATION_CODE_IS_NEEDED = 333006;

const createAction = actionsFactory('session/');

const initialState = {
  authorized: false,
  loading: true,
  loadingInProgress: false,
  organization: null,
  savedLink: null,
  sessionError: null,
  user: null,
  userById: null,
  userProfile: null,
  wsPersonActiveDouble: null,
  wsPersonDoublesCollection: new Map(),
  wsPersonDoublesCollectionSize: 0,
};

// actions
export const setUserProfile = createAction('setUserProfile');
export const setSavedLink = createAction('setSavedLink');
export const setUserOrganization = createAction('setUserOrganization');
export const setVersion = createAction('setVersion');
export const loginInit = createAction('loginInit');
export const loginSuccess = createAction('loginSuccess');
export const loginError = createAction('loginError');
export const sessionCheckSuccess = createAction('sessionCheckSuccess');
export const sessionCheckError = createAction('sessionCheckError');

// export const initSocket = (id) => (dispatch, getState) => {
//   const dispatchWsPersonDoublesCollection = flow(setWsPersonDoublesCollection, dispatch);
//   ws();

//   ws.connect({}, () => {
//     ws.subscribe(`/front/session/${id}`, () => {
//       //
//     });

//     ws.subscribe('/user/topic/message', (message) => {
//       const wsMessage = new WSMessage(message, false);
//       switch (wsMessage.type) {
//         case WSMessage.TYPES.FIFA_DUPLICATE: {
//           const { wsPersonDoublesCollection } = getState().core.session;

//           if (!wsPersonDoublesCollection.has(wsMessage.id)) {
//             dispatchWsPersonDoublesCollection(wsMessage);
//           }

//           break;
//         }

//         case WSMessage.TYPES.PERMISSION_UPDATE: {
//           axios.get(SessionService).then((response) => {
//             dispatch(sessionCheckSuccess(response.data));
//           });

//           break;
//         }

//         default:
//           break;
//       }
//     });

//     ws.send(`/back/session/start/${id}`, {}, '');
//   });
// };

export const logout = createAction('logout', () => {
  try {
    axios.get(LogoutService);
    axios.get(CareerBonusLogoutService);
  } catch (e) {
    handleError(e);
  }
});

export const getUserProfile = (id) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get(`/api/rest/person/${id}`);

    dispatch(setUserProfile(data));
  } catch (error) {
    //
  }
};

export function redirectAfterLogin(dispatch, state) {
  const { savedLink } = state.core.session;
  if (savedLink) {
    if (/^http[s]?:\/\//.test(savedLink)) {
      window.location.href = savedLink;
    } else {
      dispatch(setSavedLink(null));
      dispatch(push(savedLink));
    }
  } else dispatch(push(ROUTES_WAYS.MANAGEMENT));
}

/**
 *
 * @param data {localeUser: { timezoneOffset }, login, password} данные для авторизации пользователя
 * @returns {Function}
 */
export const makeLogin = (data) => async (dispatch, getState) => {
  try {
    dispatch(loginInit());
    const res = await axios.post(AuthService, data);
    // dispatch(initSocket(res.data.data.personid));
    await dispatch(getUserProfile(res.data.data.personid));
    await getCurrentOrganization(dispatch, res.data.data?.authorities);
    dispatch(loginSuccess(res.data));
    redirectAfterLogin(dispatch, getState());
  } catch (e) {
    const errorCode = get(e, 'response.data.errorCode');
    dispatch(loginError());
    if ([CONFIRMATION_CODE_IS_NEEDED, SMS_CONFIRMATION_CODE_IS_NEEDED].includes(errorCode)) {
      dispatch(push('/login-confirmation', { data, errorCode }));
    } else {
      handleError(e);
    }
  }
};

export const makeLogin2fa = (data, goBack) => async (dispatch, getState) => {
  try {
    dispatch(loginInit());
    const res = await axios.post(Auth2faService, data);
    await dispatch(getUserProfile(res.data.data.personid));
    dispatch(loginSuccess(res.data));
    redirectAfterLogin(dispatch, getState());
  } catch (e) {
    handleError(e);
    dispatch(loginError());

    if (goBack) {
      dispatch(push(ROUTES_WAYS.LOGIN));
    }

    const response = get(e, 'response', Object.create(null));
    const responseData = get(response, 'data', Object.create(null));

    if (response.status === 401 && !('errorCode' in responseData)) {
      dispatch(replace(ROUTES_WAYS.LOGIN));
    }
  }
};

export const sendConfirmationSms = (login) => async (dispatch) => {
  try {
    await axios.get(`/api/rest/auth/smscode?login=${login}`);
  } catch (e) {
    handleError(e);
    dispatch(loginError());

    if (response.status === 401 && !('errorCode' in responseData)) {
      dispatch(replace(ROUTES_WAYS.LOGIN));
    }
  }
};

export const redirectToManagement = () => (dispatch) => {
  dispatch(push(ROUTES_WAYS.MANAGEMENT));
};

export const makeLogout = () => (dispatch, getState) => {
  // ws.destruct();
  const currentPath = getState().router.location.pathname;
  if (currentPath !== ROUTES_WAYS.LOGIN) {
    dispatch(setSavedLink(currentPath));
  }
  // dispatch(clearWsPersonDoubleFromCollection());
  axios
    .get('/api/rest/auth/sso/session/check')
    .then(() => {
      dispatch(logout());
      window.location.href = '/sso/logout?appId=3dcf6a922384434997c49249f27cfe87';
    })
    .catch(() => {
      dispatch(logout());
      dispatch(push(ROUTES_WAYS.LOGIN));
    });
};

export const checkSessionStatus = () => async (dispatch) => {
  try {
    const { data } = await axios.get(SessionService);

    // dispatch(initSocket(data.data.personid));
    await getCurrentOrganization(dispatch, data.data?.authorities);
    await dispatch(getUserProfile(data.data.personid));
    dispatch(sessionCheckSuccess(data));
    if (window.location.pathname === '/login') {
      dispatch(redirectToManagement());
    }
  } catch (e) {
    axios
      .get(SsoService)
      .then(() => {
        dispatch(checkSessionStatus());
      })
      .catch((err) => {
        makeLogout();
        dispatch(sessionCheckError(err));
      });
  }
};

export const checkVersion = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/rest/info/version');
    dispatch(setVersion(res.data));
  } catch (e) {
    // console.error(e);
  }
};

export const initSession = () => (dispatch) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.config &&
        (error.config.url.includes('/sso/login') ||
          error.config.url.includes('/rest/session') ||
          error.config.url.includes('/sso/session/check'))
      ) {
        return Promise.reject(error);
      }
      if (error.response.status === UNAUTHORIZED) {
        dispatch(makeLogout());
      }
      return Promise.reject(error);
    },
  );
};

// reducer
const reducer = createReducer(
  {
    [loginError]: (state) => ({
      ...initialState,
      loading: false,
      savedLink: state.savedLink,
    }),
    [loginInit]: (state) => ({
      ...state,
      loginInProgress: true,
    }),
    [loginSuccess]: (state, { data }) => ({
      ...state,
      loading: false,
      loginInProgress: false,
      permissions: data.baseauthoritiesNames,
      user: data,
    }),
    [logout]: (state) => ({
      ...initialState,
      loading: false,
      savedLink: state.savedLink,
    }),
    [sessionCheckError]: (state) => ({
      ...initialState,
      loading: false,
      savedLink: state.savedLink,
    }),
    [sessionCheckSuccess]: (state, { data }) => ({
      ...state,
      loading: false,
      permissions: data.baseauthorities,
      user: data,
    }),
    [setSavedLink]: (state, payload) => ({
      ...state,
      savedLink: payload,
    }),
    [setUserProfile]: (state, userProfile) => ({
      ...state,
      userProfile,
    }),
    [setVersion]: (state, payload) => ({
      ...state,
      version: payload,
    }),
  },
  initialState,
);

export default reducer;
