import { createReducer } from 'redux-act';

import { setEventCombinations, setEventCategoryInfo, clearData } from 'redux/actions/PayConstructor/events';

const initialState = {};

const eventsReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setEventCategoryInfo]: (state, { eventId, data }) => ({
      ...state,
      [eventId]: {
        ...state[eventId],
        categoryInfo: data,
      },
    }),
    [setEventCombinations]: (state, { eventId, data }) => ({
      ...state,
      [eventId]: {
        ...state[eventId],
        combinations: data,
      },
    }),
  },
  initialState,
);

export default eventsReducer;
