import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';
import { handleError, handleSuccess } from 'magic/handlers';

const createAction = actionsFactory('employees/view/');

export const clearData = createAction('clearData');
export const setEmployeesList = createAction('setEmployeesList');
export const addEmployee = createAction('addEmployee');
export const changeEmployee = createAction('changeEmployee');
export const removeEmployee = createAction('removeEmployee');
export const setEmployeesLoading = createAction('setEmployeesLoading');
export const setEmployeesSaveLoading = createAction('setEmployeesSaveLoading');
export const setEmployeesRemoveLoading = createAction('setEmployeesRemoveLoading');

const baseUrl = '/api/rest/judiciary/mailing/employee';

export const getEmployeesList = (committee) => async (dispatch) => {
  try {
    dispatch(setEmployeesLoading(true));

    const {
      data: { data },
    } = await axios.get(baseUrl, { params: { committeeId: committee?.id } });

    dispatch(setEmployeesList({ committee, data }));
  } catch (err) {
    handleError(err);
    dispatch(setEmployeesList(null));
  } finally {
    dispatch(setEmployeesLoading(false));
  }
};

export const saveEmployee = (params, callback) => async (dispatch) => {
  try {
    dispatch(setEmployeesSaveLoading(true));

    const {
      data: { data },
    } = await axios.post(baseUrl, params);

    dispatch(params.id ? changeEmployee(data) : addEmployee(data));
    handleSuccess(`Сотрудник успешно ${params.id ? 'изменен' : 'создан'}!`);
    if (callback) callback();
  } catch (err) {
    handleError(err);
    dispatch(setEmployeesSaveLoading(false));
  } finally {
    dispatch(setEmployeesSaveLoading(false));
  }
};

export const onRemoveEmployee = (employeeId) => async (dispatch) => {
  try {
    dispatch(setEmployeesRemoveLoading(true));

    await axios.delete(`${baseUrl}/${employeeId}`);

    dispatch(removeEmployee(employeeId));
    handleSuccess('Сотрудник успешно удален!');
  } catch (err) {
    handleError(err);
    dispatch(setEmployeesRemoveLoading(false));
  } finally {
    dispatch(setEmployeesRemoveLoading(false));
  }
};
