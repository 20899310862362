/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import cn from 'classnames';

import Notification from 'components/rfs-ui/Notification/Notification';
import { Input, Button, Icon } from 'components/ui';
import { makeFileUrl } from 'utils/createUrl';

import css from './FileUploader.scss';

const DEFAULT_MAXSIZE = 10485760;

class FileUpload extends React.Component {
  static propTypes = {
    accept: PropTypes.arrayOf(PropTypes.string),
    buttonText: PropTypes.string,
    className: PropTypes.string,
    customUploadButton: PropTypes.node,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    label: PropTypes.string,
    maxCount: PropTypes.number,
    maxSize: PropTypes.number,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    type: PropTypes.string,
  };

  static defaultProps = {
    accept: undefined,
    buttonText: '',
    className: undefined,
    customUploadButton: undefined,
    data: undefined,
    label: '',
    maxCount: undefined,
    maxSize: DEFAULT_MAXSIZE,
    multiple: false,
    onChange: () => {},
    type: '',
  };

  get files() {
    const { multiple, data } = this.props;
    if (multiple && Array.isArray(data)) {
      return data;
    }

    return data ? [data] : [];
  }

  getIconByType = (type) => {
    switch (type) {
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
        return 'file-word';
      case 'application/pdf':
        return 'file-pdf';
      case 'image/png':
      case 'image/jpeg':
      case 'image/jpg':
        return 'file-image';
      default:
        return 'file-alt';
    }
  };

  handleFileUpload = (e) => {
    const { accept, maxSize, onChange, multiple, maxCount } = this.props;
    const { files } = e.target;
    const newFiles = multiple ? [...this.files] : [];

    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      try {
        if (multiple && maxCount && newFiles.length === maxCount) {
          const err = new Error(`Максимальное количество файлов для загрузки: ${maxCount}`);
          err.isMaxCount = true;
          throw err;
        }
        if (accept && !accept.includes(file.type)) {
          throw new Error(`Файл ${file.name} имеет недопустимое расширение и не может быть загружен`);
        }
        if (!file.size) {
          throw new Error(`Файл ${file.name} пуст`);
        }
        if (maxSize && file.size > maxSize) {
          throw new Error(`Размер файла ${file.name} превышает допустимый`);
        }
        file.uid = uniqueId('fileItem_');
        newFiles.push(file);
      } catch ({ message, isMaxCount }) {
        Notification.error({
          children: { message },
        });
        if (isMaxCount) {
          break;
        }
      }
    }

    onChange(multiple ? newFiles : newFiles[0]);
  };

  openFileBrowse = () => {
    this.input.value = null; // нужно, чтобы можно было перезалить один и тот же файл
    this.input.click();
  };

  clearInput = (idx) => {
    const { onChange, multiple } = this.props;
    const filteredFiles = multiple ? this.files.filter((_, index) => idx !== index) : [];

    if (onChange) {
      onChange(multiple ? filteredFiles : null);
    }
  };

  render() {
    const { accept, multiple, customUploadButton, label, className, onChange, type, buttonText, maxCount } = this.props;
    const isMaxCount = maxCount && this.files.length >= maxCount;
    const showAddButton = onChange && ((multiple && !isMaxCount) || !this.files.length);

    return (
      <div className={cn(css.container, className)}>
        {!!this.files.length && (
          <div className={css.filesContainer}>
            {this.files.map((file, idx) => (
              <div className={css.fileContainer} key={file.id || file.uid}>
                <div className={css.file}>
                  {file.id ? (
                    <Fragment>
                      <Icon icon={this.getIconByType(file.contentType)} size="1x" pack="fal" className={css.extension} />
                      <a href={makeFileUrl(file)} className={css.filename}>
                        {file.fileName}
                      </a>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Icon icon="file-upload" size="1x" pack="fal" className={css.extension} />
                      <span className={css.filename}>{file.name}</span>
                    </Fragment>
                  )}
                </div>
                {onChange && <Icon icon="times" size="1x" pack="fal" className={css.removeFile} onClick={() => this.clearInput(idx)} />}
              </div>
            ))}
          </div>
        )}
        {showAddButton &&
          (customUploadButton ? (
            <div className={cn(css.customUploadButton)}>
              {React.cloneElement(customUploadButton, { onClick: this.openFileBrowse })}
              {label && <span className={css.uploadText}>{label}</span>}
            </div>
          ) : (
            <div>
              {type === 'string' ? (
                <div onClick={this.openFileBrowse} className={css.stringButton}>
                  <Icon icon="plus-circle" />
                  {buttonText}
                </div>
              ) : (
                <Button type="secondary" className={css.uploadButton} onClick={this.openFileBrowse}>
                  Выберите файл
                </Button>
              )}
              {label && <span className={css.uploadText}>{label}</span>}
            </div>
          ))}
        <Input
          type="file"
          onChange={this.handleFileUpload}
          className={css.inputUploader}
          refer={(input) => {
            this.input = input;
          }}
          multiple={multiple}
          accept={accept.join(', ')}
        />
      </div>
    );
  }
}

export default FileUpload;
