import React from 'react';
import { shape, func, bool, oneOfType, string, node, number } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkSessionStatus, checkVersion } from 'redux/modules/session/session';
import Preloader from 'components/ui/Preloader/Preloader';

const PTActions = {
  checkSessionStatus: func.isRequired,
  checkVersion: func.isRequired,
};

class SessionProvider extends React.Component {
  static propTypes = {
    actions: shape(PTActions).isRequired,
    children: oneOfType([string, node, number]),
    loading: bool.isRequired,
    router: shape().isRequired,
  };

  static defaultProps = {
    children: undefined,
  };

  componentDidMount() {
    const { actions, router } = this.props;
    actions.checkVersion();

    const shouldCheck = !router.location.pathname.startsWith('/email') && router.location.pathname.indexOf('/password') < 0;

    if (shouldCheck) {
      actions.checkSessionStatus();
    }
  }

  render() {
    const { children, loading, router } = this.props;
    const { pathname } = router.location;

    if (
      pathname === '/' ||
      pathname === '/login' ||
      pathname === '/registration' ||
      pathname.startsWith('/password') ||
      pathname.startsWith('/email') ||
      !loading
    ) {
      return children;
    }

    return <Preloader size="8x" />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      checkSessionStatus,
      checkVersion,
    },
    dispatch,
  ),
});
const mapStateToProps = (state) => ({
  loading: state.core.session.loading,
  router: state.router,
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionProvider);
