import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/ui/Modal/Modal';
import cn from 'classnames';
import styles from './Dialog.scss';

const Dialog = ({ children, actions, className }) => (
  <Modal className={cn(styles.dialog, { [className]: className })}>
    {children}
    {actions}
  </Modal>
);

Dialog.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.node,
  className: PropTypes.string,
};

export default Dialog;
