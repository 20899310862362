import { createSelector } from 'reselect';

const selectViewState = (state) => state.legal.view;

export const selectLegalData = createSelector(selectViewState, (state) => state.data);
export const selectLegalLoading = createSelector(selectViewState, (state) => state.loading);

export const selectInfrastructuresItems = createSelector(selectViewState, (state) => state.infrastructures.items);
export const selectInfrastructuresLoaded = createSelector(selectViewState, (state) => state.infrastructures.loaded);
export const selectInfrastructuresLoading = createSelector(selectViewState, (state) => state.infrastructures.loading);
export const selectInfrastructuresPagination = createSelector(selectViewState, (state) => state.infrastructures.pagination);

export const selectEmployeesFilters = createSelector(selectViewState, (state) => state.employees.filters);
export const selectEmployeesItems = createSelector(selectViewState, (state) => state.employees.items);
export const selectEmployeesLoaded = createSelector(selectViewState, (state) => state.employees.loaded);
export const selectEmployeesLoading = createSelector(selectViewState, (state) => state.employees.loading);
export const selectEmployeesPagination = createSelector(selectViewState, (state) => state.employees.pagination);

export const selectConstitutors = createSelector(selectViewState, (state) => state.constitutors);
export const selectDocuments = createSelector(selectViewState, (state) => state.documents);

export const selectHistoryItems = createSelector(selectViewState, (state) => state.history.items);
export const selectHistoryPageData = createSelector(selectViewState, (state) => state.history.pageData);
export const selectHistoryLoaded = createSelector(selectViewState, (state) => state.history.loaded);
export const selectHistoryLoading = createSelector(selectViewState, (state) => state.history.loading);
