import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import css from './Divider.scss';

const Divider = memo(({ noMargin }) => {
  const classNames = cn({
    [css.divider]: true,
    [css.noMargin]: noMargin,
  });

  return <div className={classNames} />;
});

Divider.propTypes = {
  noMargin: PropTypes.bool,
};

Divider.defaultProps = {
  noMargin: false,
};

export default Divider;
