import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ id, className, name, value, onChange, checked, disabled, defaultChecked }) => (
  <input
    id={id}
    type="checkbox"
    className={className}
    name={name}
    value={value}
    checked={checked}
    onChange={onChange}
    defaultChecked={defaultChecked}
    disabled={disabled}
  />
);

Checkbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
};

export default Checkbox;
