import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Label.scss';

const cn = classNames.bind(styles);

const Label = ({ className, required, disabled, htmlFor, children }) => {
  const labelClass = useMemo(() => cn(styles.label, className, { disabled, required }), [disabled, required, className]);

  return (
    <label className={labelClass} htmlFor={htmlFor}>
      {children}
    </label>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  htmlFor: PropTypes.string,
  required: PropTypes.bool,
};

Label.defaultProps = {
  children: null,
  className: undefined,
  disabled: false,
  htmlFor: undefined,
  required: false,
};

export default React.memo(Label);
