import React, { useMemo, useContext, useCallback } from 'react';
import { checkPermissions } from 'magic/permissions';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import AccessDenied from './AccessDenied';
import PermissionsContext from './PermissionsContext';

/**
 * Управляет отображением других компонентов в зависимости от наличия у пользователя прав.
 * @param {number= || string=} permissionKey - id организации, для которой проводится проверка.
 * @param {array} permissions - Массив прав, необходимых для доступа.
 * @param {string} mode - Позволяет указать каким образом обрабатывается отказ:
 * hide: При отказе дочерние компоненты не выводятся вообще.
 * replace: При отказе дочерние компоненты заменяются другим компонентом (replaceComponent)
 * redirect: При отказе происходит перенаправление на другой url (redirectPath)
 * render: Принимает на вход render функцию с параметром hasPermissions
 * @param {string=} rejectText - Текст, выводимый в компонент по умолчанию в режиме "replace".
 * @param {string=} render - render функция, используемая в режиме "render".
 * @param {string} redirectPath - URL на который просиходит перенаправление в режиме "redirect".
 * @param {element=} replaceComponent - Компонент, который будет выводиться при отказе в режиме "replace".
 * @param {boolean=} additionalCondition - Дополнительное условие, требующееся для показа компонента.
 * @param {boolean=} oneOf - дополнительное условие, ищет хотя бы одно совпадение в правах. Для отрисовки компонентов с вложенными консьюмерами.
 */
const PermissionsConsumer = ({
  permissionKey,
  permissions,
  children,
  some,
  oneOf,
  mode,
  rejectText,
  render,
  redirectPath,
  additionalCondition,
  replaceComponent,
}) => {
  const userPermissions = useContext(PermissionsContext);

  const rejectComponent = useMemo(() => {
    switch (mode) {
      case 'hide':
        return null;
      case 'redirect':
        return <Redirect to={redirectPath} />;
      case 'replace':
        return replaceComponent || <AccessDenied text={rejectText} />;
      default:
        return null;
    }
  }, [mode, redirectPath, rejectText, replaceComponent]);

  const handleCheckPermissions = useCallback(
    ({ some: _some, oneOf: _oneOf, permissionKey: _permissionKey, permissions: _permissions }) =>
      checkPermissions(_some, _oneOf, _permissionKey, userPermissions, _permissions),
    [userPermissions],
  );

  const hasPermissions = useMemo(() => checkPermissions(some, oneOf, permissionKey, userPermissions, permissions), [
    permissionKey,
    userPermissions,
    permissions,
    some,
    oneOf,
  ]);

  const canRender = hasPermissions && additionalCondition;

  if (mode === 'render') {
    return render(canRender, userPermissions, handleCheckPermissions);
  }

  return canRender ? children : rejectComponent;
};

PermissionsConsumer.defaultProps = {
  additionalCondition: true,
  children: null,
  mode: 'hide',
  oneOf: false,
  permissionKey: 'global',
  permissions: [],
  redirectPath: '/',
  rejectText: 'У вас недостаточно прав.',
  render: () => {},
  replaceComponent: undefined,
  some: false,
};

PermissionsConsumer.propTypes = {
  additionalCondition: PropTypes.bool,
  children: PropTypes.node,
  mode: PropTypes.string,
  oneOf: PropTypes.bool,
  permissionKey: PropTypes.any,
  permissions: PropTypes.arrayOf(PropTypes.string),
  redirectPath: PropTypes.string,
  rejectText: PropTypes.string,
  render: PropTypes.func,
  replaceComponent: PropTypes.element,
  some: PropTypes.bool,
};

export default PermissionsConsumer;
