import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ReactDropzone from 'react-dropzone';

import Notification from 'components/rfs-ui/Notification/Notification';
import { Avatar, Button, Preloader } from 'components/ui';
import { makeImageUrl } from 'utils/createUrl';
import { isEmpty } from 'utils/lodash';

import styles from './Dropzone.scss';

const cx = classNames.bind(styles);

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    if (file && file.type) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    }
  }).catch((e) => console.log('e', e));

/**
 * Пока пришлось поотключать все фишки, не связанные с формой
 */
export default class Dropzone extends React.PureComponent {
  static propTypes = {
    circle: PropTypes.bool,
    className: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    disabled: PropTypes.bool,
    hint: PropTypes.string,
    imageSize: PropTypes.number,
    isBase64: PropTypes.bool,
    isLogo: PropTypes.bool,
    label: PropTypes.string,
    maxCount: PropTypes.number,
    multiple: PropTypes.bool,
    onDataChange: PropTypes.func,
  };

  state = {
    imagesTest: [],
    uploading: false,
  };

  componentDidMount() {
    this.initImages();
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (prevProps.data !== data) {
      this.initImages();
    }
  }

  initImages = async () => {
    const { data, isBase64 } = this.props;
    let images = Array.isArray(data) ? data : [data];
    if (images.length && data) {
      if (isBase64) {
        images = await Promise.all(
          images.map(async (file) => ({
            file,
            src: await toBase64(file),
          })),
        );
      }

      this.setState({ imagesTest: images });
    } else {
      this.setState({ imagesTest: [] });
    }
  };

  /**
   * Загружает файл на сервер.
   * Написал через fetch, т.к. на момент написания, gp-api не поддерживает кастомные request headers.
   * TODO Переписать на APIService когда будет поддержка.
   *
   * @param {file} file
   */
  upload = async (file) => file;

  /**
   * Обрабатывает загруженные изображения и записывает их в стейт
   *
   * @param {array} accepted - массив загруженных файлов
   * @param {array} rejected - массив отклоненных файлов
   */
  addItems = async (accepted, rejected) => {
    this.setState({ uploading: true });
    const { maxCount = 1, data = [], multiple, onDataChange } = this.props;

    if (!isEmpty(accepted)) {
      const slicedArray = accepted.slice(0, maxCount);
      const images = await Promise.all(slicedArray.map((image) => this.upload(image)));

      onDataChange(multiple ? [...data, ...images] : images);
    }

    if (!isEmpty(rejected)) {
      const slicedArray = rejected.slice(0, maxCount);

      slicedArray.forEach((file) => {
        const fileSize = file.size / 1048576; // размер файла в Мегабайтах
        Notification.error({
          children: {
            message: `Файл ${file.name} (${fileSize.toFixed(2)} Мбайт) не удовлетворяет требованиям для загрузки файлов.\n
                      Размер загружаемого файл не должен превышать 4 Мбайт.`,
          },
        });
      });
    }

    this.setState({ uploading: false });
  };

  /**
   * Вызов диалога выбора файлов
   */
  openDialog = (e) => {
    e.stopPropagation();
    this.dropzoneNode.open();
  };

  /**
   * Очистка картинки
   */
  clearImage = (id) => (e) => {
    e.stopPropagation();
    const { onDataChange, data, isBase64 } = this.props;
    const images = Array.isArray(data) ? data : [data];
    const filteredImages = images.filter((image) => (isBase64 ? image.name !== id : image.id !== id));

    onDataChange(!isEmpty(filteredImages) ? filteredImages : undefined);
  };

  renderImages = (images) => {
    const {
      circle = true, // окружность иконки
      imageSize = 140, // размер картинки Npx X Npx
      isLogo,
      isBase64,
    } = this.props;
    const { uploading } = this.state;

    if (isBase64) {
      return images.map(({ file, src }, index) => (
        <Avatar
          circle={circle}
          isLoading={uploading}
          size={imageSize}
          imageBase64={src}
          onClick={this.clearImage(file ? file.name : '')}
          overlayIcon="trash-alt"
          isLogo={isLogo}
          key={file ? file.name + index : index + 1}
        />
      ));
    }

    return images.map((image) => (
      <Avatar
        circle={circle}
        isLoading={uploading}
        size={imageSize}
        image={image.id ? makeImageUrl(image) : image.preview}
        onClick={this.clearImage(image.id)}
        overlayIcon="trash-alt"
        isLogo={isLogo}
        key={image.id}
      />
    ));
  };

  render() {
    const {
      disabled, // активность поля
      className, // внешний класс
      circle = true, // окружность иконки
      imageSize = 140, // размер картинки Npx X Npx
      label = 'Выберите',
      multiple,
      hint = 'JPG или PNG не больше 4 Мбайт.',
      maxCount = 1,
    } = this.props;

    const { uploading, imagesTest } = this.state;
    const moreThanMax = imagesTest.length === maxCount;
    const classes = cx({
      [className]: !!className,
      disabled,
      dropzone: true,
    });
    return (
      <div>
        <ReactDropzone
          ref={(node) => {
            this.dropzoneNode = node;
          }}
          onDrop={this.addItems}
          className={classes}
          disabled={disabled || moreThanMax}
          maxSize={4194304}
          accept="image/jpeg, image/png"
          multiple={multiple}
        >
          {uploading ? (
            <Preloader size="8x" className={styles.preloader} />
          ) : (
            <div className={styles.imageContainer}>
              {imagesTest && imagesTest.length ? (
                this.renderImages(imagesTest)
              ) : (
                <Avatar circle={circle} size={imageSize} onClick={this.openDialog} />
              )}
            </div>
          )}
          {!label && <div className={styles.hint}>Перетащите сюда или</div>}
          <Button className={styles.button} onClick={this.openDialog} disabled={disabled || moreThanMax} type="secondary">
            {label}
          </Button>
          <div className={styles.hint}>{hint}</div>
        </ReactDropzone>
      </div>
    );
  }
}
