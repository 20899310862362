import { createReducer } from 'redux-act';
import qs from 'qs';
import actionsFactory from 'magic/actionsFactory';
import { push } from 'connected-react-router';
import Notification from 'components/rfs-ui/Notification/Notification';
import { handleError } from 'magic/handlers';
import axios from 'axios';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import negate from 'lodash/negate';
import { ORGANIZATION_TYPES } from 'constants/organizationTypes';
import { ROUTES_WAYS } from 'constants/routesWays';
import { uploadImage } from 'utils/uploadFiles';
import { getAddressInfo, addressRestructure, getAddressLocations } from 'utils/dadata';
import { dataReStructure } from '../utils';
import { getOrganizationInfo } from '../View/reducer';
import { restructureData, restructureDataForEdit } from '../Add/utils';
import { transliterateCyrillicText } from 'utils/text';

const createAction = actionsFactory('organization/edit/');

const initialState = {
  data: {},
  initialData: {},
  forbidden: false,
  inProgress: false,
};

// actions
export const setForbidden = createAction('setForbidden');
export const setOrganizationInfo = createAction('setOrganizationInfo');
export const setLoading = createAction('setLoading');
export const updateData = createAction('updateData');
export const clearData = createAction('clearData');

/**
 * Получаем информацию об организации
 *
 * @return {Array}
 */
export const getOrganizationInfoEdit = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/rest/organization/${id}`);
    const organization = res.data.data;

    if (organization.status === 'DUPLICATE') {
      throw Error();
    }

    const head = {};
    const mainInfo = {};

    Object.entries(organization).forEach(([key, value]) => {
      if (key === 'head') {
        head.flat = { ...value };
      }

      mainInfo[key] = value;
    });

    if (organization.type.discriminator === ORGANIZATION_TYPES.SCHOOL && (organization.isFirstClassAcademy || organization.isAcademy)) {
      mainInfo.isAcademyCheck = true;
    }

    const payload = { ...head.flat, ...mainInfo };

    dispatch(setOrganizationInfo(payload));
  } catch (e) {
    const status = get(e, 'response');

    if (status === 403) {
      dispatch(setForbidden());
    } else {
      handleError(e);
      if (status === 404) {
        dispatch(push(ROUTES_WAYS.SEARCH_ORGANIZATION));
      }
    }
  }
};

/**
 * Сохранить отредактированную инфу по организации
 *
 * @param {string} orgType тип организации
 */
export const editOrganization = (orgType, urlEnd) => async (dispatch, getState) => {
  dispatch(setLoading(true));

  try {
    const { data } = getState().organization.edit;

    const queryParams = qs.stringify({ organizationId: data.id }, { addQueryPrefix: true });

    const allMembers = reduce(
      data.members,
      (accumulator, memberValue, memberKey) => {
        if (isEmpty(memberValue)) {
          return accumulator;
        }

        const members = memberValue.map((member) => {
          const objectId = (() => {
            switch (memberKey) {
              case 'profile':
                return member.id;
              case 'legal':
                return member.objectId;

              default:
                return member.objectId || member.id;
            }
          })();

          return {
            objectId,
            type: memberKey,
          };
        });

        return [...accumulator, ...members];
      },
      [],
    );

    if (negate(isEmpty)(allMembers)) {
      await axios({
        data: {
          members: allMembers,
        },
        method: 'post',
        url: `/api/rest/organization/members/massadd${queryParams}`,
      });

      Notification.success({
        children: {
          icon: 'check',
          message: 'Члены организации добавлены!',
        },
      });
    }

    const body = dataReStructure(data, orgType);

    if (body.address) {
      const dadataObj = await getAddressInfo(body.address.value, getAddressLocations(body.address));

      if (dadataObj) {
        body.address = addressRestructure(dadataObj);
      } else if (body.address.data) {
        body.address = addressRestructure(body.address);
      }
    }

    const legShortName = body.legal?.ogrn
      ? body.legal?.shortName + ', ' + body.legal?.ogrn || body.legal?.fullName + ', ' + body.legal?.ogrn
      : body.legal?.shortName || body.legal?.fullName;
    const legShortNameEn =
      body.legal?.shortNameEng ||
      (body.legal?.shortName && transliterateCyrillicText(body.legal?.shortName)) ||
      (body.legal?.fullName && transliterateCyrillicText(body.legal?.fullName));

    body.logotip = await uploadImage(body.logotip);
    await axios.post(
      `/api/rest/organization/${urlEnd || orgType}`,
      urlEnd
        ? restructureDataForEdit(body)
        : {
            ...body,
            legShortName: body.legal?.id ? legShortName : body.fullName || '',
            legShortNameEn: body.legal?.id ? legShortNameEn : body.fullName && transliterateCyrillicText(body.fullName),
          },
    );

    Notification.success({
      children: {
        icon: 'check',
        message: 'Организация отредактирована!',
      },
    });

    dispatch(getOrganizationInfo(data.id));

    dispatch(setLoading(false));
    return true;
  } catch (e) {
    handleError(e);
    dispatch(setLoading(false));
  }
};

// reducer
const reducer = createReducer(
  {
    [clearData]: (state, payload) => {
      if (payload) {
        return {
          ...state,
          [payload]: null,
        };
      }

      return initialState;
    },
    [setForbidden]: (state) => ({
      ...state,
      forbidden: true,
    }),
    [setLoading]: (state, inProgress) => ({
      ...state,
      inProgress,
    }),
    [setOrganizationInfo]: (state, data) => ({
      ...state,
      data: { ...data, isAcademyCheck: data.isAcademy || data.isFirstClassAcademy },
      initialData: { ...data, isAcademyCheck: data.isAcademy || data.isFirstClassAcademy },
      inProgress: false,
    }),
    [updateData]: (state, payload) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),
  },
  initialState,
);

export default reducer;
