import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactPaginate from 'react-paginate';

import Icon from 'components/ui/Icon/Icon';
import Button from 'components/ui/Button/Button';
import { noop } from 'utils/lodash';

import styles from './Pagination.scss';

class Pagination extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    currentPage: PropTypes.number.isRequired,
    hasItemsPerPageController: PropTypes.bool,
    itemsCount: PropTypes.number.isRequired,
    minItemsPerPage: PropTypes.number,
    multiplier: PropTypes.number,
    onItemsPerPageChange: PropTypes.func,
    onPageChange: PropTypes.func,
    pageCount: PropTypes.number.isRequired,
    pagesCounter: PropTypes.arrayOf(PropTypes.number, PropTypes.string),
    perPage: PropTypes.number.isRequired,
    showAll: PropTypes.bool,
  };

  static defaultProps = {
    className: undefined,
    hasItemsPerPageController: true,
    minItemsPerPage: undefined,
    multiplier: 2,
    onItemsPerPageChange: undefined,
    onPageChange: noop,
    pagesCounter: undefined,
    showAll: true,
  };

  /**
   * Генерирует фильтр для отображения различного количества элементов на странице
   */
  getCountingOptions = () => {
    const { itemsCount, perPage, minItemsPerPage, multiplier, onItemsPerPageChange, pagesCounter } = this.props;
    const countArray = [];

    if (Array.isArray(pagesCounter) && pagesCounter.length) {
      return pagesCounter.reduce((accumulator, el) => {
        if (el <= itemsCount) {
          const buttonView = (
            <Button type="text" onClick={() => onItemsPerPageChange(el)} key={el}>
              {el}
            </Button>
          );

          return [...accumulator, buttonView];
        }

        return accumulator;
      }, []);
    }

    if (itemsCount && itemsCount > minItemsPerPage) {
      let possibleSortNumber = minItemsPerPage;
      let lastPossibleSortNumber = minItemsPerPage;

      while (lastPossibleSortNumber < itemsCount) {
        const perPageNumber = possibleSortNumber;
        const displayButton = (
          <Button
            type="text"
            onClick={() => onItemsPerPageChange(perPageNumber)}
            className={cn({ [styles.activeOption]: possibleSortNumber === perPage })}
            key={perPageNumber}
          >
            {possibleSortNumber}
          </Button>
        );

        countArray.push(displayButton);
        lastPossibleSortNumber = possibleSortNumber;
        possibleSortNumber *= multiplier;
      }
    }

    return countArray.length ? countArray : null;
  };

  /**
   * Генерирует надпись про отображаемое кол-во элементов от общего количества элементов
   */
  generatePageCounter = () => {
    const { currentPage, perPage, itemsCount, pageCount } = this.props;

    if (pageCount === 1) {
      return `Результатов: ${itemsCount}`;
    }

    // номер первого элемента на странице с учетом отображаемого кол-ва элементов на странице
    const firstItemNumber = currentPage === 0 ? 1 : currentPage * perPage + 1;
    // номер второго элемента на странице с учетом отображаемого кол-ва элементов на странице
    const lastItemNumber = currentPage + 1 !== pageCount ? (currentPage + 1) * perPage : itemsCount;

    return `Результатов: ${firstItemNumber} - ${lastItemNumber} из ${itemsCount}`;
  };

  handleChangePage = (data) => {
    const { onPageChange } = this.props;
    onPageChange(data.selected);
  };

  render() {
    const {
      itemsCount, // общее количество элементов (required)
      pageCount, // количество страниц (required)
      perPage, // отображаемое количество элементов на странице (required)
      currentPage, // номер текущей страницы (required)
      className, // внешний класс
      onItemsPerPageChange, // событие для смены количества отображаемых элементов на странице
      minItemsPerPage, // минимальное количество элементов на странице,
      hasItemsPerPageController, // наличие контроллера количества отображаемых элементов на странице
      showAll,
    } = this.props;

    const showItemsPerPageController = onItemsPerPageChange && minItemsPerPage && itemsCount > minItemsPerPage;
    const showPageController = pageCount > 1;
    const showPerPageController = showItemsPerPageController && hasItemsPerPageController;

    return (
      <div className={cn(styles.container, className, { [styles.rightSide]: !showPerPageController })}>
        {showPerPageController ? (
          <div className={styles.itemsPerPageContainer}>
            <span className={styles.title}>Показать: </span>
            {this.getCountingOptions()}
            {showAll && (
              <Button
                type="text"
                onClick={() => onItemsPerPageChange(itemsCount)}
                className={cn({ [styles.activeOption]: perPage === itemsCount })}
                key={itemsCount}
              >
                Все
              </Button>
            )}
          </div>
        ) : (
          <div />
        )}
        <div className={styles.paginationContainer}>
          <div className={styles.pageCountLabel}>{this.generatePageCounter()}</div>
          {showPageController && (
            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              previousLabel={<Icon icon="angle-left" />}
              nextLabel={<Icon icon="angle-right" />}
              forcePage={currentPage}
              onPageChange={this.handleChangePage}
              containerClassName={styles.paginator}
              activeClassName={styles.activePage}
              disabledClassName={styles.disabledControl}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Pagination;
