import { createReducer } from 'redux-act';

import {
  clearData,
  clearFormData,
  setActionTypes,
  setGeneralData,
  setPlayer,
  updateFormData,
  updateGeneralData,
} from 'redux/actions/transferAdminActions';

const initialState = {
  actionTypes: [],
  formData: {},
  generalData: {},
  player: null,
};

const transferAdminReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [clearFormData]: (state) => ({
      ...state,
      formData: { ...initialState.formData },
    }),
    [setActionTypes]: (state, actionTypes) => ({
      ...state,
      actionTypes,
    }),
    [setGeneralData]: (state, generalData) => ({
      ...state,
      generalData,
    }),
    [setPlayer]: (state, player) => ({
      ...state,
      player,
    }),
    [updateFormData]: (state, payload) => ({
      ...state,
      formData: {
        ...state.formData,
        ...payload,
      },
    }),
    [updateGeneralData]: (state, payload) => ({
      ...state,
      generalData: {
        ...state.generalData,
        ...payload,
      },
    }),
  },
  initialState,
);

export default transferAdminReducer;
