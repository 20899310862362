exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------ \nCommon\n--------------------------*/\n\n\n/* layouts width*/\n\n\n/* ------------------------ \nDark Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* input*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nLight Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nRating\n--------------------------*/\n\n\n.container-Du_hg {\n  background-color: #042b75;\n}\n\n\n.clickBar-DASH2 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  padding: 20px;\n  cursor: pointer;\n}\n\n\n.arrow-22HJ6 {\n  margin-right: 20px;\n  width: 20px !important;\n  -webkit-transition: .3s;\n  transition: .3s;\n}\n\n\n.arrow-22HJ6.reverse-3lk_U {\n    -webkit-transform: rotateX(180deg);\n            transform: rotateX(180deg);\n    -webkit-transition: .3s;\n    transition: .3s;\n  }\n\n\n.title-1qhFo {\n  margin-bottom: 0;\n}\n\n\n.content-7TPde {\n  padding: 0 60px 20px;\n}\n\n\n.theme-dark .container-Du_hg {\n    background-color: #042b75;\n  }\n\n\n.theme-light .container-Du_hg {\n    background-color: #fff;\n  }\n", ""]);

// exports
exports.locals = {
	"container": "container-Du_hg",
	"clickBar": "clickBar-DASH2",
	"arrow": "arrow-22HJ6",
	"reverse": "reverse-3lk_U",
	"title": "title-1qhFo",
	"content": "content-7TPde"
};