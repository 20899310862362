import React from 'react';
import axios from 'axios'
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import Preloader from 'components/ui/Preloader/Preloader';
import { ROUTES_WAYS } from 'constants/routesWays';

export class PrivateRoute extends React.Component {
  static propTypes = {
    component: PropTypes.elementType.isRequired,
    keyParam: PropTypes.string,
    loginInProgress: PropTypes.bool,
    user: PropTypes.object,
  };

  static defaultProps = {
    keyParam: undefined,
    loginInProgress: false,
    user: undefined,
  };

  renderRoute = (routeProps) => {
    const { component: WrappedComponent, keyParam } = this.props;
    const key = keyParam ? routeProps.match.params[keyParam] : undefined;
    return <WrappedComponent {...routeProps} key={key} />;
  };

  render() {
    const { component, keyParam, loginInProgress, user, ...rest } = this.props;

    if (loginInProgress && !user) {
      return <Preloader size="8x" />;
    }

    if (!user) {
      return <Redirect to={ROUTES_WAYS.LOGIN} />;
    }

    return <Route {...rest} render={this.renderRoute} />;
  }
}
