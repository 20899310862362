import { number, shape, arrayOf, string } from 'prop-types';

import { PTAddress } from './addressPropTypes';
import { PTTelephone } from './telephonePropTypes';
import { PTOrganization } from './organizationPropTypes';

/**
 * @description Юридическое лицо
 */
export const PTLegal = {
  /**
   * @description Дата регистрации
   */
  dateRegistr: number,

  /**
   * @description Дата актуальности сведений
   */
  dateUpdate: number,
  /**
   * @description Форма собственности
   */
  forma: string.isRequired,
  /**
   * @description Полное наименовние
   */
  fullName: string.isRequired,
  /**
   * @description Идентификатор
   */
  id: number,
  /**
   * @description ИНН
   */
  inn: string,
  /**
   * @description КПП
   */
  kpp: string,
  legaladdress: shape(PTAddress),
  /**
   * @description Примечание
   */
  note: string,
  /**
   * @description ОГРН
   */
  ogrn: string,
  /**
   * @description ОКПО
   */
  okpo: string,

  organizations: arrayOf(shape(PTOrganization)),
  postaladdress: shape(PTAddress),
  /**
   * @description Сокращенное наименовние
   */
  shortName: string,
  /**
   * @description Статус организации
   */
  status: string,
  telephones: arrayOf(PTTelephone),
  /**
   * @description Идентификатор с фронта
   */
  tempId: string,
};
