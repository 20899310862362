import { createReducer } from 'redux-act';
import { push, replace } from 'connected-react-router';
import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError } from 'magic/handlers';

import { FORBIDDEN } from 'constants/statusCodes';
import { uploadFile } from 'utils/uploadFiles';
import { STATEMENT_STATUSES, STATEMENT_TYPES } from '../constants';

const createAction = actionsFactory('applications/transfer/list/');

const initialState = {
  incomings: {
    all: {
      items: {},
    },
    fresh: {},
    freshPagination: {},
    pagination: {},
  },
  loading: false,
  outcomings: {
    all: {
      items: {},
    },
    fresh: {},
    pagination: {},
  },
  requestInfo: {},
};

export const setNewIncomingRequests = createAction('setNewIncomingRequests');
export const setAllIncomingRequests = createAction('setAllIncomingRequests');
export const setIncomingPagination = createAction('setIncomingPagination');
export const setNewOutcomingRequests = createAction('setNewOutcomingRequests');
export const setAllOutcomingRequests = createAction('setAllOutcomingRequests');
export const setOutcomingPagination = createAction('setOutcomingPagination');
export const setLoading = createAction('setLoading');
export const setRequestInfo = createAction('setRequestInfo');
export const setRequestStatus = createAction('setRequestStatus');
export const clearData = createAction('clearData');

export const getNewIncomingRequests = (page, pagesize) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const params = { page, pagesize, type: STATEMENT_TYPES.NEW };
    const fresh = await axios.get('/api/rest/application/v2/incoming/statements', { params });
    dispatch(setNewIncomingRequests(fresh.data));
  } catch (e) {
    handleError(e);
    dispatch(setLoading(false));
  }
};

export const getAllIncomingRequests = (page, pagesize) => async (dispatch) => {
  try {
    const params = { page, pagesize, type: STATEMENT_TYPES.ALL };
    const requests = await axios.get('/api/rest/application/v2/incoming/statements', { params });
    dispatch(setAllIncomingRequests(requests.data.data));
    dispatch(setIncomingPagination(requests.data.pageData));
  } catch (e) {
    handleError(e);
  }
};

export const getNewOutcomingRequests = (page, pagesize) => async (dispatch) => {
  try {
    const params = { page, pagesize, type: STATEMENT_TYPES.NEW };
    const fresh = await axios.get('/api/rest/application/v2/outbox/statements', { params });
    dispatch(setNewOutcomingRequests(fresh.data.data));
  } catch (e) {
    handleError(e);
  }
};

export const getAllOutcomingRequests = (page, pagesize) => async (dispatch) => {
  try {
    const params = { page, pagesize, type: STATEMENT_TYPES.ALL };
    const all = await axios.get('/api/rest/application/v2/outbox/statements', { params });
    dispatch(setAllOutcomingRequests(all.data.data));
    dispatch(setOutcomingPagination(all.data.pageData));
  } catch (e) {
    handleError(e);
  }
};

/**
 * @deprecated do not use
 */
export const acceptRequest = (body, backUrl) => async (dispatch) => {
  try {
    await axios.post('/api/rest/process/confirm/registration/statement', body);
    if (backUrl) {
      dispatch(push(backUrl));
    }
  } catch (e) {
    handleError(e);
  }
};

/**
 * @deprecated do not use
 */
export const changeRequestStatus = (statementId, status, backUrl) => async (dispatch) => {
  try {
    await axios.post('/api/rest/process/statement/status/change', {
      statementId,
      status,
    });
    if (backUrl) {
      dispatch(push(backUrl));
    } else {
      dispatch(setRequestStatus(status));
    }
  } catch (err) {
    handleError(err);
  }
};

export const processStatementWait = (id, route) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/rest/application/v2/removal/wait/${id}`, null);

    if (route) {
      const newId = response.data.data;
      dispatch(push(route.replace(':id', newId)));
    } else {
      dispatch(setRequestStatus(STATEMENT_STATUSES.REVIEWING));
    }
  } catch (err) {
    handleError(err);
  }
};

export const getRequestInfo = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.get(`/api/rest/application/v2/statement/${id}`);
    dispatch(setRequestInfo(response.data.data));
  } catch (e) {
    handleError(e);
    const { status } = e.response;
    if (status === FORBIDDEN) {
      dispatch(replace('/requests'));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const processRequest = (params, backUrl) => async (dispatch) => {
  const { statementId, status, ...data } = params;
  try {
    if (Array.isArray(data.documents)) {
      data.documents = await Promise.all(data.documents.map((doc) => (doc instanceof File ? uploadFile(doc) : doc)));
    }
    if (status === STATEMENT_STATUSES.REVIEWING) {
      await axios.post(`/api/rest/application/v2/removal/wait/process/${statementId}`, data);
    }
    if (status === STATEMENT_STATUSES.REVIEWING_RFS) {
      await axios.post(`/api/rest/application/v2/removal/wait/process/rfs/${statementId}`, data);
    }

    if (backUrl) {
      dispatch(push(backUrl));
    } else {
      dispatch(getRequestInfo(statementId));
    }
  } catch (err) {
    handleError(err);
  }
};

// reducer
const reducer = createReducer(
  {
    [clearData]: () => initialState,
    [setAllIncomingRequests]: (state, items) => ({
      ...state,
      incomings: {
        ...state.incomings,
        all: {
          ...state.all,
          items,
        },
      },
      loading: false,
    }),
    [setAllOutcomingRequests]: (state, items) => ({
      ...state,
      loading: false,
      outcomings: {
        ...state.outcomings,
        all: {
          ...state.all,
          items,
        },
      },
    }),
    [setIncomingPagination]: (state, payload) => ({
      ...state,
      incomings: {
        ...state.incomings,
        pagination: {
          itemsCount: payload.size,
          page: payload.numberPage,
          pageCount: payload.countPage,
          pageSize: payload.sizePage,
          perPage: payload.sizePage,
        },
      },
    }),
    [setLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
    [setNewIncomingRequests]: (state, { data: fresh, pageData }) => ({
      ...state,
      incomings: {
        ...state.incomings,
        fresh,
        freshPagination: {
          itemsCount: pageData.size,
          page: pageData.numberPage,
          pageCount: pageData.countPage,
          pageSize: pageData.sizePage,
          perPage: pageData.sizePage,
        },
      },
      loading: false,
    }),
    [setNewOutcomingRequests]: (state, fresh) => ({
      ...state,
      loading: false,
      outcomings: {
        ...state.outcomings,
        fresh,
      },
    }),
    [setOutcomingPagination]: (state, payload) => ({
      ...state,
      outcomings: {
        ...state.outcomings,
        pagination: {
          itemsCount: payload.size,
          page: payload.numberPage,
          pageCount: payload.countPage,
          pageSize: payload.sizePage,
          perPage: payload.sizePage,
        },
      },
    }),
    [setRequestInfo]: (state, requestInfo) => ({
      ...state,
      requestInfo,
    }),
    [setRequestStatus]: ({ requestInfo, ...state }, status) => ({
      ...state,
      requestInfo: {
        ...requestInfo,
        status,
      },
    }),
  },
  initialState,
);

export default reducer;
