import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { sortByKey } from 'utils/arrays';

const createAction = actionsFactory('payConstructor/compensations/');

export const setCompensationsByPayment = createAction('setCompensationsByPayment');
export const setCompensationsCategoryDepended = createAction('setCompensationsCategoryDepended');
export const setCompensationsCategoryFactors = createAction('setCompensationsCategoryFactors');
export const clearData = createAction('clearData');

export const getCompensationsByPayment = (paymentId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/rest/costcalculation/payouttype/${paymentId}`);
    const data = {
      ...response.data.data,
      allocations: sortByKey(response.data.data.allocations, 'year'),
    };
    dispatch(setCompensationsByPayment({ data, paymentId }));
  } catch (err) {
    handleError(err);
    dispatch(setCompensationsByPayment({ data: [], paymentId }));
  }
};

export const updateCategoryDepended = (data, paymentId) => async (dispatch) => {
  try {
    const params = { enabled: data };
    await axios.post(`/api/rest/costcalculation/payouttype/${paymentId}/categoryDependentFactor`, null, { params });
    handleSuccess('Настройки компенсации успешно изменены!');

    dispatch(setCompensationsCategoryDepended({ data, paymentId }));
  } catch (err) {
    handleError(err);
  }
};

export const updateCategoryInfo = (coefficients, paymentId) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.post(`/api/rest/costcalculation/payouttype/${paymentId}/categoryfactor/save`, coefficients);
    handleSuccess('Настройки коэффициентов в зависимости от категорий успешно изменены!');

    dispatch(setCompensationsCategoryFactors({ data, paymentId }));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const saveCompensation = ({ paymentId, ...data }) => async (dispatch) => {
  try {
    await axios.post(`/api/rest/costcalculation/payouttype/${paymentId}/allocation/add`, data);
    await dispatch(getCompensationsByPayment(paymentId));
    handleSuccess('Выплата успешно добавлена');
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const deleteCompensation = (id, paymentId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/costcalculation/payoutallocation/${id}`);
    await dispatch(getCompensationsByPayment(paymentId));
    handleSuccess('Выплата успешно удалена', 'trash');
  } catch (err) {
    handleError(err);
  }
};
