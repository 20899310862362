exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------ \nCommon\n--------------------------*/\n\n\n/* layouts width*/\n\n\n/* ------------------------ \nDark Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* input*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nLight Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nRating\n--------------------------*/\n\n\n.contentContainer-3ILGv {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    -webkit-box-pack: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    -webkit-box-align: start;\n        -ms-flex-align: start;\n            align-items: flex-start;\n  }\n\n\n.checkbox-PyhqB {\n    margin-bottom: 20px;\n  }\n\n\n.container-1zt9H {\n    border: #042b75;\n    background-color: #042b75;\n    padding: 20px;\n  }\n\n\n.container-1zt9H:not(:last-child) {\n      margin-bottom: 20px;\n    }\n\n\n.title-10rza {\n    margin-bottom: 20px;\n  }", ""]);

// exports
exports.locals = {
	"contentContainer": "contentContainer-3ILGv",
	"checkbox": "checkbox-PyhqB",
	"container": "container-1zt9H",
	"title": "title-10rza"
};