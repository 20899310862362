import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import { Button } from 'components/ui';

import css from './TopBarItem.scss';

const cn = classnames.bind(css);

const TopBarItem = ({ title, sysName, active, onClick }) => {
  const handleClick = useCallback(() => onClick(sysName), [onClick, sysName]);
  const classNames = useMemo(() => cn(css.item, { active }), [active]);

  return (
    <Button type="text" key={sysName} className={classNames} onClick={handleClick}>
      <span className={css.title}>{title}</span>
    </Button>
  );
};

TopBarItem.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  sysName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
};

TopBarItem.defaultProps = {
  active: false,
  sysName: undefined,
};

export default React.memo(TopBarItem);
