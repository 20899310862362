import { THEMES } from 'components/composed/ThemeContext/constants';
import { isFunction, merge } from 'utils/lodash';

const styles = {
  clearIndicator: (_, state) => {
    const { themeType } = state.selectProps;
    const textColor = themeType === THEMES.LIGHT ? '#212121' : '#fff';

    return {
      ':hover': {
        opacity: '1',
      },
      color: textColor,
      marginRight: state.options.length > 0 ? '0' : '10px',
      opacity: '0.5',
      transition: '0.3s',
    };
  },
  container: (base) => ({
    ...base,
  }),
  control: (base, state) => {
    const { isFocused, selectProps } = state;
    const { menuIsOpen, light, dark, themeType, isDisabled, noBorder, options, isRequired, isMulti, customStyles = {} } = selectProps;

    let background;
    let borderColor;
    let borderHoverColor;
    let borderActiveColor;
    let disabledBackground;
    let textColor;
    let textColorDisabled;

    if (themeType === THEMES.LIGHT) {
      background = (light && '#eaf6ff') || (dark && '#eaf6ff') || '#fff';
      borderColor = !isDisabled ? '#e8e8ec' : !light && isMulti ? '#e8e8ec' : 'transparent';
      borderHoverColor = !isDisabled ? '#1d94c1' : 'transparent';
      borderActiveColor = '#0a81ff';
      disabledBackground = '#dedfe4';
      textColor = '#212121';
      textColorDisabled = '#fff';
    } else {
      background = (light && '#083e89') || (dark && '#001240') || '#042b75';
      borderColor = !isDisabled ? '#0a81ff' : 'transparent';
      borderHoverColor = !isDisabled ? '#1fadff' : 'transparent';
      borderActiveColor = '#0a81ff';
      disabledBackground = '#373865';
      textColor = '#fff';
      textColorDisabled = '#fff';
    }

    const borderStyle = `1px solid ${borderColor}`;

    return {
      ...base,
      ':hover': {
        borderColor: borderHoverColor,
      },
      backgroundColor: isDisabled ? disabledBackground : background,
      border: noBorder ? 'none' : borderStyle,
      borderRadius: menuIsOpen && options.length ? '4px 4px 0 0' : '4px',
      boxShadow: 'none',
      boxSizing: 'border-box',
      color: isDisabled ? textColorDisabled : textColor,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      flexWrap: 'nowrap',
      height: '40px',
      transition: 'border 0.3s',
      ...customStyles.control,
    };
  },
  dropdownIndicator: (_, state) => {
    const { menuIsOpen, isLoading } = state.selectProps;
    const { hasValue, options } = state;
    const isDropdownVisible = options.length || (!hasValue && !isLoading);
    const isDropdowActive = menuIsOpen && options.length;

    return {
      display: isDropdownVisible ? 'initial' : 'none',
      opacity: isDropdowActive ? '1' : '0.5',
      transform: `rotate(${isDropdowActive ? '180deg' : '0deg'})`,
      transformOrigin: 'center',
      transition: '0.3s',
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: () => ({}),
  menu: (base, state) => {
    const { light, dark, themeType, isRequired, customStyles = {} } = state.selectProps;

    let backgroundColor;
    let borderColor;

    if (themeType === THEMES.LIGHT) {
      backgroundColor = (light && '#eaf6ff') || (dark && '#eaf6ff') || '#fff';
      borderColor = '#0a81ff';
    } else {
      backgroundColor = (light && '#083e89') || (dark && '#001240') || '#042b75';
      borderColor = isRequired ? '#0a81ff' : '#042b75';
    }

    return {
      ...base,
      backgroundColor,
      border: `1px solid ${borderColor}`,
      borderRadius: '0 0 4px 4px',
      borderTop: 'none',
      display: state.options.length > 0 ? 'initial' : 'none',
      margin: 0,
      padding: 0,
      top: '40px',
      zIndex: 2,
      ...customStyles.menu,
    };
  },
  menuList: (base, state) => {
    const { customStyles = {} } = state.selectProps;

    return {
      ...base,
      '::-webkit-scrollbar': {
        width: '10px',
      },
      '::-webkit-scrollbar-thumb': {
        ':hover': {
          backgroundColor: '#1fadff',
        },
        backgroundColor: '#0a81ff',
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
      padding: 0,
      ...customStyles.menuList,
    };
  },
  multiValue: (base, state) => {
    const { themeType, customStyles } = state.selectProps;
    const bgColor = themeType === THEMES.LIGHT ? '#eaf6ff' : '#083e89';

    if (state.selectProps.multiTight) {
      return {
        ...base,
        backgroundColor: bgColor,
        justifyContent: 'space-between',
      };
    }

    const innerStyles = {
      ...base,
      backgroundColor: bgColor,
      flex: 1,
      justifyContent: 'space-between',
      width: 0,
    };

    // Добавляем возможность передать свои стили
    // TODO: Уточнить, возможно стоит убрать дефолтную 100%-ширину
    return isFunction(customStyles?.multiValue) ? merge(innerStyles, customStyles.multiValue()) : innerStyles;
  },
  multiValueLabel: (base, state) => {
    const { themeType } = state.selectProps;
    const textColor = themeType === THEMES.LIGHT ? '#212121' : '#fff';

    return {
      ...base,
      color: textColor,
    };
  },
  multiValueRemove: (base, state) => {
    const { themeType } = state.selectProps;
    const bgColor = themeType === THEMES.LIGHT ? '#0a81ff' : '#083e89';

    return {
      ...base,
      ':hover': {
        backgroundColor: bgColor,
      },
    };
  },
  option: (_, state) => {
    const { isSelected, isFocused } = state;
    const { themeType } = state.selectProps;

    let textColor;
    let hoverColor;

    if (themeType === 'lightTheme') {
      textColor = isFocused ? '#212121' : 'rgba(33, 33, 33, 0.7)';
      hoverColor = '#1fadff';
    } else {
      textColor = isFocused ? '#fff' : 'rgba(255, 255, 255, 0.7)';
      hoverColor = '#fff';
    }

    const borderLeftColor = isSelected ? '#1fadff' : 'transparent';

    return {
      ':hover': {
        color: hoverColor,
      },
      borderLeft: `4px solid ${borderLeftColor}`,
      color: textColor,
      cursor: 'pointer',
      lineHeight: '20px',
      padding: '5px 10px 5px 6px',
      transition: '0.3s',
    };
  },
  placeholder: (base, state) => {
    const { themeType } = state.selectProps;
    const textColor = themeType === THEMES.LIGHT ? '#9196af' : '#757d96';

    return {
      ...base,
      color: textColor,
    };
  },
  singleValue: (base, state) => {
    const { themeType } = state.selectProps;
    const textColor = themeType === THEMES.LIGHT ? '#212121' : '#fff';
    return {
      color: textColor,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    };
  },
  valueContainer: (base, state) => {
    const { themeType } = state.selectProps;
    const textColor = themeType === THEMES.LIGHT ? '#212121' : '#fff';

    return {
      ...base,
      color: textColor,
      flexWrap: 'nowrap',
      height: '40px',
      padding: '0 10px',
    };
  },
};

export default styles;
