import dateFormats from 'constants/dateFormats';
import moment from 'moment';

export const parseValue = (string, type) => {
  if (type === 'number') {
    return string ? parseFloat(string) : undefined;
  }

  if (type === 'timestamp') {
    const mDate = moment.utc(string, dateFormats.SERVER_DATE_FORMAT);
    return mDate.isValid() ? mDate.valueOf() : undefined;
  }

  return string;
};

export const stringifyValue = (value, type) => {
  if (type === 'number') {
    return value || value === 0 ? String(value) : undefined;
  }

  if (type === 'timestamp') {
    return value || value === 0 ? moment.utc(value).format(dateFormats.SERVER_DATE_FORMAT) : undefined;
  }

  return value;
};
