import { createSelector } from 'reselect';

const selectEventsState = (state) => state.payConstructor.events;
const selectEventId = (_, props) => props.event?.id;

const selectEventConfigById = createSelector(selectEventsState, selectEventId, (state, eventId) => state[eventId] || {});

export const selectEventCategoryDepended = createSelector(selectEventConfigById, (state) => state.isCategoryDepended || false);
export const selectEventCategoryInfo = createSelector(selectEventConfigById, (state) => state.categoryInfo || []);
export const selectEventCombinations = createSelector(selectEventConfigById, (state) => state.combinations || []);
