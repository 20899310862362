exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".datePickerContainer-2dNIg {\n  background: #001240;\n  position: absolute;\n  z-index: 1000;\n}\n\n.DayPicker-Day--disabled {\n    opacity: 0.5;\n  }\n", ""]);

// exports
exports.locals = {
	"datePickerContainer": "datePickerContainer-2dNIg"
};