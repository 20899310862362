import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';
import { Notification } from 'components/rfs-ui';
import { handleError } from 'magic/handlers';
import { push } from 'connected-react-router';

const createAction = actionsFactory('trainingGroups/view/');

const initialState = {
  loading: true,
};

export const startLoading = createAction('startLoading');
export const stopLoadingWithError = createAction('stopLoadingWithError');
export const setData = createAction('setData');
export const clearData = createAction('clearData');

export const getDataById = (id, revision) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/rest/coachgroup/${id}`, {
      params: {
        revision: revision || undefined,
      },
    });
    dispatch(setData(response.data.data));
  } catch (e) {
    handleError(e);
    dispatch(stopLoadingWithError());
  }
};

export const deleteGroup = () => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const { id, organization = {} } = getState().trainingGroup.view;
    await axios.delete(`/api/rest/coachgroup/${id}`);
    Notification.success({
      children: {
        icon: 'trash',
        message: `Группа подготовки #${id} удалена`,
      },
    });
    dispatch(push(`/organization/${organization.id}`));
  } catch (e) {
    handleError(e);
    dispatch(stopLoadingWithError());
  }
};

const reducer = createReducer(
  {
    [clearData]: () => initialState,
    [setData]: (state, data) => ({
      ...state,
      loading: false,
      ...data,
    }),
    [startLoading]: (state) => ({
      ...state,
      loading: true,
    }),
    [stopLoadingWithError]: (state) => ({
      ...state,
      loading: false,
    }),
  },
  initialState,
);

export default reducer;
