import React, { useRef, useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDropzone from 'react-dropzone';
import classNames from 'classnames/bind';
import cn from 'classnames';
import CropModal from 'components/composed/CropModal';
import Notification from 'components/rfs-ui/Notification/Notification';
import { Avatar as AvatarUI, Button, Icon } from 'components/ui';
import { makeImageUrl } from 'utils/createUrl';
import { noop } from 'utils/lodash';
import { IMAGE_TYPES } from 'constants/applicationTypes';

import styles from './Avatar.scss';
import css from '../../composed/Avatar/Avatar.scss';

const cx = classNames.bind(styles);

const FILE_TYPES = IMAGE_TYPES.join(', ');
const MAX_SIZE = 4194304;
const MAX_COUNT = 1;

const Avatar = ({
  circle,
  disabled,
  className,
  label,
  icon,
  hint,
  data,
  imageSize,
  onChange,
  showMainControl,
  disabledMainControl,
  defaultSelectedMain,
  onlyAvatar,
}) => {
  const [isOpenCrop, setOpenCrop] = useState(false);
  const [image, setImage] = useState();
  const dropzoneRef = useRef();
  const imageSrc = useMemo(() => data && (data.id ? makeImageUrl(data) : data.result.preview), [data]);
  const dropzoneStyles = useMemo(() => cx({ dropzone: true, onlyAvatar }), [onlyAvatar]);

  const handleSelectFile = useCallback(() => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  }, []);

  const handleOpenCrop = useCallback(() => {
    if (data && data.file) {
      setImage(data.file);
      setOpenCrop(true);
    } else {
      handleSelectFile();
    }
  }, [data, handleSelectFile]);

  const handleClear = useCallback(() => {
    setImage();
    onChange();
  }, [onChange]);

  const handleClearImage = useCallback(() => setImage(), []);

  const handleCloseCrop = useCallback(() => {
    setOpenCrop(false);
    setImage();
  }, []);

  const handleAdd = useCallback((accepted, rejected) => {
    if (accepted.length) {
      const file = accepted[0];
      setImage(file);
      setOpenCrop(true);
    }

    const countFiles = accepted.length + rejected.length;
    if (countFiles > MAX_COUNT) {
      Notification.error({
        children: {
          message: `Максимальное число файлов для загрузки ${MAX_COUNT}`,
        },
      });
    } else if (rejected.length) {
      const file = rejected[0];
      const fileSize = file.size / 1048576;

      const message = `
          Файл ${file.name} (${fileSize.toFixed(2)} Мбайт) не удовлетворяет требованиям для загрузки файлов.
          Размер загружаемого файл не должен превышать 4 Мбайт.
        `;

      Notification.error({
        children: { message },
      });
    }
  }, []);

  const isClearable = data && data.id && !onlyAvatar;
  const inputRef = useRef();
  const handleAddPhoto = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = null;
      inputRef.current.click();
    }
  }, []);

  return (
    <div className={className}>
      <ReactDropzone
        ref={dropzoneRef}
        onDrop={handleAdd}
        className={dropzoneStyles}
        disabled={disabled}
        maxSize={MAX_SIZE}
        accept={FILE_TYPES}
        multiple={false}
        disableClick
      >
        <div className={css.inner}>
          <AvatarUI image={imageSrc} icon={icon} size={imageSize} circle={circle} onClick={isClearable ? handleClear : handleOpenCrop} />
          <button className={css.addPhoto} type="button" aria-label="Добавление фото" onClick={handleSelectFile}>
            <Icon className={css.icon} icon="camera" pack="fas" />
          </button>
        </div>
        {!onlyAvatar && (
          <React.Fragment>
            <Button className={styles.button} onClick={handleSelectFile} disabled={disabled} type="secondary">
              {label}
            </Button>
            <div className={styles.hint}>{hint}</div>
          </React.Fragment>
        )}
      </ReactDropzone>
      <input ref={inputRef} className={css.file} type="file" accept={FILE_TYPES} onChange={onChange} />
      <CropModal
        open={isOpenCrop}
        onClear={handleClearImage}
        onClose={handleCloseCrop}
        onApply={onChange}
        onChangeFile={handleSelectFile}
        image={image}
        canDelete={!!data}
        showMainControl={showMainControl}
        disabledMainControl={disabledMainControl}
        defaultSelectedMain={defaultSelectedMain}
      />
    </div>
  );
};

Avatar.propTypes = {
  circle: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.object,
  defaultSelectedMain: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledMainControl: PropTypes.bool,
  hint: PropTypes.string,
  icon: PropTypes.string,
  imageSize: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onlyAvatar: PropTypes.bool,
  showMainControl: PropTypes.bool,
};

Avatar.defaultProps = {
  circle: true,
  className: undefined,
  data: undefined,
  defaultSelectedMain: undefined,
  disabled: false,
  disabledMainControl: undefined,
  hint: 'JPG или PNG не больше 4 Мбайт.',
  icon: '',
  imageSize: 140,
  label: 'Выберите',
  onChange: noop,
  onlyAvatar: false,
  showMainControl: undefined,
};

export default Avatar;
