import { number, string, object, shape } from 'prop-types';

/**
 * @description Справочник
 */
export const PTInsuranceType = {
  /**
   * @description Описание
   */
  description: string,
  /**
   * @description Идентификатор
   */
  id: number,
  /**
   * @description Наименование
   */
  name: string.isRequired,
  /**
   * @description Системеное наименование
   */
  sysName: string,
  /**
   * @description Идентификатор с фронта
   */
  tempId: string,
};

export const PTInsurance = {
  endDate: number,
  /**
   * @description Идентификатор
   */
  id: number,

  number: string,
  person: object,
  startDate: number,
  /**
   * @description Идентификатор с фронта
   */
  tempId: string,

  type: shape(PTInsuranceType),
};
