import { createReducer } from 'redux-act';
import { clearData, setTeamData, setTeamLoading } from 'redux/actions/teamActions';

const initialState = {
  data: {},
  loading: false,
  organization: {},
};

const teamReducer = createReducer(
  {
    [clearData]: () => ({
      ...initialState,
    }),
    [setTeamData]: (state, data) => ({
      ...state,
      data,
    }),
    [setTeamLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
  },
  initialState,
);

export default teamReducer;
