import { createReducer } from 'redux-act';
import {
  cleanData,
  setCommonInfo,
  setMeetingLoading,
  setFederations,
  setFederationsLoading,
  setCasesInfo,
  setAttachCasesLoading,
  setCompositionCasesInfo,
  setCompositionCasesLoading,
  setDeAttachCasesLoading,
  setDecisionsInfo,
  setDecisionsLoading,
  setMembersInfo,
  setMembersLoading,
  setMembersSaveLoading,
  setMailingInfo,
  setMailingLoading,
  setMailingSaveLoading,
  setMailSaveLoading,
  setSaveDeletingBlockLoading,
  setAgendaLoading,
  setAgenda,
  setSaveAgendaLoading,
  setAgendaMailing,
  setAgendaMailingLoading,
  setSaveAgendaMailLoading,
  setNotifications,
  setNotificationsLoading,
  setNotification,
  setNotificationLoading,
  setDocumentsLoading, setDocuments,
} from 'redux/actions/Meetings/editActions';
import { DEFAULT_PAGINATION } from 'constants/pagination';

const initState = {
  agendaLoading: false,
  agendaMailingLoading: false,
  agendaSaveMailLoading: false,
  attachCasesLoading: false,
  casesPagination: DEFAULT_PAGINATION,
  compositionCasesLoading: false,
  data: {
    agenda: {},
    agendaMailing: {},
    cases: [],
    common: {},
    compositionCases: {},
    decisions: {},
    documents: [],
    mailing: {},
    members: [],
    notification: {},
  },
  deAttachCasesLoading: false,
  decisionsLoading: false,
  documentsLoading: false,
  federations: [],
  federationsLoading: false,
  loading: false,
  mailSaveLoading: false,
  mailingLoading: false,
  mailingSaveLoading: false,
  membersLoading: false,
  membersSaveLoading: false,
  notificationLoading: false,
  saveAgendaLoading: false,
  saveDeletingBlockLoading: false,
};

const editReducer = createReducer(
  {
    [cleanData]: () => initState,
    [setAgenda]: (state, agenda) => ({
      ...state,
      agendaLoading: false,
      data: {
        ...state.data,
        agenda,
      },
    }),
    [setAgendaLoading]: (state, agendaLoading) => ({
      ...state,
      agendaLoading,
    }),
    [setAgendaMailing]: (state, agendaMailing) => ({
      ...state,
      agendaMailingLoading: false,
      data: {
        ...state.data,
        agendaMailing,
      },
    }),
    [setAgendaMailingLoading]: (state, agendaMailingLoading) => ({
      ...state,
      agendaMailingLoading,
    }),
    [setAttachCasesLoading]: (state, attachCasesLoading) => ({
      ...state,
      attachCasesLoading,
    }),
    [setCasesInfo]: (state, { cases, pagination }) => ({
      ...state,
      casesPagination: pagination,
      data: {
        ...state.data,
        cases,
      },
      loading: false,
    }),
    [setCommonInfo]: (state, common) => ({
      ...state,
      data: {
        ...state.data,
        common,
      },
      loading: false,
    }),
    [setCompositionCasesInfo]: (state, data) => ({
      ...state,
      compositionCasesLoading: false,
      data: {
        ...state.data,
        compositionCases: data,
      },
    }),
    [setCompositionCasesLoading]: (state, compositionCasesLoading) => ({
      ...state,
      compositionCasesLoading,
    }),
    [setDeAttachCasesLoading]: (state, deAttachCasesLoading) => ({
      ...state,
      deAttachCasesLoading,
    }),
    [setDecisionsInfo]: (state, decisions) => ({
      ...state,
      data: {
        ...state.data,
        decisions,
      },
      decisionsLoading: false,
    }),
    [setDecisionsLoading]: (state, decisionsLoading) => ({
      ...state,
      decisionsLoading,
    }),
    [setDocuments]: (state, documents) => ({
      ...state,
      data: {
        ...state.data,
        documents,
      },
      documentsLoading: false,
    }),
    [setDocumentsLoading]: (state, documentsLoading) => ({
      ...state,
      documentsLoading,
    }),
    [setFederations]: (state, data) => ({
      ...state,
      federations: data,
      federationsLoading: false,
    }),
    [setFederationsLoading]: (state, federationsLoading) => ({
      ...state,
      federationsLoading,
    }),
    [setMailSaveLoading]: (state, mailSaveLoading) => ({
      ...state,
      mailSaveLoading,
    }),
    [setMailingInfo]: (state, mailing) => ({
      ...state,
      data: {
        ...state.data,
        mailing,
      },
      mailingLoading: false,
    }),
    [setMailingLoading]: (state, mailingLoading) => ({
      ...state,
      mailingLoading,
    }),
    [setMailingSaveLoading]: (state, mailingSaveLoading) => ({
      ...state,
      mailingSaveLoading,
    }),
    [setMeetingLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
    [setMembersInfo]: (state, members) => ({
      ...state,
      data: {
        ...state.data,
        members,
      },
      membersLoading: false,
    }),
    [setMembersLoading]: (state, membersLoading) => ({
      ...state,
      membersLoading,
    }),
    [setMembersSaveLoading]: (state, membersSaveLoading) => ({
      ...state,
      membersSaveLoading,
    }),
    [setNotification]: (state, notification) => ({
      ...state,
      data: {
        ...state.data,
        notification,
      },
      notificationLoading: false,
    }),
    [setNotificationLoading]: (state, notificationLoading) => ({
      ...state,
      notificationLoading,
    }),
    [setSaveAgendaLoading]: (state, saveAgendaLoading) => ({
      ...state,
      saveAgendaLoading,
    }),
    [setSaveAgendaMailLoading]: (state, agendaSaveMailLoading) => ({
      ...state,
      agendaSaveMailLoading,
    }),
    [setSaveDeletingBlockLoading]: (state, saveDeletingBlockLoading) => ({
      ...state,
      saveDeletingBlockLoading,
    }),
  },
  initState,
);

export default editReducer;
