/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import cn from 'classnames';

import { Icon } from 'components/ui';

import { notificationError } from 'magic/notification';
import { IMAGE_TYPES } from 'constants/applicationTypes';
import { isEmpty, uniqueId } from 'utils/lodash';

import { makeImageUrl } from 'utils/createUrl';
import css from './PhotoUpload.scss';

const MAX_SIZE = 10 * 1024 * 1024;
const MAX_COUNT = 5;

const preventDefault = (event) => {
  event.stopPropagation();
}

const PhotoUpload = ({ data, innerRef, onChange, maxCount, maxSize, className, disabled }) => {
  const availableFilesCount = maxCount - data.length;
  const isDropzoneDisabled = disabled || (maxCount > 0 ? availableFilesCount <= 0 : false);

  const dropzoneStyles = useMemo(
    () =>
      cn({
        [css.dropzone]: true,
        [css.disabled]: isDropzoneDisabled,
      }),
    [isDropzoneDisabled],
  );

  const handleAddFile = useCallback(
    (accepted, rejected) => {
      const newFiles = maxCount > 0 ? accepted.slice(0, availableFilesCount) : accepted;

      const addedFiles = newFiles.map((file) => {
        // eslint-disable-next-line no-param-reassign
        file.uuid = uniqueId('file_');
        return file;
      });

      if (rejected.length) {
        rejected.forEach((file) => notificationError(`Файл ${file.name} имеет недопустимый тип или размер.`));
      }

      if (addedFiles.length > accepted.length) {
        notificationError(`Максимальное число файлов для загрузки ${maxCount}.`);
      }

      onChange([...data, ...addedFiles]);
    },
    [data, availableFilesCount, onChange, maxCount],
  );

  const handleRemoveFile = useCallback(
    (e) => {
      const index = Number(e.currentTarget.getAttribute('data-index'));
      onChange(data.filter((_, idx) => idx !== index));
    },
    [onChange, data],
  );

  return (
    <div className={className}>
      <div className={css.filesContainer}>
        <Dropzone
          className={dropzoneStyles}
          accept={IMAGE_TYPES}
          disabled={isDropzoneDisabled}
          maxSize={maxSize}
          onDrop={handleAddFile}
          multiple
        >
          {isEmpty(data) ? (
            <div className={css.label}>Выберите с диска или перетащите сюда изображения в формате jpg, png до 10 мб.</div>
          ) : (
            <div className={css.files}>
              {data.map((doc, idx) => (
                <div className={css.preview} key={doc.id || doc.uuid} onClick={preventDefault}>
                  <img src={doc.preview || makeImageUrl(doc)} alt={doc.fileName || doc.filename || doc.name} />
                  {!disabled && (
                    <div className={css.removeBtn}>
                      <Icon data-index={idx} icon="times" onClick={handleRemoveFile} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          <div ref={innerRef} />
        </Dropzone>
      </div>
    </div>
  );
};

PhotoUpload.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  maxCount: PropTypes.number,
  maxSize: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

PhotoUpload.defaultProps = {
  className: undefined,
  data: [],
  disabled: false,
  innerRef: undefined,
  maxCount: MAX_COUNT,
  maxSize: MAX_SIZE,
};

export default PhotoUpload;
