export const readFile = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export const getCroppedImg = async (file, pixelCrop) => {
  const image = document.createElement('img');
  image.src = await readFile(file);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(image, -pixelCrop.x, -pixelCrop.y, image.width, image.height);
  const data = ctx.getImageData(0, 0, pixelCrop.width, pixelCrop.height);
  ctx.putImageData(data, 0, 0);

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      const resultFile = blob;
      const lastModifiedDate = new Date();

      const lastDotIdx = file.name.lastIndexOf('.');
      const fileName = file.name.slice(0, lastDotIdx > -1 ? lastDotIdx : undefined);

      resultFile.lastModifiedDate = lastModifiedDate;
      resultFile.lastModified = lastModifiedDate.valueOf();
      resultFile.name = `${fileName}.png`;
      resultFile.preview = URL.createObjectURL(blob);
      resolve(resultFile);
    }, 'image/png');
  });
};
