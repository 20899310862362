export const DOCUMENT_STATUSES = {
  ACTIVE: 1,
  ACTIVE_ORG: 3,
  ARCHIVE: -1,
  IN_ACTIVE: 2,
  VERIFIED: 0,
};

export const DOCUMENTS_CATEGORIES = {
  IDENTITY: 'identity',
  INSURANCE: 'insurance',
  JOB: 'job',
  OTHER: 'other',
  SCAN: 'scan',
};

export const DOCUMENTS_TYPES = {
  BIRTH_CERTIFICATE: 'birthCertificate',
  EMPLOYMENT_CONTRACT: 'employmentContract',
  EMPLOYMENT_HISTORY: 'employmentHistory',
  EMPLOYMENT_HISTORY_EXT: 'employmentHistoryExtension',
  INTERNATIONAL_PASSPORT: 'internationalPassport',
  NATIONAL_PASSPORT: 'nationalPassport',
  REGISTRATION: 'registration',
  REGISTRATION_APPLICATION: 'registrationApplication',
  RUSSIAN_PASSPORT: 'russianPassport',
  SNILS: 'snils',
};
