import { combineReducers } from 'redux';
import view from './viewReducer';
import edit from './editReducer';

const regulationReducer = combineReducers({
  edit,
  view,
});

export default regulationReducer;
