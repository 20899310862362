export const allFilterFields = [
  'federation',
  'committee',
  'relationType',
  'personDefendant',
  'organizationDefendant',
  'decisionType',
  'decisionStatus',
  'decisionStatusDate',
  'number',
  'dateFrom',
  'dateTo',
  'tournament',
  'match',
];

export const filterFieldsWithIds = [
  'decisionStatus',
  'number',
  'federation',
  'committee',
  'personDefendant',
  'organizationDefendant',
  'decisionType',
  'tournament',
  'match',
];
