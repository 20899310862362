import { DEFAULT_PAGINATION } from 'constants/pagination';
import axios from 'axios';
import { handleError, handleSuccess } from 'magic/handlers';
import actionsFactory from 'magic/actionsFactory';

const createAction = actionsFactory('meetings/view/');

export const clearData = createAction('clearData');
export const setMeetingsData = createAction('setMeetingsData');
export const setMeetingsLoading = createAction('setMeetingsLoading');

export const getMeetingsList = (filters, pagination = DEFAULT_PAGINATION) => async (dispatch) => {
  try {
    dispatch(setMeetingsLoading(true));
    const url = '/api/rest/judiciary/meeting/list';

    const { page, perPage } = pagination;
    const params = {
      ...filters,
      page,
      perPage,
      status: filters?.status?.name,
    };

    const {
      data: { data, pageData = {} },
    } = await axios.get(url, { params });

    const newPagination = {
      itemsCount: pageData.size,
      page,
      pageCount: pageData.countPage,
      perPage,
    };

    dispatch(setMeetingsData({ data, filters, pagination: newPagination }));
  } catch (err) {
    handleError(err);
  } finally {
    dispatch(setMeetingsLoading(false));
  }
};

export const onRemoveMeeting = (meetingId, successCallback) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/judiciary/meeting/${meetingId}`);
    handleSuccess('Заседание успешно удалено!');
    successCallback();
  } catch (err) {
    handleError(err);
  } finally {
    dispatch(setMeetingsLoading(false));
  }
};
