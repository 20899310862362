import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'utils/lodash';

const Row = ({ columns, item }) => (
  <tr>
    {columns.map(({ key, renderValue, width }) => (
      <td key={key} style={width ? { width } : null}>
        {renderValue ? renderValue(get(item, key), item) : get(item, key)}
      </td>
    ))}
  </tr>
);

const PTColumn = {
  key: PropTypes.string,
  renderValue: PropTypes.func,
  width: PropTypes.string,
};

Row.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(PTColumn)).isRequired,
  item: PropTypes.object.isRequired,
};

export default Row;
