import { createReducer } from 'redux-act';
import axios from 'axios';

import flow from 'lodash/flow';

import actionsFactory from 'magic/actionsFactory';
import { handleError } from 'magic/handlers';

const createAction = actionsFactory('@@TrainingGroup/Footballers/');

const initialState = {
  footballersByOrganizationId: [],
  footballersByOrganizationIdLoading: false,
};

export const purgeFootballersReducer = createAction('purgeFootballersReducer');
const setFootballersByOrganizationIdLoading = createAction('setFootballersByOrganizationIdLoading');
const setFootballersByOrganizationId = createAction('setFootballersByOrganizationId');

export const getFootballersByOrganizationId = (organizationId) => async (dispatch) => {
  const dispatchLoading = flow(setFootballersByOrganizationIdLoading, dispatch);

  dispatchLoading(true);
  try {
    const {
      data: { data },
    } = await axios.get('/api/rest/coachgroup/players', { params: { organizationId } });

    flow(setFootballersByOrganizationId, dispatch)(data);
  } catch (error) {
    handleError(error);
  } finally {
    dispatchLoading(false);
  }
};

const reducer = createReducer(
  {
    [purgeFootballersReducer]: () => ({ ...initialState }),
    [setFootballersByOrganizationId]: (state, payload) => ({
      ...state,
      footballersByOrganizationId: payload,
    }),
    [setFootballersByOrganizationIdLoading]: (state, payload) => ({
      ...state,
      footballersByOrganizationIdLoading: payload,
    }),
  },
  initialState,
);

export default reducer;
