exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------ \nCommon\n--------------------------*/\n\n\n/* layouts width*/\n\n\n/* ------------------------ \nDark Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* input*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nLight Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nRating\n--------------------------*/\n\n\n.container-1plfS {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  border: 1px solid #1fadff;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  color: #1fadff;\n  margin-bottom: 40px;\n  padding: 14px 10px;\n  background-color: #1fadff4f;\n}\n\n\n.content-__ps6 {\n  font-size: 18px;\n  margin: 0 40px;\n  -webkit-box-flex: 1;\n      -ms-flex-positive: 1;\n          flex-grow: 1; \n  text-align: center;\n}\n\n\n.icon-3v-xf {\n  font-size: 24px;\n  width: 40px;\n  height: 40px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n}\n\n\n.warning-10U2r {\n  background-color: rgba(255, 109, 127, 0.24);\n  border-color: #ff6d7f;\n  color: #ff6d7f;\n}\n", ""]);

// exports
exports.locals = {
	"container": "container-1plfS",
	"content": "content-__ps6",
	"icon": "icon-3v-xf",
	"warning": "warning-10U2r"
};