exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* react-flexbox-grid breakpoints*/\n/* ------------------------ \nCommon\n--------------------------*/\n/* layouts width*/\n/* ------------------------ \nDark Theme \n--------------------------*/\n/* colors*/\n/* input*/\n/* modal content padding*/\n/* ------------------------ \nLight Theme \n--------------------------*/\n/* colors*/\n/* modal content padding*/\n/* ------------------------ \nRating\n--------------------------*/\n.modal-3tvHA {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  z-index: 532;\n}\n.overlay-3EW_b {\n  position: absolute;\n  top: 0;\n  right: 0;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  overflow-y: auto;\n  padding: 40px;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n}\n.content-3CsDR {\n  margin: auto;\n  padding: 20px;\n  border-radius: 4px;\n  background-color: #001a5d;\n}\n.theme-dark .content-3CsDR {\n  background-color: #001a5d;\n}\n.theme-light .content-3CsDR {\n  background-color: #fff;\n}\n.closer-container-2yB5u {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  height: 40px;\n}\n.closer-container-2yB5u .closer-1cjjo {\n    cursor: pointer;\n  }\n.children-3HcRW {\n  /***** СЮДА МОЖНО БУДЕТ ЗАСУНУТЬ IMMUTABLE-СТИЛИ ДЛЯ ДЕТЕЙ *****/\n}\n.modalPreloader-2up_q {\n  margin-bottom: 40px;\n}\n/*************************  MEDIA  *************************/\n@media (max-width: 1023px) {\n  .overlay-3EW_b {\n    padding: 140px 0 40px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"modal": "modal-3tvHA",
	"overlay": "overlay-3EW_b",
	"content": "content-3CsDR",
	"closer-container": "closer-container-2yB5u",
	"closer": "closer-1cjjo",
	"children": "children-3HcRW",
	"modalPreloader": "modalPreloader-2up_q"
};