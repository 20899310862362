import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import css from './index.scss';

const InlineTooltip = ({ text, className, children }) => (
  <Fragment>
    <div className={cn(className, css.trigger)}>{children}</div>
    <div className={css.tooltip}>{text}</div>
  </Fragment>
);

InlineTooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
};

InlineTooltip.defaultProps = {
  children: null,
  className: undefined,
  text: '',
};

export default InlineTooltip;
