import { combineReducers } from 'redux';

import currentReducer from './currentReducer';
import freeTemplatesReducer from './freeTemplatesReducer';
import viewReducer from './viewReducer';

const printTemplatesReducer = combineReducers({
  current: currentReducer,
  free: freeTemplatesReducer,
  view: viewReducer,
});

export default printTemplatesReducer;
