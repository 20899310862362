import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import { push } from 'connected-react-router';
import Notification from 'components/rfs-ui/Notification/Notification';
import { sessionCheckSuccess } from 'redux/modules/session/session';
import { handleError } from 'magic/handlers';
import axios from 'axios';
import { get } from 'utils/lodash';
import { uploadImage } from 'utils/uploadFiles';
import { getAddressInfo, addressRestructure, getAddressLocations } from 'utils/dadata';
import { dataReStructure } from '../utils';
import { restructureData } from './utils';
import { transliterateCyrillicText } from 'utils/text';

const createAction = actionsFactory('organization/add/');

const initialState = {
  data: {},
  inProgress: false,
  orgTypes: null,
  selectedType: null,
  handleUrl: null,
};

// actions
export const setOrganizationTypes = createAction('setOrganizationTypes');
export const setSelectedType = createAction('setSelectedType');
export const setHandleUrl = createAction('setHandleUrl');
export const setLoading = createAction('setLoading');
export const updateData = createAction('updateData');
export const updateHead = createAction('updateHead');
export const updateLegal = createAction('updateLegal');
export const clearData = createAction('clearData');
export const setData = createAction('setData');

/**
 * Получить список типов организаций
 *
 * @return {Array}
 */
export const getOrganizationTypes = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/rest/organization/organizationtypes?visible=true');
    dispatch(setOrganizationTypes(res.data.data));
  } catch (e) {
    handleError(e);
  }
};

/**
 * Сохранить организацию
 */
export const createOrganization = (isLocal) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const params = { isNew: true };
  try {
    const { data, selectedType, handleUrl } = getState().organization.add;
    const body = dataReStructure(data, selectedType);

    if (body.address) {
      const dadataObj = await getAddressInfo(body.address.value, getAddressLocations(body.address));

      if (dadataObj) {
        body.address = addressRestructure(dadataObj);
      } else if (body.address.data) {
        body.address = addressRestructure(body.address);
      }
    }

    body.logotip = await uploadImage(body.logotip);
    const response = await axios.post(
      `/api/rest/organization/${handleUrl}`,
      isLocal
        ? {
            ...body,
            isForeign: false,
            legShortName: body.legShortName ? body.legShortName : body?.fullName || '',
            legShortNameEn: body.legShortNameEn
              ? body.legShortNameEn
              : body?.sportNameEn || transliterateCyrillicText(body?.fullName) || '',
          }
        : restructureData(body),
      { params },
    );
    const res = await axios.get('/api/rest/auth/session');
    const isWithLegal = get(data, 'legal');

    Notification.success({
      children: {
        icon: 'check',
        message: 'Организация создана!',
      },
    });

    dispatch(sessionCheckSuccess(res.data));
    dispatch(
      data.legal
        ? push({
            pathname: `/legal/${isWithLegal.id}`,
            state: { section: selectedType },
          })
        : push(`/organization/${response.data.data.id}`),
    );
  } catch (e) {
    handleError(e);
    dispatch(setLoading(false));
  }
};

export const dropData = (key) => (dispatch, getState) => {
  const data = { ...getState().organization.add.data };

  if (data[key]) {
    if (Object.keys(data).length === 1) {
      dispatch(setData({}));
    } else {
      delete data[key];
      dispatch(setData(data));
    }
  }
};

// reducer
const reducer = createReducer(
  {
    [clearData]: () => initialState,
    [setData]: (state, data) => ({
      ...state,
      data,
    }),
    [setLoading]: (state, inProgress) => ({
      ...state,
      inProgress,
    }),
    [setOrganizationTypes]: (state, orgTypes) => ({
      ...state,
      orgTypes,
    }),
    [setSelectedType]: (state, selectedType) => ({
      ...state,
      selectedType,
    }),
    [setHandleUrl]: (state, handleUrl) => ({
      ...state,
      handleUrl,
    }),
    [updateData]: (state, payload) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),
    [updateHead]: (state, payload) => ({
      ...state,
      head: {
        ...state.head,
        ...payload,
      },
    }),
    [updateLegal]: (state, payload) => ({
      ...state,
      data: {
        legal: { ...payload },
      },
    }),
  },
  initialState,
);

export default reducer;
