import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './H.scss';

const cx = classNames.bind(styles);

class H extends React.PureComponent {
  static propTypes = {
    center: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    line: PropTypes.bool,
    noMargin: PropTypes.bool,
    onClick: PropTypes.func,
    regular: PropTypes.bool,
    size: PropTypes.number,
    uppercase: PropTypes.bool,
  };

  static defaultProps = {
    center: false,
    children: null,
    className: undefined,
    line: false,
    noMargin: false,
    onClick: undefined,
    regular: false,
    size: undefined,
    uppercase: false,
  };

  renderHeader = () => {
    const { size, children } = this.props;
    switch (size) {
      case 1:
        return <h1 className={styles.h1}>{children}</h1>;
      case 2:
        return <h2 className={styles.h2}>{children}</h2>;
      case 3:
        return <h3 className={styles.h3}>{children}</h3>;
      case 4:
        return <h4 className={styles.h4}>{children}</h4>;
      case 5:
        return <h5 className={styles.h5}>{children}</h5>;
      case 6:
        return <h6 className={styles.h6}>{children}</h6>;
      default:
        return <h1 className={styles.h1}>{children}</h1>;
    }
  };

  render() {
    const { center, className, line, onClick, regular, uppercase, noMargin } = this.props;

    const classes = cx({
      center,
      [className]: !!className,
      heading: true,
      noMargin,
      regular,
      uppercase,
    });

    return (
      <div className={classes} onClick={onClick}>
        {line && center && <div className={`${styles.line} h-line-themed`} />}
        {this.renderHeader()}
        {line && <div className={`${styles.line} h-line-themed`} />}
      </div>
    );
  }
}

export default H;
