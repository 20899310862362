import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
// import { push } from 'connected-react-router';
// import { handleError } from 'magic/handlers';
// import { Notification } from 'components/rfs-ui';

const createAction = actionsFactory('exercise/edit/equipment/');

const initialState = {};

// actions
export const fillEquipment = createAction('fillEquipment');
export const updateData = createAction('updateData');
export const clearEquipment = createAction('clearEquipment');

// reducer
const reducer = createReducer({
  [fillEquipment]: (state, payload) => payload,
  [updateData]: (state, payload) => ({
    ...state,
    ...payload,
  }),
  [clearEquipment]: () => initialState,
}, initialState);

export default reducer;
