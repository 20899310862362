/**
 * @enum ROUTES_WAYS
 */
// eslint-disable-next-line import/prefer-default-export
export const ROUTES_WAYS = Object.freeze({
  /**
   * @description /profile/:id/account/create - Создать учетную запись пользователя
   */
  ACCOUNT_CREATE: '/profile/:id/account/create',
  /**
   * @description /profile/:id/account/edit - Редактировать  учетную запись пользователя
   */
  ACCOUNT_EDIT: '/profile/:id/account/edit',
  APPEALS_CONSTRUCTOR: '/appeals/constructor',
  APPEALS_INCOMING: '/appeals/incoming',
  APPEALS_OUTGOING: '/appeals/outgoing',
  /**
   * @description /cases - Страница просмотра дисциплинарных дел
   */
  CASES: '/cases',
  /**
   * @description /case/create - Страница создания дисциплинарного дела
   */
  CASES_CREATE: '/case/create',
  /**
   * @description /case/edit/:id/:tab - Страница редактирования дисциплинарного дела
   */
  CASES_EDIT: '/case/edit/:id/:tab',
  /**
   * @description /categories - Управление категориями клубов и школ
   * @example https://gost-jira.atlassian.net/wiki/spaces/RFSEAIS/pages/1384349720/API
   */
  CATEGORIES: '/categories',
  /**
   * @description /contracts - Страница просмотра контрактов (инструкций ДТМС)
   */
  CONTRACTS: '/contracts',
  /**
   * @description /contracts/:id - Страница просмотра контракта (инструкция ДТМС)
   */
  CONTRACT_BY_ID: '/contracts/:id',
  /**
   * @description /contracts/:id/payments - Страница просмотра и управления выплатами по контракту (инструкция ДТМС)
   */
  CONTRACT_PAYMENTS: '/contracts/:id/payments',
  /**
   * @description /profile/:id/contract-registration - Регистрация договора
   */
  CONTRACT_REGISTRATION: '/profile/:id/contract-registration',
  /**
   * @description /control-indicators - Расчет контрольных индикаторов
   */
  CONTROL_INDICATORS: '/control-indicators',
  /**
   * @description /decisions - Реестр решений
   */
  DECISIONS: '/decisions',
  /**
   * @description /decision/:id - Просмотр решения
   */
  DECISION_EDIT: '/decision/:id',
  /**
   * @description /dictionaries - Управление справочниками
   */
  DICTIONARIES: '/dictionaries',
  /**
   * @description /document - Документы
   */
  DOCUMENT: '/document',
  /**
   * @description /document/add - Заключение договора о сотрудничестве
   */
  DOCUMENT_ADD: '/document/add',
  /**
   * @description /document-constructor - Конструктор документов
   */
  DOCUMENT_CONSTRUCTOR: '/document-constructor',
  /**
   * @description /document-constructor/person/add - Конструктор документов, добавление нового типа документа физ. лица
   */
  DOCUMENT_CONSTRUCTOR_PERSON_ADD: '/document-constructor/person/add',
  /**
   * @description /document-constructor/person/:id/edit - Конструктор документов, редактирование существующего типа документа физ. лица по id
   */
  DOCUMENT_CONSTRUCTOR_PERSON_EDIT_BY_ID: '/document-constructor/person/:id/edit',
  /**
   * @description /document-constructor/org/add - Конструктор документов, добавление нового типа документа организации
   */
  DOCUMENT_CONSTRUCTOR_ORG_ADD: '/document-constructor/org/add',
  /**
   *   /**
   * @description /document-constructor/infrastructure/add - Конструктор документов, добавление нового типа документа ОФИ
   */
  DOCUMENT_CONSTRUCTOR_INFRASTRUCTURE_ADD: '/document-constructor/infrastructure/add',
  /**
   * @description /document-сonstructor/infrastructure/:id/edit - Конструктор документов, редактирование существующего типа документа ОФИ по id
   */
  DOCUMENT_CONSTRUCTOR_INFRASTRUCTURE_EDIT_BY_ID: '/document-constructor/infrastructure/:id/edit',
    /**
   * @description /document-сonstructor/org/:id/edit - Конструктор документов, редактирование существующего типа документа организации по id
   */
    DOCUMENT_CONSTRUCTOR_ORG_EDIT_BY_ID: '/document-constructor/org/:id/edit',
  /**
   * @description /document/edit/:id - Редактирование договора о сотрудничестве
   */
  DOCUMENT_EDIT: '/document/edit',
  /**
   * @description /document - Просмотр договора
   */
  DOCUMENT_VIEW: '/document/:id',
  /**
   * @description /employers - Просмотр сотрудников юр.органов
   */
  EMPLOYERS: '/employers',
  /**
   * @description /infrastructure/:id - Страница ОФИ
   */
  INFRASTRUCTURE_BY_ID: '/infrastructure/:id',
  /**
   * @description /contract/:id/labor-contract - Страница просмотра трудового договора инструкции ДТМС
   */
  LABOR_CONTRACT_BY_ID: '/contracts/:id/labor-contract',
  /**
   * @description /contract/:id/labor-contract/edit - Страница редактирования трудового договора инструкции ДТМС
   */
  LABOR_CONTRACT_EDIT: '/contracts/:id/labor-contract/edit',
  /**
   * @description /legal/:id - Страница профиля юр. лица
   */
  LEGAL_BY_ID: '/legal/:id',
  /**
   * @description /legal/:id/createBranch - Создание филиала юр.лица
   */
  LEGAL_CREATE_BRANCH: '/legal/:id/createBranch',
  /**
   * @description /login - Страница логина
   */
  LOGIN: '/login',
  /**
   * @description /management - Страница управления
   */
  MANAGEMENT: '/management',
  /**
   * @description /meeting - Страница просмотра заседаний
   */
  MEETING: '/meeting',
  /**
   ** @description /meeting/create - Страница создания заседаний
   */
  MEETING_CREATE: '/meeting/create',
  /**
   ** @description /meeting/edit/:id/:tab - Страница редактирования заседания
   */
  MEETING_EDIT: '/meeting/edit/:id/:tab',
  /**
   * @description /migrated-data - Работа с мигрированными данными
   */
  MIGRATED_DATA: '/migrated-data',
  /**
   * @description /nationalTeam/:id - Просмотр сборной
   */
  NATIONAL_TEAM: '/nationalTeam/:id',
  /**
   * @description /nationalTeam/edit/:id - Редактирование сборной
   */
  NATIONAL_TEAM_EDIT: '/nationalTeam/edit/:id',
  ORGANIZATION_BY_ID: '/organization/:id',
  /**
   * @description /transferStatistic - Страница отчета по статистике переходов
   */
  ORGANIZATION_TRANSFER_STATISTIC: '/transferStatistic',
  /**
   * @description /organizations - Просмотр моих организаций
   */
  OWN_ORGANIZATIONS: '/organizations',
  /**
   * @description /group/passports - Страница экспорта паспортов футболистов (массовое действие)
   */
  PASSPORTS_GROUP: '/group/passports',
  /**
   * @description /profile/:id/pay-calculator - Калькулятор выплат за подготовку спортсмена
   */
  PAY_CALCULATOR: '/profile/:id/pay-calculator',
  /**
   * @description /pay-constructor - Конструктор выплат
   */
  PAY_CONSTRUCTOR: '/pay-constructor',
  /**
   * @description /personalData - Политика обработки перс. данных и пользовательское соглашение
   */
  PERSONAL_DATA: '/personalData',
  /**
   * @description /print-templates - Шаблоны печатных форм
   */
  PRINT_TEMPLATES: '/print-templates',
  /**
   * @description /print-templates/:id - Форма управления шаблоном печатной формы
   */
  PRINT_TEMPLATES_BY_ID: '/print-templates/:id',
  /**
   * @description /profile/:id - Просмотр профиля участника
   */
  PROFILE_BY_ID: '/profile/:id',
  /**
   * @description /profile/:id/duplicates - Просмотр возможных дублей FIFA
   */
  PROFILE_DUPLICATES: '/profile/:id/duplicates',
  /**
   * @description /group/ranks - Страница массового присвоения званий/разрядов
   */
  RANKS_GROUP: '/group/ranks',
  /**
   * @description /rating/:personId/:matchId - Страница просмотра оценок соревновательной деятельности
   */
  RATING: '/rating/:personId/:matchId',
  /**
   * @description /rating/:personId/:matchId/create - Страница создания оценок соревновательной деятельности
   */
  RATING_CREATE: '/rating/:personId/:matchId/create',
  /**
   * @description /rating/:personId/:matchId/edit - Страница редактирования оценок соревновательной деятельности
   */
  RATING_EDIT: '/rating/:personId/:matchId/edit',
  /**
   * @description /regulation - Страница регламеторв
   */
  REGULATION: '/regulation',
  /**
   * @description /regulation/:id - Страница просмотра регламента
   */
  REGULATION_EDIT: '/regulation/:id',
  /**
   * /releases - информация о релизах РФС.ЦП
   */
  RELEASES: '/releases',
  /**
   * @description /schedule - Мое расписание
   */
  RETURN_FROM_RENT_BY_ID: '/contracts/:id/return-from-rent',
  /**
   * @description /contracts/:id/return-from-rent - Страница просмотра соглашения о досрочном расторжении
   */
  RETURN_FROM_RENT_EDIT: '/contracts/:id/return-from-rent/edit',
  /**
   * @description /contract/:id/return-from-rent/edit - Страница редактирования соглашения о досрочном расторжении
   */
  SCHEDULE: '/schedule',
  /**
   * @description /infrastructure/search - Поиск объектов инфраструктуры
   */
  SEARCH_INFRASTRUCTURE: '/infrastructure/search',
  /**
   * @description /organization/search - Поиск организаций
   */
  SEARCH_ORGANIZATION: '/organization/search',
  /**
   * @description /profile/search - Поиск участников
   */
  SEARCH_PROFILE: '/profile/search',
  /**
   * @description /team/:id - Просмотр команды
   */
  TEAM_BY_ID: '/team/:id',
  /**
   * @description /contracts/:id/termination-agreement - Страница просмотра соглашение о расторжении ТД
   */
  TERMINATION_AGREEMENT_BY_ID: '/contracts/:id/termination-agreement',
  /**
   * @description /contracts/:id/transfer-agreement/edit - Страница редактирования соглашение о расторжении ТД
   */
  TERMINATION_AGREEMENT_EDIT: '/contracts/:id/termination-agreement/edit',
  /**
   * @description /training/edit/:id - Редактирование тренировки
   */
  TRAINING_EDIT: '/training/edit/:id',
  /**
   * @description /document - Просмотр тренировки
   */
  TRAINING_GROUP_ADD_BY_ORGANIZATION_ID: '/trainingGroup/add/:organizationId',
  TRAINING_GROUP_BY_ID: '/trainingGroup/:id',
  TRAINING_GROUP_EDIT_BY_GROUP_ID: '/trainingGroup/edit/:groupId',
  TRAINING_VIEW: '/training/:id',
  TRANSFERS_ADMIN_ALL: '/transfers/admin',
  TRANSFERS_ADMIN_BY_ID: '/transfers/admin/:id',
  TRANSFERS_GROUP: '/transfers/group',
  TRANSFERS_USER_ALL: '/transfers/user',
  TRANSFERS_USER_BY_ID: '/transfers/user/:id',
  /**
   * @description /contracts/:id/transfer-agreement - Страница просмотра трансферного соглашения инструкции ДТМС
   */
  TRANSFER_AGREEMENT_BY_ID: '/contracts/:id/transfer-agreement',
  /**
   * @description /contracts/:id/transfer-agreement/edit - Страница редактирования трансферного соглашения инструкции ДТМС
   */
  TRANSFER_AGREEMENT_EDIT: '/contracts/:id/transfer-agreement/edit',
  /**
   * @description /career-bonus/front/ - Страница с таблицей ЕЕВ
   */
  CONTRIBUTIONS: '/career-bonus/front/',
});
