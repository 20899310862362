import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';
import { handleError } from 'magic/handlers';
import isEmpty from 'lodash/isEmpty';

const createAction = actionsFactory('training/summarize/exercises/');

const initialState = {};

let timer = 0;
const DELAY = 500;

export const fillExercises = createAction('fillExercises');
export const updateExercises = createAction('updateExercises');
export const clearExercises = createAction('clearExercises');

export const fillInitialExercises = (data = {}) => (dispatch) => {
  const { groups = [], ...restData } = data;
  const filledGroups = groups.map((group = {}) => {
    const { exercises = [] } = group;
    return isEmpty(exercises) ? { ...group, exercises: [{}] } : group;
  });

  dispatch(
    fillExercises({
      ...restData,
      groups: filledGroups,
    }),
  );
};

export const calculateExercises = () => (dispatch, getState) => {
  clearTimeout(timer);
  timer = setTimeout(async () => {
    try {
      const { exercises } = getState().training.summarize;
      const response = await axios.post('/api/rest/training/calculate', exercises);
      dispatch(updateExercises(response.data.data));
    } catch (e) {
      handleError(e);
    }
  }, DELAY);
};

const reducer = createReducer(
  {
    [clearExercises]: () => initialState,
    [fillExercises]: (state, exercisesData) => exercisesData,
    [updateExercises]: (state, data) => ({
      ...state,
      ...data,
    }),
  },
  initialState,
);

export default reducer;
