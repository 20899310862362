import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';

import { selectCompensationsData } from 'redux/selectors/Contracts/compensationsSelectors';
import { uploadFile } from 'utils/uploadFiles';

const createAction = actionsFactory('contracts/compensations/');

export const clearData = createAction('clearData');
export const setCompensationsData = createAction('setCompensationsData');

export const getCompensations = (instructionId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/rest/contract/dtms/instruction/${instructionId}/compensation/list`);
    dispatch(setCompensationsData(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setCompensationsData([]));
  }
};

export const saveCompensation = (instructionId, data) => async (dispatch) => {
  try {
    await axios.post(`/api/rest/contract/dtms/instruction/${instructionId}/compensation/save`, data);
    handleSuccess('Компенсация успешно сохранена!');
    await dispatch(getCompensations(instructionId));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const confirmCompensation = (instructionId, paymentId) => async (dispatch) => {
  try {
    await axios.put(`/api/rest/contract/dtms/compensation/${paymentId}/approve-payment`, null);
    handleSuccess('Платеж по компенсации подтвержден!');
    await dispatch(getCompensations(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const deleteCompensation = (compensationId) => async (dispatch, getState) => {
  try {
    await axios.delete(`/api/rest/contract/dtms/compensation/${compensationId}/delete`);
    handleSuccess('Компенсация успешно удалена!', 'trash');
    const compensations = selectCompensationsData(getState());
    dispatch(setCompensationsData(compensations.filter(({ id }) => id !== compensationId)));
  } catch (err) {
    handleError(err);
  }
};

export const addCompensationPayment = (instructionId, compensationId, data) => async (dispatch) => {
  try {
    const body = { ...data };
    body.document = await uploadFile(body.document);
    await axios.post(`/api/rest/contract/dtms/compensation/${compensationId}/add-payment`, body);
    handleSuccess('Выплата по компенсации успешно добавлена!');
    await dispatch(getCompensations(instructionId));
  } catch (err) {
    handleError(err);
    throw err;
  }
};
