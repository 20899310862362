import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/ui';
import Input from './Input';

import css from './Input.scss';

const MIN_LIMIT_ERROR = 'Значение должно быть больше минимального';
const MAX_LIMIT_ERROR = 'Значение должно быть меньше максимального';

class NumberInput extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.string,
    lessThan: PropTypes.number,
    maxNumber: PropTypes.number,
    moreThan: PropTypes.number,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    withNegate: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    error: '',
    lessThan: undefined,
    maxNumber: undefined,
    moreThan: undefined,
    onChange: () => {},
    value: undefined,
    withNegate: false,
  };

  state = {
    currentValue: this.props.value,
    maxLimitError: false,
    minLimitError: false,
  };

  setErrors = (minLimitError, maxLimitError) => {
    this.setState({
      maxLimitError,
      minLimitError,
    });
  };

  increaseValue = () => {
    const { value, onChange } = this.props;
    const increasedValue = Number(value || 0) + 1;
    const isValid = this.checkValid(increasedValue);

    if (isValid) {
      this.setState({ currentValue: increasedValue });
      onChange(increasedValue);
    }
  };

  reduceValue = () => {
    const { value, onChange } = this.props;
    const reducedValue = Number(value || 0) - 1;
    const isValid = this.checkValid(reducedValue);

    if (isValid) {
      this.setState({ currentValue: reducedValue });
      onChange(reducedValue);
    }
  };

  checkValid = (value, setErrors) => {
    const { lessThan, moreThan, maxNumber, withNegate } = this.props;
    const numValue = Number(value || 0);

    const isLessMaxNumber = !lessThan || lessThan > numValue;
    const isMoreMinNumber = !moreThan || moreThan < numValue;
    const isLessLimit = !maxNumber || numValue <= maxNumber;
    const isMoreZero = withNegate || numValue >= 0;

    if (setErrors) {
      this.setErrors(!isMoreMinNumber, !isLessMaxNumber);
    }

    return isLessMaxNumber && isMoreMinNumber && isLessLimit && isMoreZero;
  };

  handleChange = (value) => {
    const { onChange, maxNumber, withNegate } = this.props;
    const numValue = value && (Number(value) || 0);
    const isLessLimit = !maxNumber || numValue <= maxNumber;
    const isMoreZero = withNegate || numValue >= 0;

    if (!numValue || (isLessLimit && isMoreZero)) {
      this.checkValid(value, true);
      onChange(numValue);
    }
  };

  handleBlur = (e) => {
    const { currentValue } = this.state;
    const { value, onChange } = this.props;
    this.setErrors(false, false);

    if (!value) {
      onChange(value === 0 ? value : undefined);
      e.target.value = value === 0 ? value : ''; // Clear input when value is not text (to fix Firefox bug)
    } else if (!this.checkValid(value)) {
      onChange(Number(currentValue));
    } else {
      this.setState({ currentValue: value });
    }
  };

  renderArrows = () => (
    <div className={css.arrowContainer}>
      <Button icon="angle-up" type="text" onClick={this.increaseValue} />
      <Button icon="angle-down" type="text" onClick={this.reduceValue} />
    </div>
  );

  render() {
    const { disabled, error, lessThan, moreThan, withNegate, ...inputProps } = this.props;
    const { maxLimitError, minLimitError } = this.state;
    const errMessage =
      error || (maxLimitError && `${MAX_LIMIT_ERROR} ${lessThan}`) || (minLimitError && `${MIN_LIMIT_ERROR} ${moreThan}`) || '';

    return (
      <Input
        {...inputProps}
        type="number"
        disabled={disabled}
        onChange={this.handleChange}
        actionIcon={!disabled && this.renderArrows()}
        inputClassName={css.accuracyInput}
        onBlur={this.handleBlur}
        error={errMessage}
        valid={!maxLimitError && !minLimitError && !error}
      />
    );
  }
}

export default NumberInput;
