import { createReducer } from 'redux-act';
import {
  setEmployeesList,
  clearData,
  setEmployeesLoading,
  setEmployeesSaveLoading,
  addEmployee,
  setEmployeesRemoveLoading,
  removeEmployee,
  changeEmployee,
} from 'redux/actions/EmployeesActions/viewActions';

const initState = {
  committee: {},
  list: [],
  loading: false,
  removeLoading: false,
  saveLoading: false,
};

const viewReducer = createReducer(
  {
    [addEmployee]: (state, data) => ({
      ...state,
      list: [...state.list, data],
      saveLoading: false,
    }),
    [changeEmployee]: (state, data) => ({
      ...state,
      list: state.list.map((item) => (item.id === data.id ? { ...data } : { ...item })),
      saveLoading: false,
    }),
    [clearData]: () => initState,
    [removeEmployee]: (state, employeeId) => ({
      ...state,
      list: state.list.filter(({ id }) => id !== employeeId),
      removeLoading: false,
    }),
    [setEmployeesList]: (state, { data, committee }) => ({
      ...state,
      committee,
      list: data,
      loading: false,
    }),
    [setEmployeesLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
    [setEmployeesRemoveLoading]: (state, removeLoading) => ({
      ...state,
      removeLoading,
    }),
    [setEmployeesSaveLoading]: (state, saveLoading) => ({
      ...state,
      saveLoading,
    }),
  },
  initState,
);

export default viewReducer;
