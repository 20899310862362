import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ columns }) => (
  <tr>
    {columns.map(({ key, width, header }) => (
      <th key={key} style={width ? { width } : null}>
        {header}
      </th>
    ))}
  </tr>
);

const PTColumn = {
  header: PropTypes.string,
  key: PropTypes.string,
  width: PropTypes.string,
};

Header.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(PTColumn)).isRequired,
};

export default Header;
