import React from 'react';
import ReactSlider from "react-slick";
import cn from 'classnames';

import "slick-carousel/slick/slick.css?external";
import "slick-carousel/slick/slick-theme.css?external";

import {
  Button,
  Icon,
} from 'components/ui';

import css from './index.scss';

const NextArrow = ({ className, onClick }) => (
  <Button className={cn(className, css.arrow)} onClick={onClick} type="text">
    <Icon icon='angle-right' size='5x' color='#fff' pack='fal' />
  </Button>
);

const PrevArrow = ({ className, onClick }) => (
  <Button className={cn(className, css.arrow)} onClick={onClick} type="text">
    <Icon icon='angle-left' size='5x' color='#fff' pack='fal' />
  </Button>
);

const Slider = React.forwardRef(({ children, ...sliderProps }, ref) => {
  return (
    <ReactSlider
      ref={ref}
      dots
      customPaging={() => (
        <div className={css.dotWrapper}>
          <div className={css.dot} />
        </div>
      )}
      nextArrow={<NextArrow />}
      prevArrow={<PrevArrow />}
      {...sliderProps}
    >
      {children}
    </ReactSlider>
  );
});

export default Slider;
