import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { DEFAULT_PER_PAGE } from 'constants/pagination';
import { push } from 'connected-react-router';
import axios from 'axios';
import { ROUTES_WAYS } from 'constants/routesWays';
import { stringifyValue } from 'utils/documents';

const createAction = actionsFactory('infrastructure/view/');

const initialState = {
  data: {},
  certificate: {},
  certificates: [],
  pagination: {
    page: 0,
    perPage: DEFAULT_PER_PAGE,
    pageCount: null,
    itemsCount: null,
  },
  forbidden: false,
  loading: true,
};

// actions
export const setInfrastructureInfo = createAction('setInfrastructureInfo');
export const setCertificateInfo = createAction('setCertificateInfo');
export const editCertificate = createAction('editCertificate');
export const setLoading = createAction('setLoading');
export const updateData = createAction('updateData');
export const setPagination = createAction('setPagination');
export const setForbidden = createAction('setForbidden');
export const clearCertificate = createAction('clearCertificate');
export const clearData = createAction('clearData');

export const setDocuments = createAction('setDocuments');
export const setDocumentCategories = createAction('setDocumentCategories');
export const setDocumentTypes = createAction('setDocumentTypes');

export const saveDocument = ({ scans, ...data }) => async (dispatch) => {
  try {
    const body = {
      ...data,
      documentFieldValues: data.documentFieldValues.map(({ field, fieldId, value }) => ({
        fieldId,
        value: stringifyValue(value, field.type),
      })),
    };

    const docResponse = await axios.post('/api/rest/infrastructureobject/document/add', body);
    const docId = docResponse.data.data;

    handleSuccess(`Документ успешно ${!body.id ? 'добавлен' : 'обновлён'}!`, 'file-check');
  } catch (e) {
    handleError(e);
    throw e;
  }
};


export const getDocuments = (sportObjectId) => async (dispatch) => {
  try {
    const {
      data: { data: docsData },
    } = await axios.get(`/api/rest/infrastructureobject/document/registry?sportObjectId=${sportObjectId}`);

    dispatch(setDocuments(docsData));
  } catch (err) {
    console.log(err);
  }
};

export const getDocumentTypes = () => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get('/api/rest/document/sport/objects/types');
    dispatch(setDocumentTypes(data));
  } catch (err) {
    console.log(err);
  }
};

export const getDocumentCategories = () => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get('/api/rest/document/sport/objects/categories');
    dispatch(setDocumentCategories(data));
  } catch (err) {
    console.log(err);
  }
};

export const getInfrastructureInfo = id => async (dispatch) => {
  try {
    const res = await axios.get(`/api/rest/infrastructureobject/${id}`);
    dispatch(setInfrastructureInfo(res.data.data));
  } catch (e) {
    const { status } = e.response;

    if (status === 403) {
      dispatch(setForbidden());
    } else {
      handleError(e);
      if (status === 404) {
        dispatch(push(ROUTES_WAYS.SEARCH_INFRASTRUCTURE));
      }
    }
    dispatch(setLoading(false));
  }
};

export const getCertificatesInfo = id => async (dispatch) => {
  try {
    const res = await axios.get(`/api/rest/infrastructureobject/certificate/all/${id}?page=0&pagesize=50`);
    dispatch(setCertificateInfo(res.data.data));
  } catch (e) {
    handleError(e);
    dispatch(setLoading(false));
  }
};

export const getCertificateInfo = id => async (dispatch) => {
  try {
    const res = await axios.get(`/api/rest/infrastructureobject/certificate/${id}`);
    dispatch(editCertificate(res.data.data));
  } catch (e) {
    handleError(e);
    dispatch(setLoading(false));
  }
};

// reducer
const reducer = createReducer({
  [setInfrastructureInfo]: (state, data) => ({
    ...state,
    data,
    loading: false,
  }),
  [setCertificateInfo]: (state, payload) => ({
    ...state,
    certificates: payload,
  }),
  [editCertificate]: (state, payload) => ({
    ...state,
    editCertificate: {
      ...state.editCertificate,
      ...payload,
    },
  }),
  [setPagination]: (state, pagination) => ({
    ...state,
    pagination,
  }),
  [setLoading]: (state, loading) => ({
    ...state,
    loading,
  }),
  [updateData]: (state, payload) => ({
    ...state,
    newCertificate: {
      ...state.newCertificate,
      ...payload,
    },
  }),
  [setForbidden]: state => ({
    ...state,
    forbidden: true,
  }),
  [clearCertificate]: state => ({
    ...state,
    newCertificate: {},
  }),
  [setDocumentCategories]: (state, documentCategories) => ({
    ...state,
    documentCategories,
  }),
  [setDocumentTypes]: (state, documentTypes) => ({
    ...state,
    documentTypes,
  }),
  [setDocuments]: (state, documents) => ({
    ...state,
    documents,
  }),
  [clearData]: () => initialState,
}, initialState);

export default reducer;
