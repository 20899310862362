import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import { push, goBack } from 'connected-react-router';
import axios from 'axios';
import { reduce, isEmpty, get } from 'utils/lodash';

import { Notification } from 'components/rfs-ui';
import { handleError } from 'magic/handlers';
import { statuses } from '../constants';

const createAction = actionsFactory('certification/view/');

const initialState = {
  category: null,
  owner: false,
  ioId: null,
  name: '',
  loading: true,
};

export const init = createAction('init');
export const setLoading = createAction('setLoading');
export const updateAgreement = createAction('updateAgreement');
export const updateData = createAction('updateData');
export const updateDocuments = createAction('updateDocuments');
export const updateSection = createAction('updateSection');
export const updateRequisites = createAction('updateRequisites');
export const updateRequisiteSection = createAction('updateRequisiteSection');
export const clearData = createAction('clearData');

export const dropDocuments = (section, docType) => async (dispatch, getState) => {
  const state = getState().certificationApplications.view;
  const updatedSection = reduce(state[section], (fields, field, key) => {
    if (key !== docType) {
      return { ...fields, [key]: field };
    }

    delete field.documents;
    return !isEmpty(field) ? { ...fields, [key]: field } : fields;
  }, {});
  dispatch(updateSection({ section, data: !isEmpty(updatedSection) ? updatedSection : undefined }));
};

export const dropRequisites = requisiteKey => async (dispatch, getState) => {
  const { certificationPayment } = getState().certificationApplications.view;
  const updatedRequisites = reduce(certificationPayment, (fields, field, key) => {
    if (key !== requisiteKey) {
      return { ...fields, [key]: field };
    }
    return fields;
  }, {});
  dispatch(updateRequisites(!isEmpty(updatedRequisites) ? updatedRequisites : undefined));
};

export const changeStatus = newStatus => async (dispatch, getState) => {
  dispatch(setLoading(true));

  try {
    const { templates, isIncoming, loading, ...certificationStatement } = getState().certificationApplications.view;

    await axios.post('/api/rest/process/infrastructure/statement/status/change', {
      certificationStatement,
      processStatus: newStatus,
    });

    if (newStatus === statuses.DRAFT) {
      Notification.success({
        children: {
          message: 'Заявление сохранено как черновик!',
          icon: 'check',
        },
      });
      dispatch(goBack());
    } else if (newStatus === statuses.NEW) {
      dispatch(push(`/applications/certification/outgoing/${certificationStatement.id}`));
    } else {
      dispatch(push('/applications/certification'));
    }
  } catch (e) {
    handleError(e);
  } finally {
    dispatch(setLoading(false));
  }
};

const reducer = createReducer({
  [init]: (state, initialData) => ({
    ...state,
    ...initialData,
    loading: false,
  }),
  [setLoading]: (state, loading) => ({
    ...state,
    loading,
  }),
  [updateData]: (state, payload) => ({
    ...state,
    ...payload,
  }),
  [updateAgreement]: (state, payload) => ({
    ...state,
    [payload.section]: {
      ...get(state, payload.section, {}),
      [payload.docType]: {
        ...get(state, [payload.section, payload.docType], {}),
        agreed: payload.agreed,
      },
    },
  }),
  [updateDocuments]: (state, payload) => ({
    ...state,
    [payload.section]: {
      ...get(state, payload.section, {}),
      [payload.docType]: {
        ...get(state, [payload.section, payload.docType], {}),
        documents: payload.documents,
      },
    },
  }),
  [updateSection]: (state, payload) => ({
    ...state,
    [payload.section]: payload.data,
  }),
  [updateRequisites]: (state, payload) => ({
    ...state,
    certificationPayment: {
      ...get(state, 'certificationPayment', {}),
      [payload.section]: {
        ...get(state, ['certificationPayment', payload.section], {}),
        [payload.requisiteKey]: payload.value,
      },
    },
  }),
  [updateRequisiteSection]: (state, payload) => ({
    ...state,
    certificationPayment: {
      ...get(state, 'certificationPayment', {}),
      [payload.section]: payload.data,
    },
  }),
  [clearData]: () => initialState,
}, initialState);

export default reducer;
