/* eslint-disable no-console */
import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';
import { handleError } from 'magic/handlers';

const createAction = actionsFactory('controlIndicators');

export const setIndicatorsData = createAction('setIndicatorsData');
export const setIndicatorsLoading = createAction('setIndicatorsLoading');
export const setIndicatorsCalculating = createAction('setIndicatorsCalculating');
export const clearData = createAction('clearData');

export const getIndicatorsData = () => async (dispatch) => {
  try {
    dispatch(setIndicatorsLoading(true));
    const response = await axios.get('/api/rest/tessa/actualIndicatorsValue');
    dispatch(setIndicatorsData(response.data.data));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIndicatorsLoading(false));
  }
};

export const calculateIndicators = (population) => async (dispatch) => {
  try {
    dispatch(setIndicatorsCalculating(true));
    const response = await axios.post(`/api/rest/tessa/updateIndicatorsValue?population=${population}`, null);
    dispatch(setIndicatorsData(response.data.data));
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setIndicatorsCalculating(false));
  }
};
