import React, { Component } from 'react';
import { func, string, oneOfType, number, object } from 'prop-types';
import axios from 'axios';

import { Select } from 'components/rfs-ui';

import { DADATA_TOKEN } from 'constants/tokens';

export default class Dadata extends Component {
  static propTypes = {
    onChange: func.isRequired,
    requestParams: object,
    url: string.isRequired,
    value: oneOfType([number, string]),
  };

  static defaultProps = {
    requestParams: undefined,
    value: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: props.value || null,
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (value !== prevProps.value) {
      this.updateSelected();
    }
  }

  updateSelected = () => {
    const { value } = this.props;
    this.setState({ selected: value });
  };

  getSuggestions = async (value) => {
    const { url, requestParams } = this.props;
    try {
      const response = await axios({
        data: {
          ...requestParams,
          count: 10,
          query: value,
        },
        headers: {
          Accept: 'application/json',
          Authorization: DADATA_TOKEN,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        url,
      });
      return response.data.suggestions.map((suggestion) => {
        const { data: suggestionData, value: suggestionValue } = suggestion;
        return {
          data: suggestionData,
          label: suggestionValue,
          value: suggestionValue,
        };
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return [];
    }
  };

  handleChange = (e) => {
    const { onChange } = this.props;

    if (onChange) {
      this.setState({ selected: e });
      onChange(e);
    }
  };

  render() {
    const { selected } = this.state;
    const { requestParams, ...rest } = this.props;

    return <Select {...rest} value={selected} async onChange={this.handleChange} loadOptions={this.getSuggestions} clearable />;
  }
}
