import { number, object, shape, string } from 'prop-types';

import { PTOrganization } from './organizationPropTypes';

export const PTTelephoneType = {
  /**
   * @description Описание
   */
  description: string,
  /**
   * @description Идентификатор
   */
  id: number,
  /**
   * @description Наименование
   */
  name: string.isRequired,
  /**
   * @description Системеное наименование
   */
  sysName: string,
  /**
   * @description   Идентификатор с фронта
   */
  tempId: string,
};

/**
 * @description Телефон
 */
export const PTTelephone = {
  /**
   * @description Идентификатор
   */
  id: number,
  /**
   * @description Юридическое лицо
   */
  legal: object,
  /**
   * @description Номер телефона
   */
  number: string,

  organization: shape(PTOrganization),
  /**
   * @description Доб информация Физ лица лицо
   */
  person: object,
  /**
   * @description   Идентификатор с фронта
   */
  tempId: string,
  type: shape(PTTelephoneType),
};
