exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------ \nCommon\n--------------------------*/\n\n\n/* layouts width*/\n\n\n/* ------------------------ \nDark Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* input*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nLight Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nRating\n--------------------------*/\n\n\n.labelText-2d-JT {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-bottom: 20px;\n}\n\n\n.labelText-2d-JT:last-child {\n    margin-bottom: 0;\n  }\n\n\n.label-eXUQQ {\n  color: #9196af;\n  white-space: nowrap;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  height: 10px;\n}\n\n\n.label_margin_yes-ZSSeh {\n  margin-right: 10px;\n}\n\n\n.text-3S7gh {\n  color: #fff;\n  overflow: hidden;\n  overflow-wrap: break-word;\n  word-break: break-word;\n}\n\n\n.theme-dark .text-3S7gh {\n    color: #fff;\n  }\n\n\n.theme-light .text-3S7gh {\n    color: #212121;\n  }\n\n\n.tooltipContainer-GyFnn {\n  display: inline-block;\n  margin-left: 10px;\n}\n\n\n.tooltipContainer-GyFnn .tooltip-3vMbA, .tooltipContainer-GyFnn:hover .tooltip-3vMbA {\n      width: -webkit-max-content;\n      width: -moz-max-content;\n      width: max-content;\n      max-width: 300px;\n    }\n\n\n.tooltipText-178l4 p {\n    margin: 0;\n    padding: 0;\n  }\n\n", ""]);

// exports
exports.locals = {
	"labelText": "labelText-2d-JT",
	"label": "label-eXUQQ",
	"label_margin_yes": "label_margin_yes-ZSSeh",
	"text": "text-3S7gh",
	"tooltipContainer": "tooltipContainer-GyFnn",
	"tooltip": "tooltip-3vMbA",
	"tooltipText": "tooltipText-178l4"
};