import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';
import { handleError, handleSuccess } from 'magic/handlers';

const createAction = actionsFactory('regulation/view/');

export const clearData = createAction('clearData');
export const setRegulations = createAction('setRegulations');
export const addRegulations = createAction('addRegulations');
export const removeRegulations = createAction('addRegulations');
export const setRegulationsLoading = createAction('setRegulationsLoading');
export const setRegulationsCreateLoading = createAction('setRegulationsCreateLoading');
export const setRegulationsRemoveLoading = createAction('setRegulationsRemoveLoading');

export const getRegulationsList = (params) => async (dispatch) => {
  try {
    dispatch(setRegulationsLoading(true));

    const {
      data: { data },
    } = await axios.get('/api/rest/judiciary/regulation/search', { params });
    dispatch(setRegulations(data));
  } catch (err) {
    handleError(err);
    dispatch(setRegulationsLoading(false));
  }
};

export const onCreateRegulation = (params) => async (dispatch) => {
  try {
    dispatch(setRegulationsCreateLoading(true));

    const {
      data: { data },
    } = await axios.post('/api/rest/judiciary/regulation/save', params);
    dispatch(addRegulations(data));
    handleSuccess('Регламент успешно создан!');
  } catch (err) {
    handleError(err);
    dispatch(setRegulationsCreateLoading(false));
  }
};

export const onRemoveRegulation = (regulationId) => async (dispatch) => {
  try {
    dispatch(setRegulationsRemoveLoading(true));

    await axios.delete(`/api/rest/judiciary/regulation/${regulationId}`);
    dispatch(removeRegulations(regulationId));
    handleSuccess('Регламент успешно удален!');
  } catch (err) {
    handleError(err);
    dispatch(setRegulationsRemoveLoading(false));
  }
};
