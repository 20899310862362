import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import { push } from 'connected-react-router';
import { handleError } from 'magic/handlers';
import axios from 'axios';
import { ROUTES_WAYS } from 'constants/routesWays';

const createAction = actionsFactory('team');

const initialState = {
  loading: false,
  saving: false,
  team: {},
  organization: {},
  dictionaries: {
    loading: false,
  },
};

// actions
export const setLoading = createAction('setLoading');
export const setTeam = createAction('setTeam');
export const setOrganization = createAction('setOrganization');
export const setSaving = createAction('setSaving');
export const setDictionary = createAction('setDictionary');

export const saveTeam = body => async (dispatch) => {
  try {
    dispatch(setSaving(true));
    const response = await axios.post('/api/rest/organization/team/save', body);
    dispatch(setSaving(false));
    dispatch(push(ROUTES_WAYS.NATIONAL_TEAM.replace(':id', response.data.data)));
  } catch (e) {
    dispatch(setSaving(false));
    handleError(e);
  }
};

export const getTeam = id => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const teamRes = await axios.get(`/api/rest/organization/team/forview/${id}`);
    const team = teamRes.data.data;
    const organizationRes = await axios.get(`/api/rest/organization/${team.organization.id}`);
    const organization = organizationRes.data.data;
    dispatch(setTeam({ ...team, organization }));
    dispatch(setLoading(false));
  } catch (e) {
    handleError(e);
    dispatch(setLoading(false));
  }
};

export const getOrganization = id => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const organizationRes = await axios.get(`/api/rest/organization/${id}`);
    dispatch(setOrganization(organizationRes.data.data));
    dispatch(setLoading(false));
  } catch (e) {
    handleError(e);
    dispatch(setLoading(false));
  }
};

export const getTeamForEdit = id => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const teamRes = await axios.get(`/api/rest/organization/team/foredit/${id}`);
    const team = teamRes.data.data;
    const organizationRes = await axios.get(`/api/rest/organization/${team.organization.id}`);
    const organization = organizationRes.data.data;
    dispatch(setTeam({ ...team, organization }));
    dispatch(setOrganization(organization));
    dispatch(setLoading(false));
  } catch (e) {
    handleError(e);
    dispatch(setLoading(false));
  }
};

export const getTeamTypes = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/organization/team/teamType');
    dispatch(setDictionary({ teamTypes: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getDisciplines = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/organization/disciplines');
    dispatch(setDictionary({ disciplines: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getGenders = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/person/gender/getall');
    dispatch(setDictionary({ genders: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getSeasons = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/organization/seasons');
    dispatch(setDictionary({ seasons: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getBaseYears = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/coachgroup/baseyearbirths');
    dispatch(setDictionary({ baseYears: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getPositionsTeam = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/organization/team/positionsTeam');
    dispatch(setDictionary({ positionsTeam: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getDictionaries = () => (dispatch) => {
  dispatch(setDictionary({ loading: true }));
  return Promise.all([
    dispatch(getTeamTypes()),
    dispatch(getDisciplines()),
    dispatch(getGenders()),
    dispatch(getSeasons()),
    dispatch(getBaseYears()),
    dispatch(getPositionsTeam()),
  ]).then(() => {
    dispatch(setDictionary({ loading: false }));
  });
};

const reducer = createReducer({
  [setLoading]: (state, loading) => ({
    ...state,
    loading,
  }),
  [setTeam]: (state, team) => ({
    ...state,
    team,
  }),
  [setOrganization]: (state, organization) => ({
    ...state,
    organization,
  }),
  [setSaving]: (state, saving) => ({
    ...state,
    saving,
  }),
  [setDictionary]: (state, dictionary) => ({
    ...state,
    dictionaries: {
      ...state.dictionaries,
      ...dictionary,
    },
  }),
}, initialState);

export default reducer;
