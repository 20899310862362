import * as React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TextareaField from 'components/ui/Textarea/Textarea';
import Label from 'components/ui/Label/Label';
import styles from './Textarea.scss';

const cn = classNames.bind(styles);

class Textarea extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string, // плейсхолдер инпута
    value: PropTypes.string, // значение инпута
    defaultValue: PropTypes.string,
    className: PropTypes.string, // внешний класс
    disabled: PropTypes.bool, // активность инпута (актив или нет)
    inline: PropTypes.bool, // инлайновый тип контейнера
    title: PropTypes.string,
    onDataChange: PropTypes.func,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    light: PropTypes.bool,
    rows: PropTypes.number,
  };

  /**
   * Функция-обертка над внешней функцией onChange, изменяющая стейт
   */
  handleChange = (e, onChange = this.props.onChange) => {
    const { value } = e.target;

    if (onChange && typeof onChange === 'function') {
      onChange(value, e);
    }
  };

  render() {
    const {
      id,
      name,
      placeholder, // плейсхолдер инпута
      value, // значение инпута
      defaultValue,
      className, // внешний класс
      disabled, // активность инпута (актив или нет)
      inline, // инлайновый тип контейнера
      title,
      required,
      onDataChange,
      light,
      maxLength = 1000,
      rows,
    } = this.props;

    const isRequired = required && !disabled;
    const containerClass = cn(styles.container, { [className]: className, inline });
    const textareaClass = cn({ [styles.textareaField]: !!title, required: isRequired, light }, { light });

    const onChangeEvent = (event) => this.handleChange(event, onDataChange);

    return (
      <div className={containerClass}>
        {title && (
          <Label className={styles.textareaLabel} disabled={disabled} required={isRequired} htmlFor={id}>
            {title}
          </Label>
        )}
        {isRequired && <div className={styles.requireMessage}>Обязательное поле</div>}
        <TextareaField
          id={id}
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          onChange={onChangeEvent}
          className={textareaClass}
          required={isRequired}
          value={value}
          defaultValue={defaultValue}
          maxLength={maxLength}
          rows={rows}
        />
      </div>
    );
  }
}
export default Textarea;
