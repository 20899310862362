import { createReducer } from 'redux-act';

import {
  clearData,
  setContractData,
  setContractLoading,
  setResendInstructionInProgress,
  setTransferAgreement,
} from 'redux/actions/Contracts/currentActions';

const initialState = {
  data: {},
  transferAgreement: {},
  loading: false,
  resendInstructionInProgress: false,
};

const currentReducer = createReducer(
  {
    [clearData]: () => initialState,
    [setContractData]: (state, data) => ({ ...state, data }),
    [setTransferAgreement]: (state, transferAgreement) => ({ ...state, transferAgreement }),
    [setContractLoading]: (state, loading) => ({ ...state, loading }),
    [setResendInstructionInProgress]: (state, resendInstructionInProgress) => ({ ...state, resendInstructionInProgress }),
  },
  initialState,
);

export default currentReducer;
