import React from 'react';
import { shape } from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';

import { Logo, Button, LinkButton, Avatar } from 'components/ui';

import { ROUTES_WAYS } from 'constants/routesWays';
import { makeImageUrl } from 'utils/createUrl';

import * as _sessionActions from 'redux/modules/session/session';
import NavbarHeader from 'components/composed/Navbar/NavbarHeader';
// import PersonFIFADoubles from './PersonFIFADoubles';

import MobileMenu from './MobileMenu/MobileMenu';
import LinkSelect from './LinkSelect/LinkSelect';
import styles from './Header.scss';
import { getCurrentCompetitionsAddress } from 'redux/actions/competitionsIntegrationActions';
import { selectCompetitionsAddress } from 'redux/selectors/competitionIntegrationSelectors';

class Header extends React.Component {
  static propTypes = {
    profile: shape(),
    sessionActions: shape(),
    user: shape(),
  };

  static defaultProps = {
    profile: {},
    sessionActions: undefined,
    user: {},
  };

  constructor() {
    super();
    this.state = {
      showUserWindow: false,
    };
  }

  setUserWindowNode = (node) => {
    this.userWindowNode = node;

    if (node) {
      document.addEventListener('click', this.handleClickOutside);
    }
  };

  showUserWindow = () => this.setState({ showUserWindow: true });

  handleClickOutside = (event) => {
    if (this.userWindowNode && !this.userWindowNode.contains(event.target)) {
      document.removeEventListener('click', this.handleClickOutside);
      this.setState({ showUserWindow: false });
    }
  };

  handleLogout = () => {
    const { sessionActions } = this.props;
    sessionActions.makeLogout();
  };

  componentDidMount() {
    this.props.competitionsActions.getCurrentCompetitionsAddress()
  }


  render() {
    const { user, profile } = this.props;
    const { showUserWindow } = this.state;
    const { hasMyOrgs } = user;
    const { oranizations } = user;

    return (
      <header className={styles.header}>
        <MobileMenu org={oranizations.length ? oranizations[0].id : undefined} />
        <Link className={styles.logoLink} to="/management">
          <Logo small />
        </Link>
        <nav className={styles.navigation}>
          <NavbarHeader light />
        </nav>
        <LinkSelect />
        <div className={styles.headerInfo}>
          {/* Скрыто в рамках задачи RFSEIAS-6504 */}
          {/* <PersonFIFADoubles /> */}
          <div className={styles.userContainer}>
            <Button type="text" className={cn(styles.userButton, { [styles.active]: showUserWindow })} onClick={this.showUserWindow}>
              <Avatar image={makeImageUrl(profile.mainInfo?.image)} size={40} circle />
            </Button>
            {showUserWindow && (
              <div className={styles.userWindow} ref={this.setUserWindowNode}>
                {(user.personid || user.personid === 0) && (
                  <LinkButton type="text" to={`/profile/${user.personid}`} className={styles.menuButton} buttonClassName={styles.button}>
                    Мой профиль
                  </LinkButton>
                )}
                {hasMyOrgs && (
                  <LinkButton type="text" to={ROUTES_WAYS.OWN_ORGANIZATIONS} className={styles.menuButton} buttonClassName={styles.button}>
                    Мои организации
                  </LinkButton>
                )}
                {/* <LinkButton type="text" to={`/settings/${user.personid}`} className={styles.menuButton} buttonClassName={styles.button}> */}
                {/*  Настройки */}
                {/* </LinkButton> */}
                <Button type="text" onClick={this.handleLogout} className={styles.menuButton}>
                  Выход
                </Button>
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.profile.view.data,
  profile: state.core.session.userProfile,
  user: state.core.session.user,
});

const mapDispatchToProps = (dispatch) => ({
  sessionActions: bindActionCreators(_sessionActions, dispatch),
  competitionsActions: bindActionCreators({getCurrentCompetitionsAddress}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
