import { combineReducers } from 'redux';

import organizationAdd from 'components/pages/Organization/Add/reducer';
import organizationEdit from 'components/pages/Organization/Edit/reducer';
import organizationView from 'components/pages/Organization/View/reducer';
import photoGallery from './photoGalleryReducer';
import contacts from './contactsReducer';
import accreditations from './accreditationReducer';
import uniform from './uniformReducer';
import structure from './structureReducer';
import licenses from './licensesReducer';
import decisions from './decisionsReducer';

export default combineReducers({
  add: organizationAdd,
  decisions,
  edit: organizationEdit,
  licenses,
  view: organizationView,
  photoGallery,
  contacts,
  accreditations,
  uniform,
  structure,
});
