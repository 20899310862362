import React from 'react';
import PropTypes from 'prop-types';

import { Radio } from 'components/rfs-ui';
import { Button } from 'components/ui';
import dateFormats from 'constants/dateFormats';

import css from './Components.scss';

const viewTitles = {
  week: 'Неделя',
  month: 'Месяц',
};

class Toolbar extends React.Component {
  static propTypes = {
    date: PropTypes.any,
    localizer: PropTypes.object,
    children: PropTypes.node,
    view: PropTypes.string,
    views: PropTypes.arrayOf(PropTypes.string),
    onNavigate: PropTypes.func,
    onView: PropTypes.func,
    onViewChange: PropTypes.func,
  };

  state = {};

  static getDerivedStateFromProps (props) {
    const { date, view, localizer } = props;
    let now, calendarDate;

    if (view === 'week') {
      now = localizer.format(new Date(), dateFormats.APP_DATE_FORMAT);
      calendarDate = localizer.format(date, dateFormats.APP_DATE_FORMAT);
    } else {
      const monthYearFormat = 'MM YYYY';
      now = localizer.format(new Date(), monthYearFormat);
      calendarDate = localizer.format(date, monthYearFormat);
    }

    return { today: now === calendarDate };
  }

  handleNavigate = (action, date) => () => {
    this.props.onNavigate(action, date);
  }

  handleViewChange = view => () => {
    this.props.onView(view);
  }

  render () {
    const {
      date,
      localizer,
      view: selectedView = 'week',
      views = ['week'],
    } = this.props;

    return (
      <div className={css.toolbarContainer}>
        <div className={css.toolbar}>
          <div className={css.controls}>
            <div className={css.buttonContainer}>
              <Button
                type="secondary"
                icon="angle-left"
                className={css.arrows}
                onClick={this.handleNavigate('PREV')}
              />
              <Button
                type="secondary"
                onClick={this.handleNavigate('TODAY')}
                disabled={this.state.today}
              >
                Сегодня
              </Button>
              <Button
                type="secondary"
                icon="angle-right"
                className={css.arrows}
                onClick={this.handleNavigate('NEXT')}
              />
            </div>
            <span className={css.date}>{localizer.format(date, 'MMMM YYYY')}</span>
            {
              views.length > 1 &&
              <div>
                {views.map(viewOption => (
                  <Radio
                    id={viewOption}
                    title={viewTitles[viewOption]}
                    labelPosition="right"
                    checked={viewOption === selectedView}
                    onChange={this.handleViewChange(viewOption)}
                    value={viewOption}
                    name="viewOption"
                    className={css.radioOption}
                  />
                ))}
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Toolbar;
