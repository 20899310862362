import React from 'react';
import { components } from 'react-select';
import { Icon } from 'components/ui';
import css from '../styles.scss';

const ClearIndicator = (props) => {
  return components.ClearIndicator && (
    <components.ClearIndicator {...props}>
      <div className={css.clearWrapper}>
        <Icon icon='times' />
      </div>
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
