import axios from 'axios';
import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { removeListFromCollection, uploadListToCollection } from 'utils/uploadFiles';

const createAction = actionsFactory('appeals/constructor/');

export const clearData = createAction('clearData');
export const setAppealsTypes = createAction('setAppealsTypes');
export const addAppealType = createAction('addAppealType');
export const setAppealsGroups = createAction('setAppealsGroups');

export const getAppealsTypes = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/appeals/types');
    dispatch(setAppealsTypes(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setAppealsTypes([]));
  }
};

export const getAppealsGroups = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/appeals/groups');
    dispatch(setAppealsGroups(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setAppealsGroups([]));
  }
};

export const postAppealsType = (data, callback) => async (dispatch) => {
  try {
    const { docsToRemove, docsToUpload, ...body } = data;

    const response = await axios.post('/api/rest/appeals/type/add', body);
    const appealTypeId = response.data.data.id;

    await removeListFromCollection(docsToRemove, 'appeal_type');
    await uploadListToCollection(docsToUpload, 'appeal_type', appealTypeId);

    handleSuccess('Тип обращения успешно сохранен!');
    await dispatch(getAppealsTypes());

    if (callback) {
      callback(response.data.data);
    }
  } catch (err) {
    handleError(err);
  }
};
