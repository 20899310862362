import { combineReducers } from 'redux';
import view from './viewReducer';
import creation from './creationReducer';
import edit from './editRedures';

const casesReducer = combineReducers({
  creation,
  edit,
  view,
});

export default casesReducer;
