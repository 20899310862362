import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { uploadFilesMultiple } from 'utils/uploadFiles';

const createAction = actionsFactory('profile/footballerRank/');

export const clearData = createAction('clearData');
export const setFootballerRankData = createAction('setFootballerRankData');
export const setFootballerRankLoading = createAction('setFootballerRankLoading');

// В целом в системе нет отличий между понятиями Разряд и Звание
// Для того чтобы выдавать более точные сообщения, хардкодим сиснеймы
// званий. Все остальное является разрядом
const sportTitles = ['msInternationalRank', 'msRank'];

function isSportTitle(rank) {
  return sportTitles.includes(rank?.sportrank?.sysName);
}

export const getFootballerRank = (id) => async (dispatch) => {
  try {
    dispatch(setFootballerRankLoading(true));
    const {
      data: { data },
    } = await axios.get(`/api/rest/person/personrank/${id}`);
    dispatch(setFootballerRankData(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(setFootballerRankData(null));
  } finally {
    dispatch(setFootballerRankLoading(false));
  }
};

export const createFootballerRank = (data) => async (dispatch) => {
  try {
    const body = { ...data };
    body.documentFile = await uploadFilesMultiple(body.documentFile);

    await axios.post('/api/rest/person/personrank/add', body);
    handleSuccess(isSportTitle(body) ? 'Звание успешно присвоено' : 'Разряд успешно присвоен');

    await dispatch(getFootballerRank(body.person.id));
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const deleteFootballerRank = (rank, footballerId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/person/personrank/delete/${rank.id}`);
    await dispatch(getFootballerRank(footballerId));
    handleSuccess(isSportTitle(rank) ? 'Звание футболиста успешно удалено' : 'Разряд футболиста успешно удален');
  } catch (error) {
    handleError(error);
  }
};

export const suspendFootballerRank = (rank, footballerId, dateAvoided) => async (dispatch) => {
  try {
    const params = { dateEnd: dateAvoided };
    await axios.post(`/api/rest/person/personrank/revoke/${footballerId}`, null, { params });
    await dispatch(getFootballerRank(footballerId));
    handleSuccess(isSportTitle(rank) ? 'Звание футболиста успешно отозвано' : 'Разряд футболиста успешно отозван');
  } catch (error) {
    handleError(error);
  }
};
