import axios from 'axios';
import { CREATOR_TYPES } from 'constants/appeals';
import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { removeListFromCollection, uploadListToCollection, getFilesCollection } from 'utils/uploadFiles';

const createAction = actionsFactory('appeals/creation/');

export const clearData = createAction('clearData');
export const setAppealData = createAction('setAppealData');
export const setAppealLoading = createAction('setAppealLoading');

export const setAppealTypesData = createAction('setAppealTypesData');
export const setAppealTypesLoading = createAction('setAppealTypesLoading');

export const setAppealTemplatesData = createAction('setAppealTemplatesData');
export const setAppealTemplatesLoading = createAction('setAppealTemplatesLoading');

export const getAppeal = (id) => async (dispatch) => {
  try {
    dispatch(setAppealLoading(true));
    const {
      data: { data: appeal },
    } = await axios.get(`/api/rest/appeals/${id}`);
    const documents = await getFilesCollection(id, 'appeal');
    appeal.documents = documents.filter((doc) => doc.param === CREATOR_TYPES.MEMBER);
    appeal.rfsDocuments = documents.filter((doc) => doc.param === CREATOR_TYPES.RFS);
    appeal.initialDocuments = appeal.documents;

    dispatch(setAppealData(appeal));
  } catch (err) {
    handleError(err);
    dispatch(setAppealData(null));
  } finally {
    dispatch(setAppealLoading(false));
  }
};

export const getAppealsTypes = () => async (dispatch) => {
  try {
    dispatch(setAppealTypesLoading(true));
    const response = await axios.get('/api/rest/appeals/types');
    dispatch(setAppealTypesData(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setAppealTemplatesData([]));
  } finally {
    dispatch(setAppealTypesLoading(false));
  }
};

export const getTemplates = (typeId) => async (dispatch) => {
  try {
    dispatch(setAppealTemplatesLoading(true));
    const data = await getFilesCollection(typeId, 'appeal_type');
    dispatch(setAppealTemplatesData(data));
  } catch (err) {
    handleError(err);
    dispatch(setAppealTemplatesData([]));
  } finally {
    dispatch(setAppealTemplatesLoading(false));
  }
};

export const createAppeal = (data) => async () => {
  try {
    const { docsToRemove, docsToUpload, ...body } = data;
    const response = await axios.post('/api/rest/appeals/add', body);
    const appealId = response.data.data.id;

    await removeListFromCollection(docsToRemove, 'appeal');
    await uploadListToCollection(docsToUpload, 'appeal', appealId, undefined, CREATOR_TYPES.MEMBER);
    handleSuccess('Обращение успешно создано!');
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const deleteAppeal = (appealId) => async () => {
  try {
    await axios.delete(`/api/rest/appeals/delete/${appealId}`);
    handleSuccess('Обращение успешно удалено!');
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const updateAppeal = (data) => async () => {
  try {
    const { docsToRemove, docsToUpload, ...body } = data;
    const response = await axios.post('/api/rest/appeals/update/sender', body);
    const appealId = response.data.data.id;

    await removeListFromCollection(docsToRemove, 'appeal');
    await uploadListToCollection(docsToUpload, 'appeal', appealId, undefined, CREATOR_TYPES.MEMBER);
    handleSuccess('Обращение успешно обновлено!');
  } catch (err) {
    handleError(err);
    throw err;
  }
};
