import axios from 'axios';
import { handleError, handleSuccess } from 'magic/handlers';
import actionsFactory from 'magic/actionsFactory';

const createAction = actionsFactory('cases/edit/');

export const clearData = createAction('clearData');
export const setCaseData = createAction('setCaseData');
export const setCaseLoading = createAction('setCaseLoading');

export const clearDecisions = createAction('clearDecisions');
export const setDecisionsData = createAction('setDecisionsData');
export const addDecisions = createAction('addDecisions');
export const setDecisionsLoading = createAction('setDecisionsLoading');
export const setSaveDecisionLoading = createAction('setSaveDecisionLoading');

export const onRemoveDecision = createAction('onRemoveDecision');
export const setRemoveDecisionLoading = createAction('setRemoveDecisionLoading');

export const setDocuments = createAction('setDocuments');
export const setUploadDocumentLoading = createAction('setUploadLoading');

export const setRemoveDocumentLoading = createAction('setRemoveDocumentLoading');
export const setDocument = createAction('setDocument');

export const setSaveViolationLoading = createAction('setSaveViolationLoading');

export const getCase = (caseId) => async (dispatch) => {
  try {
    dispatch(setCaseLoading(true));

    const {
      data: { data },
    } = await axios.get(`/api/rest/judiciary/case/${caseId}`);
    dispatch(setCaseData(data));
  } catch (err) {
    handleError(err);
    dispatch(setCaseLoading(false));
  }
};

export const saveDecision = (values, caseId) => async (dispatch) => {
  const sanctionsObject = values.sanctionIds || {};
  const sanctionIds = Object.keys(sanctionsObject).filter((sanctionId) => sanctionsObject[sanctionId].selected);

  try {
    dispatch(setSaveDecisionLoading(true));
    const payload = {
      ...values,
      sanctionIds,
    };

    const {
      data: { data },
    } = await axios.post(`/api/rest/judiciary/decision/add/${caseId}`, payload);
    dispatch(addDecisions(data));
    handleSuccess('Решение успешно добавлено!');
  } catch (err) {
    handleError(err);
    dispatch(setSaveDecisionLoading(false));
  }
};

export const getDecisions = (caseId) => async (dispatch) => {
  try {
    dispatch(setDecisionsLoading(true));

    const {
      data: { data },
    } = await axios.get('/api/rest/judiciary/decision/by-case', { params: { caseId } });

    dispatch(setDecisionsData(data));
  } catch (err) {
    handleError(err);
    dispatch(setDecisionsLoading(false));
  }
};

export const removeDecision = (decisionId) => async (dispatch) => {
  try {
    dispatch(setRemoveDecisionLoading(true));

    await axios.delete(`/api/rest/judiciary/decision/${decisionId}`);
    dispatch(onRemoveDecision(decisionId));
    handleSuccess('Решение успешно удалено!');
  } catch (err) {
    handleError(err);
    dispatch(setRemoveDecisionLoading(false));
  }
};

export const uploadCaseDocument = (caseId, file, name) => async (dispatch) => {
  try {
    dispatch(setUploadDocumentLoading(true));

    const payload = { file, name };

    const {
      data: { data },
    } = await axios.post(`/api/rest/judiciary/case/${caseId}/document`, payload);
    dispatch(setDocuments(data));
    handleSuccess('Документ успешно добавлен!');
  } catch (err) {
    handleError(err);
    dispatch(setUploadDocumentLoading(false));
  }
};

export const removeCaseDocument = (caseId, documentId) => async (dispatch) => {
  try {
    dispatch(setRemoveDocumentLoading(true));

    await axios.delete(`/api/rest/judiciary/case/${caseId}/document/${documentId}`);
    dispatch(setDocument(documentId));
    handleSuccess('Документ успешно удален!');
  } catch (err) {
    handleError(err);
    dispatch(setRemoveDocumentLoading(false));
  }
};

export const saveViolation = (caseId, values, successCallback) => async (dispatch) => {
  try {
    dispatch(setSaveViolationLoading(true));

    const getKeyValue = (key) => (key.person ? 'person' : key.organization ? 'organization' : undefined);

    await axios.post(`/api/rest/judiciary/case/${caseId}/violation/save`, {
      ...values,
      defendant: {
        [getKeyValue(values.disciplinaryCaseDefendant)]: {
          id: values.disciplinaryCaseDefendant[getKeyValue(values.disciplinaryCaseDefendant)].id,
        },
      },
      participants: values.participants?.map((item) => ({ [getKeyValue(item)]: { id: item[getKeyValue(item)]?.id } })),
    });
    dispatch(setSaveViolationLoading(false));
    if (successCallback) successCallback()
    handleSuccess('Нарушения успешно сохранены!');
  } catch (err) {
    handleError(err);
    dispatch(setSaveViolationLoading(false));
  }
};
