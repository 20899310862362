import { createReducer } from 'redux-act';
import { LOADING_STATUSES } from 'constants/loadingStatuses';

import {
  clearListData,
  setListDataCache,
  setListDataLoading,
  setListDataFailure,
  setListDataSuccess,
} from 'redux/actions/Dictionaries/listActions';

const initialState = {
  cache: {},
  data: [],
  status: LOADING_STATUSES.INITIAL,
};

const listReducer = createReducer(
  {
    [clearListData]: () => ({}),
    [setListDataCache]: (state, { data, source }) => ({
      ...state,
      cache: {
        ...state.cache,
        [source]: data,
      },
    }),
    [setListDataFailure]: (state) => ({
      ...state,
      status: LOADING_STATUSES.FAILURE,
    }),
    [setListDataLoading]: (state) => ({
      ...state,
      data: [],
      status: LOADING_STATUSES.LOADING,
    }),
    [setListDataSuccess]: (state, data) => ({
      ...state,
      data,
      status: LOADING_STATUSES.SUCCESS,
    }),
  },
  initialState,
);

export default listReducer;
