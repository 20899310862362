export const statuses = {
  DRAFT: 0, // "Черновик"
  NOTES: 2, // "Есть замечания"
  PAYMENT: 3, // "Необходима оплата"
  PAID: 4, // "Оплачено"
  AGREED: 5, // "Сертификация"
  NOTESADDRESSED: 6, // "Замечания устранены"
  NEW: 7, // "Новая"
  REJECT: 8, // "Отклонена"
};

export const categories = {
  FIRST: 'first',
  SECOND: 'second',
  THIRD: 'third',
  FOURTH: 'fourth',
  FIFTH: 'fifth',
  SIXTH: 'sixth',
  HIGHER: 'higher',
};
