import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';

const createAction = actionsFactory('training/plan/participants/');

const initialState = {};

export const fillParticipants = createAction('fillParticipants');
export const updateParticipants = createAction('updateParticipants');
export const clearParticipants = createAction('clearParticipants');

const reducer = createReducer(
  {
    [clearParticipants]: () => initialState,
    [fillParticipants]: (state, participantsData) => participantsData,
    [updateParticipants]: (state, players) => ({
      ...state,
      players: {
        ...state.players,
        players,
      },
    }),
  },
  initialState,
);

export default reducer;
