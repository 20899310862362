import { createReducer } from 'redux-act';
import { get, isFunction, isEmpty } from 'utils/lodash';
import axios from 'axios';
import { push } from 'connected-react-router';
import qs from 'qs';

import { DEFAULT_PAGINATION } from 'constants/pagination';
import { ORGANIZATION_TYPES } from 'constants/organizationTypes';
import { ROUTES_WAYS } from 'constants/routesWays';
import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { uploadFilesMultiple } from 'utils/uploadFiles';

import { categoryHistorySelector } from './selectors';

const { CLUB, MRO, RFS, FEDERATION } = ORGANIZATION_TYPES;
const ORGANIZATIONS_WITH_STATS = [MRO, RFS, FEDERATION, CLUB];

const createAction = actionsFactory('organization/view');

const initialState = {
  activityHistoryCards: [],
  categoryHistory: {
    data: [],
    loading: false,
    pagination: DEFAULT_PAGINATION,
  },
  competitionHistory: {
    data: [],
    filters: {},
    loaded: false,
    loading: false,
    pagination: DEFAULT_PAGINATION,
  },
  data: {},
  dictionaries: {
    loading: false,
  },
  loading: true,
  organizationMembers: [],
  organizationMembersPagination: DEFAULT_PAGINATION,
  organizationMembersSection: null,
  players: [],
  restrictions: [],
  statistics: {},
  teams: {
    loading: false,
    pagination: {},
    results: [],
  },
  regions: [],
  socialLinks: [],
};

// actions
export const setOrganizationInfo = createAction('setOrganizationInfo');
export const setLoading = createAction('setLoading');
export const setPlayers = createAction('setPlayers');
export const clearData = createAction('clearData');
export const clearOrganizationData = createAction('clearOrganizationData');
export const setDictionary = createAction('setDictionary');
export const setTeams = createAction('setTeams');
export const setStatistics = createAction('setStatistics');
export const setRestrictions = createAction('setRestrictions');
export const clearRestrictions = createAction('clearRestrictions');

export const setCompetitionHistoryData = createAction('setCompetitionHistoryData');
export const setCompetitionHistoryLoading = createAction('setCompetitionHistoryLoading');
export const setCompetitionHistoryFilters = createAction('setCompetitionHistoryFilters');
export const setCompetitionHistoryPagination = createAction('setCompetitionHistoryPagination');
export const clearCompetitionHistory = createAction('clearCompetitionHistory');
export const setLogo = createAction('setLogo');
export const setRegions = createAction('setRegions');
export const setSocialLinks = createAction('setSocialLinks');
export const setTournaments = createAction('setTournaments');

export const getOrganizationInfo = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const {
      data: { data: orgData },
    } = await axios.get(`/api/rest/organization/${id}`);

    if (orgData.parent) {
      const parentResponse = await axios.get(`/api/rest/organization/${orgData.parent.id}`);
      orgData.parentOrg = parentResponse.data.data;
    }

    dispatch(setOrganizationInfo(orgData));
    dispatch(setStatistics({}));

    if (ORGANIZATIONS_WITH_STATS.includes(orgData.type.discriminator)) {
      const {
        data: { data: orgStats },
      } = await axios.get(`/api/rest/organization/stats/${id}`);
      dispatch(setStatistics(orgStats));
    }
  } catch (e) {
    const status = get(e, 'response');

    handleError(e);

    if (status === 404) {
      dispatch(push(ROUTES_WAYS.SEARCH_ORGANIZATION));
    }
  } finally {
    dispatch(setLoading(false));
  }
};

export const getTeamTypes = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/organization/team/teamType');
    dispatch(setDictionary({ teamTypes: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getDisciplines = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/organization/disciplines');
    dispatch(setDictionary({ disciplines: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getGenders = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/person/gender/getall');
    dispatch(setDictionary({ genders: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getSeasons = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/organization/seasons');
    dispatch(setDictionary({ seasons: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getBaseYears = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/coachgroup/baseyearbirths');
    dispatch(setDictionary({ baseYears: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getCitizenship = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/person/country/getall');
    dispatch(setDictionary({ citizenship: response.data.data }));
  } catch (e) {
    console.log('e', e);
    handleError(e);
  }
};

export const getStatus = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/organization/status');
    dispatch(setDictionary({ status: response.data.data }));
  } catch (e) {
    console.log('e', e);
    handleError(e);
  }
};

export const getStatisticTypes = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/organization/transfer/statistic/event-types');
    dispatch(setDictionary({ statisticTypes: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getCompetitionTypes = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/organization/competitionTypes');
    dispatch(setDictionary({ competitionTypes: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getSocialNetworks = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/dictionary/socialNetworkType');
    dispatch(setDictionary({ socials: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getDictionaries = () => (dispatch) => {
  dispatch(setDictionary({ loading: true }));
  return Promise.all([
    dispatch(getTeamTypes()),
    dispatch(getDisciplines()),
    dispatch(getGenders()),
    dispatch(getSeasons()),
    dispatch(getBaseYears()),
    dispatch(getCitizenship()),
    dispatch(getStatus()),
    dispatch(getStatisticTypes()),
    dispatch(getCompetitionTypes()),
    dispatch(getSocialNetworks()),
  ]).then(() => {
    dispatch(setDictionary({ loading: false }));
  });
};

export const findTeams = (params = {}, pagePagination = {}) => async (dispatch, getState) => {
  dispatch(setTeams({ loading: true }));
  const { pagination } = getState().organization.view.teams;
  const defaultPerPage = isEmpty(pagePagination) ? DEFAULT_PAGINATION.perPage : pagination.perPage;
  const { page = DEFAULT_PAGINATION.page, perPage: pagesize = defaultPerPage } = pagePagination;
  try {
    const response = await axios.get('/api/rest/organization/team/findByParam', { params: { ...params, page, pagesize } });
    const { data, pageData } = response.data;
    dispatch(
      setTeams({
        loading: false,
        pagination: {
          itemsCount: pageData.size,
          page,
          pageCount: pageData.countPage,
          perPage: pagesize,
        },
        results: data,
      }),
    );
  } catch (e) {
    handleError(e);
    dispatch(setTeams({ loading: false }));
  }
};

export const setOrganizationMembers = createAction('setOrganizationMembers');

export const setOrganizationMembersSection = createAction('setOrganizationMembersSection');

export const removeOrganizationMember = (memberId, onSuccess) => async (dispatch, getState) => {
  try {
    const response = await axios.delete('/api/rest/organization/members', {
      params: {
        memberId,
      },
    });
    if (isFunction(onSuccess)) {
      onSuccess({ response, dispatch, getState });
    }
  } catch (e) {
    handleError(e);
  }
};

export const getOrganizationMembers = (organizationId) => ({ memberType=null, pagination = Object.create(null) }) => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/organization/members', {
      params: {
        organizationId,
        memberType,
        ...pagination,
      },
    });
    const {
      data: { data, pageData },
    } = response;
    dispatch(setOrganizationMembers({ data, pageData }));
  } catch (e) {
    handleError(e);
  }
};

export const purgeOrganizationMembers = createAction('purgeOrganizationMembers');

const setCategoryHistory = createAction('setCategoryHistory');
export const fetchCategoryHistory = ({ organizationId, pagination = {} }) => async (dispatch, getState) => {
  try {
    dispatch(setCategoryHistory({ data: [], loading: true }));
    const { pagination: statePagination } = categoryHistorySelector(getState());
    const page = pagination.page || DEFAULT_PAGINATION.page;
    const pagesize = pagination.perPage || statePagination.perPage || DEFAULT_PAGINATION.perPage;
    const params = { organizationId, page, pagesize };

    const response = await axios.get('/api/rest/organization/category/history', { params });
    const data = get(response, 'data.data', []);
    const pageData = get(response, 'data.pageData', {});

    const paginationData = {
      itemsCount: pageData.size || data.length,
      page,
      pageCount: pageData.countPage || 1,
      perPage: pagesize,
    };

    dispatch(setCategoryHistory({ data, loading: false, pagination: paginationData }));
  } catch (error) {
    handleError(error);
    dispatch(setCategoryHistory({ loading: false }));
  }
};

export const addOrganizationMembers = ({ organizationId, members, memberType }, onSuccess) => async () => {
  const queryParams = qs.stringify({ organizationId }, { addQueryPrefix: true });
  const _members = members.map((member) => {
    const objectId = (() => {
      switch (memberType) {
        case 'profile':
          return member.id;
        case 'legal':
          return member.objectId;

        default:
          return member.objectId || member.id;
      }
    })();
    return {
      objectId,
      type: memberType,
    };
  });

  try {
    const response = await axios({
      method: 'post',
      url: `/api/rest/organization/members/massadd${queryParams}`,
      data: {
        members: _members,
      },
    });
    if (isFunction(onSuccess)) {
      onSuccess(response);
    }
  } catch (error) {
    handleError(error);
  }
};

export const getRestrictions = (orgId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/rest/organization/club/restrict/${orgId}`);
    dispatch(setRestrictions(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setRestrictions([]));
  }
};

export const deleteRestriction = (restrictId, orgId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/organization/restrict/${restrictId}`);
    handleSuccess('Запрет успешно удален!', 'trash');
    await dispatch(getRestrictions(orgId));
  } catch (err) {
    handleError(err);
  }
};

export const postRestriction = (data) => async (dispatch) => {
  try {
    const body = { ...data };
    body.documents = await uploadFilesMultiple(data.documents);
    await axios.post('/api/rest/organization/restrict', body);
    handleSuccess('Запрет успешно сохранен!');

    await dispatch(getRestrictions(body.organization.id));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const getCompetitionHistory = (filters, pagination = DEFAULT_PAGINATION) => async (dispatch) => {
  try {
    dispatch(setCompetitionHistoryLoading(true));
    const { page, perPage: pagesize } = pagination;
    const params = { ...filters, page, pagesize };

    const {
      data: { data, pageData },
    } = await axios.get('/api/rest/integration/competition/team/history', { params });

    const newPagination = {
      itemsCount: pageData.size || data.length,
      page,
      pageCount: pageData.countPage || 1,
      perPage: pagesize,
    };

    dispatch(setCompetitionHistoryData(data));
    dispatch(setCompetitionHistoryPagination(newPagination));
  } catch (err) {
    handleError(err);
    dispatch(setCompetitionHistoryData([]));
  } finally {
    dispatch(setCompetitionHistoryLoading(false));
  }
};

export const getSocialLinks = (orgId) => async (dispatch) => {
  try {
    const socialLinks = await axios.get(`/api/rest/organization/socialNetwork/${orgId}`);
    dispatch(setSocialLinks(socialLinks.data.data));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const postSocialLink = (body, organizationId) => async (dispatch) => {
  try {
    const params = { organizationId };

    if (body.id) {
      await axios.put(`/api/rest/organization/socialNetwork/edit/${body.id}`, body);
    } else {
      await axios.post('/api/rest/organization/socialNetwork', body, { params });
    }
    dispatch(getSocialLinks(organizationId));
    handleSuccess(`Социальная сеть успешно ${body.id ? 'обновлена' : 'сохранена'}!`);
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const deleteSocialLink = (id) => async (dispatch, getState) => {
  try {
    const { id: orgId } = getState().organization.view.data;
    await axios.delete(`/api/rest/organization/socialNetwork/${id}`);
    handleSuccess('Социальная сеть успешно удалена!', 'trash');
    dispatch(getSocialLinks(orgId));
  } catch (err) {
    handleError(err);
  }
};

export const getTournaments = (orgId) => async (dispatch) => {
  try {
    const tournaments = await axios.get(`/api/rest/organization/${orgId}/tournament`);
    dispatch(setTournaments(tournaments.data.data));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const postTournament = (body, organizationId) => async (dispatch) => {
  try {
    await axios.post(`/api/rest/organization/${organizationId}/tournament`, body);
    dispatch(getTournaments(organizationId));
    handleSuccess(`Турнир успешно сохранен!`);
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const deleteTournament = (id) => async (dispatch, getState) => {
  try {
    const { id: orgId } = getState().organization.view.data;
    await axios.delete(`/api/rest/organization/tournament/${id}`);
    handleSuccess('Турнир успешно удален!', 'trash');
    dispatch(getTournaments(orgId));
  } catch (err) {
    handleError(err);
  }
};

export const getRegions = (orgId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/rest/organization/subject/${orgId}`);
    dispatch(setRegions(res.data.data));
    return true;
  } catch (e) {
    handleError(e);
  }
};

export const updateRegions = (subjects, orgId) => async (dispatch) => {
  try {
    const body = { subjects, orgId };
    await axios.post('/api/rest/organization/subject', body);
    handleSuccess('Информация о регионах успешно обновлена!');
    dispatch(getRegions(orgId));
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Ошибка: ', err);
    handleError({ text: 'Произошла ошибка при сохранении блока, обратитесь к администратору' });
  }
};

// reducer
const reducer = createReducer(
  {
    [clearCompetitionHistory]: (state) => ({
      ...state,
      competitionHistory: { ...initialState.competitionHistory },
    }),
    [clearData]: () => ({ ...initialState }),
    [clearOrganizationData]: (state) => ({ ...state, data: {} }),
    [clearRestrictions]: (state) => ({ ...state, restrictions: [] }),
    [purgeOrganizationMembers]: (state) => ({
      ...state,
      organizationMembers: initialState.organizationMembers,
      organizationMembersPagination: initialState.organizationMembersPagination,
    }),
    [setCategoryHistory]: ({ categoryHistory, ...state }, payload) => ({
      ...state,
      categoryHistory: {
        ...categoryHistory,
        ...payload,
      },
    }),
    [setCompetitionHistoryData]: ({ competitionHistory, ...state }, data) => ({
      ...state,
      competitionHistory: {
        ...competitionHistory,
        data,
      },
    }),
    [setCompetitionHistoryFilters]: ({ competitionHistory, ...state }, filters) => ({
      ...state,
      competitionHistory: {
        ...competitionHistory,
        filters,
      },
    }),
    [setCompetitionHistoryLoading]: ({ competitionHistory, ...state }, loading) => ({
      ...state,
      competitionHistory: {
        ...competitionHistory,
        loaded: !loading,
        loading,
      },
    }),
    [setCompetitionHistoryPagination]: ({ competitionHistory, ...state }, pagination) => ({
      ...state,
      competitionHistory: {
        ...competitionHistory,
        pagination,
      },
    }),
    [setDictionary]: (state, dictionary) => ({
      ...state,
      dictionaries: {
        ...state.dictionaries,
        ...dictionary,
      },
    }),
    [setLoading]: (state, payload) => ({
      ...state,
      loading: payload,
    }),
    [setOrganizationInfo]: (state, data) => ({
      ...state,
      data,
      loading: false,
    }),
    [setOrganizationMembers]: (state, payload) => ({
      ...state,
      organizationMembers: payload.data,
      ...(payload.pageData && { organizationMembersPagination: payload.pageData }),
    }),
    [setOrganizationMembersSection]: (state, payload) => ({
      ...state,
      organizationMembersSection: payload,
    }),
    [setPlayers]: (state, players) => ({
      ...state,
      players,
    }),
    [setRestrictions]: (state, restrictions) => ({
      ...state,
      restrictions,
    }),
    [setStatistics]: (state, statistics) => ({
      ...state,
      statistics,
    }),
    [setTeams]: (state, teams) => ({
      ...state,
      teams: {
        ...state.teams,
        ...teams,
      },
    }),
    [setLogo]: (state, file) => ({
      ...state,
      data: {
        ...state.data,
        logotip: file,
      },
    }),
    [setRegions]: (state, payload) => ({
      ...state,
      regions: payload,
    }),
    [setSocialLinks]: (state, payload) => ({
      ...state,
      socialLinks: payload,
    }),
    [setTournaments]: (state, payload) => ({
      ...state,
      tournaments: payload,
    }),
  },
  initialState,
);

export default reducer;
