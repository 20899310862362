import { createReducer } from 'redux-act';
import { clearData, setCoachCategoryData, setCoachCategoryLoading } from 'redux/actions/Profile/coachCategoryActions';

const initialState = {
  data: null,
  isLoading: false,
};

const coachCategoryReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setCoachCategoryData]: (state, data) => ({
      ...state,
      data,
    }),
    [setCoachCategoryLoading]: (state, isLoading) => ({
      ...state,
      isLoading,
    }),
  },
  initialState,
);

export default coachCategoryReducer;
