import * as React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import classNames from 'classnames/bind';

import Icon from 'components/ui/Icon/Icon';
import InputField from 'components/ui/Input/Input';
import Label from 'components/ui/Label/Label';

import { isFunction } from 'utils/lodash';

import styles from './Input.scss';

const cn = classNames.bind(styles);

class Input extends React.PureComponent {
  static propTypes = {
    actionIcon: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    doValidate: PropTypes.bool,
    error: PropTypes.string,
    formatValue: PropTypes.func,
    iconBefore: PropTypes.node,
    iconClassName: PropTypes.string,
    iconName: PropTypes.string,
    iconStyle: PropTypes.string,
    id: PropTypes.string,
    inputClassName: PropTypes.string,
    light: PropTypes.bool,
    loading: PropTypes.bool,
    mask: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])), PropTypes.func]),
    maxlength: PropTypes.number,
    noRequiredLabel: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onEnter: PropTypes.func,
    onFocus: PropTypes.func,
    onPaste: PropTypes.func,
    onlyIntegerNumbers: PropTypes.bool,
    placeholder: PropTypes.string,
    refer: PropTypes.func,
    required: PropTypes.bool,
    theme: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.string,
    valid: PropTypes.bool,
    validate: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    actionIcon: undefined,
    className: undefined,
    disabled: false,
    doValidate: true,
    error: undefined,
    formatValue: undefined,
    iconBefore: null,
    iconClassName: undefined,
    iconName: undefined,
    iconStyle: 'fas',
    id: undefined,
    inputClassName: undefined,
    light: false,
    loading: false,
    mask: undefined,
    maxlength: undefined,
    noRequiredLabel: false,
    onBlur: undefined,
    onChange: undefined,
    onClick: undefined,
    onEnter: undefined,
    onFocus: undefined,
    onPaste: undefined,
    onlyIntegerNumbers: false,
    placeholder: undefined,
    refer: undefined,
    required: false,
    theme: undefined,
    title: undefined,
    type: 'text',
    valid: true,
    validate: () => false,
    value: '',
  };

  state = {
    cursorPosition: null,
    validationError: false,
    validationOn: this.props.doValidate && !!this.props.value,
    value: this.props.value,
  };

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    this.updateCursorPosition();

    if (prevProps.value !== value) {
      this.setValidation(value);
    }
  }

  createRef = (node) => {
    const { refer } = this.props;
    this.inputRef = node;
    if (typeof refer === 'function') {
      refer(node);
    }
  };

  setValidation = (value) => {
    const { doValidate, validate } = this.props;
    const shouldValidate = doValidate && !!value;
    const validationError = shouldValidate ? validate(value) : false;
    this.setState({
      validationError,
      validationOn: shouldValidate,
    });
  };

  /**
   * Рендерит контейнер с иконкой после инпута в зависимости от валидности поля и статуса подгрузки
   */
  getActionIcon = () => {
    const { loading, valid = true, iconClassName } = this.props;
    const { validationOn, validationError } = this.state;
    const isValid = validationOn && valid && !validationError;

    if (loading) {
      return (
        <div className={cn(styles.iconAfter, styles.iconAfterLoading)}>
          <Icon icon="spinner-third" spin pack="fas" className={cn(styles.iconValid, iconClassName)} />
        </div>
      );
    }

    if (isValid) {
      return (
        <div className={cn(styles.iconAfter, styles.iconAfterSuccess)}>
          <Icon icon="check" pack="fas" className={cn(styles.iconValid, iconClassName)} />
        </div>
      );
    }

    return (
      <div className={cn(styles.iconAfter, styles.iconAfterError)}>
        <Icon icon="exclamation" pack="fas" className={styles.iconInvalid} />
      </div>
    );
  };

  handleChange = (event) => {
    const { onChange, formatValue } = this.props;

    if (onChange) {
      const { value } = event.target;

      const newValue = typeof formatValue === 'function' ? formatValue(value) : value;
      onChange(newValue);
      this.setValidation(newValue);

      this.setState({
        cursorPosition: this.inputRef ? this.inputRef.selectionEnd : null,
        value,
      });
    }
  };

  handleEnterPress = (event) => event.key === 'Enter' && this.props.onEnter && this.props.onEnter(event);

  updateCursorPosition = () => {
    const { value, type } = this.props;
    const { cursorPosition, value: inputValue } = this.state;
    const isValueChanged = value && inputValue && inputValue !== value;
    const isTextInput = type === 'text' || type === 'password';

    if (this.inputRef && isTextInput && isValueChanged && cursorPosition !== null) {
      const newPosition = String(value).length - (inputValue.length - cursorPosition);
      this.inputRef.setSelectionRange(newPosition, newPosition);
      this.setState({ value });
    }
  };

  handleFocusMaskedInput = (event) => {
    const { onFocus, value, mask } = this.props;

    if (isFunction(onFocus)) {
      onFocus(event);
    }

    if ((!value && value !== 0) || !this.maskedInput) {
      return;
    }

    const str = String(value);
    const currentMask = Array.isArray(mask) ? mask.filter((m) => m) : mask;

    if (Array.isArray(currentMask) && str.length === currentMask.length) {
      setTimeout(() => {
        let charPosition = this.maskedInput.selectionEnd;

        for (let i = charPosition - 1; i >= 0; i -= 1) {
          const charMask = currentMask[i];
          if (charMask instanceof RegExp) {
            if (!charMask.test(str[i])) {
              charPosition = i;
            } else {
              break;
            }
          }
        }

        this.maskedInput.setSelectionRange(charPosition, charPosition);
      }, 50);
    }
  };

  renderInput = (inputClass) => {
    const {
      id,
      onFocus,
      onBlur,
      onPaste,
      required,
      disabled,
      type,
      maxlength,
      placeholder,
      value = '',
      theme,
      mask,
      inputClassName,
      light,
      onlyIntegerNumbers,
    } = this.props;

    const defaultInputProps = {
      disabled,
      maxlength,
      name: id,
      onBlur,
      onChange: this.handleChange,
      onFocus,
      onKeyPress: this.handleEnterPress,
      onPaste,
      onlyIntegerNumbers,
      placeholder,
      required,
      theme,
      type,
      value,
    };

    const className = cn(inputClass, inputClassName, { light });

    return mask ? (
      <MaskedInput
        mask={mask}
        guide
        showMask
        placeholderChar="_"
        {...this.props}
        className={className}
        onChange={this.handleChange}
        render={(ref, props) => (
          <InputField
            {...defaultInputProps}
            {...props}
            onFocus={this.handleFocusMaskedInput}
            refer={(input) => {
              ref(input);
              this.maskedInput = input;
              if (props.refer) props.refer(input);
            }}
          />
        )}
      />
    ) : (
      <InputField {...defaultInputProps} refer={this.createRef} className={className} />
    );
  };

  render() {
    const {
      id,
      title,
      className, // внешний класс
      disabled, // активность инпута (актив или нет)
      required,
      iconName, // иконка перед инпутом
      valid, // отображает валидность поля
      error, // сообщение об ошибке, если валидация не пройдена
      loading, // статус асинхронной подгрузки
      iconStyle,
      inputClassName,
      iconClassName,
      actionIcon,
      iconBefore,
      noRequiredLabel, // отображать ли лейбл «Обязательное поле»
    } = this.props;

    const { validationOn, validationError } = this.state;

    // состояния инпута
    const isInvalid = validationOn && !loading && !disabled && (!valid || !!validationError);
    const isRequired = required && !disabled;

    // параметры отвечают за показ определенных элементов компонента
    const showRightIcon = validationOn || loading;
    const showErrorMsg = isInvalid && (validationError || error);
    const showLabel = !iconName && title;

    const inputClass = cn(inputClassName, {
      inputPaddingLeft: iconName || iconBefore,
      inputPaddingRight: showRightIcon || actionIcon,
      invalid: isInvalid,
      required: isRequired,
    });

    const containerClass = cn(styles.container, className);
    const inputWrapperClass = cn(styles.inputContainer, { withIcon: iconName && !isRequired });

    return (
      <div className={containerClass}>
        {showLabel && (
          <Label className={styles.inputLabel} disabled={disabled} required={isRequired} htmlFor={id}>
            {title}
          </Label>
        )}
        {isRequired && !noRequiredLabel && <div className={styles.requireMessage}>Обязательное поле</div>}
        {showErrorMsg && <div className={styles.errorTooltip}>{showErrorMsg}</div>}
        <div className={inputWrapperClass}>
          {(iconName || iconBefore) && (
            <div className={styles.iconBefore}>
              {iconBefore || <Icon icon={iconName} pack={iconStyle} className={cn(styles.icon, iconClassName)} />}
            </div>
          )}
          {this.renderInput(inputClass)}
          {actionIcon ? <div className={styles.iconAfter}>{actionIcon}</div> : showRightIcon && this.getActionIcon()}
        </div>
      </div>
    );
  }
}

export default Input;
