import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';
import { handleError } from 'magic/handlers';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import { DEFAULT_PAGINATION } from 'constants/pagination';

import { addExtraDay } from 'magic/date';

const createAction = actionsFactory('changeHistory/');

const initialState = {
  filters: {},
  inProgress: false,
  loaded: false,
  modalData: null,
  pagination: DEFAULT_PAGINATION,
  results: null,
};

// actions
export const setLoading = createAction('setLoading');
export const setFilters = createAction('setFilters');
export const setResults = createAction('setResults');
export const setPagination = createAction('setPagination');
export const substituteFilters = createAction('substituteFilters');
export const fillModal = createAction('fillModal');
export const clearData = createAction('clearData');

export const applyFilters = (pagePagination = {}) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  if (isEmpty(pagePagination)) {
    dispatch(setPagination(DEFAULT_PAGINATION));
  }
  try {
    const { pagination, filters } = getState().changeHistory;
    const filtersClone = cloneDeep(filters);
    // включим день в верхнюю дату, если она есть
    if (filters.to) {
      filtersClone.to = addExtraDay(filtersClone.to);
    }

    const { page = pagination.page, perPage = pagination.perPage } = pagePagination;
    const res = await axios.post(`/api/rest/audit/list?page=${page}&pagesize=${perPage}`, filtersClone);
    const { data, pageData = {} } = res.data;
    const { countPage = 1, size = data.length, numberPage = page, sizePage = perPage } = pageData;

    dispatch(setResults(data));
    dispatch(
      setPagination({
        itemsCount: size,
        page: numberPage,
        pageCount: countPage,
        perPage: sizePage,
      }),
    );
  } catch (e) {
    handleError(e);
    dispatch(setLoading(false));
  }
};

export const dropFilter = (key) => (dispatch, getState) => {
  const filters = { ...getState().changeHistory.filters };

  if (filters[key]) {
    if (Object.keys(filters).length === 1) {
      dispatch(clearData('filters'));
    } else {
      delete filters[key];
      dispatch(substituteFilters(filters));
    }
  }
};

export const loadActionData = (uuid, objectType) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/rest/audit/list/${uuid}/${objectType}`);
    dispatch(fillModal(res.data.data));
  } catch (e) {
    handleError(e);
  }
};

const reducer = createReducer(
  {
    [clearData]: (state, key) => (key ? { ...state, [key]: initialState[key] } : initialState),
    [fillModal]: (state, modalData) => ({
      ...state,
      modalData,
    }),
    [setFilters]: (state, payload) => ({
      ...state,
      filters: {
        ...state.filters,
        ...payload,
      },
    }),
    [setLoading]: (state, inProgress) => ({
      ...state,
      inProgress,
    }),
    [setPagination]: (state, pagination) => ({
      ...state,
      pagination,
    }),
    [setResults]: (state, results) => ({
      ...state,
      inProgress: false,
      loaded: true,
      results,
    }),
    [substituteFilters]: (state, filters) => ({
      ...state,
      filters,
    }),
  },
  initialState,
);

export default reducer;
