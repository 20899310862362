import { bool, number, object, string } from 'prop-types';

/**
 * @description Учетная запись
 */
export const PTAccount = {
  block: bool,
  /**
   * @description Дата окончания
   */
  endDate: number,
  /**
   * @description Идентификатор
   */
  id: number,
  /**
   * @description Логин
   */
  login: string,
  /**
   * @description Физ лицо
   */
  person: object,
  /**
   * @description Дата начала
   */
  startDate: number,
  /**
   * @description Идентификатор с фронта
   */
  tempId: string,
  /**
   * @description Идентифицирующий ключ
   */
  token: string,
};
