/* eslint-disable lines-between-class-members */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { noop } from 'utils/lodash';

import styles from './TabContainer.scss';

class TabContainer extends React.PureComponent {
  activeTabRef = React.createRef();
  containerRef = React.createRef();
  underlineRef = React.createRef();

  static propTypes = {
    activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    children: PropTypes.node,
    className: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    activeTab: undefined,
    children: null,
    className: undefined,
    onChange: noop,
  };

  componentDidMount() {
    this.animateUnderline();
    window.addEventListener('resize', this.animateUnderline);
  }

  componentDidUpdate() {
    this.animateUnderline();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.animateUnderline);
  }

  attachTabRef = (elementRef) => (el) => {
    this.activeTabRef.current = el;

    if (elementRef) {
      if (typeof elementRef === 'object') {
        elementRef.current = el;
      } else if (typeof elementRef === 'function') {
        elementRef(el);
      }
    }
  };

  handleTabClick = (tabIndex) => {
    const { activeTab, onChange } = this.props;
    if (tabIndex !== activeTab) {
      onChange(tabIndex);
    }
  };

  animateUnderline = () => {
    const activeNode = this.activeTabRef.current;

    if (activeNode) {
      const rect = activeNode.getBoundingClientRect();
      const container = this.containerRef.current.getBoundingClientRect();
      this.underlineRef.current.style.width = `${rect.width}px`;
      this.underlineRef.current.style.transform = `translateX(${rect.left - container.left}px)`;
    }
  };

  renderTabs = () => {
    const { children, activeTab } = this.props;
    const childrenArr = React.Children.toArray(children);
    const renderTabs = childrenArr.reduce((tabs, child) => {
      if (child) {
        const index = child.props.tabIndex || tabs.length;
        const active = index === activeTab;
        const tab = React.cloneElement(child, {
          active,
          key: index,
          onClick: this.handleTabClick,
          ref: active ? this.attachTabRef(child.ref) : child.ref,
          tabIndex: index,
        });

        tabs.push(tab);
      }

      return tabs;
    }, []);

    return renderTabs;
  };

  render() {
    const { className } = this.props;

    return (
      <div className={cn(styles.wrapper, className)}>
        <div className={styles.tabContainer} ref={this.containerRef}>
          {this.renderTabs()}
        </div>
        <div className={styles.underline} ref={this.underlineRef} />
      </div>
    );
  }
}

export default TabContainer;
