exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------ \nCommon\n--------------------------*/\n\n\n/* layouts width*/\n\n\n/* ------------------------ \nDark Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* input*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nLight Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nRating\n--------------------------*/\n\n\n/* react-flexbox-grid breakpoints*/\n\n\n.navigationLink-9hCUD {\n  color: #001a5d;\n  display: block;\n  margin: 20px 0;\n  border: none;\n  background-color: transparent;\n  text-align: left;\n  text-decoration: none;\n  text-transform: uppercase;\n  font-weight: 600;\n  -webkit-transition: 0.3s;\n  transition: 0.3s;\n  cursor: pointer;\n}\n\n\n.navigationLink-9hCUD:focus {\n    outline: none;\n  }\n\n\n.navigationLink-9hCUD:hover {\n    color: #00c;\n    text-decoration: none;\n  }\n\n\n.navigationLink-9hCUD.active-2Ak33 {\n    color: #1fadff !important;\n  }\n\n\n.navigationLink-9hCUD.disabled-1zgir {\n    color: #9196af !important;\n    cursor: not-allowed;\n  }\n\n\n@media screen and (min-width: 1332px) {\n\n\n.navigationLink-9hCUD {\n    display: inline;\n    margin-left: 24px\n}\n  }\n\n\na.navigationLink-9hCUD {\n  color: #001a5d;\n}\n", ""]);

// exports
exports.locals = {
	"navigationLink": "navigationLink-9hCUD",
	"active": "active-2Ak33",
	"disabled": "disabled-1zgir"
};