import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import { Icon } from 'components/ui';

import css from './Banner.scss';

const cx = classnames.bind(css);

const Banner = ({ className, icon, iconPack, children, type }) => {
  const containerStyles = useMemo(
    () =>
      cx(
        {
          container: true,
          warning: type === 'warning',
        },
        className,
      ),
    [className, type],
  );

  return (
    <div className={containerStyles}>
      <div className={css.icon}>
        <Icon icon={icon} pack={iconPack} />
      </div>
      <div className={css.content}>{children}</div>
    </div>
  );
};

Banner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconPack: PropTypes.string,
  type: PropTypes.string,
};

Banner.defaultProps = {
  children: null,
  className: undefined,
  icon: 'exclamation-circle',
  iconPack: 'fal',
  type: undefined,
};

export default React.memo(Banner);
