import { createReducer } from 'redux-act';
import * as actions from 'redux/actions/transfersUserActions';

const initialState = {
  formData: {},
  player: {},
  selectedType: null,
  transferData: {},
  types: [],
};

const transfersUserReducer = createReducer(
  {
    [actions.changeForm]: ({ formData, ...state }, payload) => ({
      ...state,
      formData: {
        ...formData,
        ...payload,
      },
    }),
    [actions.changeType]: (state, payload) => ({
      ...state,
      selectedType: payload,
    }),
    [actions.clearData]: () => ({ ...initialState }),
    [actions.clearFormData]: (state) => ({ ...state, formData: {} }),
    [actions.setPlayer]: (state, payload) => ({
      ...state,
      player: payload,
    }),
    [actions.setTransferData]: (state, payload) => ({
      ...state,
      transferData: payload,
    }),
    [actions.setTypes]: (state, payload) => ({
      ...state,
      types: payload,
    }),
  },
  initialState,
);

export default transfersUserReducer;
