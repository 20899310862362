import axios from 'axios';
import { handleError } from 'magic/handlers';
import { setRatingAmpluaData, setRatingAmpluaLoading } from './actions';

export const getRatingAmplua = () => async (dispatch) => {
  try {
    dispatch(setRatingAmpluaLoading(true));

    const {
      data: { data },
    } = await axios.get('/api/rest/rating/amplua');

    dispatch(setRatingAmpluaData(data));

    return data;
  } catch (err) {
    handleError(err);
    dispatch(setRatingAmpluaData([]));

    return [];
  } finally {
    dispatch(setRatingAmpluaLoading(false));
  }
};
