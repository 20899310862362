import React from 'react';
import { components } from 'react-select';
import css from '../styles.scss';

const NoOptionsMessage = (props) => {
  if (!props.selectProps.inputValue) {
    return <div />;
  }

  return components.NoOptionsMessage && (
    <components.NoOptionsMessage {...props}>
      <div className={css.clearWrapper}>
        {props.children}
      </div>
    </components.NoOptionsMessage>
  );
};

export default NoOptionsMessage;
