import actionsFactory from 'magic/actionsFactory';

const createAction = actionsFactory('rating/');

export const clearData = createAction('clearData');
export const setRatingAmpluaLoading = createAction('setRatingAmpluaLoading');
export const setRatingAmpluaData = createAction('setRatingAmpluaData');

export const setRatingGroupsLoading = createAction('setRatingGroupsLoading');
export const setRatingGroupsData = createAction('setRatingGroupsData');

export const setRatingCalculateRequest = createAction('setRatingCalculateRequest');
export const setRatingCalculateComplete = createAction('setRatingCalculateComplete');

export const setRatingCalculateTotalRequest = createAction('setRatingCalculateTotalRequest');
export const setRatingCalculateTotalComplete = createAction('setRatingCalculateTotalComplete');

export const setRatingLoading = createAction('setRatingLoading');
export const setRatingDeleting = createAction('setRatingDeleting');
export const setRatingData = createAction('setRatingData');
export const setRatingError = createAction('setRatingError');
