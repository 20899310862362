import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { string, node } from 'prop-types';

export const Link = (props) => <RouterLink {...props} />;

Link.External = ({ to, children, ...props }) => {
  const link = /^https?:\/\//.test(to) ? to : `//${to}`;
  return (
    <a {...props} href={link} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

Link.External.propTypes = {
  children: node,
  to: string,
};

Link.External.defaultProps = {
  children: null,
  to: '#',
};
