import { createReducer } from 'redux-act';
import { clearData, setResults } from 'redux/actions/payCalculatorActions';
import { updateConversionHistory } from 'redux/actions/PayConstructor/events';

const initialState = {
  data: null,
  hasHistory: true,
};

const payCalculatorReducer = createReducer(
  {
    [clearData]: () => ({
      ...initialState,
    }),
    [setResults]: (state, data) => ({
      ...state,
      data,
    }),
    [updateConversionHistory]: (state, hasHistory) => ({ ...state, hasHistory }),
  },
  initialState,
);

export default payCalculatorReducer;
