import { createReducer } from 'redux-act';
import {
  clearData,
  setDocumentCategories,
  setDocumentTypes,
  clearSelectedDocumentType,
  setSelectedDocumentTypeData,
  setSelectedDocumentTypeLoading,
  setFieldTypesData,
  setFieldTypesLoading,
  setFieldCollectionData,
  setFieldCollectionLoading,
} from 'redux/actions/documentConstructorActions';

const initialState = {
  categories: [],
  fieldCollection: {
    data: [],
    isLoading: false,
  },
  fieldTypes: {
    data: [],
    isLoading: false,
  },
  selectedType: {
    data: null,
    isLoading: false,
  },
  types: [],
};

const documentConstructorReducer = createReducer(
  {
    [clearData]: () => ({
      ...initialState,
    }),
    [clearSelectedDocumentType]: (state) => ({
      ...state,
      selectedType: { ...initialState.selectedType },
    }),
    [setDocumentCategories]: (state, categories) => ({
      ...state,
      categories,
    }),
    [setDocumentTypes]: (state, types) => ({
      ...state,
      types,
    }),
    [setFieldCollectionData]: (state, data) => ({
      ...state,
      fieldCollection: {
        ...state.fieldCollection,
        data,
      },
    }),
    [setFieldCollectionLoading]: (state, isLoading) => ({
      ...state,
      fieldCollection: {
        ...state.fieldCollection,
        isLoading,
      },
    }),
    [setFieldTypesData]: (state, data) => ({
      ...state,
      fieldTypes: {
        ...state.fieldTypes,
        data,
      },
    }),
    [setFieldTypesLoading]: (state, isLoading) => ({
      ...state,
      fieldTypes: {
        ...state.fieldTypes,
        isLoading,
      },
    }),
    [setSelectedDocumentTypeData]: (state, data) => ({
      ...state,
      selectedType: {
        ...state.selectedType,
        data,
      },
    }),
    [setSelectedDocumentTypeLoading]: (state, isLoading) => ({
      ...state,
      selectedType: {
        ...state.selectedType,
        isLoading,
      },
    }),
  },
  initialState,
);

export default documentConstructorReducer;
