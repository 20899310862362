import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';

const createAction = actionsFactory('organization/structure/');

export const clearData = createAction('clearData');
export const setLoading = createAction('setLoading');
export const setData = createAction('setData');

export const getStructure = (orgId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await axios.get(`/api/rest/organization/unit/find/${orgId}`);
    dispatch(setData(res.data.data));
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteStructure = (id) => async (dispatch, getState) => {
  try {
    const { data: organization } = getState().organization.view;
    await axios.delete(`/api/rest/organization/unit/${id}`);
    handleSuccess('Данные структуры успешно удалены!');
    dispatch(getStructure(organization.id));
  } catch (error) {
    handleError(error);
  }
};

export const editStructure = (body) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));
    const { data: organization } = getState().organization.view;
    await axios.post('/api/rest/organization/unit/edit', body);
    handleSuccess('Данные структуры успешно изменены!');
    dispatch(getStructure(organization.id));
    dispatch(setLoading(false));
    return true;
  } catch (error) {
    handleError(error);
  }
};

export const deleteJurisdiction = (unitId, jurId) => async (dispatch, getState) => {
  try {
    const { data: organization } = getState().organization.view;
    const params = { unitId, jurId };
    dispatch(setLoading(true));
    await axios.delete('/api/rest/organization/unit/jurisdictional', { params });
    handleSuccess('Юрисдикционный орган успешно удален!');
    dispatch(getStructure(organization.id));
    dispatch(setLoading(false));
  } catch (error) {
    handleError(error);
  }
};

export const editPosition = (body) => async (dispatch, getState) => {
  try {
    const { data: organization } = getState().organization.view;
    dispatch(setLoading(true));
    await axios.post('/api/rest/organization/unit/detail/edit', body);
    handleSuccess('Данные должности успешно изменены!');
    dispatch(getStructure(organization.id));
    return true;
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const deletePosition = (id) => async (dispatch, getState) => {
  try {
    const { data: organization } = getState().organization.view;
    dispatch(setLoading(true));
    await axios.delete(`/api/rest/organization/unit/detail/${id}`);
    handleSuccess('Данные должности успешно удалены!');
    dispatch(getStructure(organization.id));
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const createStructure = (body) => async (dispatch, getState) => {
  try {
    const { data: organization } = getState().organization.view;
    dispatch(setLoading(true));
    await axios.post('/api/rest/organization/unit/new', body);
    handleSuccess('Данные структуры успешно сохранены!');
    dispatch(getStructure(organization.id));
    dispatch(setLoading(false));
    return true;
  } catch (e) {
    handleError(e);
  }
};
