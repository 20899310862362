exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mainContainer-2j1Pa {\n  -webkit-box-sizing: content-box;\n          box-sizing: content-box;\n  padding: 0;\n  min-width: 500px;\n}\n\n  .mainContainer-2j1Pa .toastWrapper-1wyKC {\n    padding: 0;\n    min-height: 80px;\n  }\n\n  .mainContainer-2j1Pa .toastBody-j48Qi {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    margin: 0;\n  }\n\n  .mainContainer-2j1Pa .toastBody-j48Qi.error-2a40a .iconContainer-2V-DJ {\n      background-color: #ff6d7f;\n    }\n\n  .mainContainer-2j1Pa .toastBody-j48Qi.success-3Wlqd .iconContainer-2V-DJ {\n      background-color: #8cdcb0;\n    }\n\n  .mainContainer-2j1Pa .toastBody-j48Qi.warning-3iHtw .iconContainer-2V-DJ {\n      background-color: #ffe399;\n    }\n\n  .mainContainer-2j1Pa .toastBody-j48Qi.info-1IgL8 .iconContainer-2V-DJ {\n      background-color: #75ccff;\n    }\n\n  .mainContainer-2j1Pa .toastInner-1atQ2 {\n    display: inherit;\n  }\n\n  .mainContainer-2j1Pa .toastInner-1atQ2 .iconContainer-2V-DJ {\n      min-width: 80px;\n    }\n\n  .mainContainer-2j1Pa .toastInner-1atQ2 .iconContainer-2V-DJ .icon-z-lL0 {\n        display: block;\n        margin: 0 auto;\n        height: 100%;\n      }\n\n  .mainContainer-2j1Pa .toastInner-1atQ2 .message-3gGjq {\n      padding: 30px 20px;\n      color: #3d3d41;\n      white-space: pre-line;\n      line-height: 1.43;\n    }\n", ""]);

// exports
exports.locals = {
	"mainContainer": "mainContainer-2j1Pa",
	"toastWrapper": "toastWrapper-1wyKC",
	"toastBody": "toastBody-j48Qi",
	"error": "error-2a40a",
	"iconContainer": "iconContainer-2V-DJ",
	"success": "success-3Wlqd",
	"warning": "warning-3iHtw",
	"info": "info-1IgL8",
	"toastInner": "toastInner-1atQ2",
	"icon": "icon-z-lL0",
	"message": "message-3gGjq"
};