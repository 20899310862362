import { createReducer } from 'redux-act';

import { clearData, setCompensationsData } from 'redux/actions/Contracts/compensationsActions';

const initialState = {
  data: [],
};

const compensationsReducer = createReducer(
  {
    [clearData]: () => initialState,
    [setCompensationsData]: (state, data) => ({ ...state, data }),
  },
  initialState,
);

export default compensationsReducer;
