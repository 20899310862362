import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Icon from 'components/ui/Icon/Icon';
import CheckboxField from 'components/ui/Checkbox/Checkbox';
import Label from 'components/ui/Label/Label';
import { noop } from 'utils/lodash';
import styles from './Checkbox.scss';

const cn = classNames.bind(styles);
const DEFAULT_POSITION = 'right';

class Checkbox extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    labelPosition: PropTypes.string,
    labelClass: PropTypes.string,
    boxClass: PropTypes.string,
    pack: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    checked: PropTypes.bool,
    name: PropTypes.string,
    onDataChange: PropTypes.any,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
  }

  /**
   * Возращает стиль для позиционирования элементов контейнера компонента по позиции лейбла
   */
  getStyleByPosition = () => {
    const { labelPosition = DEFAULT_POSITION } = this.props;

    // также возращаем пустоту, если указано значение по умолчанию
    if (labelPosition === DEFAULT_POSITION) {
      return;
    }

    return styles[labelPosition];
  }

  handleChange = () => {
    const { onDataChange = this.props.onChange, checked, disabled } = this.props;

    if (onDataChange && !disabled) {
      onDataChange(!checked);
    }
  }

  render() {
    const {
      className, // внешний класс для стилизации компонента
      labelPosition = DEFAULT_POSITION, // позиция лейбла относительно чекбокса
      checked, // состояния чекбокса (выбран или нет)
      name, // название чекбокса
      value, // значение
      pack, // стиль иконки (fas, far, fal - по умолчанию)
      labelClass, // размер лейбла N x N
      boxClass, // размер чекбокса
      title,
      id,
      disabled,
    } = this.props;

    const containerClass = cn(styles.container, this.getStyleByPosition(), { [className]: !!className });
    const checkboxWrapperClass = `${cn(styles.checkboxWrapper, { [`checkboxMargin-${labelPosition}`]: Boolean(title) }, { checked, disabled })} ${checked ? 'checkbox-checked-themed' : ''} ${disabled ? 'checkbox-disabled-themed' : ''}`;

    const checkboxLabelClass = cn(styles.checkboxLabel, labelClass, { disabled });
    const checkboxClass = cn(styles.custombox, boxClass);

    return (
      <div className={containerClass}>
        {title &&
          <Label className={checkboxLabelClass} htmlFor={id}>{title}</Label>
        }
        <div className={`${checkboxWrapperClass} checkbox-wrapper-themed`} onClick={this.handleChange}>
          <CheckboxField
            id={id}
            type="checkbox"
            className={styles.checkbox}
            name={name}
            value={value}
            checked={checked}
            disabled={disabled}
            onChange={noop}
          />
          <span className={`${checkboxClass} custom-checkbox-themed`}>
            <Icon icon="check" size="xs" pack={pack} className="checkbox-icon-themed" />
          </span>
        </div>
      </div>
    );
  }
}

export default Checkbox;
