import React from 'react';
import { range } from 'utils/lodash';
import { addZero } from 'magic/date';
import classnames from 'classnames/bind';
import css from './Time.scss';

const cn = classnames.bind(css);
const hours = range(0, 24);
const minutes = range(0, 60);

class Time extends React.Component {
  handleHourChange = hour => () => {
    this.props.onChange('hour', hour);
  }

  handleMinuteChange = minute => () => {
    this.props.onChange('minute', minute);
  }

  render() {
    const { hour: selectedHour, minute: selectedMinute } = this.props;

    return (
      <div className={`${css.timepicker} timepicker-container-themed`}>
        <div className={`${css.time} timepicker-time-container-themed`}>
          {
            hours.map((hour) => {
              const hourWithZero = addZero(hour.toString());

              return (
                <li
                  key={hour}
                  className={cn(css.timeLi, { active: hour === selectedHour })}
                  onClick={this.handleHourChange(hour)}
                >
                  {hourWithZero}
                </li>
              );
            })
          }
        </div>
        <div className={css.colon}>:</div>
        <div className={`${css.time} timepicker-time-container-themed`}>
          {
            minutes.map((minute) => {
              const minuteWithZero = addZero(minute.toString());

              return (
                <li
                  key={minuteWithZero}
                  className={cn(css.timeLi, { active: minute === selectedMinute })}
                  onClick={this.handleMinuteChange(minute)}
                >
                  {minuteWithZero}
                </li>
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default Time;
