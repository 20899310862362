/* eslint-disable no-console */
import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';
import { handleError, handleSuccess } from 'magic/handlers';

const createAction = actionsFactory('migratedData/');

export const clearData = createAction('clearData');
export const setMigratedData = createAction('setMigratedData');

export const getMigratedData = () => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get('/api/rest/actualization/sportactivity/statistic');

    dispatch(setMigratedData(data));
  } catch (error) {
    handleError(error);
    dispatch(clearData());
  }
};

export const actualizeMigratedData = (personCount) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.post(`/api/rest/actualization/sportactivity/actualize?personCount=${personCount}`);

    const msg = `Актуализировано ${data} профилей футболистов!`;

    if (data) {
      handleSuccess(msg, 'user');
    } else {
      const err = new Error();
      err.text = msg;
      handleError(err);
    }

    await dispatch(getMigratedData());
  } catch (error) {
    handleError(error);
    dispatch(clearData());
  }
};

export const actualizeCurrentPerson = (personId) => async () => {
  try {
    const {
      data: { data },
    } = await axios.post(`/api/rest/actualization/sportactivity/actualize/person/${personId}`);

    if (!String(data).includes('успешно')) {
      const err = new Error();
      err.text = typeof data === 'string' ? data : 'Не удалось актуализировать спортивную активность';
      throw err;
    }

    handleSuccess(`${data}`, 'user');
  } catch (error) {
    handleError(error);
    throw error;
  }
};
