exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".childrenContainer-2fRvj {\n  opacity: 1;\n  -webkit-transition: height .3s ease-out, opacity .2s .1s ease-out;\n  transition: height .3s ease-out, opacity .2s .1s ease-out;\n  z-index: 500;\n}\n\n  .childrenContainer-2fRvj.transition-3aRku {\n    overflow: hidden;\n  }\n\n  .childrenContainer-2fRvj.collapsed-2KIVx {\n    opacity: 0;\n    overflow: hidden;\n    -webkit-transition-timing-function: ease-in, ease-in;\n            transition-timing-function: ease-in, ease-in;\n  }\n\n  .childrenContainer-2fRvj > div {\n    padding: 0.05px;\n  }\n", ""]);

// exports
exports.locals = {
	"childrenContainer": "childrenContainer-2fRvj",
	"transition": "transition-3aRku",
	"collapsed": "collapsed-2KIVx"
};