exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* react-flexbox-grid breakpoints*/\n.breadcrumbs-2o-EO {\n  height: 40px;\n  background-color: #e4e7ed;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  padding: 0 80px;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.breadcrumb-jmk1j {\n  color: #565e85;\n}\n.separator-19aym {\n  margin: 0 10px;\n  top: 1px;\n  position: relative;\n}\n/*************************  MEDIA  *************************/\n@media (max-width: 1023px) {\n  .breadcrumbs-2o-EO {\n    display: none;\n  }\n}\n.theme-dark .breadcrumbs-2o-EO {\n    background-color: #e4e7ed;\n  }\n.theme-dark .breadcrumb-jmk1j {\n    color: #565e85;\n  }\n.theme-dark .breadcrumb-jmk1j a,\n    .theme-dark .breadcrumb-jmk1j svg { \n      color: #565e85; \n    }\n.theme-light .breadcrumbs-2o-EO {\n    background-color: #507093;\n  }\n.theme-light .breadcrumb-jmk1j {\n    color: #f3f3f3;\n  }\n.theme-light .breadcrumb-jmk1j a,\n    .theme-light .breadcrumb-jmk1j svg { \n      color: #f3f3f3; \n    }\n", ""]);

// exports
exports.locals = {
	"breadcrumbs": "breadcrumbs-2o-EO",
	"breadcrumb": "breadcrumb-jmk1j",
	"separator": "separator-19aym"
};