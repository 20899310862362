import React from 'react';
import PropTypes from 'prop-types';

import {
  DateTimePicker,
  FormElement,
} from 'components/rfs-ui';

import css from './Components.scss';

const DateRange = ({ start, end, onChange, withTime }) => (
  <div className={css.dates}>
    {
      start &&
      <FormElement title='Начало' required className={css.startDate}>
        <DateTimePicker
          onChange={onChange('start')}
          value={start}
          withTime={withTime}
          required
        />
      </FormElement>
    }
    {
      end &&
      <FormElement title='Окончание' required className={css.endDate}>
        <DateTimePicker
          onChange={onChange('end')}
          value={end}
          withTime={withTime}
          required
        />
      </FormElement>
    }
  </div>
);

DateRange.propTypes = {
  start: PropTypes.any,
  end: PropTypes.any,
  onChange: PropTypes.func,
  withTime: PropTypes.bool,
};

export default DateRange;
