import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';

const createAction = actionsFactory('exercise/edit/intensity/');

const initialState = {};

// actions
export const fillIntensity = createAction('fillIntensity');
export const updateData = createAction('updateData');
export const clearIntensity = createAction('clearIntensity');

// reducer
const reducer = createReducer(
  {
    [clearIntensity]: () => initialState,
    [fillIntensity]: (state, payload) => payload,
    [updateData]: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },
  initialState,
);

export default reducer;
