export const SORT_OPTIONS = [
  { label: 'сначала новые', value: 'DESC' },
  { label: 'сначала старые', value: 'ASC' },
];

export const FILTERS_FIELDS = [
  'dateRegisterFrom',
  'dateRegisterTo',
  'status',
  'federation',
  'committee',
  'tournament',
  'match',
  'regulation',
  'article',
  'part',
  'organizationDefendant',
  'personDefendant',
  'personParticipant',
  'organizationParticipant',
];
export const FILTERS_FIELDS_WITH_IDS = [
  'federation',
  'committee',
  'tournament',
  'match',
  'regulation',
  'article',
  'part',
  'organizationDefendant',
  'personDefendant',
  'personParticipant',
  'organizationParticipant',
];
