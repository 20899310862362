import { createReducer } from 'redux-act';
import { clearData, setMigratedData } from 'redux/actions/migratedDataActions';

const initialState = {
  data: null,
};

const migratedDataReducer = createReducer(
  {
    [clearData]: () => ({
      ...initialState,
    }),
    [setMigratedData]: (state, data) => ({
      ...state,
      data,
    }),
  },
  initialState,
);

export default migratedDataReducer;
