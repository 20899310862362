import axios from 'axios';

import { DEFAULT_PAGE, DEFAULT_PER_PAGE, DEFAULT_PAGINATION } from 'constants/pagination';
import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { get } from 'utils/lodash';

const createAction = actionsFactory('categoriesClubs');

export const setSeasons = createAction('setSeasons');
export const setClubCategories = createAction('setClubCategories');
export const setOrganizations = createAction('setOrganizations');
export const setOrganizationsPagination = createAction('setOrganizationsPagination');

export const cleanClubsState = createAction('cleanClubsState');

export const getSeasons = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/organization/seasons');
    const data = get(response, 'data.data', []);

    const seasons = data.sort((itemA, itemB) => {
      if (itemA.dateStart < itemB.dateStart) {
        return -1;
      }
      if (itemA.dateStart > itemB.dateStart) {
        return 1;
      }
      return 0;
    });

    dispatch(setSeasons(seasons));
  } catch (error) {
    handleError(error);
  }
};

export const getClubCategories = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/organization/category/dict');
    const data = get(response, 'data.data', []);
    dispatch(setClubCategories(data));
  } catch (error) {
    handleError(error);
  }
};

export const getOrganizationsByCategory = ({ categoryId, page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE }) => async (dispatch) => {
  try {
    const params = { categoryId, page, pagesize: perPage };
    const response = await axios.get('/api/rest/organization/category/organization', { params });
    const { data, pageData } = get(response, 'data', {});

    const pagination = {
      categoryId,
      itemsCount: pageData && pageData.size,
      page,
      pageCount: pageData && pageData.countPage,
      perPage,
    };
    dispatch(setOrganizations(data));
    dispatch(setOrganizationsPagination(pagination));
  } catch (error) {
    dispatch(setOrganizations([]));
    dispatch(setOrganizationsPagination(DEFAULT_PAGINATION));
    handleError(error);
  }
};

export const removeCategoryById = ({ id }) => async (dispatch, getState) => {
  try {
    await axios.delete(`/api/rest/organization/category/${id}`);
    const pagination = get(getState(), 'categories.clubs.organizationsPagination', DEFAULT_PAGINATION);
    dispatch(getOrganizationsByCategory(pagination));
    handleSuccess('Категория клуба успешно удалена', 'trash');
  } catch (error) {
    handleError(error);
  }
};

export const setCategoryToClubs = (body) => async (dispatch, getState) => {
  try {
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => formData.append(key, value));

    await axios.post('/api/rest/organization/category/changeCategory', formData);
    const pagination = get(getState(), 'categories.clubs.organizationsPagination', DEFAULT_PAGINATION);
    handleSuccess('Клубы успешно добавлены в список', 'plus');
    dispatch(getOrganizationsByCategory(pagination));
    return true;
  } catch (error) {
    handleError(error);
    return false;
  }
};
