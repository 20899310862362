exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------ \nCommon\n--------------------------*/\n\n\n/* layouts width*/\n\n\n/* ------------------------ \nDark Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* input*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nLight Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nRating\n--------------------------*/\n\n\n.container-1Vatr {\n  margin-bottom: 20px;\n}\n\n\n.inputContainer-2pAED {\n  margin-bottom: 0;\n}\n\n\n.message-9kU49 {\n  margin-top: 4px;\n  font-size: .9rem;\n  text-align: right;\n  padding: 0 20px;\n  -webkit-transition: color .1s;\n  transition: color .1s;\n}\n\n\n.message-9kU49.active-2krxt {\n    color: #eb0a26;\n  }\n\n\n.theme-dark .message-9kU49 {\n    color: #fff;\n  }\n\n\n.theme-dark .message-9kU49.active-2krxt {\n      color: #eb0a26;\n    }\n\n\n.theme-light .message-9kU49 {\n    color: #212121;\n  }\n\n\n.theme-light .message-9kU49.active-2krxt {\n      color: #eb0a26;\n    }\n", ""]);

// exports
exports.locals = {
	"container": "container-1Vatr",
	"inputContainer": "inputContainer-2pAED",
	"message": "message-9kU49",
	"active": "active-2krxt"
};