import React from 'react';
import { components } from 'react-select';
import { Checkbox } from 'components/rfs-ui';
import PropTypes from 'prop-types';
import css from './styles.scss';

const CustomOption = ({ children, ...rest }) => {
  const { isSelected } = rest;

  return (
    <components.Option className={css.customOption} {...rest}>
      <Checkbox checked={isSelected} /> {children}
    </components.Option>
  );
};

CustomOption.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default CustomOption;
