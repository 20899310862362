import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import ValueItem from './ValueItem';
import css from './Multiselect.scss';

const cn = classnames.bind(css);

const ValuesList = ({
  values,
  labelKey,
  valueKey,
  valuesComponent: ValuesComponent,
  onRemove,
  className,
  reverse,
  loadingValues,
  disabled,
}) => (
  <div className={cn(css.tags, { reverse })}>
    {values.map((value, idx) =>
      ValuesComponent ? (
        <ValuesComponent value={value} onRemove={onRemove(idx)} key={value[valueKey] || idx} />
      ) : (
        <ValueItem
          key={value[valueKey] || idx}
          label={value[labelKey] || value}
          value={value[valueKey] || value}
          index={idx}
          className={className}
          onRemove={onRemove}
          reverse={reverse}
          loadingValues={loadingValues}
          disabled={disabled}
        />
      ),
    )}
  </div>
);

ValuesList.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  labelKey: PropTypes.string,
  loadingValues: PropTypes.array,
  onRemove: PropTypes.func,
  reverse: PropTypes.bool,
  valueKey: PropTypes.string,
  values: PropTypes.array,
  valuesComponent: PropTypes.node,
};

ValuesList.defaultProps = {
  className: undefined,
  disabled: false,
  labelKey: undefined,
  loadingValues: [],
  onRemove: () => {},
  reverse: false,
  valueKey: undefined,
  values: [],
  valuesComponent: null,
};

export default React.memo(ValuesList);
