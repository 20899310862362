import * as Redux from 'redux';
import { connectRouter } from 'connected-react-router';

import accessRightsReducer from 'redux/reducers/accessRightsReducer';
import dictionariesReducer from 'redux/reducers/Dictionaries';
import session from 'redux/modules/session/session';
import personalDataReducer from 'redux/modules/personalData/reducer';
import duplication from 'components/pages/Duplication/reducer';
import permissionFiltersView from 'components/pages/PermissionFilters/View/reducer';
import permissionFiltersEdit from 'components/pages/PermissionFilters/Edit/reducer';
import legalReducer from 'redux/reducers/Legal';
import documentAdd from 'components/pages/Document/Add/reducer';
import accountCreation from 'components/pages/AccountCreation/AccountCreation.reducer';
import nationalTeam from 'components/pages/NationalTeam/Team.reducer';
import accountEdit from 'components/pages/AccountEdit/AccountEdit.reducer';
import documentView from 'components/pages/Document/View/reducer';
import profile from 'redux/reducers/Profile';
import transfersHistory from 'components/pages/Profile/View/TransfersHistory/reducer';
import transferApplications from 'components/pages/TransferApplications/List/reducer';
import changeHistory from 'components/pages/ChangeHistory/reducer';
import changeHistoryV2 from 'components/pages/ChangeHistoryV2/reducer';
import infrastructureEdit from 'components/pages/Infrastructure/Edit/reducer';
import infrastructureView from 'components/pages/Infrastructure/View/reducer';
import certificationList from 'components/pages/CertificationApplications/List/reducer';
import certificationView from 'components/pages/CertificationApplications/Template/reducer';
import competitionHistory from 'components/pages/Profile/View/CompetitionHistory/reducer';
import trainingGroupEdit from 'components/pages/TrainingGroup/Edit/reducer';
import trainingGroupView from 'components/pages/TrainingGroup/View/reducer';
import transferAdmin from 'redux/reducers/transferAdminReducer';
import transfersUser from 'redux/reducers/transfersUserReducer';
import exerciseMainInfo from 'components/pages/Exercise/Edit/MainInfo/reducer';
import exerciseIntensity from 'components/pages/Exercise/Edit/Intensity/reducer';
import exerciseEquipment from 'components/pages/Exercise/Edit/Equipment/reducer';
// import exerciseHelpParams from 'components/pages/Exercise/Edit/HelpParams/reducer';
import trainingPlanParticipants from 'components/pages/Training/Plan/Participants/reducer';
import trainingPlanExercises from 'components/pages/Training/Plan/Exercises/reducer';
import trainingPlanEquipment from 'components/pages/Training/Plan/Equipment/reducer';
import trainingSummarizeMainInfo from 'components/pages/Training/Summarize/MainInfo/reducer';
import trainingSummarizeParticipants from 'components/pages/Training/Summarize/Participants/reducer';
import trainingSummarizeExercises from 'components/pages/Training/Summarize/Exercises/reducer';
import trainingSummarizeEquipment from 'components/pages/Training/Summarize/Equipment/reducer';
import categoriesReducer from 'redux/reducers/categoriesReducer';
import personDoublesReducer from 'redux/reducers/personDoublesReducer';
import testReducer from 'components/pages/Test/Test.reducer';
import totalTrainingGroupFormReducer from 'components/pages/TrainingGroup/helpers/TotalTrainingGroupForm/TotalTrainingGroupForm.reducer';
import trainingGroupCoachesReducer from 'components/pages/TrainingGroup/helpers/Coaches/Coaches.reducer';
import trainingGroupFootballersReducer from 'components/pages/TrainingGroup/helpers/Footballers/Footballers.reducer';
import trainingGroupEditByGroupIdReducer from 'components/pages/TrainingGroup/TrainingGroupEditByGroupId/TrainingGroupEditByGroupId.reducer';
import controlIndicatorsReducer from 'redux/reducers/controlIndicatorsReducer';
import documentConstructor from 'redux/reducers/documentConstructorReducer';
import competitionsIntegrationReducer from 'redux/reducers/competitionsIntegrationReducer';
import appealsReducer from 'redux/reducers/Appeals';
import organization from 'redux/reducers/Organization';
import teamReducer from 'redux/reducers/teamReducer';
import printTemplatesReducer from 'redux/reducers/PrintTemplates';
import payConstructorReducer from 'redux/reducers/PayConstructor';
import payCalculatorReducer from 'redux/reducers/payCalculatorReducer';
import contractsReducer from 'redux/reducers/Contracts';
import permissionsReducer from 'redux/reducers/permissionsReducer';
import migratedDataReducer from 'redux/reducers/migratedDataReducer';
import meetingsDataReducer from 'redux/reducers/Meetings/index';
import casesDataReducer from 'redux/reducers/Cases/index';
import decisionsReducer from 'redux/reducers/Decisions';
import ratingReducer from 'redux/reducers/Rating';
import employeesReducer from 'redux/reducers/EmployeesReducer';
import regulationReducer from 'redux/reducers/Regulation';

export const core = Redux.combineReducers({
  session,
});

const account = Redux.combineReducers({
  create: accountCreation,
  edit: accountEdit,
});

const permissionFilters = Redux.combineReducers({
  edit: permissionFiltersEdit,
  view: permissionFiltersView,
});

const document = Redux.combineReducers({
  add: documentAdd,
  view: documentView,
});

const infrastructure = Redux.combineReducers({
  edit: infrastructureEdit,
  view: infrastructureView,
});

const certificationApplications = Redux.combineReducers({
  list: certificationList,
  view: certificationView,
});

const trainingGroup = Redux.combineReducers({
  edit: trainingGroupEdit,
  totalTrainingGroupForm: totalTrainingGroupFormReducer,
  trainingGroupCoaches: trainingGroupCoachesReducer,
  trainingGroupEditByGroupId: trainingGroupEditByGroupIdReducer,
  trainingGroupFootballers: trainingGroupFootballersReducer,
  view: trainingGroupView,
});

const exerciseEdit = Redux.combineReducers({
  equipment: exerciseEquipment,
  intensity: exerciseIntensity,
  mainInfo: exerciseMainInfo,
  // helpParams: exerciseHelpParams,
});

const exercise = Redux.combineReducers({
  edit: exerciseEdit,
});

const trainingPlan = Redux.combineReducers({
  equipment: trainingPlanEquipment,
  exercises: trainingPlanExercises,
  participants: trainingPlanParticipants,
});

const trainingSummarize = Redux.combineReducers({
  equipment: trainingSummarizeEquipment,
  exercises: trainingSummarizeExercises,
  mainInfo: trainingSummarizeMainInfo,
  participants: trainingSummarizeParticipants,
});

const training = Redux.combineReducers({
  plan: trainingPlan,
  summarize: trainingSummarize,
});

const createReducer = (history) =>
  Redux.combineReducers({
    accessRights: accessRightsReducer,
    account,
    appeals: appealsReducer,
    cases: casesDataReducer,
    categories: categoriesReducer,
    certificationApplications,
    changeHistory,
    changeHistoryV2,
    competitionHistory,
    contracts: contractsReducer,
    controlIndicators: controlIndicatorsReducer,
    core,
    decisions: decisionsReducer,
    dictionaries: dictionariesReducer,
    document,
    documentConstructor,
    duplication,
    employees: employeesReducer,
    exercise,
    infrastructure,
    legal: legalReducer,
    meetings: meetingsDataReducer,
    migratedData: migratedDataReducer,
    nationalTeam,
    organization,
    payCalculator: payCalculatorReducer,
    payConstructor: payConstructorReducer,
    permissionFilters,
    permissions: permissionsReducer,
    personDoubles: personDoublesReducer,
    personalData: personalDataReducer,
    printTemplates: printTemplatesReducer,
    profile,
    rating: ratingReducer,
    regulation: regulationReducer,
    router: connectRouter(history),
    team: teamReducer,
    test: testReducer,
    training,
    trainingGroup,
    transferAdmin,
    transferApplications,
    transfersHistory,
    transfersUser,
    competitionsIntegrationReducer,
  });

export default createReducer;
