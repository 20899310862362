import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';
import { handleError } from 'magic/handlers';

const createAction = actionsFactory('profile/competitionHistory/');

const initialState = {
  data: {},
  loading: true,
};

export const setLoading = createAction('setLoading');
export const fillPersonStats = createAction('fillPersonStats');
export const clearData = createAction('clearData');

export const getStatsByRole = ({ personId, sportingRole, ...filters }) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.get('/api/rest/integration/competition/history', {
      params: {
        personId,
        sportingRole,
        ...filters,
      },
    });
    dispatch(fillPersonStats(response.data.data || initialState.data));
  } catch (e) {
    handleError(e);
  } finally {
    dispatch(setLoading(false));
  }
};

const reducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [fillPersonStats]: (state, data) => ({
      ...state,
      data,
    }),
    [setLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
  },
  initialState,
);

export default reducer;
