class AppConfig {
  TALENTS_HOST = '';

  MDM_HOST = '';

  REFEREE_HOST = '';

  TRAVEL_HOST = '';

  YANDEX_KEY = '';

  NODE_ENV = '';

  setVariables(data) {
    this.TALENTS_HOST = data.REACT_APP_TALENTS_HOST || '';
    this.MDM_HOST = data.REACT_APP_MDM_HOST || '';
    this.REFEREE_HOST = data.REACT_APP_REFEREE_HOST || '';
    this.TRAVEL_HOST = data.REACT_APP_TRAVEL_HOST || '';
    this.YANDEX_KEY = data.REACT_APP_YANDEX_KEY ? Number(data.REACT_APP_YANDEX_KEY) : null;
    this.NODE_ENV = data.NODE_ENV || 'development';
  }
}
const appConfig = new AppConfig();
export { appConfig };
