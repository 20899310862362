import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError } from 'magic/handlers';
import { selectDictionariesListCache } from 'redux/selectors/Dictionaries/listSelectors';

const createAction = actionsFactory('dictionaries/list/');

export const clearListData = createAction('clearListData');
export const setListDataCache = createAction('setListDataCache');
export const setListDataLoading = createAction('setListDataLoading');
export const setListDataSuccess = createAction('setListDataSuccess');
export const setListDataFailure = createAction('setListDataFailure');

export const getDictionariesList = (source) => async (dispatch, getState) => {
  const cachedData = selectDictionariesListCache(getState())[source];

  if (cachedData) {
    dispatch(setListDataSuccess(cachedData));
    return cachedData;
  }

  try {
    dispatch(setListDataLoading());
    const params = { dictionarySource: source };

    const {
      data: { data },
    } = await axios.get('/api/rest/dictionary/editable/list', { params });

    dispatch(setListDataSuccess(data));
    dispatch(setListDataCache({ data, source }));

    return data;
  } catch (err) {
    handleError(err);
    dispatch(setListDataFailure());

    return [];
  }
};
