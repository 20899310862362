import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

const closeModal = (target) => {
  if (target) {
    unmountComponentAtNode(target);
    target.parentNode.removeChild(target);
  }
};

export const confirmAction = (Component) => (props) => {
  const target = document.createElement('div');
  document.body.appendChild(target);
  const onClose = () => closeModal(target);
  render(<Component {...props} onClose={onClose} isOpen />, target);
};

export const confirmActionAsync = (Component) => (props) =>
  new Promise((resolve, reject) => {
    const target = document.createElement('div');
    document.body.appendChild(target);

    const onReject = () => reject(new Error('Действие отменено пользователем'));
    const onClose = () => closeModal(target);

    render(<Component {...props} onClose={onClose} onReject={onReject} onApply={resolve} isOpen />, target);
  });

export const routerConfirm = (Component) => (message, callback) => {
  const target = document.createElement('div');
  document.body.appendChild(target);

  const onApply = () => callback(true);
  const onReject = () => callback(false);
  const onClose = () => closeModal(target);

  render(
    <Component onApply={onApply} onReject={onReject} onClose={onClose} isOpen>
      {message}
    </Component>,
    target,
  );
};
