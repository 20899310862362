import { intersection, isEmpty, includes, find } from 'utils/lodash';

/**
 * Проверяет наличие прав у юзера
 *
 * @param {boolean} some - флаг, который позволяет проверять наличие хотя бы одного обязательного права либо в глобальных правах, либо у организаций юзера (но это неточно)
 * @param {boolean} oneOf - флаг, который позволяет проверять наличие хотя бы одного обязательного права в организациях юзера, иначе сразу всех обязательных прав в организациях
 * @param {string | array} permissionKey - указатель, какие права юзера проверять: глобально или среди организаций
 * @param {object} userPermissions - объект с правами юзера
 * @param {array} requiredPermissions - массив обязательных прав для отображения компонента
 *
 * @returns {boolean} - имеет права или нет
 */
export const checkPermissions = (some, oneOf, permissionKey, userPermissions, requiredPermissions) => {
  // кейс, если requiredPermissions = []
  if (isEmpty(requiredPermissions)) {
    return true;
  }

  // кейс, если permissionKey = []
  if (isEmpty(permissionKey) && !some) {
    return false;
  }

  // проверка права глобально
  const checkGlobally = (permission) =>
    find(userPermissions.global, (globalPermission) => {
      const hasPermissionGlobally = includes(globalPermission, permission);
      return hasPermissionGlobally;
    });

  if (permissionKey === 'global') {
    return oneOf ? requiredPermissions.some(checkGlobally) : requiredPermissions.every(checkGlobally);
  }

  // хрен знает, что такое
  const checkGloballyWithOrganization = (permission) =>
    find(userPermissions.global, (globalPermission) => {
      const hasPermissionGlobally = includes(globalPermission, permission);
      const globalOrgCheck = globalPermission.organizationid ? includes(permissionKey, globalPermission.organizationid) : true;

      return hasPermissionGlobally && globalOrgCheck;
    });

  // проверка права в организациях текущего пользователя
  const checkInOrganizations = (permission) =>
    find(userPermissions.orgs, (organization) => {
      const hasPermission = includes(organization.authoritiesNames, permission);
      const inOrganization = includes(permissionKey, organization.id);
      const inChilds = !isEmpty(intersection(organization.childs, permissionKey));
      return hasPermission && (inOrganization || inChilds);
    });

  if (some) {
    const inUserOrgs = requiredPermissions.some(checkInOrganizations);
    const globally = requiredPermissions.some(checkGloballyWithOrganization);

    return inUserOrgs || globally;
  }

  return oneOf ? requiredPermissions.some(checkInOrganizations) : requiredPermissions.every(checkInOrganizations);
};
