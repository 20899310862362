import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import { push } from 'connected-react-router';
import axios from 'axios';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';

import { handleError } from 'magic/handlers';

import { DEFAULT_PAGINATION } from 'constants/pagination';
import { DIGIT_EVALUATION } from 'constants/digitEvaluations';

const createAction = actionsFactory('test');

const initialState = {
  dictionaries: {
    loading: false,
  },
  list: {
    loading: false,
    pagination: {},
    results: [],
  },
  loading: false,
  saving: false,
  test: {},
};

// actions
export const setLoading = createAction('setLoading');
export const setSaving = createAction('setSaving');
export const setTest = createAction('setTest');
export const setList = createAction('setList');
export const setDictionary = createAction('setDictionary');

export const findTests = (searchText = '', pagePagination = {}) => async (dispatch, getState) => {
  dispatch(setList({ loading: true, results: [] }));
  const { pagination } = getState().test.list;
  const defaultPerPage = isEmpty(pagePagination) ? DEFAULT_PAGINATION.perPage : pagination.perPage;
  const { page = DEFAULT_PAGINATION.page, perPage: pagesize = defaultPerPage } = pagePagination;
  try {
    const response = await axios.get('/api/rest/testing/template/find', {
      params: {
        page,
        pagesize,
        searchText,
      },
    });
    const { data, pageData } = response.data;

    const setListPayload = {
      loading: false,
      pagination: {
        itemsCount: pageData.size,
        page,
        pageCount: pageData.countPage,
        perPage: pagesize,
      },
      results: data,
    };

    dispatch(setList(setListPayload));
  } catch (e) {
    handleError(e);
    dispatch(setList({ loading: false }));
  }
};

export const getTestForEdit = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.get(`/api/rest/testing/template/${id}`);
    const { data } = response.data;
    const metrics = [];
    for (let i = 0; i < data.metrics.length; i++) {
      const { digitEvaluation: digit, evaluation, ...metric } = data.metrics[i];
      const currentMetric = metrics.find(
        (m) => get(m, 'ageCategory.id') === get(metric, 'ageCategory.id') && get(m, 'gender.id') === get(metric, 'gender.id'),
      );
      const digitEvaluation = DIGIT_EVALUATION.find((d) => d.code === digit.code);
      const currentResult = { digitEvaluation, evaluation, id: uniqueId('result_') };
      if (currentMetric) {
        currentMetric.results.push(currentResult);
      } else {
        metrics.push({ ...metric, results: [currentResult] });
      }
    }
    dispatch(setTest({ ...data, metrics }));
    dispatch(setLoading(false));
  } catch (e) {
    handleError(e);
    if (e.response.status === 404) {
      dispatch(push('/test/list'));
    }
    dispatch(setLoading(false));
  }
};

export const getTest = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.get(`/api/rest/testing/template/view/${id}`);
    dispatch(setTest(response.data.data));
    dispatch(setLoading(false));
  } catch (e) {
    handleError(e);
    if (e.response.status === 404) {
      dispatch(push('/test/list'));
    }
    dispatch(setLoading(false));
  }
};

export const deleteTest = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await axios.delete(`/api/rest/testing/template/${id}`);
    dispatch(push('/test/list'));
    dispatch(setLoading(false));
  } catch (e) {
    handleError(e);
    dispatch(setLoading(false));
  }
};

export const saveTest = (body) => async (dispatch) => {
  const metrics = [];
  for (let i = 0; i < body.metrics.length; i++) {
    const { results, ...metric } = body.metrics[i];
    delete metric.id;
    for (let j = 0; j < results.length; j++) {
      const { ...result } = results[j];
      delete result.id;
      const newMetric = { ...metric, ...result };
      metrics.push(newMetric);
    }
  }
  try {
    dispatch(setSaving(true));
    const response = await axios.post('/api/rest/testing/template', { ...body, metrics });
    dispatch(setSaving(false));
    dispatch(push(`/test/${response.data.data}`));
  } catch (e) {
    dispatch(setSaving(false));
    handleError(e);
  }
};

export const getPrepareKind = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/training/preparekinds');
    dispatch(setDictionary({ preparekinds: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getUnits = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/training/units');
    dispatch(setDictionary({ units: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getGenders = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/person/gender/getall');
    dispatch(setDictionary({ genders: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getAgeCategory = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/testing/template/agecategory');
    dispatch(setDictionary({ agecategory: response.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getDictionaries = () => (dispatch) => {
  dispatch(setDictionary({ loading: true }));
  return Promise.all([dispatch(getPrepareKind()), dispatch(getUnits()), dispatch(getGenders()), dispatch(getAgeCategory())]).then(() => {
    dispatch(setDictionary({ loading: false }));
  });
};

const reducer = createReducer(
  {
    [setDictionary]: (state, dictionary) => ({
      ...state,
      dictionaries: {
        ...state.dictionaries,
        ...dictionary,
      },
    }),
    [setList]: (state, list) => ({
      ...state,
      list: {
        ...state.list,
        ...list,
      },
    }),
    [setLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
    [setSaving]: (state, saving) => ({
      ...state,
      saving,
    }),
    [setTest]: (state, test) => ({
      ...state,
      test,
    }),
  },
  initialState,
);

export default reducer;
