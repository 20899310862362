import actionsFactory from 'magic/actionsFactory';

const createAction = actionsFactory('accessRights/');

export const clearData = createAction('clearData');
export const setRolesFilter = createAction('setRolesFilter');
export const setPermissions = createAction('setPermissions');
export const setDictionary = createAction('setDictionary');
export const setRolesDictionary = createAction('setRolesDictionary');
export const setOrganizations = createAction('setOrganizations');
