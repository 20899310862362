import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';

import { handleError } from 'magic/handlers';
import { Notification } from 'components/rfs-ui';

const createAction = actionsFactory('permissionFilters/view/');

const initialState = {
  filters: {
    items: null,
    loading: true,
  },
  selectedFilter: {
    item: null,
    loading: false,
  },
};

// actions
export const setFiltersLoading = createAction('setFiltersLoading');
export const setFilters = createAction('setFilters');
export const startLoadingFilter = createAction('startLoadingFilter');
export const setSelectedFilter = createAction('setSelectedFilter');
export const clearFilters = createAction('clearFilters');

export const loadFilters = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/rest/permission/filter');
    dispatch(setFilters(res.data.data));
  } catch (e) {
    Notification.error({
      children: {
        message: 'Не удалось загрузить фильтры.',
      },
    });
    dispatch(setFilters(null));
  }
};

export const loadFilter = ({ id, fullName, sysName }) => async (dispatch) => {
  dispatch(startLoadingFilter());

  try {
    const {
      data: { data },
    } = await axios.get(`/api/rest/permission/filter/dictionary?sysName=${sysName}`);
    // const filteredGroups = res.data.data.groups.filter(group => group.permissions.some(permission => permission.enabled));

    dispatch(
      setSelectedFilter({
        fullName,
        groups: data.groups,
        id,
        sysName,
      }),
    );
  } catch (e) {
    Notification.error({
      children: {
        message: 'Не удалось загрузить фильтры.',
      },
    });
    dispatch(setSelectedFilter(null));
  }
};

export const deleteFilter = (sysName) => async (dispatch) => {
  dispatch(setFiltersLoading(true));

  try {
    await axios.delete(`/api/rest/permission/filter/${sysName}`);
    dispatch(loadFilters());
    dispatch(setSelectedFilter(null));
    dispatch(setFiltersLoading(false));
  } catch (e) {
    handleError(e);
    dispatch(setFiltersLoading(false));
  }
};

// reducer
const reducer = createReducer(
  {
    [setFilters]: (state, items) => ({
      ...state,
      filters: {
        items,
        loading: false,
      },
    }),
    [setFiltersLoading]: (state, loading) => ({
      ...state,
      filters: {
        ...state.filters,
        loading,
      },
    }),
    [startLoadingFilter]: (state) => ({
      ...state,
      selectedFilter: {
        ...state.selectedFilter,
        loading: true,
      },
    }),
    [setSelectedFilter]: (state, item) => ({
      ...state,
      selectedFilter: {
        item,
        loading: false,
      },
    }),
    [clearFilters]: () => initialState,
  },
  initialState,
);

export default reducer;
