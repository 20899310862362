import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { uploadFilesMultiple } from 'utils/uploadFiles';

const createAction = actionsFactory('profile/coachCategory/');

export const clearData = createAction('clearData');
export const setCoachCategoryData = createAction('setCoachCategoryData');
export const setCoachCategoryLoading = createAction('setCoachCategoryLoading');

export const getCoachCategory = (personId) => async (dispatch) => {
  try {
    dispatch(setCoachCategoryLoading(true));
    const {
      data: { data },
    } = await axios.get(`/api/rest/person/categorycoach/active/${personId}`);
    dispatch(setCoachCategoryData(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(setCoachCategoryData(null));
  } finally {
    dispatch(setCoachCategoryLoading(false));
  }
};

export const createCoachCategory = (data) => async (dispatch) => {
  try {
    const body = { ...data };
    body.documents = await uploadFilesMultiple(body.documents);

    await axios.post('/api/rest/person/categorycoach', body);
    handleSuccess('Категория успешно присвоена');

    await dispatch(getCoachCategory(body.personId));
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const deleteCoachCategory = (id, personId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/person/categorycoach/${id}`);
    await dispatch(getCoachCategory(personId));
    handleSuccess('Категория тренера успешно удалена');
  } catch (error) {
    handleError(error);
  }
};

export const suspendCoachCategory = (personId, dateAvoided) => async (dispatch) => {
  try {
    const params = { dateEnd: dateAvoided };
    await axios.put(`/api/rest/person/categorycoach/suspend/${personId}`, null, { params });
    await dispatch(getCoachCategory(personId));
    handleSuccess('Категория тренера успешно отозвана');
  } catch (error) {
    handleError(error);
  }
};
