import { createReducer } from 'redux-act';
import { setSaveCaseLoading } from 'redux/actions/Cases/creationActions';

const initState = {
  saveLoading: false,
};

const creationReducer = createReducer(
  {
    [setSaveCaseLoading]: (state, saveLoading) => ({
      ...state,
      saveLoading,
    }),
  },
  initState,
);

export default creationReducer;
