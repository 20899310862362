import { combineReducers } from 'redux';

import compensations from './compensationsReducer';
import dictionaries from './dictionariesReducer';
import events from './eventsReducer';
import threshold from './thresholdReducer';

export default combineReducers({
  compensations,
  dictionaries,
  events,
  threshold,
});
