import cyrillicToTranslit from 'cyrillic-to-translit-js';

export const toUpperCaseFirstLetter = (text) => {
  if (text) {
    return text.toLowerCase().replace(/(^|\s|-)\S/g, (a) => a.toUpperCase());
  }
  return '';
};

const makeIntoBigInt = (num) => {
  const onlyInteger = String(num).replace(/\..*/, '');
  // eslint-disable-next-line no-undef
  return /\D+/.test(onlyInteger) ? null : BigInt(onlyInteger);
};

export const formatNumber = (num, minimumFractionDigits = 0, postfix = '') => {
  const parsedNumber = parseFloat(num);
  const number = Number.isSafeInteger(parsedNumber) ? parsedNumber : makeIntoBigInt(num);
  return number || number === 0 ? number.toLocaleString('ru-Ru', { minimumFractionDigits }).replace(',', '.') + postfix : '—';
};

export const formatPercent = (num, decimals = 0) => {
  const number = parseFloat(num);
  return number || number === 0 ? `${number.toFixed(decimals)}%` : '–';
};

export const removeSpaces = (value) => value && value.replace(/\s/g, '');

export const formatEmail = (email) => email && removeSpaces(email.toLowerCase());

export const isEmptyText = (text) => !text || !String(text).trim();

export const transliterateCyrillicText = (input) => cyrillicToTranslit().transform(input, ' ');

export const transliterateCyrillicTextUnderline = (input) => cyrillicToTranslit().transform(input, '_');

export const cutText = (text, length = 65) => {
  if (!text) {
    return text;
  }

  const strText = String(text);
  return strText.length > length ? `${strText.substring(0, length - 3)}...` : text;
};

export const makeLinkFromUrl = (value) => {
  if (!value) {
    return undefined;
  }

  return /^https?:\/\//.test(value) ? value : `https://${value}`;
};

export const makeLinkFromEmail = (value) => {
  if (!value) {
    return undefined;
  }

  return `mailto:${value}`;
};

export const makeLinkFromPhone = (value) => {
  if (!value) {
    return undefined;
  }

  return `tel:${value}`;
};

export const translateWithDictExceptions = (inputString, mappings) => {
  const words = inputString.split(/\s+/); // Разбиваем входную строку на слова

  // Приводим ключи объекта к нижнему регистру для регистронезависимости
  const lowercaseMappings = {};
  for (const key in mappings) {
      lowercaseMappings[key.toLowerCase()] = mappings[key];
  }

  // Заменяем слова, если они есть в объекте mappings
  const replacedWords = words.map(word => {
      const lowercaseWord = word.toLowerCase();
      if (lowercaseMappings[lowercaseWord]) {
          return lowercaseMappings[lowercaseWord];
      }
      return word;
  });

  // Собираем обратно строку из замененных слов
  return replacedWords.join(' ');
}
