import axios from 'axios';
import actionsFactory from 'magic/actionsFactory';
import { handleError } from 'magic/handlers';
import { DEFAULT_PAGINATION } from 'constants/pagination';

const createAction = actionsFactory('appeals/view/');

export const clearData = createAction('clearData');

export const setAppealsCategoriesData = createAction('setAppealsCategoriesData');
export const setAppealsCategoriesLoading = createAction('setAppealsCategoriesLoading');

export const setAppealsData = createAction('setAppealsData');
export const setAppealsLoading = createAction('setAppealsLoading');
export const setAppealsFilters = createAction('setAppealsFilters');
export const setIncomingAppealPagination = createAction('setIncomingAppealPagination');
export const updateAppealsFilters = createAction('updateAppealsFilters');

export const getAppealsList = (isIncoming, filters, pagination = DEFAULT_PAGINATION) => async (dispatch) => {
  try {
    dispatch(setAppealsLoading(true));
    const url = isIncoming ? '/api/rest/appeals/all/inbox' : '/api/rest/appeals/all/outbox';

    const { page, perPage } = pagination;
    const incomingParams = { ...filters, page, perPage };

    const {
      data: { data, pageData },
    } = await axios.get(url, { params: incomingParams });
    dispatch(setAppealsData(data));
    dispatch(
      setIncomingAppealPagination({
        itemsCount: pageData.size,
        page,
        pageCount: pageData.countPage,
        perPage,
      }),
    );
  } catch (err) {
    handleError(err);
    dispatch(setAppealsData(null));
  } finally {
    dispatch(setAppealsLoading(false));
  }
};

export const getAppealsCategories = () => async (dispatch) => {
  try {
    dispatch(setAppealsCategoriesLoading(true));
    const response = await axios.get('/api/rest/appeals/groups');

    const { data } = response.data;
    dispatch(setAppealsCategoriesData(data));

    const groupId = data[0] && data[0].id;
    dispatch(updateAppealsFilters({ groupId }));
  } catch (err) {
    handleError(err);
    dispatch(setAppealsCategoriesData([]));
  } finally {
    dispatch(setAppealsCategoriesLoading(false));
  }
};
