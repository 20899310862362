import { arrayOf, number, object, shape, string } from 'prop-types';

import { PTContract } from './contractPropTypes';
import { PTPosition } from './positionPropTypes';

/**
 * @description Сотрудник организации
 */
export const PTProfessionalActivity = {
  contracts: arrayOf(PTContract),
  /**
   * @description Дата окончания полномочий
   */
  dateEnd: number,
  /**
   * @description Дата вступления в должность
   */
  dateStart: number,
  /**
   * @description Идентификатор
   */
  id: number,
  /**
   * @description Организация
   */
  organization: object,
  /**
   * @description Физ лицо
   */
  person: object,
  position: shape(PTPosition),
  /**
   * @description   Идентификатор с фронта
   */
  tempId: string,
};
