import { combineReducers } from 'redux';

import ampluaReducer from './ampluaReducer';
import calculatingReducer from './calculatingReducer';
import calculatingTotalReducer from './calculatingTotalReducer';
import ratingDataReducer from './ratingDataReducer';
// import ratingGroupsReducer from './ratingGroupsReducer';

const ratingReducer = combineReducers({
  amplua: ampluaReducer,
  calculating: calculatingReducer,
  calculatingTotal: calculatingTotalReducer,
  rating: ratingDataReducer,
  // ratingGroups: ratingGroupsReducer,
});

export default ratingReducer;
