exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".container-RNsi5 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.firstText-3cKtb {\n  display: inherit;\n  -webkit-box-align: end;\n      -ms-flex-align: end;\n          align-items: flex-end;\n}", ""]);

// exports
exports.locals = {
	"container": "container-RNsi5",
	"firstText": "firstText-3cKtb"
};