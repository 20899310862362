import { ORGANIZATION_TYPES } from 'constants/organizationTypes';
import { ORGANIZATION_STATUSES } from 'constants/organizationStatuses';
import { ORGANIZATION_CATEGORIES } from 'constants/organizationCategories';

/**
 * Подготавливает данные из стора к отправке на бэк.
 * @param {object} data - Данные из стора.
 * @param {string} orgType - Тип организации
 */
export const dataReStructure = (data, orgType) => {
  const head = {};
  const mainInfo = {};

  Object.entries(data).forEach(([key, value]) => {
    if (key === 'person' || key === 'validFrom' || key === 'validTo') {
      head[key] = value;
    }

    mainInfo[key] = value;
  });

  const payload = {
    ...mainInfo,
    head,
  };

  if (orgType === ORGANIZATION_TYPES.FEDERATION) {
    payload.footballBase = !!payload.minSportOrderDate && !!payload.minSportOrderNum;
  }

  delete payload.isAcademyCheck;

  return payload;
};

export const getParentLink = (parentOrg) => {
  if (!parentOrg) {
    return null;
  }
  if (!parentOrg.legal || parentOrg.status !== ORGANIZATION_STATUSES.ACTIVE) {
    return `/organization/${parentOrg.id}`;
  }
  return {
    pathname: `/legal/${parentOrg.legal.id}`,
    state: { organizationId: parentOrg.id },
  };
};

export const findForthCategory = (data) => data.sysName === ORGANIZATION_CATEGORIES.FORTH;
