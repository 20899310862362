import React from 'react';

import { Modal } from 'components/ui';
import Slider from './Slider';

import css from './index.scss';

const ModalSlider = ({ children, onClose, activeSlide, ...sliderParams }) => {
  return (
    <Modal onClose={onClose} className={css.sliderModal} overlayClassName={css.sliderOverlay}>
      <div className={css.modalContent}>
        <Slider
          infinite={false}
          dots={false}
          initialSlide={activeSlide}
          {...sliderParams}
        >
          {children}
        </Slider>
      </div>
    </Modal>
  );
};

export default ModalSlider;
