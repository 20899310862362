import axios from 'axios';
import { handleError } from 'magic/handlers';
import { setRatingCalculateRequest, setRatingCalculateComplete } from './actions';

export const calculateRating = ({ criterionId, plus, minus }) => async (dispatch) => {
  const hash = `${plus}_${minus}_${Date.now()}`;

  try {
    dispatch(setRatingCalculateRequest({ criterionId, hash }));

    const params = { minus, plus };

    const {
      data: { data },
    } = await axios.get('/api/rest/rating/calculate', { params });

    return data;
  } catch (err) {
    handleError(err);

    return {};
  } finally {
    dispatch(setRatingCalculateComplete({ criterionId, hash }));
  }
};
