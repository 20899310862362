import React, { useRef, useState, useCallback, useLayoutEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import css from './index.scss';

const getHeight = (ref) => (ref.current ? ref.current.scrollHeight : 'auto');
const addClass = (ref, className) => ref.current && ref.current.classList.add(className);
const removeClass = (ref, className) => ref.current && ref.current.classList.remove(className);

const CollapsibleElement = ({ children, collapsed, lazyRender }) => {
  const [height, setHeight] = useState(collapsed ? 0 : 'auto');
  const [shouldRender, setShouldRender] = useState(!collapsed || !lazyRender);

  const childrenRef = useRef();
  const containerRef = useRef();
  const heightTimeout = useRef();
  const isFirstEffect = useRef(true);

  const handleCollapse = () => {
    addClass(containerRef, css.transition);

    if (!collapsed) {
      removeClass(containerRef, css.collapsed);
      heightTimeout.current = setTimeout(() => setHeight(getHeight(childrenRef)), 50);
    } else {
      addClass(containerRef, css.collapsed);
      setHeight(getHeight(childrenRef));
      heightTimeout.current = setTimeout(() => setHeight(0), 50);
    }
  };

  const handleTransitionEnd = useCallback(
    (event) => {
      if (event.propertyName === 'height' && height) {
        setHeight('auto');
        removeClass(containerRef, css.transition);
      }
    },
    [height],
  );

  useLayoutEffect(() => {
    if (isFirstEffect.current) {
      if (collapsed) {
        addClass(containerRef, css.collapsed);
      }
      isFirstEffect.current = false;
    } else if (!shouldRender && !collapsed) {
      setShouldRender(true);
    } else {
      handleCollapse();
    }
    return () => clearTimeout(heightTimeout.current);
  }, [collapsed, shouldRender]);

  const containerStyle = useMemo(() => ({ height }), [height]);

  return (
    <div ref={containerRef} style={containerStyle} onTransitionEnd={handleTransitionEnd} className={css.childrenContainer}>
      <div ref={childrenRef}>{shouldRender && children}</div>
    </div>
  );
};

CollapsibleElement.propTypes = {
  children: PropTypes.node,
  collapsed: PropTypes.bool,
  lazyRender: PropTypes.bool,
};

CollapsibleElement.defaultProps = {
  children: null,
  collapsed: false,
  lazyRender: true,
};

export default CollapsibleElement;
