import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import css from './FormElement.scss';
import { Tooltip } from '../../ui';

const cx = classnames.bind(css);

const FormElement = ({ title, required, children, className, contentClassName, info }) => (
  <div className={cx({ container: true, [className]: !!className })}>
    <div className={css.top}>
      <div className={cx({ title: true, required })}>
        {title}
        {!!info && (
          <Tooltip className={css.info} text={info}>
            <FontAwesomeIcon size="sm" icon={faInfoCircle} />
          </Tooltip>
        )}
      </div>
      {required && <div className={css.requiredMessage}>Обязательное поле</div>}
    </div>
    <div className={cx(css.content, contentClassName)}>{children}</div>
  </div>
);

FormElement.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  info: PropTypes.string,
};

export default FormElement;
