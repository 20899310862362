import React, { useEffect, Suspense } from 'react';
import { shape, func } from 'prop-types';
import { Route as PublicRoute, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import SessionProvider from 'components/composed/SessionProvider';
import { PermissionsProvider } from 'components/composed/Permissions';
import ScrollToTop from 'components/composed/ScrollToTop';
import Disclaimer from 'components/composed/Disclaimer';
import PrivateRoute from 'components/composed/PrivateRoute';
import YAMetricsProvider from 'components/composed/YAMetrics';

import { ROUTES_WAYS } from 'constants/routesWays';
import ThemeContextProvider from 'components/composed/ThemeContext';

import { PTHistory } from 'propTypes/reactRouterPropTypes';
import { PTPersonalData } from 'propTypes/personalDataPropTypes';

import Preloader from 'components/ui/Preloader/Preloader';
import { Releases } from 'components/pages/Releases/Releases';
import * as lazyPages from './App.lazyPages';
import { appConfig } from '../../constants/appConfig';

export default function App(props) {
  const { history, getDictionariesActions, actions, purgeDictionariesActions, metricDataAgreement } = props;

  useEffect(() => {
    (async () => {
      const response = await fetch('/env.json');
      appConfig.setVariables(await response.json());
    })();
  }, []);

  useEffect(() => {
    Object.values(getDictionariesActions).forEach((getDictionariesAction) => {
      if (typeof getDictionariesAction === 'function') {
        getDictionariesAction();
      }
    });
    actions.initSession();

    return () => {
      Object.values(purgeDictionariesActions).forEach((purgeDictionariesAction) => {
        if (typeof purgeDictionariesAction === 'function') {
          purgeDictionariesAction();
        }
      });
    };
  }, [actions, getDictionariesActions, purgeDictionariesActions]);

  return (
    <ConnectedRouter history={history}>
      <ThemeContextProvider>
        <SessionProvider history={history}>
          <PermissionsProvider>
            <Disclaimer metricDataAgreement={metricDataAgreement} />
            <ScrollToTop>
              <Suspense fallback={<Preloader size="8x" />}>
                <Switch>
                  <PublicRoute exact path="/" component={() => <Redirect to="/management" />} />
                  <PublicRoute exact path={ROUTES_WAYS.LOGIN} component={lazyPages.Login} />
                  <PublicRoute exact path="/login-confirmation" component={lazyPages.ConfirmLogin} />
                  <PublicRoute exact path="/registration" component={lazyPages.Registration} />
                  <PublicRoute exact path="/password/recovery" component={lazyPages.Recovery} />
                  <PublicRoute exact path="/password/change/:token" component={lazyPages.Change} />
                  <PublicRoute exact path="/password/create/:token" component={lazyPages.PasswordCreate} />
                  <PublicRoute exact path="/email/change/:token" component={lazyPages.EmailChange} />
                  {/* Управление */}
                  <PrivateRoute exact path={ROUTES_WAYS.MANAGEMENT} component={lazyPages.Management} />
                  {/* Релизы */}
                  <PrivateRoute exact path={ROUTES_WAYS.RELEASES} component={Releases} />
                  {/* Заявления */}
                  <PrivateRoute exact path="/applications" component={lazyPages.Applications} />
                  {/* История блокировок */}
                  <PrivateRoute path="/lockHistory" component={lazyPages.LockHistory} />
                  {/* Поиск дублей */}
                  <PrivateRoute exact path="/duplication" component={lazyPages.Duplication} />
                  {/* Создание поиск лица */}
                  <PrivateRoute exact path={ROUTES_WAYS.SEARCH_PROFILE} component={lazyPages.ProfileSearch} />
                  {/* Создание физ лица */}
                  <PrivateRoute exact path="/profile/add" component={lazyPages.ProfileCreate} />
                  {/* История переходов физ. лица */}
                  <PrivateRoute exact path="/profile/transfers-history/:id" component={lazyPages.TransfersHistory} />
                  {/* Просмотр физ лица */}
                  <PrivateRoute exact path={ROUTES_WAYS.PROFILE_BY_ID} component={lazyPages.ProfileView} />
                  {/* Просмотр дубликатов FIFA для физ лица */}
                  <PrivateRoute exact path={ROUTES_WAYS.PROFILE_DUPLICATES} component={lazyPages.ProfileDuplicates} />
                  {/* Управление правами доступа */}
                  <PrivateRoute exact path="/accessRights" component={lazyPages.AccessRights} />
                  {/* Роли доступа */}
                  <PrivateRoute exact path="/permissionFilters" component={lazyPages.PermissionFiltersView} />
                  <PrivateRoute exact path="/permissionFilters/add" component={lazyPages.PermissionFiltersEdit} />
                  <PrivateRoute exact path="/permissionFilters/edit/:filterName" component={lazyPages.PermissionFiltersEdit} />
                  {/* Создание организаций */}
                  <PrivateRoute exact path="/organization/add" component={lazyPages.OrganizationAdd} />
                  {/* Поиск организаций */}
                  <PrivateRoute exact path={ROUTES_WAYS.SEARCH_ORGANIZATION} component={lazyPages.LegalOrgSearch} />
                  {/* Редактирование организаций */}
                  <PrivateRoute exact path="/organization/edit/:id" component={lazyPages.OrganizationEdit} />
                  {/* Просмотр организаций */}
                  <PrivateRoute exact path="/organization/:id" component={lazyPages.OrganizationView} />
                  {/* Создание команды */}
                  <PrivateRoute exact path="/organization/:id/add-team" component={lazyPages.NationalTeamAdd} />
                  {/* Просмотр сборной */}
                  <PrivateRoute exact path={ROUTES_WAYS.NATIONAL_TEAM} component={lazyPages.NationalTeamView} />
                  {/* Редактирование сборной */}
                  <PrivateRoute exact path={ROUTES_WAYS.NATIONAL_TEAM_EDIT} component={lazyPages.NationalTeamEdit} />
                  {/* Просмотр команды */}
                  <PrivateRoute exact path={ROUTES_WAYS.TEAM_BY_ID} component={lazyPages.TeamView} />
                  {/* Создание юр лица */}
                  <PrivateRoute exact path="/legal/add" component={lazyPages.LegalAdd} />
                  {/* Заключение договора о сотрудничестве */}
                  <PrivateRoute exact path={ROUTES_WAYS.DOCUMENT_ADD} component={lazyPages.DocumentAdd} />
                  {/* Редактирование договора о сотрудничестве */}
                  <PrivateRoute exact path={`${ROUTES_WAYS.DOCUMENT_EDIT}/:id`} component={lazyPages.DocumentAdd} />
                  {/* Просмотра договора */}
                  <PrivateRoute exact path={ROUTES_WAYS.DOCUMENT_VIEW} component={lazyPages.DocumentView} />
                  {/* Просмотр юр лица */}
                  <PrivateRoute keyParam="id" exact path={ROUTES_WAYS.LEGAL_BY_ID} component={lazyPages.LegalView} />
                  {/* Создание филиала юр.лица */}
                  <PrivateRoute exact path={ROUTES_WAYS.LEGAL_CREATE_BRANCH} component={lazyPages.LegalCreateBranch} />
                  {/* История изменений */}
                  <PrivateRoute exact path="/changeHistory" component={lazyPages.ChangeHistory} />
                  {/* История изменений v2 */}
                  <PrivateRoute exact path="/changeHistoryV2" component={lazyPages.ChangeHistoryV2} />
                  {/* Просмотр входящих обращений */}
                  <PrivateRoute exact path={ROUTES_WAYS.APPEALS_INCOMING} component={lazyPages.Appeals} />
                  {/* Просмотр исходящих обращений */}
                  <PrivateRoute exact path={ROUTES_WAYS.APPEALS_OUTGOING} component={lazyPages.Appeals} />
                  {/* Конструктор обращений в РФС */}
                  <PrivateRoute exact path={ROUTES_WAYS.APPEALS_CONSTRUCTOR} component={lazyPages.AppealsConstructor} />
                  {/* Уведомление Фифа */}
                  <PrivateRoute exact path="/notificationFifa" component={lazyPages.NotificationFifa} />
                  {/* Создание учетной записи пользователя */}
                  <PrivateRoute exact path={ROUTES_WAYS.ACCOUNT_CREATE} component={lazyPages.AccountCreation} />
                  {/* Редактировать  учетную запись пользователя */}
                  <PrivateRoute exact path={ROUTES_WAYS.ACCOUNT_EDIT} component={lazyPages.AccountEdit} />
                  {/* Входящие и исходящие заявки */}
                  <PrivateRoute exact path="/requests" component={lazyPages.Requests} />
                  {/* Страница заявки */}
                  <PrivateRoute exact path="/requests/incoming/:id" component={lazyPages.RequestPage} />
                  {/* Страница заявки */}
                  <PrivateRoute exact path="/requests/outcoming/:id" component={lazyPages.RequestPage} />
                  {/* Поиск ОФИ */}
                  <PrivateRoute exact path={ROUTES_WAYS.SEARCH_INFRASTRUCTURE} component={lazyPages.InfrastructureSearch} />
                  {/* Создание объекта футбольной инфраструктуры */}
                  <PrivateRoute exact path="/infrastructure/add" component={lazyPages.InfrastructureCreate} />
                  {/* Редактирование объекта футбольной инфраструктуры */}
                  <PrivateRoute exact path="/infrastructure/edit/:id" component={lazyPages.InfrastructureCreate} />
                  {/* Просмотр объекта футбольной инфраструктуры */}
                  <PrivateRoute exact path={ROUTES_WAYS.INFRASTRUCTURE_BY_ID} component={lazyPages.InfrastructureView} />
                  {/* Мои организации */}
                  <PrivateRoute exact path="/organizations" component={lazyPages.PersonOrganizations} />
                  {/* Создание заявления на сертификацию */}
                  <PrivateRoute exact path="/applications/certification/creation" component={lazyPages.CertificationCreation} />
                  {/* Заявления на сертификацию объектов */}
                  <PrivateRoute exact path="/applications/certification" component={lazyPages.CertificationList} />
                  {/* Страница входящих заявок на сертификацию */}
                  <PrivateRoute exact path="/applications/certification/incoming/:id" component={lazyPages.CertificationView} />
                  {/* Страница исходящих заявок на сертификацию */}
                  <PrivateRoute exact path="/applications/certification/outgoing/:id" component={lazyPages.CertificationView} />
                  {/* Конструктор лицензий */}
                  {/* Функционал скрыт в рамках RFSEIAS-7427 */}
                  {/* <PrivateRoute exact path="/licenseConstructor" component={lazyPages.LicenseConstructor} /> */}
                  {/* Настройки */}
                  <PrivateRoute exact path="/settings/:id" component={lazyPages.Settings} />
                  {/* Создание группы подготовки */}
                  <PrivateRoute
                    exact
                    path={ROUTES_WAYS.TRAINING_GROUP_ADD_BY_ORGANIZATION_ID}
                    component={lazyPages.TrainingGroupEditByGroupId}
                  />
                  {/* Редактирование группы подготовки */}
                  <PrivateRoute exact path={ROUTES_WAYS.TRAINING_GROUP_EDIT_BY_GROUP_ID} component={lazyPages.TrainingGroupEditByGroupId} />
                  {/* Просмотр группы подготовки */}
                  <PrivateRoute exact path="/trainingGroup/:id" component={lazyPages.TrainingGroupView} />
                  {/* Управление переходами */}
                  <PrivateRoute exact path={ROUTES_WAYS.TRANSFERS_ADMIN_ALL} component={lazyPages.TransferAdmin} />
                  <PrivateRoute exact path={ROUTES_WAYS.TRANSFERS_ADMIN_BY_ID} component={lazyPages.TransferAdmin} />
                  {/* Управление переходами */}
                  <PrivateRoute exact path={ROUTES_WAYS.TRANSFERS_USER_BY_ID} component={lazyPages.TransferUser} />
                  {/* Управление переходами (массовые действия) */}
                  <PrivateRoute exact path={ROUTES_WAYS.TRANSFERS_GROUP} component={lazyPages.TransferGroup} />
                  {/* Формирование расписания тренировок */}
                  <PrivateRoute exact path="/training/schedule" component={lazyPages.TrainingSchedule} />
                  {/* План тренировки */}
                  <PrivateRoute exact path="/training/:id/plan" component={lazyPages.TrainingPlan} />
                  {/* Подведение итогов тренировки */}
                  <PrivateRoute exact path="/training/:id/summarize" component={lazyPages.TrainingSummarize} />
                  {/* Просмотр тренировки */}
                  <PrivateRoute exact path={ROUTES_WAYS.TRAINING_VIEW} component={lazyPages.TrainingView} />
                  {/* Редактирование тренировки */}
                  <PrivateRoute exact path={ROUTES_WAYS.TRAINING_EDIT} component={lazyPages.TrainingEdit} />
                  {/* Мое расписание */}
                  <PrivateRoute exact path="/schedule" component={lazyPages.PersonSchedule} />
                  {/* Реестр упражнений */}
                  <PrivateRoute exact path="/exercise/list" component={lazyPages.ExerciseList} />
                  {/* Реестр тестов */}
                  <PrivateRoute exact path="/test/list" component={lazyPages.TestList} />
                  {/* Создание теста */}
                  <PrivateRoute exact path="/test/add" component={lazyPages.TestAdd} />
                  {/* Редактирование теста */}
                  <PrivateRoute exact path="/test/edit/:id" component={lazyPages.TestEdit} />
                  {/* Просмотр теста */}
                  <PrivateRoute exact path="/test/:id" component={lazyPages.TestView} />
                  {/* Создание упражнения */}
                  <PrivateRoute exact path="/exercise/add" component={lazyPages.ExerciseAdd} />
                  {/* Редактирование упражнения */}
                  <PrivateRoute exact path="/exercise/edit/:id" component={lazyPages.ExerciseEdit} />
                  {/* Просмотр упражнения */}
                  <PrivateRoute exact path="/exercise/:id" component={lazyPages.ExerciseView} />
                  {/* Управление категориями клубов и школ */}
                  <PrivateRoute exact path={ROUTES_WAYS.CATEGORIES} component={lazyPages.Categories} />
                  {/* Политика обработки перс. данных и пользовательское соглашение */}
                  <PrivateRoute exact path={ROUTES_WAYS.PERSONAL_DATA} component={lazyPages.PersonalData} />
                  {/* Расчет контрольных индикаторов */}
                  <PrivateRoute exact path={ROUTES_WAYS.CONTROL_INDICATORS} component={lazyPages.ControlIndicators} />
                  {/* Конструктор документов */}
                  <PrivateRoute exact path={ROUTES_WAYS.DOCUMENT_CONSTRUCTOR} component={lazyPages.DocumentConstructor} />
                  <PrivateRoute
                    exact
                    path={ROUTES_WAYS.DOCUMENT_CONSTRUCTOR_PERSON_ADD}
                    component={lazyPages.PersonDocumentConstructorForm}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES_WAYS.DOCUMENT_CONSTRUCTOR_PERSON_EDIT_BY_ID}
                    component={lazyPages.PersonDocumentConstructorForm}
                  />
                  <PrivateRoute exact path={ROUTES_WAYS.DOCUMENT_CONSTRUCTOR_ORG_ADD} component={lazyPages.OrgDocumentConstructorForm} />
                  <PrivateRoute
                    exact
                    path={ROUTES_WAYS.DOCUMENT_CONSTRUCTOR_ORG_EDIT_BY_ID}
                    component={lazyPages.OrgDocumentConstructorForm}
                  />
                   <PrivateRoute exact path={ROUTES_WAYS.DOCUMENT_CONSTRUCTOR_INFRASTRUCTURE_ADD} component={lazyPages.InfrastructureDocumentConstructorForm} />
                  <PrivateRoute
                    exact
                    path={ROUTES_WAYS.DOCUMENT_CONSTRUCTOR_INFRASTRUCTURE_EDIT_BY_ID}
                    component={lazyPages.InfrastructureDocumentConstructorForm}
                  />
                  {/* Экспорт паспортов футболистов (массовое действие) */}
                  <PrivateRoute exact path={ROUTES_WAYS.PASSPORTS_GROUP} component={lazyPages.PassportsGroup} />
                  {/* Присвоение званий/разрядов (массовое действие) */}
                  <PrivateRoute exact path={ROUTES_WAYS.RANKS_GROUP} component={lazyPages.RanksGroup} />
                  {/* Регистрация контрактов */}
                  <PrivateRoute exact path={ROUTES_WAYS.CONTRACT_REGISTRATION} component={lazyPages.ContractRegistration} />
                  {/* Калькулятор выплат за подготовку спортсмена */}
                  <PrivateRoute exact path={ROUTES_WAYS.PAY_CALCULATOR} component={lazyPages.PayCalculator} />
                  {/* Отчет по статистике переходов */}
                  <PrivateRoute
                    exact
                    path={ROUTES_WAYS.ORGANIZATION_TRANSFER_STATISTIC}
                    component={lazyPages.OrganizationTransferStatistic}
                  />
                  {/* Шаблоны печатных форм */}
                  <PrivateRoute exact path={ROUTES_WAYS.PRINT_TEMPLATES} component={lazyPages.PrintTemplatesView} />
                  {/* Управление шаблоном печатных форм */}
                  <PrivateRoute exact path={ROUTES_WAYS.PRINT_TEMPLATES_BY_ID} component={lazyPages.PrintTemplatesEdit} />
                  {/* Конструктор выплат */}
                  <PrivateRoute exact path={ROUTES_WAYS.PAY_CONSTRUCTOR} component={lazyPages.PayConstructor} />
                  {/* Список инструкций */}
                  <PrivateRoute exact path={ROUTES_WAYS.CONTRACTS} component={lazyPages.ContractsList} />
                  {/* Просмотр инструкции */}
                  <PrivateRoute path={ROUTES_WAYS.CONTRACT_BY_ID} component={lazyPages.ContractView} />
                  {/* Работа с мигрированными данными */}
                  <PrivateRoute path={ROUTES_WAYS.MIGRATED_DATA} component={lazyPages.MigratedData} />
                  {/* Управление справочниками */}
                  <PrivateRoute path={ROUTES_WAYS.DICTIONARIES} component={lazyPages.Dictionaries} />
                  {/* Страница просмотра заседаний */}
                  <PrivateRoute exact path={ROUTES_WAYS.MEETING} component={lazyPages.Meetings} />
                  {/* Страница создания заседания */}
                  <PrivateRoute exact path={ROUTES_WAYS.MEETING_CREATE} component={lazyPages.MeetingCreate} />
                  {/* Страница редактирования заседания */}
                  <PrivateRoute path={ROUTES_WAYS.MEETING_EDIT} component={lazyPages.MeetingEdit} />
                  {/* Страница просмотра дисциплинарных дел */}
                  <PrivateRoute exact path={ROUTES_WAYS.CASES} component={lazyPages.Cases} />
                  {/* Создание дисциплинарного дела */}
                  <PrivateRoute exact path={ROUTES_WAYS.CASES_CREATE} component={lazyPages.CaseCreate} />
                  {/* Редактирование дисциплинарного дела */}
                  <PrivateRoute exact path={ROUTES_WAYS.CASES_EDIT} component={lazyPages.CaseEdit} />
                  {/* Реестр решений */}
                  <PrivateRoute exact path={ROUTES_WAYS.DECISIONS} component={lazyPages.Decisions} />
                  {/* Просмотр решения */}
                  <PrivateRoute exact path={ROUTES_WAYS.DECISION_EDIT} component={lazyPages.EditDecision} />
                  {/* Оценка соревновательной деятельности */}
                  <PrivateRoute exact path={ROUTES_WAYS.RATING} component={lazyPages.RatingView} />
                  {/* Создание оценки соревновательной деятельности */}
                  <PrivateRoute exact path={ROUTES_WAYS.RATING_CREATE} component={lazyPages.RatingEdit} />
                  {/* Редактирование оценки соревновательной деятельности */}
                  <PrivateRoute exact path={ROUTES_WAYS.RATING_EDIT} component={lazyPages.RatingEdit} />
                  {/* Список сотрудников юр.органов */}
                  <PrivateRoute exact path={ROUTES_WAYS.EMPLOYERS} component={lazyPages.Employers} />
                  {/* Страница регламеторв */}
                  <PrivateRoute exact path={ROUTES_WAYS.REGULATION} component={lazyPages.Regulation} />
                  {/* Страница просмотра регламента */}
                  <PrivateRoute exact path={ROUTES_WAYS.REGULATION_EDIT} component={lazyPages.RegulationEdit} />
                  <Redirect to="/" />
                </Switch>
              </Suspense>
              <YAMetricsProvider />
            </ScrollToTop>
          </PermissionsProvider>
        </SessionProvider>
      </ThemeContextProvider>
    </ConnectedRouter>
  );
}

const PTGetDictionariesActions = { getPersonalDataAll: func.isRequired };

const PTPurgeDictionariesActions = { purgePersonalData: func.isRequired };

const PTActions = { initSession: func.isRequired };

App.propTypes = {
  actions: shape(PTActions).isRequired,
  getDictionariesActions: shape(PTGetDictionariesActions).isRequired,
  history: shape(PTHistory).isRequired,
  personalDataAgreement: shape(PTPersonalData),
  purgeDictionariesActions: shape(PTPurgeDictionariesActions).isRequired,
};

App.defaultProps = {
  personalDataAgreement: null,
  // personalDataAgreement: undefined,
};
