import { connect } from 'react-redux';

import { PrivateRoute } from './PrivateRoute.container';

const mapStateToProps = (state) => ({
  loginInProgress: state.core.session.loginInProgress,
  user: state.core.session.user,
});

export default connect(mapStateToProps)(PrivateRoute);
