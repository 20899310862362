import { createReducer } from 'redux-act';
import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';

const createAction = actionsFactory('account/create/');

const initialState = {
  isLoading: false,
  login: '',
};

// actions
export const clearData = createAction('clearData');
export const setLoading = createAction('setLoading');
export const setLogin = createAction('setLogin');

export const getProfileLogin = (personId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const params = { personId };
    const {
      data: { data: login },
    } = await axios.get('/api/rest/person/login', { params });

    dispatch(setLogin(login || ''));
  } catch (err) {
    handleError(err);
    dispatch(setLogin(''));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createProfileLogin = (personId, personLogin) => async (dispatch) => {
  try {
    const params = { personId, personLogin };
    await axios.post('/api/rest/person/set-login', {}, { params });
    handleSuccess('Логин для синхронизации успешно сохранен!');

    dispatch(setLogin(personLogin));
  } catch (err) {
    handleError(err);
  }
};

export const sendInvite = (body) => async () => {
  try {
    await axios.post('/api/rest/person/account/register/step/one', body);
    handleSuccess('Письмо успешно отправлено!', 'paper-plane');
  } catch (e) {
    handleError(e);
    throw e;
  }
};

const reducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setLoading]: (state, isLoading) => ({
      ...state,
      isLoading,
    }),
    [setLogin]: (state, login) => ({
      ...state,
      login,
    }),
  },
  initialState,
);

export default reducer;
