import { createSelector } from 'reselect';

import { PERSONAL_DATA_TYPES } from 'constants/personalDataTypes';

const personalDataSelector = (state) => state.personalData;

export const personalDataAllSelector = createSelector(personalDataSelector, (state) => state.personalDataAll);
export const isAllowJurisPagesSelector = createSelector(personalDataSelector, (state) => state.isAllowJurisPages);

export const personalDataAllByTypesSelector = (() => {
  const reduceAccumulator = Object.freeze({ personalDataAgreement: null, userAgreement: null });

  const reduceCallback = (accumulator, personalData) => {
    const { personalDataFileType } = personalData;

    switch (personalDataFileType) {
      case PERSONAL_DATA_TYPES.PERSONAL_DATA_AGREEMENT:
        return { ...accumulator, personalDataAgreement: personalData };
      case PERSONAL_DATA_TYPES.USER_AGREEMENT:
        return { ...accumulator, userAgreement: personalData };
      case PERSONAL_DATA_TYPES.METRIC_DATA_AGREEMENT:
        return { ...accumulator, metricDataAgreement: personalData };

      default:
        return accumulator;
    }
  };

  return createSelector(personalDataAllSelector, (personalDataAll) => {
    if (Array.isArray(personalDataAll)) {
      return personalDataAll.reduce(reduceCallback, reduceAccumulator);
    }

    return reduceAccumulator;
  });
})();

export const personalDataAgreementSelector = createSelector(personalDataAllByTypesSelector, (state) => state.personalDataAgreement);

export const userAgreementSelector = createSelector(personalDataAllByTypesSelector, (state) => state.userAgreement);

export const metricDataAgreementSelector = createSelector(personalDataAllByTypesSelector, (state) => state.metricDataAgreement);
