import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { selectEventCombinations } from 'redux/selectors/PayConstructor/events';
import { sortByKey } from 'utils/arrays';

const createAction = actionsFactory('payConstructor/events/');

export const setEventCategoryInfo = createAction('setEventCategoryInfo');
export const setEventCombinations = createAction('setEventCombinations');
export const clearData = createAction('clearData');

export const getEventInfo = (eventId) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get(`/api/rest/costcalculation/payouteventtype/${eventId}`);

    dispatch(setEventCategoryInfo({ data: data.categoryFactors, eventId }));
  } catch (err) {
    handleError(err);
    dispatch(setEventCategoryInfo({ data: [], eventId }));
  }
};

export const getEventCombinations = (eventId) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get(`/api/rest/costcalculation/payouteventtype/${eventId}/combination/all`);

    dispatch(setEventCombinations({ data: sortByKey(data, 'combinationNumber'), eventId }));
  } catch (err) {
    handleError(err);
    dispatch(setEventCombinations({ data: [], eventId }));
  }
};

export const updateCombination = (body, eventId) => async (dispatch, getState) => {
  try {
    const {
      data: { data },
    } = await axios.post(`/api/rest/costcalculation/payouteventtype/${eventId}/combination/save`, body);
    handleSuccess(`Комбинация триггеров успешно ${body.id ? 'сохранена' : 'добавлена'}!`);

    const combinations = selectEventCombinations(getState(), { event: { id: eventId } }).concat();
    const idx = combinations.findIndex(({ combinationNumber }) => combinationNumber === data.combinationNumber);

    if (idx < 0) {
      combinations.push(data);
    } else {
      combinations[idx] = data;
    }

    dispatch(setEventCombinations({ data: combinations, eventId }));
  } catch (err) {
    handleError(err);
  }
};

export const deleteCombination = (number, eventId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/costcalculation/payouteventtype/${eventId}/combination/${number}`);
    await dispatch(getEventCombinations(eventId));
    handleSuccess('Комбинация триггеров успешно удалена!', 'trash');
  } catch (err) {
    handleError(err);
  }
};

export const updateConversionHistory = createAction('updateConversionHistory');

export const checkConversionHistory = (personId) => async (dispatch) => {
  try {
    const {
      data: { data: hasHistory },
    } = await axios.get(`/api/rest/costcalculation/checkConversionHistory/${personId}`);

    dispatch(updateConversionHistory(hasHistory));
  } catch (err) {
    handleError(err);
  }
};
