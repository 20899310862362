exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".dialog-tBk5u {\n  border-radius: 8px;\n  background-color: #fff;\n  color: #001a5d;\n}\n", ""]);

// exports
exports.locals = {
	"dialog": "dialog-tBk5u"
};