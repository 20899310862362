import { ROUTES_WAYS } from 'constants/routesWays';
import { PERMISSIONS } from 'constants/permissions';
import { appConfig } from 'constants/appConfig';

import applications from './applications';

const links = {
  main: [
    {
      apex: true,
      link: '/ords/f?p=1:1:::::P1_PAGE_ID:515',
      permission: ['viewCompetition'],
      title: 'Соревнования',
    },
    {
      apex: true,
      link: '/ords/f?p=1:1:::::P1_PAGE_ID:910',
      permission: ['viewMatch'],
      title: 'Матчи',
    },
    {
      link: ROUTES_WAYS.SEARCH_PROFILE,
      permission: ['findParticipant'],
      title: 'Участники',
    },
    {
      link: ROUTES_WAYS.SEARCH_ORGANIZATION,
      permission: ['findOrg'],
      title: 'Организации',
    },
    {
      link: ROUTES_WAYS.SEARCH_INFRASTRUCTURE,
      permission: ['findInfrastructure'],
      title: 'Спортивные объекты',
    },
  ],
  control: [
    {
      link: ROUTES_WAYS.MANAGEMENT,
      oneOf: true,
      permission: [],
      replaceLink: {
        additionalCondition: 'isReferee',
        link: ROUTES_WAYS.MANAGEMENT,
        permission: [PERMISSIONS.EDIT_MATCH_REFEREE],
        title: 'Управление',
      },
      title: 'Управление',
    },
    {
      link: ROUTES_WAYS.CONTRACTS,
      permissionKey: 'global',
      replaceLink: {
        oneOf: true,
        link: ROUTES_WAYS.CONTRACTS,
        permission: [
          PERMISSIONS.CONTROL_FOOT_STATUS,
          PERMISSIONS.CONTRACT_ADMIN,
          PERMISSIONS.CONTRACT_CONCLUSION,
          PERMISSIONS.CONTRACT_AGREEMENT,
        ],
        title: 'Контракты',
      },
      permission: [PERMISSIONS.CONTRACT_ADMIN],
      title: 'Контракты',
    },
    {
      link: ROUTES_WAYS.APPEALS_INCOMING,
      permission: [PERMISSIONS.GET_APPEAL],
      replaceLink: {
        link: ROUTES_WAYS.APPEALS_OUTGOING,
        permission: [PERMISSIONS.DO_APPEAL],
        title: 'Обращения в РФС',
      },
      title: 'Обращения в РФС',
    },
    {
      link: '/applications',
      oneOf: true,
      permission: applications.permissions,
      title: 'Заявления',
    },
    {
      apex: true,
      link: `${appConfig.TALENTS_HOST}/directories`,
      title: 'Справочная информация',
    },
    {
      apex: true,
      link: `${appConfig.MDM_HOST}/nsi`,
      newTab: true,
      permission: [PERMISSIONS.CONTROL_GUIDE],
      title: 'НСИ',
    },
  ],
};

export default links;
