import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/ui';

import css from './Components.scss';

const ControlButtons = ({ onEdit, onRemove }) => (
  <div className={css.controlsContainer}>
    <Button onClick={onEdit}>Редактировать</Button>
    <Button type="secondary" onClick={onRemove}>Удалить</Button>
  </div>
);

ControlButtons.propTypes = {
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

export default ControlButtons;
