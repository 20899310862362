import styles from '../styles';

export const makeStyles = {
  ...styles,
  option: (base, state) => {
    return {
      ...styles.option(base, state),
      borderLeft: 'none',
    };
  },
  menu: (base, state) => {
    return {
      ...styles.menu(base, state),
      boxShadow: '2px 4px 9px rgba(0, 0, 0, 0.14)',
      border: 'none',
      padding: '0 10px 0 14px',
    };
  },
  menuList: (base, state) => {
    return {
      ...styles.menuList(base, state),
      '::-webkit-scrollbar': {
        width: '4px',
      },
      '::-webkit-scrollbar-thumb': {
        ':hover': {
          backgroundColor: '#1fadff',
        },
        backgroundColor: '#0a81ff',
        borderRadius: '14px',
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: '#BABABE',
        borderRadius: '14px',
        marginBottom: '7px',
        marginTop: '16px',
      },
    };
  },
};
