import axios from 'axios';

import { ConfirmModal } from 'components/rfs-ui';
import { CONTRACT_STATUSES } from 'constants/contracts';
import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { selectContractData } from 'redux/selectors/Profile/contractsSelectors';

import { uploadFilesMultiple } from 'utils/uploadFiles';

const createAction = actionsFactory('profile/contracts/');

export const clearData = createAction('clearData');
export const setContractsListLoading = createAction('setContractsListLoading');
export const setContractsListData = createAction('setContractsListData');
export const setContractsTypesLoading = createAction('setContractsTypesLoading');
export const setContractsTypesData = createAction('setContractsTypesData');

export const getContractRequest = createAction('getContractRequest');
export const getContractSuccess = createAction('getContractSuccess');
export const clearContractData = createAction('clearContractData');

export const clearContractFormData = createAction('clearContractFormData');
export const setContractFormData = createAction('setContractFormData');
export const setContractFormLoading = createAction('setContractFormLoading');
export const updateContractFormData = createAction('updateContractFormData');

export const getContractsList = (personId) => async (dispatch) => {
  try {
    dispatch(setContractsListLoading(true));
    const response = await axios.get(`/api/rest/contract/find?page=0&pagesize=100&personId=${personId}`);
    dispatch(setContractsListData(response.data.data));
  } catch (error) {
    handleError(error);
    dispatch(setContractsListData([]));
  } finally {
    dispatch(setContractsListLoading(false));
  }
};

export const getContractsTypes = () => async (dispatch) => {
  try {
    dispatch(setContractsTypesLoading(true));
    const response = await axios.get('/api/rest/contract/types');
    dispatch(setContractsTypesData(response.data.data));
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setContractsTypesLoading(false));
  }
};

export const getContract = (contractId) => async (dispatch) => {
  try {
    dispatch(getContractRequest(contractId));
    const {
      data: { data },
    } = await axios.get(`/api/rest/contract/${contractId}`);
    dispatch(getContractSuccess({ contractId, data }));
  } catch (error) {
    handleError(error);
    dispatch(clearContractData(contractId));
  }
};

const handleUpdateContract = (contractId, personId) => async (dispatch) => {
  await Promise.all([
    contractId ? dispatch(getContract(contractId)) : Promise.resolve(),
    personId || personId === 0 ? dispatch(getContractsList(personId)) : Promise.resolve(),
  ]);
};

export const postContract = (contract) => async (dispatch) => {
  try {
    const body = { ...contract };
    body.documents = await uploadFilesMultiple(body.documents);

    if (body.documents.some((obj) => !obj)) {
      return;
    }

    await axios.post('/api/rest/contract', body);

    handleSuccess(`Контракт успешно ${body.id ? 'изменен' : 'сохранен'}!`, 'file-check');
    await dispatch(handleUpdateContract(body.id, body.personId));
  } catch (err) {
    handleError(err);
    return Promise.reject(err);
  }
};

export const postAgreement = (data) => async (dispatch) => {
  try {
    const { agreement, contract } = data;
    agreement.documents = await uploadFilesMultiple(agreement.documents);

    await axios.post(`/api/rest/contract/${contract.id}/additional-agreement`, agreement);

    handleSuccess(`Дополнительное соглашение успешно ${contract.id ? 'изменено' : 'сохранен'}!`, 'file-check');
    await dispatch(handleUpdateContract(contract.id, contract.personId));
  } catch (err) {
    handleError(err);
    return Promise.reject(err);
  }
};

export const deleteAgreement = (data) => async (dispatch) => {
  try {
    const { additionalAgreementId, contract } = data;
    await axios.delete(`/api/rest/contract/additional-agreement/${additionalAgreementId}`);
    handleSuccess('Дополнительное соглашение успешно удалено');
    await dispatch(handleUpdateContract(contract.id, contract.personId));
  } catch (err) {
    handleError(err);
    return Promise.reject(err);
  }
};

export const deleteContract = (contractId, personId) => async (dispatch) => {
  try {
    await ConfirmModal.confirmAsync({
      applyText: 'Удалить',
      children: 'Вы желаете удалить контракт?',
    });

    await axios.delete('/api/rest/contract', { data: [contractId] });
    dispatch(clearContractData(contractId));
    handleSuccess('Контракт успешно удален!', 'trash');

    await dispatch(handleUpdateContract(undefined, personId));
  } catch (error) {
    handleError(error);
  }
};

export const suspendContract = (contract, personId) => async (dispatch) => {
  try {
    const params = { contractId: contract.id };

    let applyText;
    let confirmText;
    let successMessage;

    if (contract.status.name === CONTRACT_STATUSES.SUSPEND) {
      applyText = 'Возобновить';
      confirmText = 'Вы желаете возобновить контракт?';
      successMessage = 'Контракт успешно возобновлен!';
      params.isSuspended = false;
    } else {
      applyText = 'Приостановить';
      confirmText = 'Вы желаете приостановить контракт?';
      successMessage = 'Контракт успешно приостановлен!';
      params.isSuspended = true;
    }

    await ConfirmModal.confirmAsync({
      applyText,
      children: confirmText,
    });

    await axios.put('/api/rest/contract/suspend', null, { params });
    handleSuccess(successMessage, 'file-check');

    await dispatch(handleUpdateContract(contract.id, personId));
  } catch (error) {
    handleError(error);
  }
};

export const avoidContract = (contract, personId) => async (dispatch) => {
  try {
    const params = { contractId: contract.id };
    await axios.put('/api/rest/contract/finished', null, { params });
    handleSuccess('Контракт успешно завершен!', 'file-times');
    await dispatch(handleUpdateContract(contract.id, personId));
  } catch (err) {
    handleError(err);
    return Promise.reject(err);
  }
};

export const initializeContractForm = (contract) => async (dispatch, getState) => {
  try {
    dispatch(setContractFormLoading(true));
    dispatch(setContractFormData(contract));

    if (contract.id) {
      const contractFromState = selectContractData(getState(), { contract });
      if (contractFromState) {
        dispatch(setContractFormData(contractFromState));
      } else {
        await dispatch(getContract(contract.id));
        const loadedContract = selectContractData(getState(), { contract });
        dispatch(setContractFormData(loadedContract));
      }
    }
  } catch (err) {
    handleError(err);
  } finally {
    dispatch(setContractFormLoading(false));
  }
};
