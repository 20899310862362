import { createReducer } from 'redux-act';

import { setThresholdSum, setThresholdData, clearData } from 'redux/actions/PayConstructor/threshold';

const initialState = {
  data: [],
  sum: {},
};

const thresholdReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setThresholdData]: (state, data) => ({
      ...state,
      data,
    }),
    [setThresholdSum]: (state, sum) => ({
      ...state,
      sum,
    }),
  },
  initialState,
);

export default thresholdReducer;
