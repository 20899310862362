export const DEFAULT_APPLICATION_TYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/jpg',
];

export const IMAGE_AND_PDF_TYPES = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
export const IMAGE_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

export const OFFICE_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
];

export const TEMPLATE_TYPES = ['.jrxml'];
