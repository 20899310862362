exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".container-113_5 {\n  display: inline-block;\n}\n\n.label-Mq6q- {\n  margin-bottom: 10px;\n}\n\n.checkboxGroup-37HaW {\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n}\n\n.checkboxGroup-37HaW .checkboxItem-2e8y7 {\n    margin-right: 20px;\n  }\n\n.checkboxContainer-2iqrQ {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: baseline;\n      -ms-flex-align: baseline;\n          align-items: baseline;\n  position: relative;\n  margin-bottom: 10px;\n}\n\n.checkboxContainer-2iqrQ:last-child {\n    margin-bottom: 0;\n  }\n\n\n", ""]);

// exports
exports.locals = {
	"container": "container-113_5",
	"label": "label-Mq6q-",
	"checkboxGroup": "checkboxGroup-37HaW",
	"checkboxItem": "checkboxItem-2e8y7",
	"checkboxContainer": "checkboxContainer-2iqrQ"
};