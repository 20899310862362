import { applyMiddleware, compose, createStore } from 'redux';

import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import createReducer from './modules/reducer';

function createAppStore(history) {
  const middlewares = [thunk, routerMiddleware(history)];

  const composeEnhancers = (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const store = createStore(createReducer(history), composeEnhancers(applyMiddleware(...middlewares)));

  return store;
}

export default createAppStore;
