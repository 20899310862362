exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------ \nCommon\n--------------------------*/\n\n\n/* layouts width*/\n\n\n/* ------------------------ \nDark Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* input*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nLight Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nRating\n--------------------------*/\n\n\n.container-2yeAK {\n  border: 1px solid #0a81ff;\n  border-radius: 5px;\n  width: 100%;\n  clear: both;\n}\n\n\n.container-2yeAK:hover {\n    border-color: #1fadff;\n  }\n\n\n.wrapper-NRcon {\n  width: 100%;\n}\n\n\n.label-3hC1S {\n  background-color: #0a81ff;\n  right: 0;\n  position: absolute;\n  text-transform: uppercase;\n  font-size: 9px;\n  padding: 2px 10px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "container-2yeAK",
	"wrapper": "wrapper-NRcon",
	"label": "label-3hC1S"
};