import axios from 'axios';
import { CREATOR_TYPES } from 'constants/appeals';
import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { removeListFromCollection, uploadListToCollection, getFilesCollection } from 'utils/uploadFiles';

const createAction = actionsFactory('appeals/processing/');

export const clearData = createAction('clearData');
export const setAppealData = createAction('setAppeal');
export const setAppealLoading = createAction('setAppealLoading');

export const getAppeal = (id) => async (dispatch) => {
  try {
    dispatch(setAppealLoading(true));
    const {
      data: { data: appeal },
    } = await axios.get(`/api/rest/appeals/${id}`);
    const documents = await getFilesCollection(id, 'appeal');
    appeal.documents = documents.filter((doc) => doc.param === CREATOR_TYPES.RFS);
    appeal.subjectDocuments = documents.filter((doc) => doc.param === CREATOR_TYPES.MEMBER);

    dispatch(setAppealData(appeal));
  } catch (err) {
    handleError(err);
    dispatch(setAppealData(null));
  } finally {
    dispatch(setAppealLoading(false));
  }
};

export const processAppeal = (data) => async () => {
  try {
    const { docsToUpload, docsToRemove, ...body } = data;
    await axios.post('/api/rest/appeals/update/subject', body);

    await removeListFromCollection(docsToRemove, 'appeal');
    await uploadListToCollection(docsToUpload, 'appeal', body.appealId, undefined, CREATOR_TYPES.RFS);
    handleSuccess('Информация по обращению успешно обновлена!');
  } catch (err) {
    handleError(err);
    throw err;
  }
};
