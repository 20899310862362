import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Time from './Time';

class Timepicker extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    format: PropTypes.string,
    onChange: PropTypes.func,
  };

  constructor (props) {
    super(props);
    const { value, format } = props;
    const momentTime = moment(value, format);

    this.state = {
      hour: momentTime.hour(),
      minute: momentTime.minute(),
    };
  }

  handleTimeChange = (key, value) => {
    this.setState({ [key]: value }, () => {
      const { hour, minute } = this.state;
      const { format, onChange } = this.props;
      const time = moment({ hour, minute }).format(format);
      onChange(time);
    });
  }

  render () {
    const { hour, minute } = this.state;

    return (
      <Time
        hour={hour}
        minute={minute}
        onChange={this.handleTimeChange}
      />
    );
  }
}

export default Timepicker;
