import { createReducer } from 'redux-act';
import { clearData, setIsLoading, setUniformData, setColorTypes } from 'redux/actions/Organization/uniformActions';

const initialState = {
  data: [],
  colorTypes: [],
  isLoading: false,
};

const licensesReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setIsLoading]: (state, payload) => ({
      ...state,
      isLoading: payload,
    }),
    [setUniformData]: (state, payload) => ({
      ...state,
      data: payload,
    }),
    [setColorTypes]: (state, payload) => ({
      ...state,
      colorTypes: payload,
    }),
  },
  initialState,
);

export default licensesReducer;
