import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import axios from 'axios';
import { CasesEditTabs } from 'components/pages/Cases/Edit/static';
import { ROUTES_WAYS } from 'constants/routesWays';
import { omit } from '../../../utils/lodash';

const createAction = actionsFactory('cases/creation/');

export const setSaveCaseLoading = createAction('setSaveCaseLoading');

export const saveCase = (data, successCallback, successNotification) => async (dispatch) => {
  try {
    dispatch(setSaveCaseLoading(true));
    const requestBody = {
      ...data,
      committee: { federationId: data.federation?.federationId, ...data.committee },
      tournament: {
        ...data.tournament,
        ekp: data.tournament?.tournamentNumEkp,
      },
      ...omit(data.event || {}, 'id'),
    };

    const url = '/api/rest/judiciary/case/save';

    const {
      data: {
        data: { id },
      },
    } = await axios.post(url, { ...requestBody });
    handleSuccess(successNotification || 'Дело успешно создано!');
    if (successNotification) successCallback();
    else successCallback(ROUTES_WAYS.CASES_EDIT.replace(':id', id).replace(':tab', CasesEditTabs[0].section));
    dispatch(setSaveCaseLoading(false));
  } catch (err) {
    handleError(err);
    dispatch(setSaveCaseLoading(false));
  }
};
