import { createReducer } from 'redux-act';

import { clearData, setRatingData, setRatingDeleting, setRatingError, setRatingLoading } from 'redux/actions/Rating';

const initialState = {
  data: null,
  isDeleting: false,
  isError: false,
  isLoading: false,
};

const viewReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setRatingData]: (state, data) => ({
      ...state,
      data,
    }),
    [setRatingDeleting]: (state, isDeleting) => ({
      ...state,
      isDeleting,
    }),
    [setRatingError]: (state, isError) => ({
      ...state,
      isError,
    }),
    [setRatingLoading]: (state, isLoading) => ({
      ...state,
      isLoading,
    }),
  },
  initialState,
);

export default viewReducer;
