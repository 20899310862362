import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

import { handleError } from 'magic/handlers';
import { THEMES } from './constants';

const DEFAULT_THEME = THEMES.LIGHT;
const STORAGE_KEY = 'theme';

export const ThemeContext = React.createContext({
  theme: DEFAULT_THEME,
  toggleTheme: () => {},
});

class ThemeContextProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    user: PropTypes.object,
  };

  static defaultProps = {
    children: null,
    user: undefined,
  };

  state = {
    context: {
      theme: null,
      toggleTheme: this.toggleTheme,
    },
    theme: null,
  };

  componentDidMount() {
    const theme = DEFAULT_THEME;
    const isValidTheme = theme && Object.values(THEMES).includes(theme);
    this.toggleTheme(isValidTheme ? theme : DEFAULT_THEME);
    // this.fetchThemeSettings();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    const prevUserId = prevProps.user && prevProps.user.personid;
    const userId = user && user.personid;

    if (prevProps.user && !user) {
      this.toggleTheme(DEFAULT_THEME);
    } else if (prevUserId !== userId) {
      // this.fetchThemeSettings();
    }
  }

  fetchThemeSettings = async () => {
    const { user } = this.props;
    if (!user) {
      return;
    }

    try {
      const response = await axios.get(`/api/rest/person/theme/get?personId=${user.personid}`);
      const theme = response.data.data ? THEMES.DARK : THEMES.LIGHT;
      this.toggleTheme(theme);
    } catch (e) {
      handleError(e);
    }
  };

  updateTheme = () => {
    const { theme } = this.state;
    document.documentElement.className = 'js-focus-visible';

    if (theme === THEMES.DARK) {
      document.documentElement.classList.add('theme-dark');
    }

    if (theme === THEMES.LIGHT) {
      document.documentElement.classList.add('theme-light');
    }

    const context = { theme, toggleTheme: this.toggleTheme };
    this.setState({ context });
  };

  toggleTheme = (nextTheme) => {
    const { theme } = this.state;
    if (nextTheme !== theme) {
      this.setState({ theme: nextTheme }, this.updateTheme);
      localStorage.setItem(STORAGE_KEY, nextTheme);
    }
  };

  render() {
    const { children } = this.props;
    const { context } = this.state;

    return <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>;
  }
}

const mapStateToProps = (state) => ({
  user: state.core.session.user,
});

export default connect(mapStateToProps)(ThemeContextProvider);
