import React, { useMemo, useEffect, useContext, useState } from 'react';
import PermissionsContext from 'components/composed/Permissions/PermissionsContext';
import { PERMISSIONS } from 'constants/permissions';
import { checkPermissions } from 'magic/permissions';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import cn from 'classnames';

import { PermissionsConsumer } from 'components/composed/Permissions';
import { get } from 'utils/lodash';
import { preventDefault } from 'utils/helpers';
import { SPORTING_ROLES } from 'constants/sportingRoles';
import { PTHistory } from 'propTypes';

import links from './links';
import css from './NavbarHeader.scss';

const NavbarHeader = ({ history, light, userProfile, competitionsAddress }) => {

  const [dynamicLinks, setDynamicLinks] = useState(links)

  const userPermissions = useContext(PermissionsContext);
  const hasNewCompetitionsAccess = checkPermissions(false, false, 'global', userPermissions, [PERMISSIONS.NEW_COMPETITION])

  const additionalConditions = useMemo(() => {
    const sportActivities = get(userProfile, 'sportActivityIns', []);
    const isReferee = sportActivities.some(
      (spotActivity) => spotActivity.sportingRole && spotActivity.sportingRole.sysName === SPORTING_ROLES.REFEREE,
    );

    return { isReferee };
  }, [userProfile]);

  useEffect(() => {
    if (hasNewCompetitionsAccess && competitionsAddress) {
      const _links = links
      _links.main[0].link = `${competitionsAddress}/competition-management/`
      _links.main[0].permission = [PERMISSIONS.NEW_COMPETITION, PERMISSIONS.VIEW_COMPETITION]
      _links.main[1].link = `${competitionsAddress}/matches/`
      _links.main[1].permission = [PERMISSIONS.NEW_COMPETITION, 'viewMatch']
      setDynamicLinks(prevLinks => ({ ...prevLinks, ..._links }));
    }
  }, [userPermissions, hasNewCompetitionsAccess, competitionsAddress])

  const renderLink = (item, index) => {
    const linkClass = cn({
      [css.navLink]: true,
      [css.light]: light,
      [css.active]: history.location.pathname.indexOf(item.link) >= 0,
      [css.disabled]: item.disabled,
    });

    const link = item.apex ? (
      <div className={css.linkContainer}>
        <a
          className={linkClass}
          target={item.newTab ? '_blank' : '_self'}
          key={item.link}
          href={item.link}
          title={item.label}
          rel="noreferrer"
        >
          {item.title}
        </a>
        <div className={css.underline} />
      </div>
    ) : (
      <div className={css.linkContainer}>
        <Link to={item.link} className={linkClass} key={item.link} onClick={item.disabled ? preventDefault : undefined} title={item.label}>
          {item.title}
        </Link>
        <div className={css.underline} />
      </div>
    );

    const mode = item.replaceLink ? 'replace' : 'hide';
    const replaceComponent = item.replaceLink ? renderLink(item.replaceLink, item.replaceLink.link) : null;
    const additionalCondition = item.additionalCondition ? additionalConditions[item.additionalCondition] : true

    return (
      <PermissionsConsumer
        additionalCondition={additionalCondition}
        permissions={item.permission}
        oneOf={item.oneOf}
        some={item.some}
        key={index}
        mode={mode}
        replaceComponent={replaceComponent}
      >
        {link}
      </PermissionsConsumer>
    );
  };

  return (
    <div className={css.mainContainer}>
      <div className={css.menu}>{dynamicLinks.main.map(renderLink)}</div>
      <div className={css.menu}>{dynamicLinks.control.map(renderLink)}</div>
    </div>
  );
};

NavbarHeader.propTypes = {
  history: PropTypes.shape(PTHistory).isRequired,
  light: PropTypes.bool,
  userProfile: PropTypes.object,
};

NavbarHeader.defaultProps = {
  light: false,
  userProfile: {},
};

const mapState = (state) => ({
  userProfile: state.core.session.userProfile,
  competitionsAddress: state.competitionsIntegrationReducer?.address,
});

export default compose(withRouter, connect(mapState))(NavbarHeader);
