import React from 'react';
import { object, node, string, oneOf, oneOfType } from 'prop-types';
import cn from 'classnames';

import css from './index.scss';

function Tooltip(props) {
  const { text, containerClassName, triggerClassName, className, children, style, position } = props;

  return (
    <div className={cn(css.tooltipContainer, containerClassName)}>
      {children && <div className={cn(css.tooltipTrigger, triggerClassName)}>{children}</div>}
      <div className={cn(css.tooltip, className, css[position])} style={style}>
        {text}
      </div>
    </div>
  );
}

Tooltip.defaultProps = {
  children: undefined,
  className: '',
  containerClassName: 'defined',
  position: 'top',
  style: undefined,
  text: '',
  triggerClassName: '',
};

Tooltip.propTypes = {
  children: node,
  className: string,
  containerClassName: string,
  position: oneOf(['top', 'bottom']),
  style: object,
  text: oneOfType([string, node]),
  triggerClassName: string,
};

export default Tooltip;
