/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import css from './Input.scss';

const muteSymbolsNumberInput = ['.', ',', 'e', 'E', '-'];

const Input = ({
  id,
  className,
  placeholder,
  maxlength,
  name,
  value,
  disabled,
  onChange,
  onKeyPress,
  onPaste,
  onFocus,
  onBlur,
  refer,
  type,
  accept,
  multiple,
  autoComplete,
  onlyIntegerNumbers,
}) => {
  const inputClassName = useMemo(() => cn(css.input, className), [className]);
  const integerNumberInput = useMemo(() => type === 'number' && onlyIntegerNumbers, [onlyIntegerNumbers, type]);

  return (
    <input
      id={id}
      className={inputClassName}
      placeholder={placeholder}
      name={name}
      value={value}
      disabled={disabled}
      onPaste={onPaste}
      onChange={onChange}
      onKeyPress={(e) => (muteSymbolsNumberInput.includes(e.key) && integerNumberInput ? e.preventDefault() : onKeyPress(e))}
      onFocus={onFocus}
      onBlur={onBlur}
      ref={refer}
      type={type}
      accept={accept}
      multiple={multiple}
      maxLength={maxlength}
      autoComplete={autoComplete}
    />
  );
};

Input.propTypes = {
  accept: PropTypes.string,
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxlength: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onPaste: PropTypes.func,
  onlyIntegerNumbers: PropTypes.bool,
  placeholder: PropTypes.string,
  refer: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Input;
