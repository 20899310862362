/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Button, Tooltip } from 'components/ui';

import css from './TooltipButton.scss';

const TooltipButton = ({ className, buttonClassName, icon, iconPack, title, onClick, disabled, index, customIcon }) => {
  const tooltipStyles = useMemo(() => cn(css.tooltipContainer, className), [className]);
  const buttonStyles = useMemo(() => cn(css.button, buttonClassName), [buttonClassName]);

  return (
    <Tooltip text={title} containerClassName={tooltipStyles}>
      <Button
        className={buttonStyles}
        icon={icon}
        customIcon={customIcon}
        iconPack={iconPack}
        onClick={onClick}
        disabled={disabled}
        data-index={index}
        type="text"
      />
    </Tooltip>
  );
};

TooltipButton.Create = (props) => <TooltipButton title="Создать" icon="plus-circle" {...props} />;
TooltipButton.Delete = (props) => <TooltipButton title="Удалить" icon="trash" {...props} />;
TooltipButton.Edit = (props) => <TooltipButton title="Редактировать" icon="pen" {...props} />;
TooltipButton.Pause = (props) => <TooltipButton title="Приостановить" icon="pause-circle" {...props} />;
TooltipButton.Resume = (props) => <TooltipButton title="Возобновить" icon="play-circle" {...props} />;
TooltipButton.Verify = (props) => <TooltipButton title="Верифицировать" icon="check" {...props} />;

TooltipButton.propTypes = {
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconPack: PropTypes.string,
  index: PropTypes.number,
  onClick: PropTypes.func,
  title: PropTypes.string,
  customIcon: PropTypes.node,
};

TooltipButton.defaultProps = {
  buttonClassName: undefined,
  className: undefined,
  disabled: undefined,
  icon: undefined,
  iconPack: undefined,
  index: undefined,
  onClick: undefined,
  title: '',
  customIcon: undefined,
};

export default TooltipButton;
