import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter, matchPath } from 'react-router-dom';

import Icon from 'components/ui/Icon/Icon';
import { PTLocation } from 'propTypes';
import { isEmpty } from 'utils/lodash';

import BreadcrumbsMap from './BreadcrumbsMap';
import styles from './Breadcrumbs.scss';

const Breadcrumbs = ({ location }) => {
  const { pathname } = location;

  const mapKey = useMemo(() => {
    const matchedPath = Object.keys(BreadcrumbsMap).find((path) => matchPath(pathname, { exact: true, path }));
    return matchedPath || Object.keys(BreadcrumbsMap).find((key) => pathname.indexOf(key) >= 0);
  }, [pathname]);

  const chain = useMemo(() => {
    if (!mapKey) {
      return null;
    }

    const { params } = matchPath(pathname, { exact: true, path: mapKey }) || {};

    if (isEmpty(params)) {
      return BreadcrumbsMap[mapKey];
    }

    return BreadcrumbsMap[mapKey].map((item) => {
      if (!item.path) {
        return item;
      }

      return {
        ...item,
        path: Object.keys(params).reduce((link, key) => link.replace(`:${key}`, params[key]), item.path),
      };
    });
  }, [mapKey, pathname]);

  if (!chain || chain.length <= 1) {
    return null;
  }

  return (
    <div className={styles.breadcrumbs}>
      {chain.map((breadcrumb, key) =>
        key < chain.length - 1 ? (
          <div key={key} className={styles.breadcrumb}>
            <Link to={breadcrumb.path}>{breadcrumb.label}</Link>
            <Icon icon="angle-right" size="1x" pack="fal" className={styles.separator} />
          </div>
        ) : (
          <span className={styles.breadcrumb} key={key}>
            {breadcrumb.label}
          </span>
        ))}
    </div>
  );
};

Breadcrumbs.propTypes = {
  location: PropTypes.shape(PTLocation).isRequired,
};

export default withRouter(Breadcrumbs);
