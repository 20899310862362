import { notificationError, notificationSuccess } from 'magic/notification';
import { get } from 'utils/lodash';

/**
 *
 * @param {object} error объект ошибки на вход, из axios или обычный
 * @param {func=} callback если необходима дополнительная обработка ошибки, можно передать функцию
 */
export const handleError = (error, callback) => {
  const message = get(error, 'response.data.synopsis', error.text);

  if (message) {
    notificationError(message);
  } else {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  if (callback) callback();
};

/**
 *
 * @param {string} message сообщение уведомления
 * @param {string} icon иконка для уведомления
 */
export const handleSuccess = (message, icon = 'check') => {
  notificationSuccess(message, icon);
};
