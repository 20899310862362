import React, { useMemo, useCallback } from 'react';
import { oneOf, oneOfType, string, bool, node, object } from 'prop-types';
import classNames from 'classnames/bind';
import { Tooltip, Icon } from 'components/ui';

import Link from '../Link';

import styles from './Labeltext.scss';

const cx = classNames.bind(styles);

const ToolTip = ({ text }) => {
  return (
    <Tooltip  className={styles.tooltip}
    containerClassName={styles.tooltipContainer}
   text={text}>
      <Icon icon="info-circle" pack="fas" />
    </Tooltip>
  );
};

export default function LabelText({
  className,
  classNameLabel,
  classNameText,
  hasMargin,
  label,
  link,
  separator,
  target,
  text,
  externalLink,
  showEmpty,
  tooltipText,
}) {
  const classes = useMemo(
    () =>
      cx({
        [className]: Boolean(className),
        labelText: hasMargin,
      }),
    [className, hasMargin],
  );

  const classesLabel = useMemo(
    () =>
      cx({
        [classNameLabel]: Boolean(classNameLabel),
        label: styles.label,
        label_margin_yes: label,
      }),
    [label, classNameLabel],
  );

  const classesText = useMemo(() => cx(styles.text, { [classNameText]: Boolean(classNameText) }), [classNameText]);
  const classesLink = useMemo(() => cx(styles.link, { [classNameText]: Boolean(classNameText) }), [classNameText]);
  const isVisible = useMemo(() => text || text === 0 || showEmpty, [text, showEmpty]);

  const handlePreventClick = useCallback((e) => e.stopPropagation(), []);

  if (isVisible) {
    return (
      <div className={classes}>
        <div className={classesLabel}>
          {label}
          {tooltipText && <ToolTip text={tooltipText} />}
          {separator}
        </div>
        {link && externalLink && (
          <a href={link} onClick={handlePreventClick} target={target} className={classesLink}>
            {text}
          </a>
        )}
        {link && !externalLink && (
          <Link to={link} className={classesLink}>
            {text}
          </Link>
        )}
        {!link && <div className={classesText}>{text}</div>}
      </div>
    );
  }

  return null;
}

LabelText.propTypes = {
  className: string,
  classNameLabel: string,
  classNameText: string,
  externalLink: bool,
  hasMargin: bool,
  label: oneOfType([string, node]),
  link: oneOfType([string, object]),
  separator: string,
  showEmpty: bool,
  target: oneOf(['_blank', '_self', '_parent', '_top']),
  text: oneOfType([string, node]),
};

LabelText.defaultProps = {
  className: undefined,
  classNameLabel: undefined,
  classNameText: undefined,
  externalLink: true,
  hasMargin: true,
  label: '',
  link: undefined,
  separator: ':',
  showEmpty: false,
  target: '_blank',
  text: '',
};
