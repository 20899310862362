import React from 'react';
import ReactTable from 'react-table';
import cn from 'classnames';
import 'react-table/react-table.css?external';

import css from './index.scss';

const BlockTable = ({ columns, children: data, className, ...restProps }) => (
  <ReactTable
    data={data}
    className={cn(css.tableContainer, className)}
    columns={columns}
    sortable={false}
    resizable={false}
    showPagination={false}
    defaultPageSize={data.length}
    getTrGroupProps={() => ({ className: css.rowGroup })}
    getTableProps={() => ({ className: css.overflowFix })}
    getTbodyProps={() => ({ className: css.overflowFix })}
    getTheadProps={() => ({ className: css.tableHeader })}
    minRows={1}
    {...restProps}
  />
);

export default BlockTable;
