import { createSelector } from 'reselect';
import moment from 'moment';

const totalTrainingGroupFormSelector = (state) => state.trainingGroup.totalTrainingGroupForm;

export const baseYearBirthsSelector = createSelector(totalTrainingGroupFormSelector, (form) => form.baseYearBirths);
export const curatorSelector = createSelector(totalTrainingGroupFormSelector, (form) => form.curator);
export const groupNameSelector = createSelector(totalTrainingGroupFormSelector, (form) => form.groupName);
export const seasonSelector = createSelector(totalTrainingGroupFormSelector, (form) => form.season);
export const stageSelector = createSelector(totalTrainingGroupFormSelector, (form) => form.stage);
export const stageYearSelector = createSelector(totalTrainingGroupFormSelector, (form) => form.stageYear);

export const seasonRangeSelector = createSelector(seasonSelector, (season) => {
  if (!season) {
    return Object.create(null);
  }

  const { dateEnd, dateStart } = season;

  const momentDateStart = moment(dateStart);
  const momentDateEnd = moment(dateEnd);

  const startSeasonUTC = new Date(dateStart);
  const endSeasonUTC = new Date(dateEnd);

  const startSeason = momentDateStart.format('DD.MM.YYYY');
  const endSeason = momentDateEnd.format('DD.MM.YYYY');

  const disabledDays = { after: endSeasonUTC, before: startSeasonUTC };

  return {
    disabledDays,
    endSeason,
    endSeasonUTC,
    startSeason,
    startSeasonUTC,
  };
});
