import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { PermissionsConsumer } from 'components/composed/Permissions';

import styles from './Tab.scss';

const cn = classNames.bind(styles);

/**
 * @type {React.FC<Object>}
 */
const Tab = React.forwardRef((props, ref) => {
  const { children, className, onClick, disabled, active, tabIndex, permissionKey, permissions, oneOf, some } = props;

  const canClick = !disabled && !active;
  const classes = useMemo(() => cn({ active, disabled, tab: true }, className), [active, disabled, className]);
  const handleClickTab = useCallback(() => canClick && onClick(tabIndex), [onClick, tabIndex, canClick]);

  if (permissions) {
    return (
      <PermissionsConsumer permissionKey={permissionKey} permissions={permissions} oneOf={oneOf} some={some}>
        <button type="button" className={classes} onClick={handleClickTab} ref={ref}>
          {children}
        </button>
      </PermissionsConsumer>
    );
  }

  return (
    <button type="button" className={classes} onClick={handleClickTab} ref={ref}>
      {children}
    </button>
  );
});

Tab.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  oneOf: PropTypes.bool,
  permissionKey: PropTypes.any,
  permissions: PropTypes.arrayOf(PropTypes.string),
  some: PropTypes.bool,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Tab.defaultProps = {
  active: false,
  children: null,
  className: undefined,
  disabled: false,
  onClick: undefined,
  oneOf: false,
  permissionKey: undefined,
  permissions: undefined,
  some: false,
  tabIndex: undefined,
};

export default Tab;
