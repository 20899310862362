import { createReducer } from 'redux-act';
import { DEFAULT_PAGINATION } from 'constants/pagination';

import {
  clearFreeTemplatesData,
  setFreeTemplatesData,
  setFreeTemplatesPagination,
} from 'redux/actions/PrintTemplates/freeTemplatesActions';

const initialState = {
  data: [],
  pagination: DEFAULT_PAGINATION,
};

const freeTemplatesReducer = createReducer(
  {
    [clearFreeTemplatesData]: () => ({ ...initialState }),
    [setFreeTemplatesData]: (state, data) => ({
      ...state,
      data,
    }),
    [setFreeTemplatesPagination]: (state, pagination) => ({
      ...state,
      pagination,
    }),
  },
  initialState,
);

export default freeTemplatesReducer;
