import { string, object, number, shape, arrayOf, oneOf } from 'prop-types';

import { PTFileInfo } from './fileInfoPropTypes';
import { PTAddress } from './addressPropTypes';
import { PTProfessionalActivity } from './professionalActivityPropTypes';

export const PTLegalView = {
  /**
   * @description Юридический адрес / текст
   */
  address: string,
  /**
   * @description Дата актуальности сведений
   */
  dateUpdate: string,
  /**
   * @description Форма собственности
   */
  forma: string.isRequired,
  /**
   * @description Полное наименовние
   */
  fullName: string.isRequired,
  /**
   * @description Идентификатор
   */
  id: number,
  /**
   * @description ИНН
   */
  inn: string,
  /**
   * @description КПП
   */
  kpp: string,
  /**
   * @description Примечание
   */
  note: string,
  /**
   * @description ОГРН
   */
  ogrn: string,
  /**
   * @description ОКПО
   */
  okpo: string,
  /**
   * @description Сокращенное наименовние
   */
  shortName: string,
  /**
   * @description Статус организации
   */
  status: string,
};

export const PTStandardOrgView = {
  id: number,
  mergeDate: number,
  standardOrgFullName: string,
};

/**
 * @description Тип организации
 */
export const PTOrganizationType = {
  /**
   * @description Описание
   */
  description: string,

  /**
   * @description Идентификатор
   */
  id: number,
  /**
   * @description Наименование
   */
  name: string.isRequired,
  orderby: number,
  /**
   * @description Системеное наименование
   */
  sysName: string,
  /**
   * @description Идентификатор с фронта
   */
  tempId: string,
};

export const PTOrganization = {
  /**
   * @description Об организации
   */
  about: string,
  address: shape(PTAddress),
  document: shape(PTFileInfo),
  /**
   * @description E-mail
   */
  email: string,
  fifaId: string,
  fullName: string.isRequired,
  id: number,
  legal: shape(PTLegalView),
  logotip: object,
  /**
   * @description Краткое название с юр лицом
   */
  namewithlegal: string,
  /**
   * @description Сотрудники огранизации
   */
  orgpositions: arrayOf(shape(PTProfessionalActivity)),
  /**
   * @description Вышестоящая организация
   */
  parent: object,
  /**
   * @description Краткое название
   */
  shortName: string,
  /**
   * @description Спортивное название на англ
   */
  sportNameEn: string,
  standardOrgView: shape(PTStandardOrgView),
  /**
   * @description Статус организации
   */
  status: oneOf(['ACTIVE', 'DUPLICATE', 'DELETE']),
  /**
   * @description Статус организации/ название
   */
  statusName: string,
  /**
   * @description Статус примечание
   */
  statusNote: string,
  /**
   * @description Телефоны
   */
  telephon: string,
  /**
   * @description Идентификатор с фронта
   */
  tempId: string,
  type: shape(PTOrganizationType),
  /**
   * @description Веб-сайт
   */
  url: string,
};
