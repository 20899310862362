import React from 'react';
import PropTypes from 'prop-types';

const Radio = ({ id, className, name, value, onChange, checked, disabled }) => (
  <input id={id} name={name} type="radio" className={className} onChange={onChange} checked={checked} value={value} disabled={disabled} />
);

Radio.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

Radio.defaultProps = {
  checked: false,
  className: undefined,
  disabled: false,
  id: undefined,
  name: undefined,
  onChange: undefined,
  value: undefined,
};

export default Radio;
