exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".trigger-2oUEI {\n  display: inline;\n  position: relative;\n}\n\n.trigger-2oUEI:hover + .tooltip-3QiLE {\n  opacity: 1;\n  display: block;\n  -webkit-transition: opacity 1.3s;\n  transition: opacity 1.3s;\n  pointer-events: all;\n}\n\n.tooltip-3QiLE {\n  position: absolute;\n  z-index: 1;\n  padding: 12px;\n  border-radius: 4px;\n  background-color: #fff;\n  -webkit-box-shadow: 0 4px 12px 0 #202046;\n  box-shadow: 0 4px 12px 0 #202046;\n  color: #3d3d41;\n  font-size: 0.8rem;\n  text-align: left;\n\n  opacity: 0;\n  display: none;\n  -webkit-transition: opacity 1.3s;\n  transition: opacity 1.3s;\n  pointer-events: none;\n  margin-top: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"trigger": "trigger-2oUEI",
	"tooltip": "tooltip-3QiLE"
};