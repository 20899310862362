import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { uploadFilesMultiple } from 'utils/uploadFiles';

const createAction = actionsFactory('profile/refereeCategory/');

export const clearData = createAction('clearData');
export const setRefereeCategoryData = createAction('setRefereeCategoryData');
export const setRefereeCategoryLoading = createAction('setRefereeCategoryLoading');

export const getRefereeCategory = (id) => async (dispatch) => {
  try {
    dispatch(setRefereeCategoryLoading(true));
    const {
      data: { data },
    } = await axios.get(`/api/rest/person/categoryreferee/active/${id}`);
    dispatch(setRefereeCategoryData(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(setRefereeCategoryData(null));
  } finally {
    dispatch(setRefereeCategoryLoading(false));
  }
};

export const createRefereeCategory = (data) => async (dispatch) => {
  try {
    const body = { ...data };
    body.documents = await uploadFilesMultiple(body.documents);

    await axios.post('/api/rest/person/categoryreferee', body);
    handleSuccess('Категория успешно присвоена');

    await dispatch(getRefereeCategory(body.personId));
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const deleteRefereeCategory = (id, refereeId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/person/categoryreferee/${id}`);
    await dispatch(getRefereeCategory(refereeId));
    handleSuccess('Категория судьи успешно удалена', 'trash');
  } catch (error) {
    handleError(error);
  }
};

export const suspendRefereeCategory = (id, dateAvoided) => async (dispatch) => {
  try {
    const params = { dateEnd: dateAvoided };
    await axios.put(`/api/rest/person/categoryreferee/suspend/${id}`, null, { params });
    await dispatch(getRefereeCategory(id));
    handleSuccess('Категория судьи успешно отозвана');
  } catch (error) {
    handleError(error);
  }
};
