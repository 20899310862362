import { createReducer } from 'redux-act';
import { setDecisionsLoading, setDecisions, cleanData } from 'redux/actions/Organization/DecisionsActions';
import { DEFAULT_PAGINATION } from 'constants/pagination';

const initialState = {
  data: [],
  loading: false,
  pagination: DEFAULT_PAGINATION,
  withPublishDecisions: false,
};

const decisionsReducer = createReducer(
  {
    [cleanData]: () => ({ ...initialState }),
    [setDecisions]: (state, { data, pagination }) => ({
      ...state,
      data,
      loading: false,
      pagination,
      withPublishDecisions: data.some(({ status }) => status?.status?.name === 'PUBLISHED'),
    }),
    [setDecisionsLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
  },
  initialState,
);

export default decisionsReducer;
