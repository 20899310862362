import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/ui/Icon/Icon';

import styles from './Preloader.scss';

const Preloader = ({ className, size, error, icon, pack }) => {
  const classNames = useMemo(() => cn(styles.preloader, className, 'preloader-themed'), [className]);

  return <div className={classNames}>{error ? 'Ошибка' : <Icon icon={icon} pack={pack} spin size={size} />}</div>;
};

Preloader.propTypes = {
  className: PropTypes.string,
  error: PropTypes.any,
  icon: PropTypes.string,
  pack: PropTypes.string,
  size: PropTypes.string,
};

Preloader.defaultProps = {
  className: undefined,
  error: undefined,
  icon: 'spinner-third',
  pack: undefined,
  size: '2x',
};

export default Preloader;
