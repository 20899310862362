import { number, string } from 'prop-types';

/**
 * @description Гражданство
 */
export const PTCountry = {
  /**
   * @description Код
   */
  code: number,
  /**
   * @description Описание
   */
  description: string,
  /**
   * @description Полное наименовние
   */
  fullName: string,
  /**
   * @description Идентификатор
   */
  id: number,
  /**
   * @description Наименование
   */
  name: string.isRequired,
  /**
   * @description Латинское наименовние
   */
  nationalName: string,
  /**
   * @description Системеное наименование
   */
  sysName: string,
  /**
   * @description Идентификатор с фронта
   */
  tempId: string,
};
