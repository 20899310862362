import React from 'react';
import classnames from 'classnames/bind';

import { bool, oneOfType, string, shape, number, node } from 'prop-types';

import css from './Button.scss';

const cn = classnames.bind(css);

export default function ButtonGroup(props) {
  const { center, className, children } = props;

  return <div className={cn(css.buttonGroup, className, { center })}>{children}</div>;
}

ButtonGroup.defaultProps = {
  center: true,
  children: undefined,
  className: undefined,
};

ButtonGroup.propTypes = {
  center: bool,
  children: oneOfType([string, number, node]),
  className: oneOfType([string, shape()]),
};
