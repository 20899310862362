import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import css from './Components.scss';

const cn = classnames.bind(css);

const MonthEventContainer = ({ onSelect, children, event }) => (
  <div
    className={cn('rbc-event', css.eventWrapper)}
    onClick={() => onSelect(event)}
  >
    {children}
  </div>
);

MonthEventContainer.propTypes = {
  event: PropTypes.object,
  onSelect: PropTypes.func,
  children: PropTypes.node,
};

export default MonthEventContainer;
