import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { uploadFilesMultiple } from 'utils/uploadFiles';

const createAction = actionsFactory('organization/licenses/');

export const clearData = createAction('clearData');
export const setLicensesData = createAction('setLicensesData');
export const setLicensesLoading = createAction('setLicensesLoading');

export const getLicenses = (organizationId) => async (dispatch) => {
  try {
    dispatch(setLicensesLoading(true));

    const response = await axios.get(`/api/rest/clubLicense/getAll/${organizationId}`);
    dispatch(setLicensesData(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setLicensesData([]));
  } finally {
    dispatch(setLicensesLoading(false));
  }
};

export const saveLicense = (data) => async (dispatch) => {
  try {
    const body = { ...data };
    body.files = await uploadFilesMultiple(body.files);

    await axios.post('/api/rest/clubLicense', body);
    handleSuccess(data.id ? 'Лицензия успешно обновлена' : 'Лицензия успешно добавлена');

    await dispatch(getLicenses(body.organizationId));
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const deleteLicense = (id, organizationId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/clubLicense/${id}`);
    await dispatch(getLicenses(organizationId));
    handleSuccess('Лицензия успешно удалена', 'trash');
  } catch (error) {
    handleError(error);
  }
};
