import { combineReducers } from 'redux';
import editing from './editingReducer';
import view from './viewReducer';

const decisionsReducer = combineReducers({
  editing,
  view,
});

export default decisionsReducer;
