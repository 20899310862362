exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------ \nCommon\n--------------------------*/\n\n\n/* layouts width*/\n\n\n/* ------------------------ \nDark Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* input*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nLight Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nRating\n--------------------------*/\n\n\n.container-3QZLD {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n}\n\n\n.container-3QZLD.inline-1yth1 {\n    display: -webkit-inline-box;\n    display: -ms-inline-flexbox;\n    display: inline-flex;\n  }\n\n\n.textareaLabel-xrm7Q {\n  position: absolute;\n  left: 0;\n  font-size: 0.8rem;\n  font-weight: 600;\n}\n\n\n.textareaField-2Azd7 {\n  margin-top: 20px;\n}\n\n\n.requireMessage-3JsKg {\n  position: absolute;\n  right: 0;\n  padding: 2px 10px;\n  background-color: #0a81ff;\n  text-transform: uppercase;\n  font-size: 9px;\n}\n\n\n.light-3JYho {\n  background-color: #fff;\n}\n\n\n.theme-dark .requireMessage-3JsKg {\n    background-color: #0a81ff;\n  }\n\n\n.theme-dark .light-3JYho {\n    background-color: #fff;\n  }\n\n\n.theme-light .requireMessage-3JsKg {\n    background-color: #0a81ff;\n  }\n\n\n.theme-light .light-3JYho {\n    background-color: #eaf6ff;\n  }\n", ""]);

// exports
exports.locals = {
	"container": "container-3QZLD",
	"inline": "inline-1yth1",
	"textareaLabel": "textareaLabel-xrm7Q",
	"textareaField": "textareaField-2Azd7",
	"requireMessage": "requireMessage-3JsKg",
	"light": "light-3JYho"
};