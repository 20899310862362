import {
  setDecision,
  setDecisionLoading,
  cleanData,
  setDecisionActionLoading,
  setAddedDecision,
  setAddedDecisionLoading,
} from 'redux/actions/Decisions/editingActions';
import { createReducer } from 'redux-act';

const initState = {
  actionLoading: false,
  addedDecision: {},
  addedDecisionLoading: false,
  data: {},
  loading: false,
};

const editingReducer = createReducer(
  {
    [cleanData]: () => initState,
    [setAddedDecision]: (state, addedDecision) => ({
      ...state,
      addedDecision,
      addedDecisionLoading: false,
    }),
    [setAddedDecisionLoading]: (state, addedDecisionLoading) => ({
      ...state,
      addedDecisionLoading,
    }),
    [setDecision]: (state, data) => ({
      ...state,
      data,
      loading: false,
    }),
    [setDecisionActionLoading]: (state, actionLoading) => ({
      ...state,
      actionLoading,
    }),
    [setDecisionLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
  },
  initState,
);

export default editingReducer;
