import React from 'react';
import PropTypes from 'prop-types';

import { noop } from 'utils/lodash';

import TopBarItem from './TopBarItem/TopBarItem';
import css from './TopBar.scss';

const TopBar = ({ items, onSectionChange }) => {
  if (!items.length) {
    return null;
  }

  return (
    <div className={css.barContainer}>
      <div className={css.items}>
        {items.map(({ isActive, sysName, title }) => (
          <TopBarItem key={sysName} active={isActive} sysName={sysName} title={title} onClick={onSectionChange} />
        ))}
      </div>
    </div>
  );
};

TopBar.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onSectionChange: PropTypes.func,
};

TopBar.defaultProps = {
  items: [],
  onSectionChange: noop,
};

export default React.memo(TopBar);
