import { createSelector } from 'reselect';

import flow from 'lodash/flow';

import { momentDateBuilder } from 'magic/date';

import { getDateWithDateRange } from 'components/composed/TextWithDivider';

import {
  groupNameSelector,
  seasonRangeSelector,
  stageSelector,
  stageYearSelector,
} from '../helpers/TotalTrainingGroupForm/TotalTrainingGroupForm.selectors';

const trainingGroupEditByGroupIdSelector = (state) => state.trainingGroup.trainingGroupEditByGroupId;

export const coachGroupByGroupIdSelector = createSelector(
  trainingGroupEditByGroupIdSelector,
  (trainingGroupEditByGroupId) => trainingGroupEditByGroupId.coachGroupByGroupId || Object.create(null),
);

const organizationSelector = createSelector(
  coachGroupByGroupIdSelector,
  (coachGroupByGroupId) => coachGroupByGroupId.organization || Object.create(null),
);

export const organizationIdSelector = createSelector(organizationSelector, (organization) => organization && organization.id);

const coachGroupAddLoadingSelector = createSelector(
  trainingGroupEditByGroupIdSelector,
  (trainingGroupEditByGroupId) => trainingGroupEditByGroupId.coachGroupAddLoading,
);

export const coachGroupGetLoadingSelector = createSelector(
  trainingGroupEditByGroupIdSelector,
  (trainingGroupEditByGroupId) => trainingGroupEditByGroupId.coachGroupGetLoading,
);

export const isDisabledSubmitButtonSelector = createSelector(
  [stageSelector, stageYearSelector, groupNameSelector, coachGroupAddLoadingSelector],
  (stage, stageYear, groupName, coachGroupAddLoading) => {
    const _groupName = typeof groupName === 'string' && groupName.trim();

    return ![!coachGroupAddLoading, stage, stageYear, _groupName].every(Boolean);
  },
);

export const coachesByTrainingIdSelector = createSelector(
  trainingGroupEditByGroupIdSelector,
  (trainingGroupEditByGroupId) => trainingGroupEditByGroupId.coachesByTrainingId || [],
);

export const footballersByTrainingIdSelector = createSelector(
  trainingGroupEditByGroupIdSelector,
  (trainingGroupEditByGroupId) => trainingGroupEditByGroupId.footballersByTrainingId || [],
);

const _momentDateBuilder = momentDateBuilder('DD.MM.YYYY');
const formattedPersonCallback = (personsByTrainingId, seasonRange) => {
  const { endSeason, endSeasonUTC, startSeason, startSeasonUTC } = seasonRange;

  return personsByTrainingId.map((personByTrainingId) => {
    const { preparationEndDate, fio, image, preparationStartDate } = personByTrainingId;

    const imageId = image ? image.id : null;

    const subtitle = (() => {
      const getSubtitle = flow(
        (startDate, endDate) => [startDate || _momentDateBuilder(preparationStartDate), endDate || _momentDateBuilder(preparationEndDate)],
        ([startDate, endDate]) => getDateWithDateRange(startDate, endDate),
      );

      switch (true) {
        case Boolean(preparationStartDate && preparationEndDate):
          return getSubtitle();
        case Boolean(preparationStartDate):
          return getSubtitle(undefined, endSeason);
        case Boolean(preparationEndDate):
          return getSubtitle(startSeason);
        default:
          return getSubtitle(startSeason, endSeason);
      }
    })();

    const _preparationStartDate = preparationStartDate || startSeasonUTC;
    const _preparationEndDate = preparationEndDate || endSeasonUTC;

    return {
      ...personByTrainingId,
      imageId,
      preparationEndDate: _preparationEndDate,
      preparationStartDate: _preparationStartDate,
      subtitle,
      title: fio,
    };
  });
};

export const defaultFormValuesSelector = createSelector(coachGroupByGroupIdSelector, (coachGroupByGroupId) => {
  const { shortName, fullName, stage, stageYear, yearBirth: yearBirths, season, controlpersons } = coachGroupByGroupId;

  const curator = Array.isArray(controlpersons) ? controlpersons[0] : controlpersons;
  const groupName = shortName || fullName;

  return {
    curator,
    groupName,
    season,
    stage,
    stageYear,
    yearBirths,
  };
});

export const formattedFootballersByTrainingIdSelector = createSelector(
  [footballersByTrainingIdSelector, seasonRangeSelector],
  formattedPersonCallback,
);

export const formattedCoachesByTrainingIdSelector = createSelector(
  [coachesByTrainingIdSelector, seasonRangeSelector],
  formattedPersonCallback,
);
