export const ORGANIZATION_TYPES = {
  ASSOCIATION: 'association',
  CLUB: 'club',
  FEDERATION: 'federation',
  KFA: 'collegium',
  LEAGUE: 'liga',
  MRO: 'mro',
  RFS: 'rfs',
  SCHOOL: 'school',
  FIFA: 'fifa_federation',
  CONFEDERATION: 'confederation',
  NATIONAL_ASSOCIATION: 'national_association',
  ORG_MEDIATOR: 'org_mediator',
  OTHER: 'other',
};

export const ORGANIZATION_TYPES_RU = {
  [ORGANIZATION_TYPES.ASSOCIATION]: 'Ассоциация',
  [ORGANIZATION_TYPES.CLUB]: 'Клуб',
  [ORGANIZATION_TYPES.KFA]: 'Коллегия',
  [ORGANIZATION_TYPES.FEDERATION]: 'Федерация',
  [ORGANIZATION_TYPES.LEAGUE]: 'Лига',
  [ORGANIZATION_TYPES.MRO]: 'МРО',
  [ORGANIZATION_TYPES.RFS]: 'РФС',
  [ORGANIZATION_TYPES.SCHOOL]: 'Школа',
  [ORGANIZATION_TYPES.FIFA]: 'ФИФА',
  [ORGANIZATION_TYPES.CONFEDERATION]: 'Конфедерация',
  [ORGANIZATION_TYPES.NATIONAL_ASSOCIATION]: 'Национальная ассоциация',
  [ORGANIZATION_TYPES.ORG_MEDIATOR]: 'Организация посредника',
  [ORGANIZATION_TYPES.OTHER]: 'Иное',
  
};

export const FIND_CLUBS_PARAMS = { types: [2] };
export const FIND_CLUBS_SCHOOLS_PARAMS = { types: [2, 4] };
export const CLUBS_WITHOUT_CATEGORY_PARAMS = { clubCategoryIds: [5] };
