export const STATEMENT_ACTIONS = {
  JOIN_CLUB: 2,
  LEAVE_CLUB: 1,
  REQUEST_TRANSFER: 4,
  TRANSFER: 3,
};

export const STATEMENT_STATUSES = {
  ACCEPTED: 1,
  CANCELED: 2,
  DELETED: 4,
  DRAFT: 0,
  NEW: 7,
  REVIEWING: 3,
  REVIEWING_RFS: 6,
  SENT: 5,
};

export const STATEMENT_TITLES = {
  [STATEMENT_ACTIONS.LEAVE_CLUB]: 'Заявление на снятие с учета',
  [STATEMENT_ACTIONS.JOIN_CLUB]: 'Заявление на постановку на учет',
  [STATEMENT_ACTIONS.TRANSFER]: 'Заявление на трансфер',
  [STATEMENT_ACTIONS.REQUEST_TRANSFER]: 'Заявление на снятие с регистрации в клубе с последующим переходом',
};

export const STATEMENT_TYPES = {
  ALL: 'all',
  NEW: 'new',
};
