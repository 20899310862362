import axios from 'axios';
import { push } from 'connected-react-router';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { uploadFile, uploadFilesMultiple } from 'utils/uploadFiles';

import { ROUTES_WAYS } from 'constants/routesWays';

import { selectContractData } from 'redux/selectors/Contracts/currentSelectors';

const createAction = actionsFactory('contracts/current/');

export const clearData = createAction('clearData');
export const setContractData = createAction('setContractData');
export const setContractLoading = createAction('setContractLoading');
export const setResendInstructionInProgress = createAction('setResendInstructionInProgress');

export const setTransferAgreement = createAction('setTransferAgreement');

export const updateTransferPaymentsState = (paymentData) => (dispatch, getState) => {
  const { transferAgreement, ...contract } = selectContractData(getState());

  contract.transferAgreement = {
    ...transferAgreement,
    transferAgreementPayments:
      transferAgreement?.transferAgreementPayments &&
      transferAgreement.transferAgreementPayments.map((payment) => (payment.id === paymentData.id ? paymentData : payment)),
  };

  dispatch(setContractData(contract));
};

export const getContractData = (instructionId) => async (dispatch) => {
  try {
    dispatch(setContractLoading(true));

    const response = await axios.get(`/api/rest/contract/dtms/instruction/${instructionId}`);

    const recipientOrganizationId = response.data?.data?.instruction?.recipientOrganization?.id;
    const transferAgreement = response?.data?.data?.transferAgreement;
    const initiatorOrganizationId = response.data?.data?.instruction?.initiatorOrganization?.id;

    let recipientOrganization = null;

    if (typeof recipientOrganizationId === 'number') {
      const recipientOrganizationResponse = await axios.get(`/api/rest/organization/${recipientOrganizationId}`);
      recipientOrganization = recipientOrganizationResponse.data.data;
    }

    let initiatorOrganization = null;

    if (typeof initiatorOrganizationId === 'number') {
      const initiatorOrganizationResponse = await axios.get(`/api/rest/organization/${initiatorOrganizationId}`);
      initiatorOrganization = initiatorOrganizationResponse.data.data;
    }
    dispatch(setTransferAgreement(transferAgreement));
    dispatch(setContractData({ ...response.data.data, recipientOrganization, initiatorOrganization }));
  } catch (err) {
    handleError(err);
    dispatch(push(ROUTES_WAYS.CONTRACTS));
  } finally {
    dispatch(setContractLoading(false));
  }
};

export const createInstruction = (data) => async (dispatch) => {
  try {
    const response = await axios.post('/api/rest/contract/dtms/instruction/save', data);
    handleSuccess('Инструкция успешно создана!');
    dispatch(push(ROUTES_WAYS.CONTRACT_BY_ID.replace(':id', response.data.data.id)));
  } catch (err) {
    handleError(err);
  }
};

export const recallInstruction = (instructionId, data) => async (dispatch) => {
  try {
    const body = { ...data };
    body.documents = await uploadFilesMultiple(body.documents);

    await axios.post(`/api/rest/contract/dtms/instruction/${instructionId}/recall`, body);
    handleSuccess('Инструкция успешно отозвана!');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const reworkInstruction = (instructionId, data) => async (dispatch) => {
  try {
    const body = { ...data };
    body.documents = await uploadFilesMultiple(body.documents);

    await axios.post(`/api/rest/contract/dtms/instruction/${instructionId}/rework`, body);
    handleSuccess('Инструкция успешно отправлена на доработку!');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const saveContract = (data) => async (dispatch) => {
  try {
    const body = { ...data };
    body.documents = await uploadFilesMultiple(body.documents);
    await axios.post('/api/rest/contract/dtms/save', body);
    handleSuccess('Трудовой договор успешно сохранен!');
    await dispatch(getContractData(data.instructionId));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const saveTransferAgreement = (data, earlyAgreement) => async (dispatch) => {
  try {
    const body = { ...data };
    body.documents = await uploadFilesMultiple(body.documents);
    await axios.post('/api/rest/contract/dtms/transfer-agreement/save', body);
    const msg = `${earlyAgreement ? 'Соглашение о досрочном возврате из аренды ' : 'Трансферное соглашение '}успешно сохранено!`;
    handleSuccess(msg);
    await dispatch(getContractData(data.instructionId));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const sendToRegistrationContract = (instructionId) => async (dispatch) => {
  try {
    await axios.post(`/api/rest/contract/dtms/instruction/${instructionId}/send-to-registration`, {});
    handleSuccess('Инструкция передана на регистрацию в РФС!');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const sendToApproveContract = (instructionId) => async (dispatch) => {
  try {
    await axios.post(`/api/rest/contract/dtms/instruction/${instructionId}/send-to-approval`, {});
    handleSuccess('Инструкция передана на согласование клубу!');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const registerContract = (instructionId, data) => async (dispatch) => {
  try {
    const body = { ...data };
    body.documents = await uploadFilesMultiple(body.documents);
    await axios.post(`/api/rest/contract/dtms/instruction/${instructionId}/register`, body);
    handleSuccess('Инструкция успешно зарегистрирована!');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const rejectContract = (instructionId, data) => async (dispatch) => {
  try {
    await axios.post(`/api/rest/contract/dtms/instruction/${instructionId}/reject`, data);
    handleSuccess('Инструкция отклонена!', 'file-times');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const addTransferPayout = (paymentId, data) => async (dispatch) => {
  try {
    const body = { ...data };
    body.document = await uploadFile(body.document);

    const {
      data: { data: paymentData },
    } = await axios.post(`/api/rest/contract/dtms/transfer-agreement-payment/${paymentId}/add-part-payment`, body);
    handleSuccess('Выплата успешно добавлена!');

    dispatch(updateTransferPaymentsState(paymentData));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const confirmTransferPayment = (paymentPartId) => async (dispatch) => {
  try {
    const {
      data: { data: paymentData },
    } = await axios.put(`/api/rest/contract/dtms/transfer-agreement-payment/${paymentPartId}/approve-payment-part`, {});
    handleSuccess('Платёж успешно подтвержден!');

    dispatch(updateTransferPaymentsState(paymentData));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const addMediator = (data) => async (dispatch) => {
  const { id, instructionId, mediator, tab } = data;
  const url = `/api/rest/contract/dtms/${tab ? `${tab}/` : ''}${id}/mediator/save`;

  try {
    await axios.post(url, mediator);
    handleSuccess(`Посредник успешно ${mediator.id ? 'обновлен' : 'добавлен'}!`);
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const deleteMediator = (data) => async (dispatch) => {
  const { id, instructionId, mediatorId, tab } = data;
  const url = `/api/rest/contract/dtms/${tab ? `${tab}/` : ''}${id}/mediator/${mediatorId}/delete`;

  try {
    await axios.delete(url);
    handleSuccess('Посредник успешно удален!');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const addTransferAgreementPayment = (data) => async (dispatch) => {
  try {
    const { instructionId, transferAgreementId, currency, payment } = data;
    if (currency?.id) {
      await axios.post(`/api/rest/contract/dtms/transfer-agreement/${transferAgreementId}/currency/save`, { id: currency.id });
    }
    if (payment?.paymentType && (payment?.paymentData || payment?.paymentSum)) {
      await axios.post(`/api/rest/contract/dtms/transfer-agreement/${transferAgreementId}/payment/save`, payment);
    }
    handleSuccess(currency?.id && !payment?.paymentType ? 'Валюта успешно добавлена!' : 'Платеж успешно добавлен!');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const deleteTransferAgreementPayment = (data) => async (dispatch) => {
  try {
    const { instructionId, transferAgreementId, paymentId } = data;
    await axios.delete(`/api/rest/contract/dtms/transfer-agreement/${transferAgreementId}/payment/${paymentId}/delete`);
    handleSuccess('Платеж успешно удален!');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const saveBank = (data) => async (dispatch) => {
  const { id, bank, instructionId, transferAgreementId } = data;
  const url = `/api/rest/contract/dtms/transfer-agreement/${transferAgreementId}/bank/${bank}/save`;

  try {
    await axios.post(url, { id });
    handleSuccess('Банк успешно добавлен!');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const saveResalePayments = (data) => async (dispatch) => {
  const { payment, instructionId, transferAgreementId, currency } = data;
  const url = `/api/rest/contract/dtms/transfer-agreement/${transferAgreementId}/payment/save`;

  try {
    if (currency?.id) {
      await axios.post(`/api/rest/contract/dtms/transfer-agreement/${transferAgreementId}/currency/save`, { id: currency.id});
    }
    await axios.post(url, payment);
    handleSuccess('Платеж успешно добавлен!');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const saveAgreement = (data) => async (dispatch) => {
  try {
    const { agreement, instructionId } = data;
    agreement.documents = await uploadFilesMultiple(agreement.documents);

    await axios.post(`/api/rest/contract/dtms/instruction/${instructionId}/additional-agreement`, agreement);

    handleSuccess('Дополнительное соглашение успешно сохранено', 'file-check');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
    return Promise.reject(err);
  }
};

export const deleteAgreement = (data) => async (dispatch) => {
  try {
    const { additionalAgreementId, instructionId } = data;

    await axios.delete(`/api/rest/contract/additional-agreement/${additionalAgreementId}`);

    handleSuccess('Дополнительное соглашение успешно удалено');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
    return Promise.reject(err);
  }
};

export const cancelContract = (instructionId, data) => async (dispatch) => {
  try {
    const body = { ...data };
    body.documents = await uploadFilesMultiple(body.documents);
    await axios.post(`/api/rest/contract/dtms/instruction/${instructionId}/cancel`, body);
    handleSuccess('Запланированная инструкция успешно отменена');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const resendDataToFifa = (instructionId) => async (dispatch) => {
  try {
    dispatch(setResendInstructionInProgress(true));
    await axios.post(`/api/rest/contract/dtms/instruction/${instructionId}/resend-to-fifa`);
    handleSuccess('Сведения успешно отправлены в FIFA');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  } finally {
    dispatch(setResendInstructionInProgress(false));
  }
};

export const confirmCancellation = (data) => async (dispatch) => {
  try {
    const { instructionId, statusChangeCanceled } = data;

    await axios.post(`/api/rest/contract/dtms/instruction/${instructionId}/final-register`, {...statusChangeCanceled});
    handleSuccess('Инструкция успешно отменена');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const salaryPaymentSave = (data) => async (dispatch) => {
  const { salary, instructionId, transferAgreementId } = data;
  const url = `/api/rest/contract/dtms/transfer-agreement/${transferAgreementId}/salary/payment/save`;

  try {
    await axios.post(url, salary);
    handleSuccess('Платеж успешно добавлен!');
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};

export const saveInternationalTransferDetails = (data) => async (dispatch) => {
  try {
    const { transfer, instructionId } = data;
    transfer.documents = await uploadFilesMultiple(transfer.documents);

    await axios.post(`/api/rest/contract/dtms/instruction/${instructionId}/international-transfer/save`, transfer);
    handleSuccess(`Сведения успешно ${transfer.id ? 'изменены' : 'добавлены'}!`);
    await dispatch(getContractData(instructionId));
  } catch (err) {
    handleError(err);
  }
};
