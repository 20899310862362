import { createReducer } from 'redux-act';

import { clearData, setUserOrganizations } from 'redux/actions/permissionsActions';

const initialState = {
  userOrganizations: [],
};

const permissionsReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setUserOrganizations]: (state, userOrganizations) => ({
      ...state,
      userOrganizations,
    }),
  },
  initialState,
);

export default permissionsReducer;
