import React from 'react';
import { marked } from 'marked';

import PortalLayout from 'components/layouts/PortalLayout/PortalLayout';
import H from 'components/ui/H/H';

import css from './Releases.scss';
import { useReleasesData } from './useReleasesData';

export function Releases() {
  const data = useReleasesData();

  return (
    <PortalLayout>
      <H size={1}>Обновления системы</H>

      {data.map((item, i) => {
        const html = marked.parse(item.data, { breaks: true, headerIds: false });

        return (
          <div key={i} className={css.tile}>
            <div className={css.date}>
              <span className={css.fieldKey}>Дата релиза:</span> {item.date}
            </div>
            <div className={css.version}>
              <span className={css.fieldKey}>Версия релиза:</span> {item.version}
            </div>
            <div className={css.body} dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        );
      })}
    </PortalLayout>
  );
}
