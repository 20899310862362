exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------ \nCommon\n--------------------------*/\n\n\n/* layouts width*/\n\n\n/* ------------------------ \nDark Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* input*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nLight Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nRating\n--------------------------*/\n\n\n.mainMenu-1qAKH {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  border-bottom: 1px solid rgba(255, 255, 255, 0.3);\n  padding-bottom: 20px;\n  margin-bottom: 20px;\n}\n\n\n.controlMenu-GR9Px {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n}\n\n\n.navLink-1OpBw {\n  font-weight: 600;\n}\n\n\n.navLink-1OpBw:not(:last-of-type) {\n    margin-bottom: 20px;\n  }\n\n\n.navLink-1OpBw.light-2_9bg {\n    color: #39395b;\n  }\n\n\n.navLink-1OpBw.active-3pLip {\n    color: #1fadff;\n  }\n\n\n.navLink-1OpBw.disabled-tn8Du {\n    color: #9196af !important;\n    cursor: not-allowed;\n  }\n\n\n.theme-dark .mainMenu-1qAKH {\n    border-bottom: 1px solid rgba(255, 255, 255, 0.3);\n  }\n\n\n.theme-light .mainMenu-1qAKH {\n    border-bottom: 1px solid #bababa;\n  }\n", ""]);

// exports
exports.locals = {
	"mainMenu": "mainMenu-1qAKH",
	"controlMenu": "controlMenu-GR9Px",
	"navLink": "navLink-1OpBw",
	"light": "light-2_9bg",
	"active": "active-3pLip",
	"disabled": "disabled-tn8Du"
};