import { createReducer } from 'redux-act';
import axios from 'axios';
import { replace } from 'connected-react-router';

import flow from 'lodash/flow';
import compact from 'lodash/compact';

import { handleError } from 'magic/handlers';
import actionsFactory from 'magic/actionsFactory';

import { ROUTES_WAYS } from 'constants/routesWays';

import Notification from 'components/rfs-ui/Notification/Notification';

import {
  baseYearBirthsSelector,
  curatorSelector,
  groupNameSelector,
  seasonSelector,
  stageSelector,
  stageYearSelector,
} from '../helpers/TotalTrainingGroupForm/TotalTrainingGroupForm.selectors';
import { formattedCoachesByTrainingIdSelector, formattedFootballersByTrainingIdSelector } from './TrainingGroupEditByGroupId.selectors';

const createAction = actionsFactory('@@TrainingGroupEditByGroupId/');

const initialState = {
  coachGroupAddLoading: false,
  coachGroupByGroupId: null,
  coachGroupGetLoading: false,
  coachesByTrainingId: [],
  footballersByTrainingId: [],
};

const coachGroupAddLoading = createAction('coachGroupAddLoading');
const coachGroupGetLoading = createAction('coachGroupGetLoading');

export const purgeTrainingGroupEditByGroupId = createAction('purgeTrainingGroupEditByGroupId');

export const onSaveFootballersInGroup = createAction('onSaveFootballersInGroup');
export const onSaveCoachesInGroup = createAction('onSaveCoachesInGroup');
const setCoachGroupByGroupId = createAction('setCoachGroupByGroupId');

const formatDateToTimestamp = (date) => (date instanceof Date ? date.getTime() : date);
const formatDatesToTimestamp = (formattedPersonByTrainingId) => {
  const { preparationEndDate, preparationStartDate } = formattedPersonByTrainingId;

  return {
    ...formattedPersonByTrainingId,
    preparationEndDate: formatDateToTimestamp(preparationEndDate),
    preparationStartDate: formatDateToTimestamp(preparationStartDate),
  };
};
export const coachGroupAdd = (organizationId, isCreating) => async (dispatch, getState) => {
  const dispatchCoachGroupAddLoading = flow(coachGroupAddLoading, dispatch);
  dispatchCoachGroupAddLoading(true);

  const getRequestBody = flow(getState, (state) => ({
    coaches: formattedCoachesByTrainingIdSelector(state).map(formatDatesToTimestamp),
    controlpersons: flow(curatorSelector, Array.of, compact)(state),
    organization: {
      id: Number(organizationId),
    },
    players: formattedFootballersByTrainingIdSelector(state).map(formatDatesToTimestamp),
    season: seasonSelector(state),
    shortName: groupNameSelector(state),
    stage: stageSelector(state),
    stageYear: stageYearSelector(state),
    yearBirth: baseYearBirthsSelector(state),
  }));

  try {
    const {
      data: { data },
    } = await axios.post('/api/rest/coachgroup/', getRequestBody());

    Notification.success({
      children: {
        icon: 'check',
        message: `Группа успешно ${isCreating ? 'сформирована' : 'обновлена'}`,
      },
    });

    const getRouteWayById = (id) => ROUTES_WAYS.TRAINING_GROUP_BY_ID.replace(':id', id);

    flow(getRouteWayById, replace, dispatch)(data.id);
  } catch (error) {
    handleError(error);
  } finally {
    dispatchCoachGroupAddLoading(false);
  }
};

export const getCoachGroupByGroupId = (groupId, isNextStage) => async (dispatch) => {
  try {
    dispatch(coachGroupGetLoading(true));
    const {
      data: { data },
    } = await axios.get(`/api/rest/coachgroup/${groupId}`);

    if (isNextStage) {
      const {
        data: { data: stages },
      } = await axios.get('/api/rest/coachgroup/stages');
      const {
        data: { data: seasons },
      } = await axios.get('/api/rest/organization/seasons');

      const stageIdx = stages.findIndex(({ id }) => id === data.stage.id) + 1;
      if (stageIdx && stageIdx < stages.length) {
        data.stage = stages[stageIdx];
      }

      const nextSeason = data.season.name
        .split('/')
        .map((year) => +year + 1)
        .join('/');
      const seasonIdx = seasons.findIndex(({ name }) => name === nextSeason);
      if (seasonIdx && seasonIdx < seasons.length) {
        data.season = seasons[seasonIdx];
      }
    }

    dispatch(setCoachGroupByGroupId(data));
  } catch (error) {
    handleError(error);
    flow(replace, dispatch)(ROUTES_WAYS.MANAGEMENT);
  } finally {
    dispatch(coachGroupGetLoading(false));
  }
};

const reducer = createReducer(
  {
    [coachGroupAddLoading]: (state, payload) => ({
      ...state,
      coachGroupAddLoading: payload,
    }),
    [coachGroupGetLoading]: (state, payload) => ({
      ...state,
      coachGroupGetLoading: payload,
    }),
    [onSaveCoachesInGroup]: (state, payload) => ({
      ...state,
      coachesByTrainingId: payload,
    }),
    [onSaveFootballersInGroup]: (state, payload) => ({
      ...state,
      footballersByTrainingId: payload,
    }),
    [purgeTrainingGroupEditByGroupId]: () => ({ ...initialState }),
    [setCoachGroupByGroupId]: (state, payload) => ({
      ...state,
      coachGroupByGroupId: payload,
      coachesByTrainingId: payload.coaches,
      footballersByTrainingId: payload.players,
    }),
  },
  initialState,
);

export default reducer;
