import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import { push } from 'connected-react-router';
import axios from 'axios';

import { handleError, handleSuccess } from 'magic/handlers';
import { Notification } from 'components/rfs-ui';

const createAction = actionsFactory('permissionFilters/edit/');

const initialState = {
  data: null,
  loading: true,
};

// actions
export const setLoading = createAction('setLoading');
export const setFilterData = createAction('setFilterData');
export const updateData = createAction('updateData');
export const clearData = createAction('clearData');

export const loadRights = (sysName) => async (dispatch) => {
  let res;

  try {
    if (sysName) {
      res = await axios.get(`/api/rest/permission/filter/dictionary?sysName=${sysName}`);
    } else {
      res = await axios.get('/api/rest/permission/filter/dictionary');
    }

    dispatch(setFilterData(res.data.data));
  } catch (e) {
    Notification.error({
      children: {
        message: 'Не удалось загрузить права! Попробуйте снова.',
      },
    });
  }
};

export const updateFilter = () => async (dispatch, getState) => {
  const { data } = getState().permissionFilters.edit;
  let permissions = [];

  // соберем права, которые enable, в отдельный массив для бэка
  data.groups.forEach((group) => {
    const enabledPermissions = group.permissions.reduce((accPermissions, currentPermission) => {
      if (currentPermission.enabled) {
        accPermissions.push({ id: currentPermission.id });
      }

      return accPermissions;
    }, []);

    if (enabledPermissions.length) {
      permissions = [...permissions, ...enabledPermissions];
    }
  });

  dispatch(setLoading(true));

  try {
    const postData = {
      fullName: data.fullName,
      permissions,
    };

    if (data.id) {
      postData.id = data.id;
    }

    await axios.post('/api/rest/permission/filter', postData);
    dispatch(setLoading(false));
    handleSuccess(`Роль успешно ${data.id ? 'обновлена' : 'создана'}!`);
    dispatch(push('/permissionFilters'));
  } catch (e) {
    handleError(e);
    dispatch(setLoading(false));
  }
};

// reducer
const reducer = createReducer(
  {
    [clearData]: () => initialState,
    [setFilterData]: (state, data) => ({
      ...state,
      data,
      loading: false,
    }),
    [setLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
    [updateData]: (state, payload) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),
  },
  initialState,
);

export default reducer;
