import { THEMES } from 'components/composed/ThemeContext/constants';

const styles = {
  container: (base) => ({
    ...base,
  }),
  control: (base, state) => {
    const { isFocused, selectProps } = state;
    const { menuIsOpen, options, isDisabled, themeType, light, dark, isRequired } = selectProps;

    let background;
    let borderColor;
    let borderHoverColor;
    let borderActiveColor;
    let disabledBackground;
    let textColor;
    let textColorDisabled;

    if (themeType === THEMES.LIGHT) {
      background = (light && '#eaf6ff') || (dark && '#eaf6ff') || '#fff';
      borderColor = isRequired && !isDisabled ? '#0a81ff' : 'transparent';
      borderHoverColor = !isDisabled ? '#1d94c1' : 'transparent';
      borderActiveColor = '#0a81ff';
      disabledBackground = '#dedfe4';
      textColor = '#212121';
      textColorDisabled = '#fff';
    } else {
      background = (light && '#083e89') || (dark && '#001240') || '#042b75';
      borderColor = isRequired && !isDisabled ? '#0a81ff' : 'transparent';
      borderHoverColor = !isDisabled ? '#1fadff' : 'transparent';
      borderActiveColor = '#0a81ff';
      disabledBackground = '#373865';
      textColor = '#fff';
      textColorDisabled = '#fff';
    }

    const borderStyle = `1px solid ${isFocused ? borderActiveColor : borderColor}`;

    return {
      ...base,
      ':hover': {
        borderColor: borderHoverColor,
      },
      backgroundColor: isDisabled ? disabledBackground : background,
      border: borderStyle,
      borderRadius: menuIsOpen && options.length ? '4px 4px 0 0' : '4px',
      boxShadow: 'none',
      boxSizing: 'border-box',
      color: isDisabled ? textColorDisabled : textColor,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      height: '40px',
      paddingRight: '100px',
      transition: 'border 0.3s',
    };
  },
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: () => ({}),
  menu: (base, state) => {
    const { light, dark, themeType, isRequired, customStyles = {} } = state.selectProps;

    let backgroundColor;
    let borderColor;

    if (themeType === THEMES.LIGHT) {
      backgroundColor = (light && '#eaf6ff') || (dark && '#eaf6ff') || '#fff';
      borderColor = '#0a81ff';
    } else {
      backgroundColor = (light && '#083e89') || (dark && '#001240') || '#042b75';
      borderColor = isRequired ? '#0a81ff' : '#042b75';
    }

    return {
      ...base,
      backgroundColor,
      border: `1px solid ${borderColor}`,
      borderRadius: '0 0 4px 4px',
      borderTop: 'none',
      display: state.options.length > 0 ? 'initial' : 'none',
      margin: 0,
      padding: 0,
      top: '40px',
      ...customStyles.menu,
    };
  },
  menuList: (base, state) => {
    const { customStyles = {} } = state.selectProps;

    return {
      ...base,
      '::-webkit-scrollbar': {
        width: '10px',
      },
      '::-webkit-scrollbar-thumb': {
        ':hover': {
          backgroundColor: '#1fadff',
        },
        backgroundColor: '#0a81ff',
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
      padding: 0,
      ...customStyles.menuList,
    };
  },
  option: (_, state) => {
    const { isSelected, isFocused } = state;
    const { themeType } = state.selectProps;

    let textColor;
    let hoverColor;

    if (themeType === 'lightTheme') {
      textColor = isFocused ? '#212121' : 'rgba(33, 33, 33, 0.7)';
      hoverColor = '#1fadff';
    } else {
      textColor = isFocused ? '#fff' : 'rgba(255, 255, 255, 0.7)';
      hoverColor = '#fff';
    }

    const borderLeftColor = isSelected ? '#1fadff' : 'transparent';

    return {
      ':hover': {
        color: hoverColor,
      },
      borderLeft: `4px solid ${borderLeftColor}`,
      color: textColor,
      cursor: 'pointer',
      lineHeight: '20px',
      padding: '5px 60px 5px 40px',
      transition: '0.3s',
    };
  },
  placeholder: (base, state) => {
    const { themeType } = state.selectProps;
    const textColor = themeType === THEMES.LIGHT ? '#9196af' : '#757d96';

    return {
      ...base,
      color: textColor,
      margin: 0,
    };
  },
  singleValue: (base, state) => {
    const { themeType } = state.selectProps;
    const textColor = themeType === THEMES.LIGHT ? '#212121' : '#fff';
    return {
      color: textColor,
    };
  },
  valueContainer: (base, state) => {
    const { themeType } = state.selectProps;
    const textColor = themeType === THEMES.LIGHT ? '#212121' : '#fff';

    return {
      ...base,
      color: textColor,
      height: '40px',
      padding: '0 60px 0 40px',
    };
  },
};

export default styles;
