import { combineReducers } from 'redux';

import compensationsReducer from './compensationsReducer';
import currentReducer from './currentReducer';
import listReducer from './listReducer';

export default combineReducers({
  compensations: compensationsReducer,
  current: currentReducer,
  list: listReducer,
});
