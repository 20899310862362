import { get } from 'utils/lodash';

export const sortByKey = (arr, key) => {
  const newArr = [...arr].sort((itemA, itemB) => {
    if (get(itemA, key) === get(itemB, key)) {
      return 0;
    }

    return get(itemA, key) > get(itemB, key) ? 1 : -1;
  });

  return newArr;
};

export const addOrUpdateByKey = (arr, item, key) => {
  const result = [...arr];
  const index = result.findIndex((element) => get(element, key) === get(item, key));

  if (index < 0) {
    result.push(item);
  } else {
    result[index] = item;
  }

  return result;
};
