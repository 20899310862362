import React from 'react';
import { components } from 'react-select';
import { Icon } from 'components/ui';
import css from '../styles.scss';

const DropdownIndicator = (props) => {
  return components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <div className={`${css.dropdownWrapper} dropdown-wrapper-themed`}>
        <Icon icon='chevron-down' />
      </div>
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
