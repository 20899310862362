import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Logo.scss';

const Logo = ({ small }) => {
  const logoClassNames = useMemo(
    () =>
      cn({
        [styles.logo]: true,
        [styles.smallLogo]: small,
      }),
    [small],
  );

  return <div className={logoClassNames} />;
};

Logo.propTypes = {
  small: PropTypes.bool,
};

Logo.defaultProps = {
  small: false,
};

export default Logo;
