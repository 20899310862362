import axios from 'axios';
import actionsFactory from '../../../magic/actionsFactory';
import { handleError, handleSuccess } from '../../../magic/handlers';

const createAction = actionsFactory('organization/accreditations/');

export const cleanData = createAction('cleanData');
export const setLoading = createAction('setLoading');
export const setAccreditation = createAction('setAccreditation');

export const getAccreditation = (orgId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await axios.get(`/api/rest/organization/accreditationMark/${orgId}`);
    dispatch(setAccreditation(res.data.data[0] ?? {}));
  } catch (e) {
    handleError(e);
  } finally {
    dispatch(setLoading(false));
  }
};

export const postAccreditation = (data, orgId) => async (dispatch, getState) => {
  try {
    const { data: organization } = getState().organization.view;
    const body = { organization, ...data };
    await axios.post(`/api/rest/organization/accreditationMark?organizationId=${orgId}`, body);
    handleSuccess('Данные аккредитации успешно сохранены!');
    dispatch(getAccreditation(orgId));
    return true;
  } catch (err) {
    handleError(err);
    throw err;
  }
};
