import { createReducer } from 'redux-act';
import { DEFAULT_PAGINATION } from 'constants/pagination';

import {
  clearData,
  setContractsData,
  setContractsFilters,
  setContractsLoading,
  setContractsPagination,
  setResendInProgress,
  setHasResendData,
} from 'redux/actions/Contracts/listActions';

const initialState = {
  data: [],
  filters: {
    searchTextMode: 'fiorfsid',
    statuses: [
      { name: 'Черновик', sysName: 'DRAFT' },
      { name: 'Регистрация в РФС', sysName: 'ON_REGISTRATION' },
      { name: 'Отклонено', sysName: 'REJECTED' },
      { name: 'Отозван', sysName: 'RECALLED' },
      { name: 'Отменено', sysName: 'CANCELED' },
      { name: 'На доработке', sysName: 'ON_REWORK' },
      { name: 'Запланирован', sysName: 'PLANNED' },
      { name: 'На отмене', sysName: 'ON_CANCELED' },
    ],
  },
  loading: false,
  pagination: DEFAULT_PAGINATION,
  resendInProcess: false,
  hasResendData: false,
};

const listReducer = createReducer(
  {
    [clearData]: () => initialState,
    [setContractsData]: (state, data) => ({ ...state, data }),
    [setContractsFilters]: (state, filters) => ({ ...state, filters }),
    [setContractsLoading]: (state, loading) => ({ ...state, loading }),
    [setContractsPagination]: (state, pagination) => ({ ...state, pagination }),
    [setResendInProgress]: (state, resendInProcess) => ({ ...state, resendInProcess }),
    [setHasResendData]: (state, hasResendData) => ({ ...state, hasResendData }),
  },
  initialState,
);

export default listReducer;
