import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import TopBarItem from './TopBarItem/TopBarItem';

import css from './TopBarLinks.scss';

const TopBarLinks = ({ className, items }) => {
  const containerClassName = useMemo(() => cn(css.container, className), [className]);

  if (!items.length) {
    return null;
  }

  return (
    <div className={containerClassName}>
      {items.map(({ isActive, link, title }) => (
        <TopBarItem key={link} active={isActive} link={link} title={title} />
      ))}
    </div>
  );
};

TopBarLinks.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
};

TopBarLinks.defaultProps = {
  className: undefined,
  items: [],
};

export default React.memo(TopBarLinks);
