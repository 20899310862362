import { combineReducers } from 'redux';

import profileView from 'components/pages/Profile/View/reducer';
import contracts from './contractsReducer';
import coachCategory from './coachCategoryReducer';
import footballerRank from './footballerRankReducer';
import refereeCategory from './refereeCategoryReducer';
import photoGallery from './photoGalleryReducer';
import fifaDuplicates from './fifaDuplicatesReducer';

export default combineReducers({
  coachCategory,
  contracts,
  fifaDuplicates,
  footballerRank,
  photoGallery,
  refereeCategory,
  view: profileView,
});
