import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-flexbox-grid';
import { Preloader } from 'components/ui';
import cn from 'classnames';

import Header from 'components/composed/Header/Header';
import Breadcrumbs from 'components/composed/Breadcrumbs/Breadcrumbs';

import css from './PortalLayout.scss';

const PortalLayout = ({ children, loading, largeLayout }) => (
  <div>
    <Header />
    <Breadcrumbs />
    <Grid className={cn([css.main, largeLayout && css.largeLayout])}>
      <div className={css.container}>
        <div className={css.content}>{loading ? <Preloader size="8x" /> : children}</div>
      </div>
    </Grid>
  </div>
);

PortalLayout.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  largeLayout: PropTypes.bool,
};

export default PortalLayout;
