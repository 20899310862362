import { createReducer } from 'redux-act';
import { clearData, setPrintTemplatesData, setPrintTemplatesLoading } from 'redux/actions/PrintTemplates/viewActions';

const initialState = {
  data: [],
  isLoading: false,
};

const viewReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setPrintTemplatesData]: (state, data) => ({
      ...state,
      data,
    }),
    [setPrintTemplatesLoading]: (state, isLoading) => ({
      ...state,
      isLoading,
    }),
  },
  initialState,
);

export default viewReducer;
