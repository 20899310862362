/* eslint-disable no-console */
import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';
import { handleError } from 'magic/handlers';

const createAction = actionsFactory('payCalculator/');

export const clearData = createAction('clearData');
export const setResults = createAction('setResults');

export const calculatePayments = (data) => async (dispatch) => {
  try {
    const response = await axios.post('/api/rest/costcalculation/calculate', data);

    dispatch(setResults(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setResults(null));
  }
};
