import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import CollapsibleElement from 'components/rfs-ui/CollapsibleElement';
import { Icon, H } from 'components/ui';

import css from './Accordion.scss';

class Accordion extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    defaultOpen: PropTypes.bool,
    title: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    className: undefined,
    contentClassName: undefined,
    defaultOpen: false,
    title: '',
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment
    showContent: this.props.defaultOpen,
  };

  toggleVisibility = () => this.setState((state) => ({ ...state, showContent: !state.showContent }));

  render() {
    const { title, className, contentClassName, children } = this.props;
    const { showContent } = this.state;

    return (
      <div className={cn(css.container, className)}>
        <div onClick={this.toggleVisibility} className={css.clickBar}>
          <Icon icon="angle-down" size="2x" pack="fal" className={cn(css.arrow, { [css.reverse]: showContent })} />
          <H size={3} className={css.title}>
            {title}
          </H>
        </div>
        <CollapsibleElement collapsed={!showContent}>
          <div className={cn(css.content, contentClassName)}>{children}</div>
        </CollapsibleElement>
      </div>
    );
  }
}

export default Accordion;
