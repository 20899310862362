import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import RadioField from 'components/ui/Radio/Radio';
import Label from 'components/ui/Label/Label';
import styles from './Radio.scss';

const cn = classNames.bind(styles);
const DEFAULT_POSITION = 'left';

export default class Radio extends React.PureComponent {
  static propTypes = {
    checked: PropTypes.bool, // состояние радио (выбрана или нет)
    className: PropTypes.string, // внешний класс для стилизации компонента
    containerWidth: PropTypes.string, // ширина контейнера (содержимое позиционируется по justify-content: space-between)
    disabled: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // id для связи чекбокса с лейблами
    labelClass: PropTypes.string,
    labelPosition: PropTypes.string, // позиция лейбла относительно чекбокса
    name: PropTypes.string, // имя радио
    onChange: PropTypes.func, // внешняя функция на событие onChange
    radioClass: PropTypes.number,
    title: PropTypes.string, // лейбл для чекбокса
    value: PropTypes.string, // значение чекбокса
  };

  static defaultProps = {
    checked: false,
    className: undefined,
    containerWidth: undefined,
    disabled: undefined,
    id: undefined,
    labelClass: undefined,
    labelPosition: DEFAULT_POSITION,
    name: undefined,
    onChange: undefined,
    radioClass: undefined,
    title: '',
    value: undefined,
  };

  /**
   * Возращает стиль для позиционирования элементов контейнера компонента по позиции лейбла
   */
  getStyleByPosition = () => {
    const { labelPosition } = this.props;

    // также возращаем пустоту, если указано значение по умолчанию
    if (labelPosition === DEFAULT_POSITION) {
      return undefined;
    }

    return styles[labelPosition];
  };

  render() {
    const {
      id,
      className,
      title,
      labelPosition,
      containerWidth,
      checked,
      name,
      value,
      onChange,
      labelClass,
      radioClass,
      disabled,
    } = this.props;

    const containerClass = cn(styles.container, this.getStyleByPosition(), { [className]: className });
    const radioWrapper = `${cn(styles.radioWrapper, styles[`radioMargin-${labelPosition}`], radioClass, { checked })} ${
      checked ? 'radio-checked-themed' : ''
    }`;

    return (
      <div style={{ width: containerWidth }} className={containerClass}>
        {title && (
          <Label className={cn(styles.label, labelClass)} htmlFor={id}>
            {title}
          </Label>
        )}
        <label className={`${radioWrapper} radio-wrapper-themed`} htmlFor={id}>
          <RadioField
            id={id}
            name={name}
            type="radio"
            className={styles.radio}
            onChange={onChange}
            checked={checked}
            value={value}
            disabled={disabled}
          />
          <span className={`${styles.customRadio} custom-radio-themed`} />
        </label>
      </div>
    );
  }
}
