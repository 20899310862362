import { createReducer } from 'redux-act';
import { push } from 'connected-react-router';
import axios from 'axios';
import qs from 'qs';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { notificationSuccess } from 'magic/notification';
import { sortByKey } from 'utils/arrays';
import { isFunction, get } from 'utils/lodash';
import { linkFilesToCollection, removeListFromCollection, uploadFilesToFileStorage, uploadListToCollection } from 'utils/uploadFiles';
import { parseValue, stringifyValue } from 'utils/documents';

import { DOCUMENT_STATUSES } from 'constants/documents';
import { ROUTES_WAYS } from 'constants/routesWays';
import { DEFAULT_PAGINATION } from 'constants/pagination';
import isNumber from 'lodash/isNumber';

const createAction = actionsFactory('profile/view/');

const initialState = {
  coachCategory: null,
  coachLicenses: [],
  data: {},
  decisions: {
    data: [],
    loading: false,
    pagination: DEFAULT_PAGINATION,
  },
  documentCategories: [],
  documentTypes: [],
  documents: [],
  forbidden: false,
  loading: false,
  professionalActivity: [],
  pupilInfo: [],
  refereeCategory: null,
  transfersItems: null,
  userNoteLoading: false,
};

// actions
export const setLoading = createAction('setLoading');
export const setData = createAction('setData');
export const setTransfersItems = createAction('setTransfersItems');
export const setForbidden = createAction('setForbidden');
export const clearData = createAction('clearData');
export const setUserNoteLoading = createAction('noteUserNoteLoading');

export const setDocuments = createAction('setDocuments');
export const setDocumentCategories = createAction('setDocumentCategories');
export const setDocumentTypes = createAction('setDocumentTypes');

const setPupilInfo = createAction('setPupilInfo');
const setProfessionalActivity = createAction('setProfessionalActivity');
const setCoachLicenses = createAction('setCoachLicenses');

const setDecisions = createAction('setDecisions');
const setDecisionsLoading = createAction('setDecisionsLoading');

const setDocumentsPermission = createAction('setDocumentsPermission');

export const getDecisions = (pagePagination = DEFAULT_PAGINATION, status) => async (dispatch, getState) => {
  try {
    const { data: storeData } = getState().profile.view;
    dispatch(setDecisionsLoading(true));

    const { page, perPage } = pagePagination;
    const params = { page, pagesize: perPage, personId: storeData.id, status: status?.name };

    const {
      data: { data, pageData = {} },
    } = await axios.get('/api/rest/judiciary/decision/by-relation', { params });

    const newPagination = {
      itemsCount: pageData.size,
      page,
      pageCount: pageData.countPage,
      perPage,
    };
    dispatch(setDecisions({ decisions: data, pagination: newPagination }));
  } catch (e) {
    handleError(e);
  }
};

export const getProfessionalActivity = (personId) => async (dispatch) => {
  try {
    const params = { personId };
    const {
      data: { data },
    } = await axios.get('/api/rest/person/professionalActivity/list', { params });
    dispatch(setProfessionalActivity(data));
  } catch (error) {
    console.log(error);
  }
};

export const getDocuments = (personId) => async (dispatch) => {
  try {
    const {
      data: { data: docsData },
    } = await axios.post('/api/rest/person/document/list?pagesize=100', { personId });

    const data = docsData.map((doc) => {
      const fieldsList = sortByKey(doc.documentType.fieldsList, 'fieldOrder');

      const fields = fieldsList.map((el) => {
        const fieldData = doc.documentFieldValues.find((item) => item.fieldId === el.field.id);

        return {
          field: el.field,
          fieldId: el.field.id,
          isDeleted: el.isDeleted,
          value: parseValue(fieldData?.value, el.field.type),
        };
      });

      return { ...doc, fields };
    });

    dispatch(setDocuments(data));
  } catch (err) {
    console.log(err);
  }
};

export const getDocumentTypes = () => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get('/api/rest/document/types');
    dispatch(setDocumentTypes(data));
  } catch (err) {
    console.log(err);
  }
};

export const getDocumentCategories = () => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get('/api/rest/document/categories');
    dispatch(setDocumentCategories(data));
  } catch (err) {
    console.log(err);
  }
};

export const saveDocument = ({ scans, ...data }) => async (dispatch) => {
  try {
    const body = {
      ...data,
      documentFieldValues: data.documentFieldValues.map(({ field, fieldId, value }) => ({
        fieldId,
        value: stringifyValue(value, field.type),
      })),
    };

    const docResponse = await axios.post('/api/rest/person/document/add', body);
    const docId = docResponse.data.data;

    await removeListFromCollection(scans?.toDelete, 'document');
    await uploadListToCollection(scans?.toUpload, 'document', docId, true);

    dispatch(getProfileData(body.person.id));
    handleSuccess(`Документ успешно ${!body.id ? 'добавлен' : 'обновлён'}!`, 'file-check');
  } catch (e) {
    handleError(e);
    throw e;
  }
};

export const verifyDocument = (documentId, personId) => async (dispatch) => {
  try {
    const params = {
      documentId,
      documentStatus: DOCUMENT_STATUSES.VERIFIED,
    };

    await axios.put('/api/rest/person/document/status/change', null, { params });
    await dispatch(getDocuments(personId));
    handleSuccess('Документ успешно верифицирован', 'file-check');
    dispatch(getProfileData(personId));
  } catch (e) {
    handleError(e);
  }
};

export const archiveDocument = (documentId, status, personId) => async (dispatch) => {
  try {
    await axios.put(`/api/rest/person/document/status/change?documentId=${documentId}&documentStatus=${status}`);
    handleSuccess('Документ успешно архивирован');
    dispatch(getProfileData(personId));
  } catch (e) {
    handleError(e);
  }
};

export const deleteDocument = (documentId, personId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/person/document/${documentId}`);
    await dispatch(getDocuments(personId));
    handleSuccess('Документ успешно удалён', 'trash');
  } catch (e) {
    handleError(e);
  }
};

export const getCoachLicenses = (id) => async (dispatch) => {
  try {
    dispatch(setCoachLicenses([]));
    const params = { id };
    const {
      data: { data },
    } = await axios.get('/api/rest/person/coachlicense/list', { params });
    dispatch(setCoachLicenses(data));
  } catch (error) {
    console.log(error);
  }
};

export const checkDocumentsPermissions = (personId) => async (dispatch) => {
  try {
    const params = { personId };
    const data = await axios.get('/api/rest/person/document/registry/permission/check', { params });
    dispatch(setDocumentsPermission(!!data));
  } catch (error) {
    dispatch(setDocumentsPermission(false));
  }
};

export const createCoachLicense = (personId, { files = [], prevFiles = [], ...body }, onSuccess) => async (dispatch) => {
  try {
    const {
      data: {
        data: { id: coachLicenseId },
      },
    } = await axios.post('/api/rest/person/coachlicense/add', {
      ...body,
      licenseName: `Лицензия ${body.coachLicenseType.name}`,
      isExternal: false,
      isDeleted: false,
    });
    const filesToCreate = files.filter((file) => !file.storageId);
    const filesToDelete = prevFiles.filter((file) => !files.some((_file) => _file.storageId === file.storageId));
    const storageFiles = await uploadFilesToFileStorage(filesToCreate);
    await linkFilesToCollection(
      storageFiles.map((file) => file.storageId),
      coachLicenseId,
      'coach_license',
    );
    await removeListFromCollection(filesToDelete, 'coach_license');

    await dispatch(getCoachLicenses(personId));
    notificationSuccess(`Лицензия тренера успешно ${body.id ? 'обновлена' : 'добавлена'}`, 'check');
    if (isFunction(onSuccess)) {
      onSuccess();
    }
  } catch (error) {
    handleError(error);
  }
};

export const suspendCoachLicense = (id, personId) => async (dispatch) => {
  try {
    await axios.put(`/api/rest/person/coachlicense/suspend/${id}`);
    await dispatch(getCoachLicenses(personId));
    notificationSuccess('Лицензия тренера успешно отозвана', 'check');
  } catch (error) {
    handleError(error);
  }
};

export const deleteCoachLicense = (id, personId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/person/coachlicense/${id}`);
    await dispatch(getCoachLicenses(personId));
    notificationSuccess('Лицензия тренера успешно удалена', 'check');
  } catch (error) {
    handleError(error);
  }
};

export const getProfileData = (id, withLoading = true) => async (dispatch) => {
  try {
    dispatch(setLoading(withLoading));
    const {
      data: { data },
    } = await axios.get(`/api/rest/person/${id}`);

    const {
      status,
      mainInfo = {},
      professionalActivityIns,
      educationDtos,
      additionalInfo = {},
      sportActivities,
      sportActivityIns = [],
      accountOut,
      standardPersonOut,
      tabs,
      favorite,
      bankDetails,
    } = data;

    const restrictedInfo = get(tabs, 'footballer.restrictedInfo', []);

    const resultData = {
      account: accountOut,
      ...additionalInfo,
      ...mainInfo,
      restrictedInfo,
      sportActivityIns,
      sportActivities,
      standardPerson: standardPersonOut,
      status,
      tabs,
      favorite,
      bankDetails,
    };

    if (professionalActivityIns) {
      resultData.professionalActivityIns = professionalActivityIns;
    }

    if (educationDtos) {
      const { high = [], middle = [], other = [] } = educationDtos;
      resultData.education = [...high, ...middle, ...other];
    }

    dispatch(setData(resultData));
  } catch (e) {
    if (e.response && e.response.status === 403) {
      dispatch(setForbidden());
    } else {
      handleError(e);
      dispatch(push(ROUTES_WAYS.SEARCH_PROFILE));
      dispatch(setLoading(false));
    }
  }
};

export const getTransfersItems = (personId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/rest/audit/transferlist?personid=${personId}`);

    dispatch(setTransfersItems(res.data.data.organizations));
  } catch (e) {
    handleError(e);
    dispatch(setTransfersItems([]));
  }
};

export const updateNote = (personId) => (note) => async (dispatch) => {
  dispatch(setUserNoteLoading(true));

  const queryParams = qs.stringify({ id: personId, note }, { addQueryPrefix: true });

  try {
    await axios.put(`/api/rest/person/note${queryParams}`);

    await dispatch(getProfileData(personId));
    notificationSuccess('Примечание пользователя успешно сохранено', 'check');
  } catch (e) {
    handleError(e);
  } finally {
    dispatch(setUserNoteLoading(false));
  }
};

export const deleteNote = (personId) => () => async (dispatch) => {
  dispatch(setUserNoteLoading(true));
  try {
    await axios.delete(`/api/rest/person/note/${personId}`);
    await dispatch(getProfileData(personId));
    notificationSuccess('Примечание пользователя успешно удалено', 'check');
  } catch (e) {
    handleError(e);
  } finally {
    dispatch(setUserNoteLoading(false));
  }
};

export const updateSportCitizenship = ({ countryId, personId }) => async (dispatch) => {
  try {
    const params = { countryId, id: personId };
    await axios.put('/api/rest/person/sportcitizenship', null, { params });
    handleSuccess('Спортивное гражданство успешно обновлено!');
    await dispatch(getProfileData(personId));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const updateRefereeCity = (personId, refereeCity) => async (dispatch) => {
  try {
    await axios.post(`/api/rest/person/${personId}/referee-city`, refereeCity);

    handleSuccess('Город успешно обновлен');
    await dispatch(getProfileData(personId));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const updateInspectorCity = (personId, inspectorCity) => async (dispatch) => {
  try {
    await axios.post(`/api/rest/person/${personId}/inspector-city`, inspectorCity);

    handleSuccess('Город успешно обновлен');
    await dispatch(getProfileData(personId));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const getPupilInfo = (personId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/rest/person/pupils/check/${personId}`);
    dispatch(setPupilInfo(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setPupilInfo([]));
  }
};

export const updateRestriction = (personId, data) => async (dispatch) => {
  const config = { person_id: personId };
  const { docsToRemove, docsToUpload, ...body } = data;
  try {
    const response = body?.id
      ? await axios.post(`/api/rest/person/restricted/disciplines/${personId}`, data)
      : await axios.post('/api/rest/person/restricted/discipline/save', body, { params: config });
    const restrictionId = response.data.data;
    if (docsToUpload?.length && isNumber(restrictionId)) {
      const storageFiles = await uploadFilesToFileStorage(docsToUpload);
      await linkFilesToCollection(
        storageFiles.map((file) => file.storageId),
        restrictionId,
        'restriction',
      );
    }
    await removeListFromCollection(docsToRemove || [], 'restriction');
    await dispatch(getProfileData(personId, false));
    notificationSuccess('Данные о запрете успешно сохранены!', 'check');
    return true;
  } catch (err) {
    handleError(err);
  }
};

export const deleteRestriction = (restrictionId, personId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/person/restricted/discipline/${restrictionId}`);
    dispatch(getProfileData(personId, false));
    notificationSuccess('Данные о запрете успешно удалены!', 'check');
  } catch (err) {
    handleError(err);
  }
};

export const addBankRequisite = (personId, body) => async (dispatch) => {
  try {
    await axios.post(`/api/rest/person/bank/details?personId=${personId}`, body);
    dispatch(getProfileData(personId, false));
    notificationSuccess('Банковские реквизиты успешно добавлены!', 'check');
  } catch (err) {
    handleError(err);
  }
};

export const deleteBankRequisite = (requisitesId, personId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/person/bank/details/${requisitesId}?personId=${personId}`);
    dispatch(getProfileData(personId, false));
    notificationSuccess('Банковские реквизиты успешно удалены!', 'check');
  } catch (err) {
    handleError(err);
  }
};

const reducer = createReducer(
  {
    [clearData]: () => initialState,
    [setCoachLicenses]: (state, coachLicenses) => ({
      ...state,
      coachLicenses,
    }),
    [setData]: (state, data) => ({
      ...state,
      data,
      loading: false,
    }),
    [setDecisions]: (state, { decisions, pagination }) => ({
      ...state,
      decisions: {
        data: decisions,
        loading: false,
        pagination,
      },
    }),
    [setDecisionsLoading]: (state, loading) => ({
      ...state,
      decisions: {
        ...state.decisions,
        loading,
      },
    }),
    [setDocumentCategories]: (state, documentCategories) => ({
      ...state,
      documentCategories,
    }),
    [setDocumentTypes]: (state, documentTypes) => ({
      ...state,
      documentTypes,
    }),
    [setDocuments]: (state, documents) => ({
      ...state,
      documents,
    }),
    [setForbidden]: (state) => ({
      ...state,
      forbidden: true,
      loading: false,
    }),
    [setLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
    [setProfessionalActivity]: (state, professionalActivity) => ({
      ...state,
      professionalActivity,
    }),
    [setPupilInfo]: (state, pupilInfo) => ({
      ...state,
      pupilInfo,
    }),
    [setTransfersItems]: (state, transfersItems) => ({
      ...state,
      transfersItems,
    }),
    [setUserNoteLoading]: (state, userNoteLoading) => ({
      ...state,
      userNoteLoading,
    }),
    [setDocumentsPermission]: (state, documentsPermission) => ({
      ...state,
      documentsPermission,
    }),
  },
  initialState,
);

export default reducer;
