import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Modal, Button } from 'components/ui';
import { noop } from 'utils/lodash';

import { confirmAction, confirmActionAsync, routerConfirm } from './utils';
import css from './styles.scss';

const ConfirmModal = ({ applyText, closeText, onClose, onApply, onReject, children, isOpen }) => {
  const handleApply = useCallback(() => {
    onApply();
    onClose();
  }, [onApply, onClose]);

  const handleReject = useCallback(() => {
    onReject();
    onClose();
  }, [onReject, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal className={css.container}>
      <p className={css.header}>{children}</p>
      <div className={css.buttons}>
        <Button onClick={handleReject} type="text" className={cn(css.button, css.buttonNo)}>
          {closeText}
        </Button>
        <Button onClick={handleApply} type="text" className={cn(css.button, css.buttonYes)}>
          {applyText}
        </Button>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  applyText: PropTypes.string,
  children: PropTypes.node,
  closeText: PropTypes.string,
  isOpen: PropTypes.bool,
  onApply: PropTypes.func,
  onClose: PropTypes.func,
  onReject: PropTypes.func,
};

ConfirmModal.defaultProps = {
  applyText: 'Продолжить',
  children: null,
  closeText: 'Отменить',
  isOpen: false,
  onApply: noop,
  onClose: noop,
  onReject: noop,
};

ConfirmModal.confirm = confirmAction(ConfirmModal);
ConfirmModal.confirmAsync = confirmActionAsync(ConfirmModal);
ConfirmModal.routerConfirm = routerConfirm(ConfirmModal);

export default ConfirmModal;
