import { Input } from 'components/rfs-ui';
import Timepicker from 'components/rfs-ui/DateTimePicker/Timepicker';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'components/ui';
import moment from 'moment';
import css from './TimePicker.module.scss';

const TimePickerIcon = () => (
  <Button type="text" onClick={() => {}} className={css.icon}>
    <Icon icon="calendar-alt" pack="fal" />
  </Button>
);

const TimePicker = ({ onChange, value, placeholder, required, disabled }) => {
  const [showTimepicker, setShowTimepicker] = useState(false);
  const viewValue = value && moment(value).format('HH:mm');
  const timePickerRef = useRef(null);

  const handleClickOutside = (event) => {
    const { current } = timePickerRef;
    if (current && !current?.contains(event.target) && !disabled) {
      setShowTimepicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
  }, []);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={() => !disabled && setShowTimepicker(true)} className={css.container}>
        <Input disabled={disabled} required={required} placeholder={placeholder} value={viewValue} actionIcon={TimePickerIcon()} />
      </div>
      {showTimepicker && (
        <div ref={timePickerRef} className={css.timePicker} onBlur={() => setShowTimepicker(false)}>
          <Timepicker value={value} onChange={onChange} />
        </div>
      )}
    </>
  );
};

TimePicker.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
};

TimePicker.defaultProps = {
  onChange: undefined,
  placeholder: '',
  required: false,
  value: undefined,
};

export default TimePicker;
