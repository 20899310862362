import { string, number, shape } from 'prop-types';

export const PTAddressType = {
  /**
   * @description Описание
   */
  description: string,
  /**
   * @description Идентификатор
   */
  id: number,
  /**
   * @description Наименование
   */
  name: string.isRequired,
  /**
   * @description Короткое имя
   */
  shortName: string,
  /**
   * @description Системеное наименование
   */
  sysName: string,
  /**
   * @description Идентификатор с фронта
   */
  tempId: string,
};

export const PTAddress = {
  /**
   * @description Город
   */
  city: string,
  /**
   * @description Город на латинице
   */
  cityOnLatin: string,
  /**
   * @description Регион
   */
  district: string,
  /**
   * @description Дом
   */
  house: string,
  /**
   * @description Строение
   */
  housing: string,
  /**
   * @description Идентификатор
   */
  id: number,
  /**
   * @description Населенный пункт
   */
  locality: string,
  /**
   * @description название субъекта РФ
   */
  region: string,
  /**
   * @description код субъекта РФ
   */
  region_kladr_id: number,
  /**
   * @description Улица
   */
  street: string,
  /**
   * @description код субъекта
   */
  subjectCode: string,
  /**
   * @description Идентификатор с фронта
   */
  tempId: string,
  type: shape(PTAddressType),
  value: string,
  /**
   * @description Индекс
   */
  zipCode: string,
};
