import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import { handleError } from 'magic/handlers';
import { notificationSuccess } from 'magic/notification';
import axios from 'axios';

const createAction = actionsFactory('account/edit/');

const initialState = {
  accountData: null,
  authenticationTypes: [],
};

// actions
const setAccountData = createAction('setAccountData');
const setAuthenticationTypes = createAction('setAuthenticationTypes');

export const purgeAccountEditState = createAction('purgeAccountEditState');

export const getAccountById = (accountId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/rest/person/account/${accountId}`);
    dispatch(setAccountData(response.data.data));
  } catch (e) {
    handleError(e);
  }
};

export const getTypes = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/person/account/types');
    const authenticationTypes = response.data.data || [];
    dispatch(setAuthenticationTypes(authenticationTypes));
  } catch (e) {
    handleError(e);
  }
};

export const updateUserAccount = (body) => async () => {
  try {
    await axios.post('/api/rest/person/account/register/update', body);
    notificationSuccess('Учётная запись пользователя успешно отредактирована!', 'paper-plane');
  } catch (e) {
    handleError(e);
    throw e;
  }
};

export const resendMail = (accountId) => async () => {
  try {
    await axios.put(`/api/rest/person/account/mail/repeat/${accountId}`, null);
    notificationSuccess('Письмо успешно выслано на указанный email!', 'paper-plane');
  } catch (e) {
    handleError(e);
    throw e;
  }
};

export const deleteAccount = (personId) => async () => {
  try {
    await axios.put(`/api/rest/person/account/delete/${personId}`, null);
    notificationSuccess('Учетная запись успешно удалена', 'trash');
  } catch (e) {
    handleError(e);
    throw e;
  }
};

const reducer = createReducer(
  {
    [purgeAccountEditState]: () => ({
      ...initialState,
    }),
    [setAccountData]: (state, accountData) => ({
      ...state,
      accountData,
    }),
    [setAuthenticationTypes]: (state, authenticationTypes) => ({
      ...state,
      authenticationTypes,
    }),
  },
  initialState,
);

export default reducer;
