import { createReducer } from 'redux-act';

import {
  setCompensationsByPayment,
  clearData,
  setCompensationsCategoryDepended,
  setCompensationsCategoryFactors,
} from 'redux/actions/PayConstructor/compensations';

const initialState = {};

const compensationsReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setCompensationsByPayment]: (state, { paymentId, data }) => ({
      ...state,
      [paymentId]: { ...data },
    }),
    [setCompensationsCategoryDepended]: (state, { paymentId, data }) => ({
      ...state,
      [paymentId]: {
        ...state[paymentId],
        categoryDependentFactor: data,
      },
    }),
    [setCompensationsCategoryFactors]: (state, { paymentId, data }) => {
      return {
        ...state,
        [paymentId]: {
          ...state[paymentId],
          categoryFactors: data,
        },
      };
    },
  },
  initialState,
);

export default compensationsReducer;
