import axios from 'axios';

import { Notification } from 'components/rfs-ui';

import actionsFactory from 'magic/actionsFactory';
import { handleError } from 'magic/handlers';

const createAction = actionsFactory('personDoubles/');

export const clearWsPersonDoubleFromCollection = createAction('clearWsPersonDoubleFromCollection');
export const setWsPersonActiveDouble = createAction('setWsPersonActiveDouble');
export const setUserById = createAction('setUserById');
export const purgeUserById = createAction('purgeUserById');
export const setWsPersonDoublesCollection = createAction('setWsPersonDoublesCollection');
export const removeWsPersonDoubleFromCollection = createAction('removeWsPersonDoubleFromCollection');

export const updateMessagesStatus = (messageStatus, messagesUuid) => async () => {
  try {
    const _messagesUuid = Array.isArray(messagesUuid) ? messagesUuid : Array.of(messagesUuid);

    axios({
      data: { eventIds: _messagesUuid },
      method: 'post',
      params: { eventStatusName: messageStatus },
      url: '/api/rest/message/update/status',
    });
  } catch (error) {
    //
  }
};

export const updateFifaId = ({ fifaId, personId, uuid }) => async (dispatch) => {
  try {
    await axios({
      method: 'post',
      params: { fifaId, personId },
      url: '/api/rest/person/updatefifa',
    });

    Notification.success({
      children: {
        icon: 'user',
        message: 'fifa id успешно обновлён',
      },
    });

    dispatch(updateMessagesStatus('SEND', uuid));
    dispatch(removeWsPersonDoubleFromCollection(uuid));
  } catch (error) {
    handleError(error);
  }
};

export const forceFIFARegistration = (personId, uuid) => async (dispatch) => {
  try {
    await axios({
      method: 'post',
      params: { personId },
      url: '/api/rest/person/force',
    });

    Notification.success({
      children: {
        icon: 'user',
        message: 'fifa id успешно присвоен',
      },
    });

    dispatch(updateMessagesStatus('SEND', uuid));
    dispatch(removeWsPersonDoubleFromCollection(uuid));
  } catch (error) {
    handleError(error);
  }
};

export const getUserById = (id) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get(`/api/rest/person/${id}`);

    dispatch(setUserById(data));
  } catch (error) {
    //
  }
};
