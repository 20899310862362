import { createSelector } from 'reselect';
import isObject from 'lodash/isObject';
import moment from 'moment';

import dateFormats from 'constants/dateFormats';

const { APP_DATE_FORMAT } = dateFormats;

const organizationViewSelector = (state) => state.organization.view;

export const categoryHistorySelector = createSelector(organizationViewSelector, (organizationView) => organizationView.categoryHistory);

export const categoryHistoryDataSelector = createSelector(categoryHistorySelector, (history) =>
  history.data
    .map((item) => {
      const { changeDate, endDate } = item;
      const period = `${moment(changeDate).format(APP_DATE_FORMAT)}${endDate ? `-${moment(endDate).format(APP_DATE_FORMAT)}` : ''}`;

      return {
        ...item,
        period,
      };
    })
    .sort((a, b) => {
      if (a.changeDate < b.changeDate) {
        return 1;
      }
      if (a.changeDate > b.changeDat) {
        return -1;
      }
      return 0;
    }));

export const categoryHistoryLoadingSelector = createSelector(categoryHistorySelector, (history) => history.loading);
export const categoryHistoryPaginationSelector = createSelector(categoryHistorySelector, (history) => history.pagination);

export const organizationMembersSelector = createSelector(
  organizationViewSelector,
  (organizationView) => organizationView.organizationMembers,
);

export const organizationMembersPaginationSelector = createSelector(organizationViewSelector, (organizationView) => {
  const { organizationMembersPagination } = organizationView;

  if (isObject(organizationMembersPagination)) {
    const { numberPage, sizePage, size, countPage } = organizationMembersPagination;
    const pagination = {
      itemsCount: size,
      page: numberPage,
      pageCount: countPage,
      perPage: sizePage,
    };

    return pagination;
  }

  return null;
});

export const organizationMembersSectionSelector = createSelector(
  organizationViewSelector,
  (organizationView) => organizationView.organizationMembersSection,
);

export const organizationSelector = createSelector(organizationViewSelector, (view) => view.data);

export const organizationDictionariesSelector = createSelector(organizationViewSelector, (view) => view.dictionaries);
export const dictionariesSocialsSelector = createSelector(organizationDictionariesSelector, (dictionaries) => dictionaries.socials);

export const organizationSocialLinksSelector = createSelector(organizationViewSelector, (data) => data.socialLinks);

export const organizationTournamentsSelector = createSelector(organizationViewSelector, (data) => data.tournaments);

export const organizationDataSelector = createSelector(organizationViewSelector, (organizationView) => organizationView.data);
