import * as React from 'react';
import { array, string, object, func, oneOfType, number, bool } from 'prop-types';
import axios from 'axios';
import { handleError } from 'magic/handlers';
import isEmpty from 'lodash/isEmpty';

import Select from './Select';

class Reference extends React.PureComponent {
  static propTypes = {
    filterOption: func,
    makeOptions: func,
    getOptions: func,
    onChange: func.isRequired,
    onlyValue: bool,
    optionsPath: string,
    requestParams: object,
    setDefaultValue: func,
    value: oneOfType([string, number, object, array]),
    valueKey: string,
  };

  static defaultProps = {
    filterOption: undefined,
    makeOptions: undefined,
    getOptions: () => {},
    onlyValue: false,
    optionsPath: undefined,
    requestParams: undefined,
    setDefaultValue: undefined,
    value: undefined,
    valueKey: '',
  };

  state = {
    defaultValue: null,
    filteredOptions: [],
    loading: false,
    options: [],
  };

  componentDidMount() {
    if (this.props.optionsPath) {
      this.getOptions();
    }
  }

  componentDidUpdate(prevProps) {
    const { optionsPath, filterOption } = this.props;
    if (optionsPath && prevProps.optionsPath !== optionsPath) {
      this.getOptions();
    }

    if (prevProps.filterOption !== filterOption) {
      this.setFilteredOptions();
    }
  }

  getOptions = async () => {
    const { getOptions, optionsPath, setDefaultValue, requestParams, makeOptions, method } = this.props;

    if (optionsPath) {
      this.setState({ loading: true });
      try {
        const response = await axios.get(optionsPath, { params: requestParams });
        const data = typeof makeOptions === 'function' ? makeOptions(response.data.data) : response.data.data;
        const filteredData = this.filterOption(data);

        this.setState(
          {
            defaultValue: setDefaultValue ? filteredData.find(setDefaultValue) : null,
            filteredOptions: filteredData,
            loading: false,
            options: data,
          },
          () => {
            const { defaultValue } = this.state;
            const { value } = this.props;
            const emptyValue = !value || isEmpty(value);

            if (defaultValue && emptyValue) {
              const { onlyValue, valueKey } = this.props;
              this.handleChange(onlyValue ? defaultValue[valueKey] : defaultValue);
            }
          },
        );
        getOptions(data);
      } catch (e) {
        handleError(e);
        this.setState({ loading: false });
      }
    }
  };

  filterOption = (options) => {
    const { filterOption } = this.props;
    return filterOption ? options.filter((option) => filterOption({ data: option })) : options;
  };

  setFilteredOptions = () => {
    const { options } = this.state;
    this.setState({ filteredOptions: this.filterOption(options) });
  };

  handleChange = (e) => {
    this.props.onChange(e);
  };

  render() {
    const { filteredOptions, loading, defaultValue } = this.state;
    const { setDefaultValue, value, filterOption, ...restProps } = this.props;

    return <Select {...restProps} isLoading={loading} options={filteredOptions} value={value} />;
  }
}

export default Reference;
