import { createReducer } from 'redux-act';
import * as accessRightsActions from 'redux/actions/accessRightsActions';

const initialState = {
  dictionary: {
    all: [],
    organization: [],
  },
  organizations: [],
  permissions: {
    all: [],
    organizations: [],
  },
  rolesDictionary: [],
  rolesFilter: [],
};

const accessRightsReducer = createReducer(
  {
    [accessRightsActions.setDictionary]: (state, dictionary) => ({
      ...state,
      dictionary,
    }),
    [accessRightsActions.setOrganizations]: (state, organizations) => ({
      ...state,
      organizations,
    }),
    [accessRightsActions.setPermissions]: (state, permissions) => ({
      ...state,
      permissions,
    }),
    [accessRightsActions.setRolesDictionary]: (state, rolesDictionary) => ({
      ...state,
      rolesDictionary,
    }),
    [accessRightsActions.setRolesFilter]: (state, rolesFilter) => ({
      ...state,
      rolesFilter,
    }),
    [accessRightsActions.clearData]: () => ({ ...initialState }),
  },
  initialState,
);

export default accessRightsReducer;
