import moment from 'moment';
import dateFormats from 'constants/dateFormats';
import { isNumber } from 'utils/lodash';
import { pluralize } from './pluralize';

/**
 * Проверяет дату на валидность
 *
 * @param {string} dateString - строка даты
 * @param {string} format - формат для приведения
 * @returns {bool}
 */
export const isValidDate = (dateString, format = dateFormats.APP_DATE_FORMAT) => {
  const momentDate = moment(dateString, format, true);
  const date = new Date(momentDate);
  return !isNaN(date);
};

/**
 * Приводит число к формату "0X" для отображения даты
 *
 * @param {string} dayNumber - число
 * @returns {string}
 */
export const addZero = (dayNumber) => (dayNumber.length === 1 ? `0${dayNumber}` : dayNumber);

/**
 * Формирует возраст по дате рождения
 *
 * @param {string} dateOfBirth - строковая дата рождения
 * @returns {string}
 */
export const makeAge = (dateOfBirth) => moment().diff(dateOfBirth, 'years', false);

/**
 * Формирует возраст по дате рождения со склонением
 *
 * @param {string | number} dateOfBirth - строковая дата рождения
 * @returns {string}
 */
export const makeAgeString = (dateOfBirth) => {
  const currentAge = moment().diff(dateOfBirth, 'years', false);
  return `${currentAge} ${pluralize(currentAge, ['год', 'года', 'лет'])}`;
};

/**
 * Формирует дату в виде строки в определенном формате с возможностью учета временной зоны
 *
 * @param {number | string} milliseconds - дата в миллисекундах
 * @param {boolean} withTimezone - форматировать во временной зоне пользователя или в UTC
 * @param {string} format - результирующий формат даты
 * @returns {string}
 */
export const makeDateString = (milliseconds, withTimezone = false, format = dateFormats.APP_DATE_FORMAT) => {
  if (!milliseconds && milliseconds !== 0) {
    return 'Не определено';
  }

  return withTimezone ? moment(milliseconds).format(format) : moment.utc(milliseconds).format(format);
};

/**
 * Формирует дату в виде строки формата ДД.ММ.ГГГГ
 *
 * @param {number | string | null | undefined} date - дата в миллисекундах
 */
export const makeFormattedDate = (date, defaultValue = '', format = dateFormats.APP_DATE_FORMAT) =>
  (date || date === 0) && moment(date).isValid() ? moment.utc(date).format(format) : defaultValue;

export const makeDateRange = (dateFrom, dateTo) =>
  (isNumber(dateFrom) && isNumber(dateTo) && `${makeFormattedDate(dateFrom)} – ${makeFormattedDate(dateTo)}`) ||
  (isNumber(dateFrom) && `с ${makeFormattedDate(dateFrom)}`) ||
  (isNumber(dateTo) && `по ${makeFormattedDate(dateTo)}`) ||
  '–';

/**
 * Строит маску для инпута по формату даты
 *
 * @param {string} format - формат даты
 * @returns {array}
 */
export const makeMaskByFormat = (format) => format.split('').map((letter) => (/\W/.test(letter) ? letter : /[0-9]/));

/**
 * Прибавляет сутки к верхней дате в интервале
 *
 * @param {number} ms - дата в миллисекундах
 */
export const addExtraDay = (ms) => ms + 23 * 60 * 60 * 1000 + 59 * 60 * 1000 + 59 * 1000;

/**
 * Сравнивает даты и возвращает меньшую, иначе null
 *
 * @param {*} firstDate - первая дата
 * @param {*} secondDate - вторая дата
 * @param {boolean} withTime - с учетом времени
 *
 * @returns {*} - меньшая дата|null
 */
export const compareDates = (firstDate, secondDate, withTime) => {
  const date1 = new Date(firstDate);
  const date2 = new Date(secondDate);
  if (withTime) {
    date1.setSeconds(0);
    date1.setMilliseconds(0);
    date2.setSeconds(0);
    date2.setMilliseconds(0);
  } else {
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);
  }
  const timestamp1 = date1.getTime();
  const timestamp2 = date2.getTime();

  if (timestamp1 > timestamp2) {
    return secondDate;
  }

  if (timestamp1 < timestamp2) {
    return firstDate;
  }

  return null;
};

export const momentDateBuilder = (dateFormat) => (date) => {
  if (date) {
    const momentDate = moment(date);

    if (momentDate.isValid()) {
      return momentDate.format(dateFormat);
    }
  }
  return '';
};

export const getToday = (utc) => utc ? moment.utc().startOf('day').valueOf() : moment().startOf('day').valueOf(); // eslint-disable-line prettier/prettier
