import React, { useMemo } from 'react';
import { node, string, bool, func, shape } from 'prop-types';
import cn from 'classnames';

import Icon from 'components/ui/Icon/Icon';
import Preloader from 'components/ui/Preloader/Preloader';

import styles from './Button.scss';

export default function Button(props) {
  const { children, disabled, type, onClick, className, icon, iconPack, theme, loading, style, submit, customIcon, ...rest } = props;
  const buttonClassName = useMemo(() => cn(styles.button, styles[theme], styles[type], className), [type, theme, className]);

  return (
    <button
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled || loading}
      style={style}
      type={submit ? 'submit' : 'button'}
    >
      {(icon || customIcon) && (
        <Icon customIcon={customIcon} className={children ? styles.icon : styles.iconWithoutMargin} icon={icon} pack={iconPack} />
      )}
      {loading && <Preloader size="1x" className={styles.loader} />}
      {children}
    </button>
  );
}

Button.defaultProps = {
  children: undefined,
  className: undefined,
  data: undefined,
  disabled: undefined,
  icon: undefined,
  customIcon: undefined,
  iconPack: undefined,
  loading: false,
  onClick: undefined,
  style: undefined,
  submit: false,
  theme: undefined,
  type: 'primary',
};

Button.propTypes = {
  children: node,
  className: string,
  data: string,
  disabled: bool,
  icon: string,
  customIcon: node,
  iconPack: string,
  loading: bool,
  onClick: func,
  style: shape(),
  submit: bool,
  theme: string,
  type: string,
};
