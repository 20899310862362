import React from 'react';
import PropTypes from 'prop-types';

import FormElement from '../FormElement/FormElement';

import css from './TextContent.scss';

const TextContent = ({ title, content }) => (
  <FormElement title={title}>
    <p className={css.content}>{content}</p>
  </FormElement>
);

TextContent.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string,
};

TextContent.defaultProps = {
  content: '',
  title: '',
};

export default React.memo(TextContent);
