import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
// import { push } from 'connected-react-router';
// import { handleError } from 'magic/handlers';
// import { Notification } from 'components/rfs-ui';

const createAction = actionsFactory('exercise/edit/mainInfo/');

const initialState = {};

// actions
export const fillMainInfo = createAction('fillMainInfo');
export const updateData = createAction('updateData');
export const clearMainInfo = createAction('clearMainInfo');

// reducer
const reducer = createReducer({
  [fillMainInfo]: (state, payload) => payload,
  [updateData]: (state, payload) => ({
    ...state,
    ...payload,
  }),
  [clearMainInfo]: () => initialState,
}, initialState);

export default reducer;
