import { createReducer } from 'redux-act';

import { DEFAULT_PAGINATION } from 'constants/pagination';
import {
  cleanClubsState,
  setClubCategories,
  setOrganizations,
  setOrganizationsPagination,
  setSeasons,
} from 'redux/actions/categoriesActions';

const clubsInitialState = {
  clubCategories: [],
  organizations: [],
  organizationsPagination: DEFAULT_PAGINATION,
  seasons: [],
};

const clubsReducer = createReducer(
  {
    [cleanClubsState]: () => ({
      ...clubsInitialState,
    }),
    [setClubCategories]: (state, payload) => ({
      ...state,
      clubCategories: payload,
    }),
    [setOrganizations]: (state, organizations) => ({
      ...state,
      organizations,
    }),
    [setOrganizationsPagination]: (state, organizationsPagination) => ({
      ...state,
      organizationsPagination,
    }),
    [setSeasons]: (state, payload) => ({
      ...state,
      seasons: payload,
    }),
  },
  clubsInitialState,
);

export default clubsReducer;
