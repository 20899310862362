import React from 'react';
import PropTypes from 'prop-types';

const AccessDenied = ({ text }) => <div>{text}</div>;

AccessDenied.propTypes = {
  text: PropTypes.string,
};

AccessDenied.defaultProps = {
  text: undefined,
};

export default AccessDenied;
