import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './Textarea.scss';

const cx = classNames.bind(styles);

const Textarea = ({ id, placeholder, name, className, disabled, required, onChange, value, maxLength, defaultValue, rows }) => {
  const areaClassNames = useMemo(() => cx({ required, textarea: true }, className), [required, className]);

  return (
    <textarea
      id={id}
      placeholder={placeholder}
      name={name}
      className={areaClassNames}
      disabled={disabled}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      maxLength={maxLength}
      rows={rows}
    />
  );
};

Textarea.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.string,
};

Textarea.defaultProps = {
  className: undefined,
  defaultValue: undefined,
  disabled: false,
  id: undefined,
  maxLength: undefined,
  name: undefined,
  onChange: undefined,
  placeholder: undefined,
  required: false,
  rows: undefined,
  value: undefined,
};

export default Textarea;
