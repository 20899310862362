export const findFirstOption = (_, idx) => idx === 0;

export const preventDefault = (event) => event.preventDefault();

export const stopPropagation = (event) => event.stopPropagation();

export const numberMask = (value) => {
  const charMask = /\d/;
  const str = value ? String(value) : '';
  const mask = str.split('').map(() => charMask);
  return mask;
};
