import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';
import { handleError } from 'magic/handlers';
import { DEFAULT_PAGINATION } from 'constants/pagination';
import { filterFieldsWithIds } from 'components/pages/Cases/List/static';
import { omit } from '../../../utils/lodash';

const createAction = actionsFactory('decisions/view/');

export const cleanData = createAction('cleanData');

export const setDecisions = createAction('setDecisions');
export const setDecisionsLoading = createAction('setDecisionsLoading');

const getRequestFilters = (filters) =>
  Object.fromEntries(
    Object.entries(filters || {}).map(([key, value]) =>
      filterFieldsWithIds.includes(key)
        ? [
            [`${key}${key !== 'decisionStatus' && key !== 'number' ? 'Id' : ''}`],
            value?.number || value?.id || value?.name || value?.federationId,
          ]
        : [key, value],),
  );

export const getDecisions = (filters = {}, pagination = DEFAULT_PAGINATION) => async (dispatch) => {
  try {
    dispatch(setDecisionsLoading(true));
    const url = '/api/rest/judiciary/decision/list';
    const { page, perPage } = pagination;
    const requestFilters = getRequestFilters(omit(filters, 'relationType'));

    const params = {
      ...requestFilters,
      page,
      perPage,
    };

    const {
      data: { data, pageData = {} },
    } = await axios.get(url, { params });

    const newPagination = {
      itemsCount: pageData.size,
      page,
      pageCount: pageData.countPage,
      perPage,
    };

    dispatch(setDecisions({ data, filters, pagination: newPagination }));
  } catch (err) {
    handleError(err);
    dispatch(setDecisionsLoading(false));
  }
};
