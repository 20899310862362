exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------ \nCommon\n--------------------------*/\n\n\n/* layouts width*/\n\n\n/* ------------------------ \nDark Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* input*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nLight Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nRating\n--------------------------*/\n\n\n.content-2slR1 {\n  background-color: #001240;\n  height: 100px;\n  overflow-y: auto;\n  padding: 10px;\n  white-space: pre-wrap;\n}\n\n\n.theme-dark .content-2slR1 {\n    background-color: #001240;\n  }\n\n\n.theme-light .content-2slR1 {\n    background-color: #eaf6ff;\n  }\n", ""]);

// exports
exports.locals = {
	"content": "content-2slR1"
};