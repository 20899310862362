import { createReducer } from 'redux-act';
import axios from 'axios';

import flow from 'lodash/flow';

import actionsFactory from 'magic/actionsFactory';
import { handleError } from 'magic/handlers';

const createAction = actionsFactory('@@TrainingGroup/Coaches/');

const initialState = {
  coachesByOrganizationId: [],
  coachesByOrganizationIdLoading: false,
};

export const purgeCoachesReducer = createAction('purgeCoachesReducer');
const setCoachesByOrganizationIdLoading = createAction('setCoachesByOrganizationIdLoading');
const setCoachesByOrganizationId = createAction('setCoachesByOrganizationId');

export const getCoachesByOrganizationId = (organizationId) => async (dispatch) => {
  const dispatchLoading = flow(setCoachesByOrganizationIdLoading, dispatch);

  dispatchLoading(true);
  try {
    const {
      data: { data },
    } = await axios.get('/api/rest/coachgroup/coaches', { params: { organizationId } });

    flow(setCoachesByOrganizationId, dispatch)(data);
  } catch (error) {
    handleError(error);
  } finally {
    dispatchLoading(false);
  }
};

const reducer = createReducer(
  {
    [purgeCoachesReducer]: () => ({ ...initialState }),
    [setCoachesByOrganizationId]: (state, payload) => ({
      ...state,
      coachesByOrganizationId: payload,
    }),
    [setCoachesByOrganizationIdLoading]: (state, payload) => ({
      ...state,
      coachesByOrganizationIdLoading: payload,
    }),
  },
  initialState,
);

export default reducer;
