import { createReducer } from 'redux-act';

import {
  clearWsPersonDoubleFromCollection,
  purgeUserById,
  removeWsPersonDoubleFromCollection,
  setUserById,
  setWsPersonActiveDouble,
  setWsPersonDoublesCollection,
} from 'redux/actions/personDoublesActions';

const initialState = {
  userById: null,
  wsPersonActiveDouble: null,
  wsPersonDoublesCollection: new Map(),
  wsPersonDoublesCollectionSize: 0,
};

const personDoublesReducer = createReducer(
  {
    [clearWsPersonDoubleFromCollection]: (state) => {
      state.wsPersonDoublesCollection.clear();
      return { ...initialState };
    },
    [purgeUserById]: (state) => ({
      ...state,
      userById: null,
    }),
    [removeWsPersonDoubleFromCollection]: (state, payload) => {
      state.wsPersonDoublesCollection.delete(payload);
      return {
        ...state,
        wsPersonDoublesCollectionSize: state.wsPersonDoublesCollection.size,
      };
    },
    [setUserById]: (state, payload) => ({
      ...state,
      userById: payload,
    }),
    [setWsPersonActiveDouble]: (state, payload) => ({
      ...state,
      wsPersonActiveDouble: payload,
    }),
    [setWsPersonDoublesCollection]: (state, payload) => {
      state.wsPersonDoublesCollection.set(payload.uuid, payload);
      return {
        ...state,
        wsPersonDoublesCollectionSize: state.wsPersonDoublesCollection.size,
      };
    },
  },
  initialState,
);

export default personDoublesReducer;
