/* eslint-disable prettier/prettier */
import { ROUTES_WAYS } from 'constants/routesWays';

const MANAGEMENT = { label: 'Управление', path: '/management' };
const APPLICATIONS = { label: 'Заявления', path: '/applications' };

export default {
  '/profile/search': [{ label: 'Поиск участника' }],
  '/organization/search': [{ label: 'Поиск организации' }],
  '/infrastructure/search': [{ label: 'Поиск объекта инфраструктуры' }],
  '/infrastructure/add': [MANAGEMENT, { label: 'Создание футбольного объекта' }],
  '/infrastructure/edit': [MANAGEMENT, { label: 'Редактирование футбольного объекта' }],
  '/organization/add': [MANAGEMENT, { label: 'Создание организации' }],
  '/organization/edit': [MANAGEMENT, { label: 'Редактирование организации' }],
  '/profile/add': [MANAGEMENT, { label: 'Создание участника' }],
  '/profile/edit': [MANAGEMENT, { label: 'Редактирование участника' }],
  '/legal/add': [MANAGEMENT, { label: 'Создание юридического лица' }],
  '/profile/': [{ label: 'Поиск участника', path: '/profile/search' }, { label: 'Профиль участника' }],
  '/organization/': [{ label: 'Поиск организации', path: '/organization/search' }, { label: 'Профиль организации' }],
  '/legal/': [{ label: 'Поиск организаций', path: '/organization/search' }, { label: 'Профиль юридического лица' }],
  '/infrastructure/': [{ label: 'Поиск объекта инфраструктуры', path: '/infrastructure/search' }, { label: 'Профиль футбольного объекта' }],
  '/filters': [MANAGEMENT, { label: 'Редактирование роли' }],
  '/duplication': [MANAGEMENT, { label: 'Поиск дублей' }],
  '/blocking': [MANAGEMENT, { label: 'История блокировок' }],
  '/requests/outcoming': [APPLICATIONS, { label: 'Переход футболистов', path: '/requests' }, { label: 'Просмотр заявления' }],
  '/requests/incoming': [APPLICATIONS, { label: 'Переход футболистов', path: '/requests' }, { label: 'Просмотр заявления' }],
  '/requests': [APPLICATIONS, { label: 'Переход футболистов' }],
  '/transfers/': [MANAGEMENT, { label: 'Заявление на трансфер футболиста' }],
  '/applications/certification/incoming/': [
    APPLICATIONS,
    { label: 'Сертификация футбольных объектов', path: '/applications/certification' },
    { label: 'Просмотр заявления' },
  ],
  '/applications/certification/outgoing/': [
    APPLICATIONS,
    { label: 'Сертификация футбольных объектов', path: '/applications/certification' },
    { label: 'Просмотр заявления' },
  ],
  '/applications/certification': [APPLICATIONS, { label: 'Сертификация футбольных объектов', path: '/applications/certification' }],
  '/trainingGroup/add': [MANAGEMENT, { label: 'Создание группы подготовки' }],
  '/trainingGroup/edit': [MANAGEMENT, { label: 'Редактирование группы подготовки' }],
  '/trainingGroup/': [MANAGEMENT, { label: 'Профиль группы подготовки' }],
  '/exercise/add': [MANAGEMENT, { label: 'Реестр упражнений', path: '/exercise/list' }, { label: 'Создание упражнения' }],
  '/exercise/edit': [MANAGEMENT, { label: 'Редактирование упражнения' }],
  '/exercise/list': [MANAGEMENT, { label: 'Реестр упражнений' }],
  '/exercise/': [MANAGEMENT, { label: 'Профиль упражнения' }],
  [ROUTES_WAYS.ACCOUNT_CREATE]: [
    { label: 'Поиск участника', path: ROUTES_WAYS.SEARCH_PROFILE },
    { label: 'Профиль участника', path: ROUTES_WAYS.PROFILE_BY_ID },
    { label: 'Создание учетной записи' },
  ],
  [ROUTES_WAYS.ACCOUNT_EDIT]: [
    { label: 'Поиск участника', path: ROUTES_WAYS.SEARCH_PROFILE },
    { label: 'Профиль участника', path: ROUTES_WAYS.PROFILE_BY_ID },
    { label: 'Редактирование учетной записи' },
  ],
  [ROUTES_WAYS.PRINT_TEMPLATES]: [MANAGEMENT, { label: 'Шаблоны печатных форм' }],
  [ROUTES_WAYS.PRINT_TEMPLATES_BY_ID]: [
    MANAGEMENT,
    { label: 'Шаблоны печатных форм', path: ROUTES_WAYS.PRINT_TEMPLATES },
    { label: 'Просмотр шаблона' },
  ],
  [ROUTES_WAYS.PAY_CONSTRUCTOR]: [MANAGEMENT, { label: 'Конструктор выплат' }],
  [ROUTES_WAYS.CONTRACT_REGISTRATION]: [
    { label: 'Поиск участника', path: ROUTES_WAYS.SEARCH_PROFILE },
    { label: 'Профиль участника', path: ROUTES_WAYS.PROFILE_BY_ID },
    { label: 'Регистрация контракта' },
  ],
  [ROUTES_WAYS.CONTRACTS]: [MANAGEMENT, { label: 'Реестр контрактов' }],
  [ROUTES_WAYS.CONTRACT_BY_ID]: [MANAGEMENT, { label: 'Реестр контрактов', path: ROUTES_WAYS.CONTRACTS }, { label: 'Инструкция' }],
  [ROUTES_WAYS.CONTRACT_PAYMENTS]: [
    MANAGEMENT,
    { label: 'Реестр контрактов', path: ROUTES_WAYS.CONTRACTS },
    { label: 'Инструкция', path: ROUTES_WAYS.CONTRACT_BY_ID },
    { label: 'Выплаты' },
  ],
  [ROUTES_WAYS.LABOR_CONTRACT_BY_ID]: [
    MANAGEMENT,
    { label: 'Реестр контрактов', path: ROUTES_WAYS.CONTRACTS },
    { label: 'Инструкция', path: ROUTES_WAYS.CONTRACT_BY_ID },
    { label: 'Трудовой договор' },
  ],
  [ROUTES_WAYS.LABOR_CONTRACT_EDIT]: [
    MANAGEMENT,
    { label: 'Реестр контрактов', path: ROUTES_WAYS.CONTRACTS },
    { label: 'Инструкция', path: ROUTES_WAYS.CONTRACT_BY_ID },
    { label: 'Трудовой договор', path: ROUTES_WAYS.LABOR_CONTRACT_BY_ID },
    { label: 'Редактирование' },
  ],
  [ROUTES_WAYS.RETURN_FROM_RENT_BY_ID]: [
    MANAGEMENT,
    { label: 'Реестр контрактов', path: ROUTES_WAYS.CONTRACTS },
    { label: 'Инструкция', path: ROUTES_WAYS.CONTRACT_BY_ID },
    { label: 'Соглашение о досрочном возврате из аренды' },
  ],
  [ROUTES_WAYS.RETURN_FROM_RENT_EDIT]: [
    MANAGEMENT,
    { label: 'Реестр контрактов', path: ROUTES_WAYS.CONTRACTS },
    { label: 'Инструкция', path: ROUTES_WAYS.CONTRACT_BY_ID },
    { label: 'Соглашение о досрочном возврате из аренды', path: ROUTES_WAYS.RETURN_FROM_RENT_BY_ID },
    { label: 'Редактирование' },
  ],
  [ROUTES_WAYS.TRANSFER_AGREEMENT_BY_ID]: [
    MANAGEMENT,
    { label: 'Реестр контрактов', path: ROUTES_WAYS.CONTRACTS },
    { label: 'Инструкция', path: ROUTES_WAYS.CONTRACT_BY_ID },
    { label: 'Трансферное соглашение' },
  ],
  [ROUTES_WAYS.TRANSFER_AGREEMENT_EDIT]: [
    MANAGEMENT,
    { label: 'Реестр контрактов', path: ROUTES_WAYS.CONTRACTS },
    { label: 'Инструкция', path: ROUTES_WAYS.CONTRACT_BY_ID },
    { label: 'Трансферное соглашение', path: ROUTES_WAYS.TRANSFER_AGREEMENT_BY_ID },
    { label: 'Редактирование' },
  ],
  [ROUTES_WAYS.TERMINATION_AGREEMENT_BY_ID]: [
    MANAGEMENT,
    { label: 'Реестр контрактов', path: ROUTES_WAYS.CONTRACTS },
    { label: 'Инструкция', path: ROUTES_WAYS.CONTRACT_BY_ID },
    { label: 'Соглашение о расторжении ТД' },
  ],
  [ROUTES_WAYS.TERMINATION_AGREEMENT_EDIT]: [
    MANAGEMENT,
    { label: 'Реестр контрактов', path: ROUTES_WAYS.CONTRACTS },
    { label: 'Инструкция', path: ROUTES_WAYS.CONTRACT_BY_ID },
    { label: 'Соглашение о расторжении ТД', path: ROUTES_WAYS.TERMINATION_AGREEMENT_BY_ID },
    { label: 'Редактирование' },
  ],
  [ROUTES_WAYS.RELEASES]: [
    MANAGEMENT,
    { label: 'Обновления системы' },
  ],
};
