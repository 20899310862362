import { string, number } from 'prop-types';

/**
 * @description Условие перевода
 */
export const PTTransferConditionView = {
  /**
   * @description Описание
   */
  description: string,
  /**
   * @description Идентификатор
   */
  id: number,
  /**
   * @description Наименование
   */
  name: string.isRequired,
  /**
   * @description Системеное наименование
   */
  sysName: string,
  /**
   * @description Идентификатор с фронта
   */
  tempId: string,
};
