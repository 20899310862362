import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';
import { handleError, handleSuccess } from 'magic/handlers';
import { getOrganizationInfo } from 'components/pages/Organization/View/reducer';

const createAction = actionsFactory('organization/contacts/');

export const cleanData = createAction('cleanData');
export const setData = createAction('setData');
export const setLoading = createAction('setLoading');

export const getContactsGroup = (orgId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await axios.get(`/api/rest/contacts/${orgId}`);
    dispatch(setData(res.data.data));
  } catch (err) {
    handleError(err);
    throw err;
  } finally {
    dispatch(setLoading(false));
  }
};

export const postContactsGroup = (body, orgId) => async (dispatch) => {
  try {
    const params = { orgId };

    if (body.id) {
      await axios.put(`/api/rest/contacts/edit/${body.id}`, body, { params });
    } else {
      await axios.post('/api/rest/contacts/save', body, { params });
    }
    handleSuccess(`Группа контактов успешно ${body.id ? 'обновлена' : 'сохранена'}!`);
    dispatch(getContactsGroup(orgId));
    dispatch(getOrganizationInfo(orgId));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const deleteContactsGroup = (id, orgId) => async (dispatch) => {
  try {
    const params = { orgId };
    await axios.delete(`/api/rest/contacts/${id}`, { params });
    handleSuccess('Группа контактов успешно удалена!', 'trash');
    await dispatch(getContactsGroup(orgId));
  } catch (err) {
    handleError(err);
  }
};
