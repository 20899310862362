import { createSelector } from 'reselect';

const selectPrintTemplatesCurrentState = (state) => state.printTemplates.current;
const selectPrintTemplatesViewState = (state) => state.printTemplates.view;
const selectFreeTemplatesState = (state) => state.printTemplates.free;

export const selectPrintTemplatesData = createSelector(selectPrintTemplatesViewState, (state) => state.data);
export const selectPrintTemplatesLoading = createSelector(selectPrintTemplatesViewState, (state) => state.isLoading);

export const selectCurrentPrintTemplate = createSelector(selectPrintTemplatesCurrentState, (state) => state.data);

export const selectFreeTemplatesData = createSelector(selectFreeTemplatesState, (state) => state.data);
export const selectFreeTemplatesPagination = createSelector(selectFreeTemplatesState, (state) => state.pagination);
