/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import { CollapsibleElement } from 'components/rfs-ui';
import { H, Icon } from 'components/ui';

import css from './index.scss';

const cn = classnames.bind(css);

class DropdownH extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    closeOnClickOutside: PropTypes.bool,
    containerClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    defaultOpen: PropTypes.bool,
    iconPosition: PropTypes.string,
    title: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    className: undefined,
    closeOnClickOutside: false,
    containerClassName: undefined,
    contentClassName: undefined,
    defaultOpen: false,
    iconPosition: undefined,
    title: null,
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment
    showContent: this.props.defaultOpen,
  };

  handleClickOutside = () => {
    this.setState({ showContent: false });
    document.removeEventListener('click', this.handleClickOutside);
  };

  toggleVisibility = () => {
    const { closeOnClickOutside } = this.props;
    this.setState((state) => ({ showContent: !state.showContent }));

    if (closeOnClickOutside) {
      document.addEventListener('click', this.handleClickOutside);
    }
  };

  render() {
    const { title, children, contentClassName, containerClassName, iconPosition, ...headerProps } = this.props;
    const { showContent } = this.state;

    return (
      <div className={cn(css.container, containerClassName)}>
        {/* eslint-disable-next-line */}
        <div onClick={this.toggleVisibility} className={css.header}>
          {iconPosition === 'left' && (
            <Icon icon="angle-down" size="1x" pack="fal" className={cn(css.arrow, { left: iconPosition, reverse: showContent })} />
          )}
          <H {...headerProps}>
            <span>{title}</span>
            {!iconPosition && <Icon icon="angle-down" size="1x" pack="fal" className={cn(css.arrow, { reverse: showContent })} />}
          </H>
          {iconPosition === 'right' && (
            <Icon icon="angle-down" size="1x" pack="fal" className={cn(css.arrow, { reverse: showContent, right: iconPosition })} />
          )}
        </div>
        <CollapsibleElement collapsed={!showContent}>
          <div className={contentClassName}>{showContent ? children : null}</div>
        </CollapsibleElement>
      </div>
    );
  }
}

export default DropdownH;
