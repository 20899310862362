import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId, isEmpty, map, reduce, toArray } from 'utils/lodash';

import { Button, Label } from 'components/ui';

import css from './index.scss';

class List extends React.Component {
  static propTypes = {
    valueKey: PropTypes.string,
    buttonTitle: PropTypes.string,
    listTitle: PropTypes.string,
    placeholder: PropTypes.string,
    maxCount: PropTypes.number,
    onChange: PropTypes.func,
  };

  state = {
    fieldList: {},
  };

  static getDerivedStateFromProps (props, state) {
    if (props.children && isEmpty(state.fieldList)) {
      const fieldList = reduce(props.children, (newList, field) => ({ ...newList, [uniqueId('listItem_')]: field }), {});
      return { fieldList };
    }

    return null;
  }

  handleChange = listKey => itemKey => (value) => {
    const fieldList = { ...this.state.fieldList };
    const valueIsEmpty = !value || (typeof value === 'object' && isEmpty(value));

    if (valueIsEmpty) {
      delete fieldList[listKey][itemKey];
    } else {
      fieldList[listKey] = itemKey ?
        { ...fieldList[listKey], [itemKey]: value } :
        { ...fieldList[listKey], ...value };
    }

    this.setState({ fieldList });
    this.props.onChange(toArray(fieldList));
  }

  handleAddField = () => {
    const fieldList = { ...this.state.fieldList, [uniqueId('listItem_')]: {} };

    this.setState({ fieldList });
    this.props.onChange(toArray(fieldList));
  }

  handleDeleteField = fieldKey => () => {
    const fieldList = reduce(this.state.fieldList, (items, item, key) => fieldKey !== key ? { ...items, [key]: item } : items, {});

    this.setState({ fieldList });
    this.props.onChange(toArray(fieldList));
  }

  render () {
    const { fieldList } = this.state;
    const {
      buttonTitle = 'Добавить',
      listTitle,
      maxCount,
      extraProps = {},
      deleteTitle = '',
      ItemTemplate,
      className,
    } = this.props;
    const showAddItemButton = !maxCount || Object.keys(fieldList).length < maxCount;

    return (
      <div className={className}>
        {
          !isEmpty(fieldList) &&
          <div className={css.listContainer}>
            {
              listTitle &&
              <Label className={css.listTitle}>{listTitle}</Label>
            }
            {
              map(fieldList, (field, key) => (
                <div className={css.fieldContainer} key={key}>
                  <ItemTemplate
                    {...field}
                    {...extraProps}
                    onChange={this.handleChange(key)}
                  />
                  <Button
                    type="text"
                    icon="trash"
                    onClick={this.handleDeleteField(key)}
                    className={css.trashButton}
                  >
                    {deleteTitle}
                  </Button>
                </div>
              ))
            }
          </div>
        }
        {
          showAddItemButton &&
          <div className={css.buttonContainer}>
            <Button type="text" icon="plus-circle" onClick={this.handleAddField}>{buttonTitle}</Button>
          </div>
        }
      </div>

    );
  }
}

export default List;
