import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { purgePersonalData, getPersonalDataAll } from 'redux/modules/personalData/reducer';
import { initSession } from 'redux/modules/session/session';
import { personalDataAgreementSelector, metricDataAgreementSelector } from 'redux/modules/personalData/selectors';

import App from './App.container';

const mapStateToProps = (state) => ({
  personalDataAgreement: personalDataAgreementSelector(state),
  metricDataAgreement: metricDataAgreementSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      initSession,
    },
    dispatch,
  ),
  getDictionariesActions: bindActionCreators(
    {
      getPersonalDataAll,
    },
    dispatch,
  ),
  purgeDictionariesActions: bindActionCreators(
    {
      purgePersonalData,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
