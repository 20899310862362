import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import { push } from 'connected-react-router';
import { handleError } from 'magic/handlers';
import axios from 'axios';
import { uploadFile } from 'utils/uploadFiles';

const createAction = actionsFactory('document/add/');

const initialState = {
  creating: false,
  loading: false,
  documentTypes: [],
};

// actions
export const setCreating = createAction('setCreating');
export const setLoading = createAction('setLoading');
export const setDocumentType = createAction('setDocumentType');

export const createDocument = (data, backUrl) => async (dispatch) => {
  try {
    dispatch(setCreating(true));
    const { files, ...body } = data;

    if (files.length) {
      const filesToUpload = [];
      const oldFiles = [];

      files.forEach((fileData) => {
        if (fileData instanceof File) {
          filesToUpload.push(uploadFile(fileData, undefined));
        } else {
          oldFiles.push(fileData);
        }
      });

      const uploadedFiles = await Promise.all(filesToUpload);
      const [file, ...oversFiles] = [...oldFiles, ...uploadedFiles];
      body.file = file;
      body.oversFiles = oversFiles;
    }

    const res = await axios.post('/api/rest/organization/legal/document', body);

    dispatch(setCreating(false));
    dispatch(push(backUrl || `/document/${res.data.data}`));
  } catch (e) {
    dispatch(setCreating(false));
    handleError(e);
  }
};

export const getTypes = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await axios.get('/api/rest/document/types');
    dispatch(setDocumentType(response.data.data));
    dispatch(setLoading(false));
  } catch (e) {
    dispatch(setLoading(false));
    handleError(e);
  }
};

const reducer = createReducer({
  [setCreating]: (state, creating) => ({
    ...state,
    creating,
  }),
  [setLoading]: (state, loading) => ({
    ...state,
    loading,
  }),
  [setDocumentType]: (state, documentTypes) => ({
    ...state,
    documentTypes,
  }),
}, initialState);

export default reducer;
