exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------ \nCommon\n--------------------------*/\n\n\n/* layouts width*/\n\n\n/* ------------------------ \nDark Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* input*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nLight Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nRating\n--------------------------*/\n\n\n.item-8VnhI {\n  position: relative;\n}\n\n\n.item-8VnhI:not(:last-child) {\n    margin-right: 20px;\n  }\n\n\n.item-8VnhI:not(:last-child):after {\n      content: '';\n      height: 20px;\n      width: 1px;\n      background-color: #fff;\n      position: absolute;\n      right: -10px;\n    }\n\n\n.title-1fPCg {\n  text-transform: uppercase;\n  font-weight: 600;\n}\n\n\n.active-2fcHU {\n  color: #0a81ff !important;\n}\n\n\n.theme-dark .item-8VnhI:not(:last-child):after {\n        background-color: #fff;\n      }\n\n\n.theme-light .item-8VnhI:not(:last-child):after {\n        background-color: #b9b9b9;\n      }\n", ""]);

// exports
exports.locals = {
	"item": "item-8VnhI",
	"title": "title-1fPCg",
	"active": "active-2fcHU"
};