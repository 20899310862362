import { createReducer } from 'redux-act';
import {
  clearData,
  setArticles,
  setArticlesLoading,
  setCreateArticleLoading,
  setOneArticle,
  setTreeData,
  setTreeLoading,
  setCreateSanctionLoading,
  setSanctions,
  setSanction,
  setRemoveSanctionLoading,
  setParts,
  setRemovePartsLoading,
  setCreatePartsLoading,
  setPart,
  setArticle,
} from 'redux/actions/Regulation/editActions';

const initState = {
  articles: {
    createLoading: false,
    data: [],
    loading: false,
    removeLoading: false,
  },
  parts: {
    createLoading: false,
    data: [],
    removeLoading: false,
  },
  regulation: {},
  sanctions: {
    createLoading: false,
    data: [],
    removeLoading: false,
  },
  treeLoading: false,
};

const editReducer = createReducer(
  {
    [clearData]: () => initState,
    [setArticle]: (state, articleId) => ({
      ...state,
      articles: {
        data: state.articles.data.filter((item) => item.id !== articleId),
        removeLoading: false,
      },
    }),
    [setArticles]: (state, data) => ({
      ...state,
      articles: {
        ...state.articles,
        data: data.articles,
        loading: false,
      },
      regulation: data.regulation,
    }),
    [setArticlesLoading]: (state, loading) => ({
      ...state,
      articles: {
        ...state.articles,
        loading,
      },
    }),
    [setCreateArticleLoading]: (state, createLoading) => ({
      ...state,
      articles: {
        ...state.articles,
        createLoading,
      },
    }),
    [setCreatePartsLoading]: (state, createLoading) => ({
      ...state,
      parts: {
        ...state.parts,
        createLoading,
      },
    }),
    [setCreateSanctionLoading]: (state, createLoading) => ({
      ...state,
      sanctions: {
        ...state.sanctions,
        createLoading,
      },
    }),
    [setOneArticle]: (state, data) => ({
      ...state,
      articles: {
        ...state.articles,
        createLoading: false,
        data: state.articles.data.map((item) => item.id).includes(data.id)
          ? state.articles.data.map((item) => (item.id === data.id ? { ...data } : { ...item }))
          : [...state.articles.data, data],
      },
    }),
    [setPart]: (state, partId) => ({
      ...state,
      parts: {
        ...state.parts,
        data: state.parts.data.filter((item) => item.id !== partId),
        removeLoading: false,
      },
    }),
    [setParts]: (state, part) => ({
      ...state,
      parts: {
        ...state.parts,
        createLoading: false,
        data: state.parts.data.map((item) => item.id).includes(part.id)
          ? state.parts.data?.map((item) => (item.id === part.id ? { ...part, sanctions: item.sanctions || [] } : { ...item }))
          : [...state.parts.data, part],
      },
    }),
    [setRemovePartsLoading]: (state, removeLoading) => ({
      ...state,
      parts: {
        ...state.parts,
        removeLoading,
      },
    }),
    [setRemoveSanctionLoading]: (state, removeLoading) => ({
      ...state,
      sanctions: {
        ...state.sanctions,
        removeLoading,
      },
    }),
    [setSanction]: (state, sanctionId) => ({
      ...state,
      parts: {
        ...state.parts,
        data: state.parts.data.flatMap((part) => ({ ...part, sanctions: part.sanctions.filter((item) => item.id !== sanctionId) })),
        removeLoading: false,
      },
      sanctions: {
        data: state.sanctions.data.filter(({ id }) => id !== sanctionId),
        removeLoading: false,
      },
    }),
    [setSanctions]: (state, { sanction, part }) => ({
      ...state,
      parts: {
        ...state.parts,
        data: part
          ? state.parts.data.map((statePart) =>
              statePart.id === part.id
                ? {
                    ...statePart,
                    sanctions: (statePart.sanctions || []).map((item) => item.id).includes(sanction.id)
                      ? (statePart.sanctions || []).map((item) => (item.id === sanction.id ? { ...sanction } : { ...item }))
                      : [...(statePart.sanctions || []), sanction],
                  }
                : { ...statePart },
            )
          : [...state.parts.data],
      },
      sanctions: {
        ...state.sanctions,
        createLoading: false,
        // eslint-disable-next-line no-nested-ternary
        data: part
          ? [...state.sanctions.data]
          : state.sanctions.data.map((item) => item.id).includes(sanction.id)
          ? state.sanctions.data.map((item) => (item.id === sanction.id ? { ...sanction } : { ...item }))
          : [...state.sanctions.data, sanction],
      },
    }),
    [setTreeData]: (state, data) => ({
      ...state,
      parts: {
        ...state.parts,
        data: data.parts,
      },
      sanctions: {
        ...state.sanctions,
        data: data.sanctions,
      },
      treeLoading: false,
    }),
    [setTreeLoading]: (state, treeLoading) => ({
      ...state,
      treeLoading,
    }),
  },
  initState,
);

export default editReducer;
