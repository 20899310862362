import actionsFactory from 'magic/actionsFactory';
import { push } from 'connected-react-router';
import { isEmpty, cloneDeep } from 'utils/lodash';
import axios from 'axios';

import { DEFAULT_PAGINATION } from 'constants/pagination';
import dadataEndpoints from 'constants/dadataEndpoints';
import { DADATA_TOKEN } from 'constants/tokens';
import { handleError, handleSuccess } from 'magic/handlers';
import { legalRestructure, legalRestructureNew } from 'utils/dadata';
import { uploadFilesMultiple } from 'utils/uploadFiles';
import { ROUTES_WAYS } from 'constants/routesWays';
import { selectLegalData } from 'redux/selectors/Legal/viewSelectors';
import { Redirect } from 'react-router-dom';
import { DEFAULT_ACTION_TYPES } from 'components/pages/Legal/View/LocalLegalView/History/consts';

const createAction = actionsFactory('legal/view/');

export const setLegalData = createAction('setLegalData');
export const setConstitutors = createAction('setConstitutors');
export const setDocuments = createAction('setDocuments');
export const setLegalInfrastructuresItems = createAction('setLegalInfrastructuresItems');
export const setEmployees = createAction('setEmployees');
export const updateEmployeeFilters = createAction('updateEmployeeFilters');
export const setEmployeeFilters = createAction('setEmployeeFilters');
export const setLoading = createAction('setLoading');
export const startEmployeesLoading = createAction('setEmployeesLoading');
export const startInfrastructuresLoading = createAction('setInfrastructuresLoading');
export const stopEmployeesLoadingWithError = createAction('stopEmployeesLoadingWithError');
export const stopInfrastructuresLoadingWithError = createAction('stopInfrastructuresLoadingWithError');
export const setEmployeesPagination = createAction('setEmployeesPagination');
export const setInfrastructuresPagination = createAction('setInfrastructuresPagination');
export const setLegalHistoryPagination = createAction('setLegalHistoryPagination');
export const setBranches = createAction('setBranches');
export const clearData = createAction('clearData');
export const toggleLegalFavorite = createAction('toggleLegalFavorite');
export const setLegalHistory = createAction('setLegalHistory');
export const setLegalHistoryPageData = createAction('setLegalHistoryPageData');

export const getLegalInfrastructures = (pagePagination = {}) => async (dispatch, getState) => {
  try {
    const { infrastructures, data } = getState().legal.view;
    const { pagination, loading } = infrastructures;

    if (!loading) {
      dispatch(startInfrastructuresLoading());
    }

    const { id: legalId } = data;
    const defaultPerPage = isEmpty(pagePagination) ? DEFAULT_PAGINATION.perPage : pagination.perPage;
    const { page = DEFAULT_PAGINATION.page, perPage = defaultPerPage } = pagePagination;
    const res = await axios.get(`/api/rest/infrastructureobject/objects/${legalId}?page=${page}&pagesize=${perPage}`);
    const { data: items, pageData = {} } = res.data;
    const { countPage = 1, size = items.length, numberPage = page, sizePage = perPage } = pageData;

    dispatch(setLegalInfrastructuresItems(items));
    dispatch(
      setInfrastructuresPagination({
        itemsCount: size,
        page: numberPage,
        pageCount: countPage,
        perPage: sizePage,
      }),
    );
  } catch (e) {
    handleError(e);
    dispatch(stopInfrastructuresLoadingWithError());
  }
};

export const getLegalById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/rest/organization/legal/${id}`);
    dispatch(setLegalData(res.data.data));
  } catch (e) {
    handleError(e);
    if ([500, 404].includes(e.response.status)) {
      dispatch(push('/organization/search'));
    }
    throw e;
  }
};

export const getBindConstitutors = (legalId, typeBindName) => async (dispatch) => {
  try {
    const res = await axios.get('/api/rest/organization/legal/bindconstitutors', { params: { legalId, pagesize: 20, typeBindName } });
    dispatch(setConstitutors({ [typeBindName]: res.data.data }));
  } catch (e) {
    handleError(e);
  }
};

export const getDocuments = (legalId) => async (dispatch) => {
  try {
    const res = await axios.post('/api/rest/organization/legal/document/list', { legalId, sysName: 'dogovor_affiliations' });
    dispatch(setDocuments(res.data.data));
  } catch (e) {
    handleError(e);
  }
};

export const getLegalDetails = (id) => (dispatch) =>
  dispatch(getLegalById(id)).then(() => {
    Promise.all([
      dispatch(getBindConstitutors(id, 'natives')),
      dispatch(getBindConstitutors(id, 'common')),
      dispatch(getBindConstitutors(id, 'current')),
      dispatch(getDocuments(id)),
    ]).then(() => {
      dispatch(setLoading(false));
    });
  });

export const updateLegalByDadata = () => async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    const data = cloneDeep(getState().legal.view.data);
    const { id, inn, ogrn } = data;

    if (!inn && !ogrn) {
      const err = new Error();
      err.text = 'Для актуализации данных необходимы ИНН или ОГРН';
      throw err;
    }

    const response = await axios({
      data: JSON.stringify({
        query: inn || ogrn,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: DADATA_TOKEN,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      url: '/api/rest/organization/legal/states',
    });

    const dadata = legalRestructureNew(response.data.data[0]);
    await axios.post('/api/rest/organization/legal', { ...data, ...dadata });
    await dispatch(getLegalDetails(id));
    handleSuccess('Данные юр.лица актуализированы', 'calendar-check');
  } catch (e) {
    handleError(e);
    dispatch(setLoading(false));
  }
};

export const createConstitutor = (body, typeBindName) => async (dispatch) => {
  try {
    dispatch(setConstitutors({ creating: true }));
    await axios.post('/api/rest/organization/legal/constitutor', body);
    await dispatch(getBindConstitutors(body.legalId, typeBindName));
    dispatch(setConstitutors({ creating: false }));
  } catch (e) {
    dispatch(setConstitutors({ creating: false }));
    handleError(e);
    throw e;
  }
};

export const deleteConstitutor = (legalId, constitutorId, typeBindName) => async (dispatch) => {
  try {
    dispatch(setConstitutors({ deleting: true }));
    await axios.delete(`/api/rest/organization/legal/constitutor/${constitutorId}`);
    await dispatch(getBindConstitutors(legalId, typeBindName));
    dispatch(setConstitutors({ deleting: false }));
  } catch (e) {
    dispatch(setConstitutors({ deleting: false }));
    handleError(e);
  }
};

export const deleteDocument = (legalId, documentId) => async (dispatch) => {
  try {
    dispatch(setConstitutors({ deleting: true }));
    await axios.delete(`/api/rest/organization/legal/document/${documentId}`);
    await dispatch(getDocuments(legalId));
    dispatch(setConstitutors({ deleting: false }));
  } catch (e) {
    dispatch(setConstitutors({ deleting: false }));
    handleError(e);
  }
};

export const getEmployees = (pagePagination = {}) => async (dispatch, getState) => {
  dispatch(startEmployeesLoading());
  try {
    const {
      data: { id },
      employees: { pagination, filters = {} },
    } = getState().legal.view;
    const defaultPerPage = isEmpty(pagePagination) ? DEFAULT_PAGINATION.perPage : pagination.perPage;
    const { page = DEFAULT_PAGINATION.page, perPage = defaultPerPage } = pagePagination;
    const res = await axios.get('/api/rest/organization/legal/staff', {
      params: {
        page,
        pagesize: perPage,
        ...filters,
        legalId: id,
      },
    });
    const { data, pageData = {} } = res.data;
    const { countPage = 1, size = data.length, numberPage = page, pageSize = perPage } = pageData;
    dispatch(setEmployees(data));
    dispatch(
      setEmployeesPagination({
        itemsCount: size,
        page: numberPage,
        pageCount: countPage,
        perPage: pageSize,
      }),
    );
  } catch (e) {
    handleError(e);
    dispatch(stopEmployeesLoadingWithError());
  }
};

export const getBranches = (legalId, pagination = DEFAULT_PAGINATION) => async (dispatch) => {
  try {
    const { page, perPage } = pagination;
    const params = { page, pagesize: perPage, parentLegalId: legalId };

    const {
      data: { data, pageData = {} },
    } = await axios.get('/api/rest/organization/legal/branch/list', { params });

    const newPagination = {
      itemsCount: pageData.size,
      page,
      pageCount: pageData.countPage,
      perPage,
    };

    dispatch(setBranches({ data, pagination: newPagination }));
  } catch (err) {
    handleError(err);
    dispatch(setBranches([]));
  }
};

export const createBranch = (data, shouldRedirect) => async (dispatch) => {
  try {
    const body = { ...data };
    body.documents = await uploadFilesMultiple(body.documents);

    const {
      data: { data: branch },
    } = await axios.post('/api/rest/organization/legal/branch', body);

    handleSuccess('Филиал успешно сохранен!');

    if (shouldRedirect) {
      dispatch(push(ROUTES_WAYS.LEGAL_BY_ID.replace(':id', branch.legal.id)));
    }
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const deleteBranch = (branchId, legalId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/organization/legal/branch/${branchId}`);
    await dispatch(getBranches(legalId));
    handleSuccess('Филиал юр. лица успешно удален!', 'trash');
  } catch (err) {
    handleError(err);
  }
};

export const postRequisite = (body, legalId) => async (dispatch) => {
  try {
    const params = { legalId };

    if (body.id) {
      await axios.put(`/api/rest/organization/legal/bank/details/${body.id}`, body, { params });
    } else {
      await axios.post('/api/rest/organization/legal/bank/details', body, { params });
    }
    handleSuccess(`Банковские реквизиты успешно ${body.id ? 'обновлены' : 'сохранены'}!`);
    dispatch(getLegalById(legalId));
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const deleteRequisite = (id, legalId) => async (dispatch) => {
  try {
    const params = { legalId };
    await axios.delete(`/api/rest/organization/legal/bank/details/${id}`, { params });
    handleSuccess('Банковские реквизиты успешно удалены!', 'trash');
    await dispatch(getLegalById(legalId));
  } catch (err) {
    handleError(err);
  }
};

export const getLegalHistory = (legalId, pagination, sorting, filters) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/rest/audit/v2/history/legal?&objectId=${legalId}&page=${pagination?.page}&perPage=${pagination?.perPage}&sections=${
        filters?.sections?.length ? filters.sections : DEFAULT_ACTION_TYPES
      }${sorting.desc ? `&sortFieldsDesc=${sorting.desc}` : ''}${sorting.asc ? `&sortFieldAsc=${sorting.asc}` : ''}${
        filters.date.dateFrom ? `&dateFrom=${filters.date.dateFrom}` : ''
      }${filters.date.dateTo ? `&dateTo=${filters.date.dateTo}` : ''}`,
    );

    dispatch(setLegalHistory(res.data.data));
    dispatch(setLegalHistoryPageData(res.data.pageData));
  } catch (err) {
    handleError(err);
    dispatch(setLegalHistory([]));
  }
};

export const deleteLegal = (legalId) => async (dispatch) => {
  try {

    await axios.delete(`/api/rest/organization/legal/${legalId}`);
    handleSuccess('Запись успешно удалена!', 'trash');

    dispatch(push(ROUTES_WAYS.SEARCH_ORGANIZATION));
  } catch (err) {
    handleError(err);
  }
};

export const updateForeignLegalData = (body, legalId, type) => async (dispatch) => {
  try {
    await axios.post(`/api/rest/organization/legal`, body);

    handleSuccess(type === 'contacts' ? 'Контакты успешно изменены!' : type === 'info' ? 'Данные юридического лица успешно изменены!' : 'Успешно!');

    dispatch(getLegalById(legalId));
  } catch (err) {
    handleError(err);
  }
};
