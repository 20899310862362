import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError } from 'magic/handlers';

import { DEFAULT_PAGINATION } from 'constants/pagination';
import { notificationSuccess } from 'magic/notification';

const createAction = actionsFactory('contracts/list/');

export const clearData = createAction('clearData');
export const setContractsData = createAction('setContractsData');
export const setContractsFilters = createAction('setContractsFilters');
export const setContractsLoading = createAction('setContractsLoading');
export const setContractsPagination = createAction('setContractsPagination');
export const setResendInProgress = createAction('setResendInProgress');
export const setHasResendData = createAction('setHasResendData');

export const getFilteredData = async (dispatch, filters, sortDirection, pagination = DEFAULT_PAGINATION) => {
  const { page, perPage } = pagination;
  const params = { page, perPage, sortDirection, ...filters };

  const {
    data: { data, pageData, isFailedSendingToFifa },
  } = await axios.get('/api/rest/contract/dtms/instruction/list', { params });

  dispatch(setHasResendData(isFailedSendingToFifa));

  dispatch(setContractsData(data));
  dispatch(
    setContractsPagination({
      itemsCount: pageData.size,
      page,
      pageCount: pageData.countPage,
      perPage,
    }),
  );
};

export const getContractsData = (filters, sortDirection, pagination = DEFAULT_PAGINATION) => async (dispatch) => {
  try {
    dispatch(setContractsLoading(true));
    getFilteredData(dispatch, filters, sortDirection, pagination);
  } catch (err) {
    handleError(err);
  } finally {
    dispatch(setContractsLoading(false));
  }
};

const poll = (fn, validate, interval = 1000) => {
  const executePoll = async (resolve, reject) => {
    const result = await fn();

    if (validate(result)) {
      resolve(result);
    } else {
      setTimeout(executePoll, interval, resolve, reject);
    }
  };

  return new Promise(executePoll);
};

const startPolling = (dispatch, filters, pagination) => {
  const pullFn = async () => {
    const { data } = await axios.get('/api/rest/contract/dtms/instruction/resend-to-fifa/running', {});
    return !data.data;
  };

  const checkFinish = (value) => {
    if (value) {
      dispatch(getContractsData(dispatch, filters, pagination));
      notificationSuccess('Данные посланы в FIFA', 'check');
      dispatch(setResendInProgress(false));
    }
    return value;
  };

  poll(pullFn, checkFinish);
};

export const resendDataToFIFA = (filters, sortDirection, pagination) => async (dispatch) => {
  try {
    dispatch(setResendInProgress(true));

    await axios.post('/api/rest/contract/dtms/instruction/resend-to-fifa', {});

    startPolling(dispatch, filters, sortDirection, pagination);
  } catch (err) {
    handleError(err);
    dispatch(setResendInProgress(false));
  }
};
