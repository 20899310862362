exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".imageContainer-3MBzN {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n\n  .imageContainer-3MBzN > div {\n    margin-right: 20px;\n    margin-bottom: 20px;\n  }\n\n  .imageContainer-3MBzN > div:last-child {\n      margin-right: 0;\n    }\n\n.dropzone-1B1rA {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-flow: column;\n          flex-flow: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin-bottom: 40px;\n  padding: 40px;\n  width: 100%;\n  height: 100%;\n  /* border: 1px dotted rgba(255, 255, 255, 0.5);*/\n  border-radius: 4px;\n}\n\n.hint-kleF1 {\n  opacity: 0.5;\n  text-align: center;\n}\n\n.hint-kleF1:not(:last-of-type) {\n    margin-bottom: 20px;\n  }\n\n.button-72Cbj {\n  margin-bottom: 20px;\n  width: 100%;\n  max-width: 300px;\n  margin-right: 0 !important;\n}\n\n.preloader-2Rhm1 {\n  margin-bottom: 20px;\n}\n", ""]);

// exports
exports.locals = {
	"imageContainer": "imageContainer-3MBzN",
	"dropzone": "dropzone-1B1rA",
	"hint": "hint-kleF1",
	"button": "button-72Cbj",
	"preloader": "preloader-2Rhm1"
};