import actionsFactory from 'magic/actionsFactory';
import { DEFAULT_PAGINATION } from 'constants/pagination';
import axios from 'axios';
import { handleError } from 'magic/handlers';

const createAction = actionsFactory('organization/decisions/');

export const cleanData = createAction('cleanData');
export const setDecisions = createAction('setDecisions');
export const setDecisionsLoading = createAction('setDecisionsLoading');

export const getDecisions = (pagePagination = DEFAULT_PAGINATION, status, id, isPerson) => async (dispatch, getState) => {
  try {
    const { data: storeData } = getState().organization.view;

    if (storeData.id || id) {
      dispatch(setDecisionsLoading(true));
      const { page, perPage } = pagePagination;

      const params = {
        clubId: isPerson ? undefined : storeData.id || id,
        page,
        pagesize: perPage,
        personId: isPerson ? storeData.id || id : undefined,
        status: status?.name,
      };

      const {
        data: { data, pageData = {} },
      } = await axios.get('/api/rest/judiciary/decision/by-relation', { params });
      const newPagination = {
        itemsCount: pageData.size,
        page,
        pageCount: pageData.countPage,
        perPage,
      };
      dispatch(setDecisions({ data, pagination: newPagination }));
    }
  } catch (e) {
    handleError(e);
  }
};
