import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import { Icon, Preloader } from 'components/ui';

import css from './Multiselect.scss';

const cn = classnames.bind(css);

const ValueItem = ({ label, value, index, onRemove, className, reverse, loadingValues, disabled }) => {
  const handleRemove = useCallback(onRemove(index), [onRemove, index]);
  const isLoading = useMemo(() => loadingValues.includes(value), [value, loadingValues]);
  return (
    <div className={cn(css.tagContainer, className, { reverse })}>
      <div className={css.tag}>{label}</div>
      {!disabled && (
        <React.Fragment>
          {isLoading ? (
            <Preloader size="1x" className={css.loadingTag} />
          ) : (
            <Icon icon="times" size="2x" pack="fal" onClick={handleRemove} className={css.deleteTag} />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

ValueItem.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  index: PropTypes.number,
  label: PropTypes.string,
  loadingValues: PropTypes.array,
  onRemove: PropTypes.func,
  reverse: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ValueItem.defaultProps = {
  className: undefined,
  disabled: false,
  index: undefined,
  label: null,
  loadingValues: [],
  onRemove: () => {},
  reverse: false,
  value: null,
};

export default React.memo(ValueItem);
