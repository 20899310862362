import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';
import { handleError, handleSuccess } from 'magic/handlers';

const createAction = actionsFactory('regulation/view/');

export const clearData = createAction('clearData');
export const setArticles = createAction('setArticles');
export const setArticlesLoading = createAction('setArticlesLoading');
export const setCreateArticleLoading = createAction('setCreateArticleLoading');
export const setRemoveArticleLoading = createAction('setRemoveArticleLoading');
export const setOneArticle = createAction('setOneArticle');
export const setArticle = createAction('setArticle');

export const setTreeLoading = createAction('setTreeLoading');
export const setTreeData = createAction('setTreeData');

export const setCreateSanctionLoading = createAction('setCreateSanctionLoading');
export const setRemoveSanctionLoading = createAction('setRemoveSanctionLoading');
export const setSanction = createAction('setSanction');
export const setSanctions = createAction('setSanctions');

export const setParts = createAction('setParts');
export const setPart = createAction('setPart');
export const setRemovePartsLoading = createAction('setRemovePartsLoading');
export const setCreatePartsLoading = createAction('setCreatePartsLoading');

export const getArticles = (regId) => async (dispatch) => {
  try {
    dispatch(setArticlesLoading(true));

    const {
      data: { data },
    } = await axios.get(`/api/rest/judiciary/regulation/${regId}/articles`);
    dispatch(setArticles(data));
  } catch (err) {
    handleError(err);
    dispatch(setArticlesLoading(false));
  }
};

export const createArticle = (payload, editMode, successCallback) => async (dispatch) => {
  try {
    dispatch(setCreateArticleLoading(true));

    const {
      data: { data },
    } = await axios.post(`/api/rest/judiciary/${payload.regulation?.id}/article/save`, payload);
    dispatch(setOneArticle(data));
    handleSuccess(`Статья успешно ${editMode ? 'изменена' : 'создана'}!`);
    if (successCallback) successCallback();
  } catch (err) {
    handleError(err);
    dispatch(setCreateArticleLoading(false));
  }
};

export const createSanction = (payload, editMode, successCallback) => async (dispatch) => {
  try {
    dispatch(setCreateSanctionLoading(true));

    const {
      data: { data },
    } = await axios.post('/api/rest/judiciary/sanction/save', payload);
    dispatch(setSanctions({ part: payload.part, sanction: data }));
    handleSuccess(`Санкция успешно ${editMode ? 'изменена' : 'создана'}!`);
    if (successCallback) successCallback();
  } catch (err) {
    handleError(err);
    dispatch(setCreateSanctionLoading(false));
  }
};

export const createPart = (payload, editMode, successCallback) => async (dispatch) => {
  try {
    dispatch(setCreatePartsLoading(true));

    const {
      data: { data },
    } = await axios.post('/api/rest/judiciary/part/save', payload);
    dispatch(setParts(data));
    handleSuccess(`Часть успешно ${editMode ? 'изменена' : 'создана'}!`);
    if (successCallback) successCallback();
  } catch (err) {
    handleError(err);
    dispatch(setCreatePartsLoading(false));
  }
};

export const getSanctionsAndParts = (articleId) => async (dispatch) => {
  try {
    dispatch(setTreeLoading(true));

    const {
      data: { data },
    } = await axios.get(`/api/rest/judiciary/article/${articleId}/tree`);
    dispatch(setTreeData(data));
  } catch (err) {
    handleError(err);
    dispatch(setTreeLoading(false));
  }
};

export const removeSanction = (sanctionId) => async (dispatch) => {
  try {
    dispatch(setRemoveSanctionLoading(true));

    await axios.delete(`/api/rest/judiciary/sanction/${sanctionId}`);
    dispatch(setSanction(sanctionId));
    handleSuccess('Санкция успешно удалена!');
  } catch (err) {
    handleError(err);
    dispatch(setRemoveSanctionLoading(false));
  }
};

export const removePart = (partId) => async (dispatch) => {
  try {
    dispatch(setRemovePartsLoading(true));

    await axios.delete(`/api/rest/judiciary/part/${partId}`);
    dispatch(setPart(partId));
    handleSuccess('Часть успешно удалена!');
  } catch (err) {
    handleError(err);
    dispatch(setRemovePartsLoading(false));
  }
};

export const removeArticle = (articleId) => async (dispatch) => {
  try {
    dispatch(setRemoveArticleLoading(true));

    await axios.delete(`/api/rest/judiciary/article/${articleId}`);
    dispatch(setArticle(articleId));
    handleSuccess('Статья успешно удалена!');
  } catch (err) {
    handleError(err);
    dispatch(setRemoveArticleLoading(false));
  }
};
