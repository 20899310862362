export const MeetingEditTabsNames = {
  agenda: 'agenda',
  cases: 'cases',
  common: 'common',
  protocol: 'protocol',
};

export const MeetingEditTabs = [
  { section: MeetingEditTabsNames.common, title: 'ОБЩАЯ ИНФОРМАЦИЯ' },
  { section: MeetingEditTabsNames.cases, title: 'ДЕЛА' },
  { section: MeetingEditTabsNames.agenda, title: 'Повестка' },
  { section: MeetingEditTabsNames.protocol, title: 'Протокол' },
];

export const MeetingCasesEditTabs = [
  { section: 'cases', title: 'Выбор дел' },
  { section: 'composition-cases', title: 'Состав дел' },
];

export const MeetingAgendaEditTabs = [
  { section: 'agenda', title: 'Повестка' },
  { section: 'agenda-mailing', title: 'Рассылка повестки' },
];

export const ProtocolEditTabs = [
  { section: 'members', title: 'Участники заседания' },
  { section: 'decisions', title: 'Решения' },
  { section: 'mailing', title: 'Рассылка протокола' },
];
