import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { selectPhotoGalleryData } from 'redux/selectors/Profile/photoGallerySelectors';

const createAction = actionsFactory('profile/photoGallery/');

export const clearData = createAction('clearData');
export const setPhotoLoading = createAction('setPhotoLoading');
export const setPhotoData = createAction('setPhotoData');

export const getPhotoList = (personId) => async (dispatch) => {
  try {
    dispatch(setPhotoLoading(true));
    const response = await axios.get(`/api/rest/person/${personId}/photo/gallery`);

    const photos = response.data.data.sort((photoA, photoB) => {
      if (!photoA.mainPhoto === !photoB.mainPhoto) {
        return 0;
      }

      return photoA.mainPhoto ? -1 : 1;
    });

    dispatch(setPhotoData(photos));
  } catch (error) {
    handleError(error);
    dispatch(setPhotoData([]));
  } finally {
    dispatch(setPhotoLoading(false));
  }
};

export const deletePhoto = (fileId, personId) => async (dispatch, getState) => {
  try {
    await axios.delete(`/api/rest/person/${personId}/photo/${fileId}`);

    const photos = selectPhotoGalleryData(getState());
    dispatch(setPhotoData(photos.filter((photo) => photo.storageId !== fileId)));
    handleSuccess('Фото успешно удалено!', 'trash');
  } catch (error) {
    handleError(error);
  }
};

export const setMainPhoto = (fileId, personId) => async (dispatch, getState) => {
  try {
    await axios.post(`/api/rest/person/${personId}/photo/${fileId}/main`, null);

    const photos = selectPhotoGalleryData(getState());
    dispatch(setPhotoData(photos.map((photo) => ({ ...photo, mainPhoto: fileId === photo.storageId }))));
    handleSuccess('Фото успешно установлено как основное!');
  } catch (error) {
    handleError(error);
  }
};
