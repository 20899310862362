import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import { push } from 'connected-react-router';
import { handleError } from 'magic/handlers';
import axios from 'axios';

const createAction = actionsFactory('document/view/');

const initialState = {
  loading: true,
  document: {},
};

// actions
export const setLoading = createAction('setLoading');
export const setDocument = createAction('setDocument');
export const clearData = createAction('clearData');

export const getDocument = id => async (dispatch) => {
  try {
    const res = await axios.get(`/api/rest/organization/legal/document/${id}`);
    const { file, oversFiles = [], ...document } = res.data.data || {};
    document.files = file ? [file, ...oversFiles] : oversFiles;
    dispatch(setDocument(document));
    dispatch(setLoading(false));
  } catch (e) {
    handleError(e);
    if (e.response.status === 404) {
      dispatch(push('/management'));
    }

    dispatch(setLoading(false));
  }
};

const reducer = createReducer({
  [setLoading]: (state, loading) => ({
    ...state,
    loading,
  }),
  [setDocument]: (state, document) => ({
    ...state,
    document,
  }),
  [clearData]: () => initialState,
}, initialState);

export default reducer;
