import React, { useCallback, useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import Navbar from 'components/composed/Navbar/Navbar';

import Links from '../Links/Links';
import css from './MobileMenu.scss';

const addClass = (el, className) => el && el.classList.add(className);
const removeClass = (el, className) => el && el.classList.remove(className);

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [mobileMenuRoot] = useState(() => {
    const el = document.createElement('div');
    el.id = 'mobile-menu';
    return el;
  });

  const mobileMenuRef = useRef();
  const burgerRef = useRef();

  const toggleIsOpen = useCallback(() => {
    if (isOpen) {
      removeClass(mobileMenuRef.current, css.open);
      removeClass(burgerRef.current, css.open);
      removeClass(document.body, 'no-scroll');
    } else {
      addClass(mobileMenuRef.current, css.open);
      addClass(burgerRef.current, css.open);
      addClass(document.body, 'no-scroll');
    }
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    document.body.appendChild(mobileMenuRoot);

    return () => {
      mobileMenuRoot.remove();
      removeClass(document.body, 'no-scroll');
    };
  }, [mobileMenuRoot]);

  return (
    <React.Fragment>
      <button className={css.burgerBtn} type="button" onClick={toggleIsOpen}>
        <div className={css.burger} ref={burgerRef}>
          <span />
          <span />
          <span />
          <span />
        </div>
      </button>
      {createPortal(
        <div className={css.menuContainer} ref={mobileMenuRef}>
          <div className={css.navigationMenu}>
            <div className={css.mainMenu}>
              <Links />
            </div>
            <div className={css.navBarMenu}>
              <Navbar light />
            </div>
          </div>
        </div>,
        mobileMenuRoot,
      )}
    </React.Fragment>
  );
};

export default MobileMenu;
