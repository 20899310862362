import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { appConfig } from 'constants/appConfig';
import { initYandexMetrics } from './script';

const config = {
  accurateTrackBounce: true,
  clickmap: true,
  defer: true,
  trackLinks: true,
  webvisor: true,
};

const YandexListener = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/login' && appConfig.YANDEX_KEY) {
        initYandexMetrics(appConfig.YANDEX_KEY, config);
    } else {
      if (window.ym) {
        window.ym(appConfig.YANDEX_KEY, 'destruct')
        const metrikaScripts = document.querySelectorAll('script[src="https://mc.yandex.ru/metrika/tag.js"]');
        metrikaScripts.forEach(function(script) {
          script.parentNode.removeChild(script);
        });
      }
    }
  }, [pathname, appConfig.YANDEX_KEY]);

  useEffect(() => {
    if (pathname === '/login' && appConfig.YANDEX_KEY && !!window.ym) {
      setTimeout(() => {
        window.ym(appConfig.YANDEX_KEY, 'hit', pathname);
      }, 1000)
    }
  }, [pathname, appConfig.YANDEX_KEY, !!window.ym]);

  return null;
};

export default YandexListener;
