import { number, shape, string, arrayOf, oneOf } from 'prop-types';

import { PTAddress } from './addressPropTypes';
import { PTAccount } from './accountPropTypes';
import { PTCountry } from './countryPropTypes';
import { PTFileInfo } from './fileInfoPropTypes';
import { PTGender } from './genderPropTypes';
import { PTProfessionalActivity } from './professionalActivityPropTypes';
import { PTSkill } from './skillPropTypes';
import { PTTelephone } from './telephonePropTypes';
import { PTInsurance } from './insurancePropTypes';

/**
 * @description Физ лицо
 */
export const PTPerson = {
  account: shape(PTAccount),
  /**
   * @description Дата рождения
   */
  birthDate: number,

  birthplace: shape(PTAddress),
  citizenship: shape(PTCountry),
  /**
   * @description лицензия тренера
   */
  coachLicense: string,

  documents: arrayOf(shape(PTFileInfo)),
  /**
   * @description mail
   */
  email: string,
  /**
   * @description ФИО тренера
   */
  fio: string,
  /**
   * @description Имя
   */
  firstName: string,
  gender: shape(PTGender),
  /**
   * @description рост, см
   */
  height: number,
  /**
   * @description Идентификатор
   */
  id: number,
  image: shape(PTFileInfo),
  insurances: arrayOf(shape(PTInsurance)),
  /**
   * @description Фамилия
   */
  lastName: string,
  /**
   * @description Отчество
   */
  middleName: string,
  /**
   * @description примечание
   */
  note: string,
  /**
   * @description Статус
   */
  personStatus: oneOf(['ACTIVE', 'DUPLICATE', 'DELETE']),
  positions: arrayOf(shape(PTProfessionalActivity)),
  /**
   * @description размер обуви
   */
  shoeSize: number,

  skills: arrayOf(shape(PTSkill)),
  spotCitizenship: shape(PTCountry),
  telephones: arrayOf(shape(PTTelephone)),
  /**
   * @description Идентификатор с фронта
   */
  tempId: string,
  /**
   * @description вес, кг
   */
  weight: number,
};
