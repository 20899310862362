import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import { push } from 'connected-react-router';
import axios from 'axios';
import { handleError } from 'magic/handlers';

const createAction = actionsFactory('coachGroup/');

const initialState = {
  coaches: [],
  controlpersons: [],
  organization: {},
  players: [],
  season: null,
  shortName: '',
  stage: null,
  stageYear: '',
  yearBirth: [],
  loading: true,
};

// Добавление всяких данных
export const setData = createAction('setData');

// Добавление тренеров
export const setCoaches = createAction('setCoaches');

// Добавление футболистов
export const setPlayers = createAction('setPlayers');

// Очистка всего стора
export const clearData = createAction('clearData');

// Включаем прелоадер
export const startLoading = createAction('startLoading');

// Отключаем прелоадер
export const stopLoadingWithError = createAction('stopLoadingWithError');

// Получение данных группы по его id
export const getDataById = id => async (dispatch) => {
  dispatch(startLoading());

  try {
    const response = await axios.get(`/api/rest/coachgroup/${id}`);
    const { data } = response.data;

    dispatch(setData({
      groupPlayerIds: data.players.map(player => player.id),
      ...data,
    }));
  } catch (e) {
    handleError(e);
    dispatch(push('/management'));
  }
};

// Получение данных группы в следующем этапе подготовки по id
export const getNextStageData = groupId => async (dispatch) => {
  dispatch(startLoading());

  try {
    const response = await axios.get(`/api/rest/coachgroup/next?groupId=${groupId}`);
    // убираем id, чтобы создалась новая группа
    const { fullName, ...restData } = response.data.data;
    dispatch(setData(restData));
  } catch (e) {
    handleError(e);
    dispatch(push(`/trainingGroup/${groupId}`));
  }
};

// Сохранение группы
export const saveGroup = () => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    const { groupPlayerIds, loading, status, ...data } = getState().trainingGroup.edit;
    const response = await axios.post('/api/rest/coachgroup/', data);
    dispatch(push(`/trainingGroup/${response.data.data.id}`));
  } catch (e) {
    handleError(e);
    dispatch(stopLoadingWithError());
  }
};

const reducer = createReducer({
  [startLoading]: state => ({
    ...state,
    loading: true,
  }),
  [stopLoadingWithError]: state => ({
    ...state,
    loading: false,
  }),
  [setData]: (state, payload) => ({
    ...state,
    ...payload,
    loading: false,
  }),
  [setPlayers]: (state, players) => ({
    ...state,
    players,
  }),
  [setCoaches]: (state, coaches) => ({
    ...state,
    coaches,
  }),
  // Очистка всего стора
  [clearData]: () => initialState,
}, initialState);

export default reducer;
