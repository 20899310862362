import { string, number } from 'prop-types';

export const PTFileInfo = {
  contentType: string,
  dataLoad: number,
  fileName: string,
  hash: string,
  id: number,
  size: number,
  storageId: string,
  storageType: string,
};
