import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { handleError } from 'magic/handlers';

import Select from './Select';

const DELAY = 300;

class Async extends React.PureComponent {
  static propTypes = {
    callback: PropTypes.func,
    getPaginatedOptions: PropTypes.func,
    getOptions: PropTypes.func,
    method: PropTypes.string,
    onChange: PropTypes.func,
    optionsPath: PropTypes.string,
    params: PropTypes.object,
    requestValueKey: PropTypes.string,
    value: PropTypes.object,
    searchOnParamsChange: PropTypes.bool,
    clearOptionsOnBlur: PropTypes.bool,
  };

  static defaultProps = {
    callback: undefined,
    getPaginatedOptions: undefined,
    getOptions: () => {},
    method: 'get',
    onChange: undefined,
    optionsPath: undefined,
    params: {},
    requestValueKey: 'searchText',
    value: undefined,
    searchOnParamsChange: false,
    clearOptionsOnBlur: true,
  };

  state = {
    selected: '',
    fetchedOptions: [],
  };

  // Обнуляем выбранное значение в стейте, если пришло пустое value
  static getDerivedStateFromProps(props, state) {
    return !props.value && state.selected && { selected: '' };
  }

  componentDidMount() {
    if (this.props.optionsPath && this.props.searchOnParamsChange) {
      this.getOptionsAsync();
    }
  }

  componentDidUpdate(prevProps) {
    const { optionsPath } = this.props;
    if (optionsPath && prevProps.optionsPath !== optionsPath && this.props.searchOnParamsChange) {
      this.getOptionsAsync();
    }

  }

  /**
   * Получаем опшены асинхронной подгрузкой через DELAY мс после keyPress
   */
  getOptionsAsync = async (value) => {
    if (this.promiseTimer) {
      clearTimeout(this.promiseTimer);
      this.promiseTimer = undefined;
    }

    return new Promise((resolve) => {
      this.promiseTimer = setTimeout(async () => {
        const { method, params, callback, optionsPath, requestValueKey, getOptions } = this.props;

        try {
          const requestParams = { ...params, [requestValueKey]: value };
          let response;
          if (method.toUpperCase() === 'POST') {
            response = await axios.post(optionsPath, requestParams);
          } else {
            response = await axios({
              data: {},
              method,
              params: requestParams,
              url: optionsPath,
            });
          }
          this.promiseTimer = undefined;
          const result = callback ? callback(response.data.data) : response.data.data;
          this.setState({ fetchedOptions: result });
          getOptions(result);
          resolve(result);
        } catch (e) {
          handleError(e);
          this.promiseTimer = undefined;
          this.setState({ fetchedOptions: [] });
          resolve([]);
        }
      }, DELAY);
    });
  };

  handleChange = (e) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(e);
    }

    if (e !== this.state.selected) {
      this.setState({ selected: e });
    }
  };

  render() {
    const { selected, fetchedOptions } = this.state;
    const { method, params, optionsPath, value, getPaginatedOptions, clearOptionsOnBlur, ...restProps } = this.props;
    const selectedValue = value || selected;

    if (typeof getPaginatedOptions === 'function') {
      return (
        <Select
          defaultOptions={fetchedOptions}
          {...restProps}
          value={selectedValue}
          onChange={this.handleChange}
          loadOptions={getPaginatedOptions}
          onBlur={() => clearOptionsOnBlur ?this.setState({ fetchedOptions: [] }) :  () => {}}
          async
          paginate
        />
      );
    }

    return (
      <Select
        defaultOptions={fetchedOptions}
        {...restProps}
        value={selectedValue}
        onBlur={() => clearOptionsOnBlur ?this.setState({ fetchedOptions: [] }) :  () => {}}
        onChange={this.handleChange}
        loadOptions={this.getOptionsAsync}
        async
      />
    );
  }
}

export default Async;
