import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/ui';

import Async from '../Async';
import styles from './styles';
import css from './AsyncSearch.scss';
import Reference from '../Reference';

const AsyncSearch = ({ async, button, ...restProps }) => (
  <div className={css.searchbar}>
    {async ? <Async {...restProps} styles={styles} /> : <Reference {...restProps} styles={styles} />}
    <Icon icon="search" className={css.searchIcon} />
    {button && React.cloneElement(button, { className: css.searchButton })}
  </div>
);

AsyncSearch.propTypes = {
  async: PropTypes.bool,
  button: PropTypes.node.isRequired,
};

AsyncSearch.defaultProps = {
  async: true,
};

export default AsyncSearch;
