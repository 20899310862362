export const CONTRACT_STATUSES = {
  ACTIVE: 'ACTIVE',
  FINISH: 'FINISH',
  PLANNED: 'PLANNED',
  SUSPEND: 'SUSPEND',
};

export const CONTRACT_TYPES = {
  CLUB: 'CLUB_PLAYER',
  RENT: 'REND_PLAYER',
  TRANSFER: 'TRANSFER_PLAYER',
};

export const CONDITIONS = {
  END_CURRENT_ADD_NEW: 'WITH_END_OF_CURRENT_CONTRACT_AND_NEW_CONTRACT',
  WITHOUT_NEW_CONTRACT: 'WITHOUT_NEW_CONTRACT',
};
