import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Icon from 'components/ui/Icon/Icon';
import Label from 'components/ui/Label/Label';

import Input from 'components/ui/Input/Input';
import styles from './Toggle.scss';

const cn = classNames.bind(styles);

export default class Toggle extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    className: PropTypes.string,
    checked: PropTypes.bool,
    labelOn: PropTypes.string,
    labelOff: PropTypes.string,
    onChange: PropTypes.func,
    iconOn: PropTypes.string,
    iconOff: PropTypes.string,
    pack: PropTypes.string,
    disabled: PropTypes.bool,
    trackClassName: PropTypes.string,
  };

  handleChange = () => {
    const { onChange, checked, disabled } = this.props;

    if (onChange && !disabled) {
      onChange(!checked);
    }
  }

  renderToggleIcon = () => {
    const {
      checked,
      iconOn, // иконка тоггла в состоянии "вкл"
      iconOff, // иконка тоггла в состоянии "выкл"
      pack, // стиль иконки (fas, far, fal - по умолчанию)
    } = this.props;

    if (checked) {
      return iconOn ? <Icon icon={iconOn} size="xs" pack={pack} /> : false;
    }
    return iconOff ? <Icon icon={iconOff} size="xs" pack={pack} /> : false;
  }

  render() {
    const {
      id, // id для связи чекбокса с лейблами
      className, // внешний класс для стилизации компонента
      checked, // состояние чекбокса
      disabled,
      labelOn, // лейбл состояние "вкл"
      labelOff, // лейбл состояния "выкл"
      trackClassName,
    } = this.props;

    // проверяет, что оба лейбла переданы
    const hasLabels = labelOn && labelOff;

    const containerClass = cn(styles.container, { [className]: !!className });
    const labelOffClass = cn(styles.label, { active: !checked });
    const labelOnClass = cn(styles.label, { active: checked });
    const toggleWrapperClass = cn(styles.toggleWrapper, { checked, margins: hasLabels }, { [trackClassName]: !!trackClassName }, { disabled });

    return (
      <div className={`${containerClass} toggle-container-themed`}>
        {
          hasLabels &&
          <Label className={`${labelOffClass} toggle-label-themed ${!checked ? 'toggle-active-label-themed' : ''}`} htmlFor={id}>{labelOff}</Label>
        }
        <div className={toggleWrapperClass} onClick={this.handleChange}>
          <Input
            id={id}
            type="checkbox"
            className={styles.toggle}
            checked={checked}
            onChange={this.handleChange}
          />
          <span className={styles.togglebox}>
            {/* {this.renderToggleIcon()} */}
          </span>
        </div>
        {
          hasLabels &&
          <Label className={`${labelOnClass} toggle-label-themed ${checked ? 'toggle-active-label-themed' : ''}`} htmlFor={id}>{labelOn}</Label>
        }
      </div>
    );
  }
}
