import React from 'react';
import { shape, arrayOf, oneOfType, object, array, string, number, bool, func } from 'prop-types';
import ReactSelect from 'react-select';
import Async from 'react-select/lib/Async';
import AsyncPaginate from 'react-select-async-paginate';

import { ThemeContext } from 'components/composed/ThemeContext';
import DropdownIndicator from './Components/DropdownIndicator';
import ClearIndicator from './Components/ClearIndicator';
import NoOptionsMessage from './Components/NoOptionsMessage';
import LoadingIndicator from './Components/LoadingIndicator';

import styles from './styles';

export default class Select extends React.PureComponent {
  static contextType = ThemeContext;

  static propTypes = {
    async: bool,
    className: string,
    clearable: bool,
    components: shape(),
    customStyles: shape(),
    dark: bool,
    disabled: bool,
    isLoading: bool,
    isMulti: bool,
    labelKey: oneOfType([string, func, array]),
    light: bool,
    loadOptions: func,
    noBorder: bool,
    noOptionsMessage: string,
    onChange: func.isRequired,
    onlyValue: bool,
    options: arrayOf(shape()),
    paginate: bool,
    placeholder: string,
    required: bool,
    setDefaultValue: func,
    value: oneOfType([string, array, object, number]),
    valueKey: string,
  };

  static defaultProps = {
    async: undefined,
    className: undefined,
    clearable: undefined,
    components: undefined,
    customStyles: undefined,
    dark: undefined,
    disabled: undefined,
    isLoading: undefined,
    isMulti: undefined,
    labelKey: undefined,
    light: undefined,
    loadOptions: undefined,
    noBorder: undefined,
    noOptionsMessage: undefined,
    onlyValue: undefined,
    options: undefined,
    paginate: false,
    placeholder: undefined,
    required: undefined,
    setDefaultValue: undefined,
    value: undefined,
    valueKey: undefined,
  };

  componentDidMount() {
    const { setDefaultValue, value, options = [] } = this.props;

    if (!value && setDefaultValue) {
      const defaultValue = options.find(setDefaultValue);

      if (defaultValue) {
        this.handleChange(defaultValue);
      }
    }
  }

  getOptionObjectByValue = () => {
    const { value = null, options = [], valueKey = 'value', isMulti, onlyValue, async } = this.props;

    if (!value) {
      return null;
    }

    if (isMulti) {
      return options.filter((option) => value.some((selectedValue) => selectedValue === option[valueKey]));
    }

    if (onlyValue) {
      return async ? { [valueKey]: value } : options.find((option) => option[valueKey] === value);
    }

    return value;
  };

  makeOptionLabel = (option) => {
    const { labelKey } = this.props;

    switch (typeof labelKey) {
      case 'object': {
        if (Array.isArray(labelKey)) {
          const foundKey = labelKey.find((key) => key in option);
          if (foundKey in option) {
            return option[foundKey];
          }
        }

        return option.label;
      }

      case 'string': {
        return option[labelKey];
      }

      case 'function': {
        return labelKey(option);
      }

      default:
        return option.label;
    }
  };

  makeOptionValue = (option) => {
    const { valueKey } = this.props;
    return valueKey ? option[valueKey] : option.label;
  };

  handleChange = (option) => {
    // debugger;
    const { onlyValue, onChange, valueKey = 'value', isMulti, value } = this.props;
    // если значение пустое, то option = [], поэтому сделаем его null
    const selected = Array.isArray(option) && !option.length ? null : option;

    if (selected !== value) {
      if (onlyValue && selected) {
        onChange(isMulti ? selected.map((_option) => _option[valueKey]) : selected[valueKey]);
      } else {
        onChange(selected);
      }
    }
  };

  render() {
    const {
      options,
      disabled,
      isLoading,
      value = null,
      className,
      dark,
      placeholder = '',
      onlyValue,
      required,
      clearable,
      async,
      loadOptions,
      light,
      components,
      noOptionsMessage = 'Ничего не найдено',
      onChange,
      customStyles,
      paginate,
      ...restProps
    } = this.props;

    const commonProps = {
      className,
      components: {
        ClearIndicator,
        DropdownIndicator,
        LoadingIndicator,
        NoOptionsMessage,
        ...components,
      },
      customStyles,
      dark,
      getOptionLabel: this.makeOptionLabel,
      getOptionValue: this.makeOptionValue,
      hideSelectedOptions: false,
      isClearable: clearable,
      isDisabled: disabled,
      isLoading,
      isRequired: required,
      light,
      maxMenuHeight: 200,
      noOptionsMessage: () => noOptionsMessage,
      onChange: this.handleChange,
      placeholder,
      styles,
      value: onlyValue ? this.getOptionObjectByValue() : value,
    };

    const { theme } = this.context;

    if (async) {
      if (paginate) {
        return (
          <AsyncPaginate
            loadOptions={loadOptions}
            debounceTimeout={300}
            captureMenuScroll
            themeType={theme}
            {...commonProps}
            {...restProps}
          />
        );
      }

      return <Async loadOptions={loadOptions} themeType={theme} {...commonProps} {...restProps} />;
    }

    return <ReactSelect options={options} themeType={theme} {...commonProps} {...restProps} menuIsOpen={disabled ? false : undefined} />;
  }
}
