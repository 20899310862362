import axios from 'axios';
import { handleError, handleSuccess } from 'magic/handlers';
import { setRatingAmpluaLoading, setRatingData, setRatingDeleting, setRatingError, setRatingLoading } from './actions';

const fetchRating = async ({ personId, matchId, ampluaId }) => {
  try {
    const params = { ampluaId, matchId, personId };

    const {
      data: { data },
    } = await axios.get('/api/rest/rating/get', { params });

    return data;
  } catch (err) {
    handleError(err);

    throw err;
  }
};

export const getRating = (params) => async (dispatch) => {
  try {
    dispatch(setRatingLoading(true));
    dispatch(setRatingError(false));

    const data = await fetchRating(params);
    dispatch(setRatingData(data));

    return data;
  } catch (err) {
    dispatch(setRatingData(null));
    dispatch(setRatingError(true));

    return null;
  } finally {
    dispatch(setRatingLoading(false));
  }
};

export const getAccess = (matchId, personId) => async (dispatch) => {
  try {
    dispatch(setRatingAmpluaLoading(true));
    dispatch(setRatingLoading(true));
    const { data } = await axios.get(`/api/rest/integration/competition/allow/rating/${matchId}/${personId}`);
    return data;
  } catch (err) {
    handleError(err);
    return null;
  }
};

export const saveRating = (body) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.post('/api/rest/rating/save', body);
    dispatch(setRatingData(data));
    handleSuccess('Оценка успешно сохранена!');

    return data;
  } catch (err) {
    handleError(err);

    return null;
  }
};

export const deleteRating = (matchId, personId) => async (dispatch) => {
  try {
    dispatch(setRatingDeleting(true));
    await axios.delete('/api/rest/rating/delete', { params: { matchId, personId } });
    handleSuccess('Оценка успешно удалена!', 'trash');

    return true;
  } catch (err) {
    handleError(err);

    return false;
  } finally {
    dispatch(setRatingDeleting(false));
  }
};
