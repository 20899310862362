import { shape, number, oneOf, string } from 'prop-types';
import { PERSONAL_DATA_TYPES } from 'constants/personalDataTypes';

export const PTFileInfo = {
  contentType: string.isRequired,
  dataLoad: number.isRequired,
  fileName: string.isRequired,
  id: number,
  size: number,
  storageId: string.isRequired,
  storageType: string,
};

export const PTPersonalData = {
  fileInfo: shape(PTFileInfo),
  id: number.isRequired,
  personalDataFileType: oneOf(Object.values(PERSONAL_DATA_TYPES)).isRequired,
};
