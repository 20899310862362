import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import cn from 'classnames';

import { Icon } from 'components/ui';

import { notificationError } from 'magic/notification';
import { DEFAULT_APPLICATION_TYPES } from 'constants/applicationTypes';
import { makeFileUrl } from 'utils/createUrl';
import { uniqueId } from 'utils/lodash';

import css from './DocumentUpload.scss';

const MAX_SIZE = 20 * 1024 * 1024;
const MAX_COUNT = 5;

const DocumentUpload = ({ documents, onChange, maxCount, maxSize, light, disabled, placeholder, accept, collapsed, className }) => {
  const availableFilesCount = maxCount - documents.length;
  const isDropzoneDisabled = disabled || availableFilesCount <= 0;

  const dropzoneStyles = useMemo(
    () =>
      cn({
        [css.dropzone]: true,
        [css.disabled]: isDropzoneDisabled,
        [css[className]]: !!(className),
      }),
    [isDropzoneDisabled, className],
  );

  const containerStyles = useMemo(
    () =>
      cn({
        [css.light]: light,
        [css.collapsed]: collapsed,
      }),
    [light, collapsed],
  );

  const handleAddFile = useCallback(
    (accepted, rejected) => {
      const addedFiles = accepted.slice(0, availableFilesCount).map((file) => {
        // eslint-disable-next-line no-param-reassign
        file.uuid = uniqueId('file_');
        return file;
      });

      if (rejected.length) {
        rejected.forEach((file) => notificationError(`Файл ${file.name} имеет недопустимый тип или размер.`));
      }

      if (addedFiles.length > accepted.length) {
        notificationError(`Максимальное число файлов для загрузки ${maxCount}.`);
      }

      onChange([...documents, ...addedFiles]);
    },
    [documents, availableFilesCount, onChange, maxCount],
  );

  const handleRemoveFile = useCallback(
    (e) => {
      const index = Number(e.currentTarget.getAttribute('data-index'));
      onChange(documents.filter((_, idx) => idx !== index));
    },
    [onChange, documents],
  );

  const isPlaceholderVisible = !collapsed || !documents.length;

  return (
    <div className={containerStyles}>
      <div className={css.filesContainer}>
        <Dropzone
          className={dropzoneStyles}
          accept={accept}
          disabled={isDropzoneDisabled}
          maxSize={maxSize}
          onDrop={handleAddFile}
          multiple
        >
          {isPlaceholderVisible && <div className={cn(css.label, css[className])}>{placeholder}</div>}
        </Dropzone>
      </div>
      <div className={css.files}>
        {documents.map((doc, idx) => {
          const fileName = doc.fileName || doc.filename || doc.name;

          return (
            <div className={css.docFile} key={doc.id || doc.uuid}>
              {doc.id ? <a href={makeFileUrl(doc)}>{fileName}</a> : <span>{fileName}</span>}
              {!disabled && <Icon data-index={idx} icon="times" onClick={handleRemoveFile} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

DocumentUpload.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  collapsed: PropTypes.bool,
  disabled: PropTypes.bool,
  documents: PropTypes.arrayOf(PropTypes.object),
  light: PropTypes.bool,
  maxCount: PropTypes.number,
  maxSize: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

DocumentUpload.defaultProps = {
  accept: DEFAULT_APPLICATION_TYPES,
  collapsed: false,
  disabled: false,
  documents: [],
  light: false,
  maxCount: MAX_COUNT,
  maxSize: MAX_SIZE,
  placeholder: 'Выберите с диска или перетащите сюда документ в формате word, pdf, jpg, png до 20 мб.',
  className: '',
};

export default DocumentUpload;
