import React, { Fragment } from 'react';
import { func, string, bool, shape, arrayOf } from 'prop-types';
import classnames from 'classnames/bind';

import PermissionConsumer from 'components/composed/Permissions/PermissionsConsumer';
import { LinkButton, Button, H } from 'components/ui';

import css from './index.scss';

const cn = classnames.bind(css);

class Popup extends React.Component {
  static propTypes = {
    buttonClassName: string,
    canHide: bool,
    className: string,
    containerClassName: string,
    content: arrayOf(shape()),
    onChangeState: func,
    render: func,
    renderPopupContent: func,
    triggerButtonProps: shape(),
  };

  static defaultProps = {
    buttonClassName: undefined,
    canHide: true,
    className: undefined,
    containerClassName: undefined,
    content: [],
    onChangeState: () => {},
    render: undefined,
    renderPopupContent: undefined,
    triggerButtonProps: Object.create(null),
  };

  static renderPopupContent = (options) => (popupActions) => {
    const { title, handleClick } = options;
    const { closePopup } = popupActions;

    const acceptClick = () => {
      handleClick();
      closePopup();
    };

    return (
      <Fragment>
        <H className={css.popupContainerHeader} size={4}>
          {title}
        </H>
        <div className={css.popupButtonsContainer}>
          <Button onClick={acceptClick} type="text" className={cn(css.popupButton, css.buttonYes)}>
            Да
          </Button>
          <Button onClick={closePopup} type="text" className={cn(css.popupButton, css.buttonNo)}>
            Нет
          </Button>
        </div>
      </Fragment>
    );
  };

  state = { showPopup: false };

  setPopupNode = (node) => {
    this.popupNode = node;

    if (node) {
      document.addEventListener('click', this.handleClickOutside);
    }
  };

  showPopup = () => {
    const { onChangeState } = this.props;
    this.setState({ showPopup: true }, () => onChangeState(true));
  };

  closePopup = () => {
    const { onChangeState } = this.props;
    document.removeEventListener('click', this.handleClickOutside);
    this.setState({ showPopup: false }, () => onChangeState(false));
  };

  handleClickOutside = (event) => {
    const { canHide = true } = this.props;

    if (canHide && this.popupNode && !this.popupNode.contains(event.target)) {
      this.closePopup();
    }
  };

  handleOpenPopup = () => {
    const { triggerButtonProps } = this.props;
    const { onClick } = triggerButtonProps;

    if (onClick) {
      onClick();
    }

    this.showPopup();
  };

  triggerPopupVisibility = () => this.setState(({ showPopup }) => ({ showPopup: !showPopup }));

  preventDefault = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  renderContent = () => {
    const { content } = this.props;

    if (typeof content === 'function') {
      return content();
    }

    return content.map((item, index) => {
      const { to, className, buttonClassName, title, permissionParams, state, onClick, ...restProps } = item;
      const handleClick = (e) => {
        if (onClick) {
          onClick(e, item);
        }
        this.closePopup();
      };
      const key = item && item.id ? item.id : index;

      const button = to ? (
        <LinkButton
          type="text"
          to={to}
          className={cn(css.popupItem, className)}
          buttonClassName={cn(css.innerButton, buttonClassName)}
          key={key}
          state={state}
          onClick={handleClick}
          {...restProps}
        >
          {title}
        </LinkButton>
      ) : (
        <Button type="text" className={cn(css.popupItem, className)} key={key} onClick={handleClick} {...restProps}>
          {title}
        </Button>
      );

      return permissionParams ? (
        <PermissionConsumer key={key} {...permissionParams}>
          {button}
        </PermissionConsumer>
      ) : (
        button
      );
    });
  };

  render() {
    const { showPopup } = this.state;
    const { triggerButtonProps, containerClassName, className, render, renderPopupContent, buttonClassName } = this.props;
    const { className: triggerButtonClassName, title, icon, ...restProps } = triggerButtonProps;

    return (
      <div className={cn(css.popupContainer, containerClassName)} onClick={this.preventDefault}>
        {render ? (
          render({
            active: showPopup,
            className: buttonClassName,
            closePopup: this.closePopup,
            showPopup: this.showPopup,
            triggerPopupVisibility: this.triggerPopupVisibility,
          })
        ) : (
          <Button
            className={cn(css.triggerButton, triggerButtonClassName, buttonClassName, { active: showPopup })}
            icon={icon}
            onClick={this.handleOpenPopup}
            {...restProps}
          >
            {title || null}
          </Button>
        )}

        {showPopup && (
          <div className={cn(css.popup, className)} ref={this.setPopupNode}>
            {renderPopupContent
              ? renderPopupContent({
                  active: showPopup,
                  closePopup: this.closePopup,
                  showPopup: this.showPopup,
                  triggerPopupVisibility: this.triggerPopupVisibility,
                })
              : this.renderContent()}
          </div>
        )}
      </div>
    );
  }
}

export default Popup;
