import { createReducer } from 'redux-act';
import { clearData, setLicensesData, setLicensesLoading } from 'redux/actions/Organization/LicensesActions';

const initialState = {
  data: [],
  isLoading: false,
};

const licensesReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setLicensesData]: (state, data) => ({
      ...state,
      data,
    }),
    [setLicensesLoading]: (state, isLoading) => ({
      ...state,
      isLoading,
    }),
  },
  initialState,
);

export default licensesReducer;
