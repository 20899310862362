import { createReducer } from 'redux-act';
import { DEFAULT_PAGINATION } from 'constants/pagination';

import {
  clearData,
  setBranches,
  setConstitutors,
  setDocuments,
  setEmployeeFilters,
  setEmployees,
  setEmployeesPagination,
  setInfrastructuresPagination,
  setLegalData,
  setLegalInfrastructuresItems,
  setLoading,
  startEmployeesLoading,
  startInfrastructuresLoading,
  stopEmployeesLoadingWithError,
  stopInfrastructuresLoadingWithError, toggleLegalFavorite,
  updateEmployeeFilters,
  setLegalHistory,
  setLegalHistoryPageData
} from "redux/actions/Legal/viewActions";

const initialState = {
  branches: {
    data: [],
    pagination: DEFAULT_PAGINATION,
  },
  constitutors: {
    common: [],
    creating: false,
    current: [],
    deleting: false,
    natives: [],
  },
  data: {},
  documents: [],
  employees: {
    filters: {},
    items: [],
    loaded: false,
    loading: false,
    pagination: DEFAULT_PAGINATION,
  },
  infrastructures: {
    items: [],
    loaded: false,
    loading: true,
    pagination: DEFAULT_PAGINATION,
  },
  history: {
    items: [],
    loaded: false,
    loading: true,
  },
  loading: true,
};

const viewReducer = createReducer(
  {
    [clearData]: () => initialState,
    [setBranches]: (state, { data, pagination }) => ({
      ...state,
      branches: {
        ...state.branches,
        data,
        ...(pagination && { pagination }),
      },
    }),
    [setConstitutors]: (state, data) => ({
      ...state,
      constitutors: {
        ...state.constitutors,
        ...data,
      },
    }),
    [setDocuments]: (state, documents) => ({
      ...state,
      documents,
    }),
    [setEmployeeFilters]: ({ employees, ...state }, filters) => ({
      ...state,
      employees: {
        ...employees,
        filters: { ...employees.filters, ...filters },
      },
    }),
    [setEmployees]: (state, items) => ({
      ...state,
      employees: {
        ...state.employees,
        items,
        loaded: true,
        loading: false,
      },
    }),
    [setEmployeesPagination]: (state, pagination) => ({
      ...state,
      employees: {
        ...state.employees,
        pagination,
      },
    }),
    [setLegalHistoryPageData]: (state, pageData) => ({
      ...state,
      history: {
        ...state.history,
        pageData,
      },
    }),
    [setInfrastructuresPagination]: (state, pagination) => ({
      ...state,
      infrastructures: {
        ...state.infrastructures,
        pagination,
      },
    }),
    [setLegalData]: (state, data) => ({
      ...state,
      data,
    }),
    [setLegalInfrastructuresItems]: (state, items) => ({
      ...state,
      infrastructures: {
        ...state.infrastructures,
        items,
        loaded: true,
        loading: false,
      },
    }),
    [setLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
    [startEmployeesLoading]: (state) => ({
      ...state,
      employees: {
        ...state.employees,
        items: [],
        loaded: false,
        loading: true,
      },
    }),
    [startInfrastructuresLoading]: (state) => ({
      ...state,
      infrastructures: {
        ...state.infrastructures,
        items: [],
        loaded: false,
        loading: true,
      },
    }),
    [stopEmployeesLoadingWithError]: (state) => ({
      ...state,
      employees: {
        ...state.employees,
        loaded: false,
        loading: false,
      },
    }),
    [stopInfrastructuresLoadingWithError]: (state) => ({
      ...state,
      infrastructures: {
        ...state.infrastructures,
        loaded: false,
        loading: false,
      },
    }),
    [updateEmployeeFilters]: (state, filters) => ({
      ...state,
      employees: {
        ...state.employees,
        filters,
      },
    }),
    [toggleLegalFavorite]: (state, favorite) => ({
      ...state,
      data: {
        ...state.data,
        favorite,
      },
    }),
    [setLegalHistory]: (state, items) => ({
      ...state,
      history: {
        ...state.history,
        items,
        loaded: true,
        loading: false,
      },
    }),
  },
  initialState,
);

export default viewReducer;
