import { createReducer } from 'redux-act';
import { setFederations, setFederationsLoading, setSaveMeetingLoading } from 'redux/actions/Meetings/creationActions';

const initState = {
  federations: [],
  federationsLoading: false,
  saveLoading: false,
};

const creationReducer = createReducer(
  {
    [setFederations]: (state, federations) => ({
      ...state,
      federations,
      federationsLoading: false,
    }),
    [setFederationsLoading]: (state, federationsLoading) => ({
      ...state,
      federationsLoading,
    }),
    [setSaveMeetingLoading]: (state, saveLoading) => ({
      ...state,
      saveLoading,
    }),
  },
  initState,
);

export default creationReducer;
