exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tile-VO4jz {\n  background: #FFFFFF;\n  border: 1px solid #D9DFEA;\n  padding: 40px 30px 20px 30px;\n  margin-bottom: 20px;\n  font-size: 16px;\n}\n\n.date-1z-fv, .version-31SxU {\n  margin-bottom: 20px;\n}\n\n.fieldKey-wcng2 {\n  color: #39395B;\n  opacity: 0.4;\n}\n\n.body-1fV5W {\n  line-height: 20px;\n}\n\n.body-1fV5W * {\n    font-size: 16px!important;\n  }\n\n.body-1fV5W h1 {\n    color: #39395B;\n    opacity: 0.4;\n    font-weight: 400!important;\n  }", ""]);

// exports
exports.locals = {
	"tile": "tile-VO4jz",
	"date": "date-1z-fv",
	"version": "version-31SxU",
	"fieldKey": "fieldKey-wcng2",
	"body": "body-1fV5W"
};