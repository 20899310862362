export const SPORTING_ROLES = {
  COACH: 'coach',
  COMMISSIONER: 'commissioner',
  DELEGATE: 'delegate',
  FOOTBALLER: 'soccerPlayer',
  INSPECTOR: 'inspector',
  MANAGER: 'manager',
  MEDIATOR: 'mediator',
  ORGANIZER: 'organizer',
  PHYSICIAN: 'sportsPhysician',
  REFEREE: 'referee',
};

export const SPORTING_ROLES_RU = {
  [SPORTING_ROLES.COACH]: 'Тренер',
  [SPORTING_ROLES.FOOTBALLER]: 'Футболист',
  [SPORTING_ROLES.INSPECTOR]: 'Инспектор',
  [SPORTING_ROLES.MANAGER]: 'Менеджер',
  [SPORTING_ROLES.MEDIATOR]: 'Посредник',
  [SPORTING_ROLES.ORGANIZER]: 'Организатор',
  [SPORTING_ROLES.PHYSICIAN]: 'Врач',
  [SPORTING_ROLES.REFEREE]: 'Судья',
};
