import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';

const createAction = actionsFactory('payConstructor/dictionaries/');

export const setTriggers = createAction('setTriggers');
export const setEvents = createAction('setEvents');
export const setPayments = createAction('setPayments');

export const getTriggers = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/costcalculation/payouttriggertype/all');
    dispatch(setTriggers(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setTriggers([]));
  }
};

export const saveTrigger = (data) => async (dispatch) => {
  try {
    await axios.post('/api/rest/costcalculation/payouttriggertype/save', data);
    await dispatch(getTriggers());

    handleSuccess(data.id ? 'Триггер успешно обновлен!' : 'Триггер успешно добавлен!');
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const deleteTrigger = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/costcalculation/payouttriggertype/${id}`);
    await dispatch(getTriggers());
    handleSuccess('Триггер успешно удален!', 'trash');
  } catch (err) {
    handleError(err);
  }
};

export const getEvents = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/costcalculation/payouteventtype/all');
    dispatch(setEvents(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setEvents([]));
  }
};

export const saveEvent = (data) => async (dispatch) => {
  try {
    await axios.post('/api/rest/costcalculation/payouteventtype/save', data);
    await dispatch(getEvents());

    handleSuccess(data.id ? 'Событие успешно обновлено!' : 'Событие успешно добавлено!');
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/costcalculation/payouteventtype/${id}`);
    await dispatch(getEvents());
    handleSuccess('Событие успешно удалено!', 'trash');
  } catch (err) {
    handleError(err);
  }
};

export const getPayments = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/costcalculation/payouttype/all');
    dispatch(setPayments(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setPayments([]));
  }
};

export const savePayment = (data) => async (dispatch) => {
  try {
    await axios.post('/api/rest/costcalculation/payouttype/save', data);
    await dispatch(getPayments());

    handleSuccess(data.id ? 'Выплата успешно обновлена!' : 'Выплата успешно сохранена!');
  } catch (err) {
    handleError(err);
    throw err;
  }
};

export const deletePayment = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/costcalculation/payouttype/${id}`);
    await dispatch(getPayments());
    handleSuccess('Выплата успешно удалена!', 'trash');
  } catch (err) {
    handleError(err);
  }
};
