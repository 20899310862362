import { string, number } from 'prop-types';

/**
 * @description Статусы контракта
 */
export const PTContractStatus = {
  /**
   * @description описание
   */
  description: string,
  /**
   * @description название
   */
  name: string,
  order: number,
};
