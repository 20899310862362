import icons from 'assets/icons/icons';
import { PERMISSIONS } from 'constants/permissions';

const applications = {
  cards: [
    {
      icon: icons.participants,
      links: [{ title: 'Переход футболистов', to: '/requests' }],
      permissions: [PERMISSIONS.REMOVAL_STATEMENT, PERMISSIONS.EDIT_FOOT_STATUS, PERMISSIONS.CONTROL_FOOT_STATUS],
      title: 'Участники',
    },
    {
      icon: icons.infrastructures,
      links: [{ title: 'Сертификация', to: '/applications/certification' }],
      permissions: [PERMISSIONS.CHECK_STATEMENT_CERTIFICATION, PERMISSIONS.CREATE_CERTIFICATION_STATEMENT],
      title: 'Футбольные объекты',
    },
  ],
  permissions: [],
};

applications.cards.forEach((card) => {
  const linkPermissions = card.links.reduce(
    (permissions, link) => (link.permissions ? [...permissions, ...link.permissions] : permissions),
    [],
  );
  card.permissions = card.permissions ? [...linkPermissions, ...card.permissions] : linkPermissions;
  applications.permissions = applications.permissions ? [...card.permissions, ...applications.permissions] : card.permissions;
});

export default Object.freeze(applications);
