import { createReducer } from 'redux-act';

import { clearData, setAppealData, setAppealLoading } from 'redux/actions/Appeals/processingActions';

const initialState = {
  data: null,
  isLoading: false,
};

const processingReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setAppealData]: (state, data) => ({
      ...state,
      data,
    }),
    [setAppealLoading]: (state, isLoading) => ({
      ...state,
      isLoading,
    }),
  },
  initialState,
);

export default processingReducer;
