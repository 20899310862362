import { createReducer } from 'redux-act';
import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { checkPermissions } from 'magic/permissions';
import { PERMISSIONS } from 'constants/permissions';
import { AUTH_SETTINGS_TYPES } from 'components/pages/PersonalData/AuthSettingsCard/AuthSettingsCard';

const createCategoriesClubsAction = actionsFactory('personalData');

const personalDataInitialState = {
  isAllowJurisPages: true,
  personalDataAll: [],
  authRecoveryCheckboxDisabled: false,
  passRecoveryCheckboxDisabled: false,
};

export const purgePersonalData = createCategoriesClubsAction('purgePersonalData');

const setPersonalDataAll = createCategoriesClubsAction('setPersonalDataAll');
const setIsAllowJurisPages = createCategoriesClubsAction('setIsAllowJurisPages');
const setIsAuthCaptchaEnabled = createCategoriesClubsAction('authCaptchaEnabled');
const setIsPassRecoveryCaptchaEnabled = createCategoriesClubsAction('passRecoveryCaptchaEnabled');
const setIsAuthCheckboxDisabled = createCategoriesClubsAction('setIsAuthCheckboxDisabled');
const setIsPassRecoveryCheckboxDisabled = createCategoriesClubsAction('setIsPassRecoveryCheckboxDisabled');

export const getCurrentOrganization = async (dispatch, userAuthorities) => {
  const hasJurisPermission = checkPermissions(false, false, 'global', { global: userAuthorities }, [PERMISSIONS.JURIS_SECRETARY]);
  if (hasJurisPermission) {
    const {
      data: { data: hasCurrentOrganizations },
    } = await axios.get('/api/rest/judiciary/hasCurrentOrganizations');
    dispatch(setIsAllowJurisPages(hasCurrentOrganizations));
  } else {
    dispatch(setIsAllowJurisPages(false));
  }
};

export const getAuthSettings = async (dispatch) => {
  try {
    const authCaptchaEnabled = await axios.get(`/api/rest/captcha?module=AUTHORIZATION`);
    await dispatch(setIsAuthCaptchaEnabled(authCaptchaEnabled.data.data));
    const passRecoveryCaptchaEnabled = await axios.get(`/api/rest/captcha?module=PASSWORD_RECOVERY`);
    await dispatch(setIsPassRecoveryCaptchaEnabled(passRecoveryCaptchaEnabled.data.data));
  } catch (error) {
    handleError(error);
  }
};

const changeCheckboxState = async (dispatch, module, value) => {
  if (module === AUTH_SETTINGS_TYPES.AUTHORIZATION) {
    dispatch(setIsAuthCheckboxDisabled(value));
  } else {
    dispatch(setIsPassRecoveryCheckboxDisabled(value));
  }
};

export const getPersonalDataAll = () => async (dispatch) => {
  try {
    await getCurrentOrganization(dispatch);
    const {
      data: { data },
    } = await axios.get('/api/rest/personalData/all');
    await dispatch(setPersonalDataAll(data));
    getAuthSettings(dispatch);
  } catch (error) {
    handleError(error);
  }
};

export const changeAuthSettings = (module, value) => async (dispatch) => {
  try {
    changeCheckboxState(dispatch, module, true);
    await axios.put(`/api/rest/captcha?enable=${value}&module=${module}`);
    await getAuthSettings(dispatch);
    handleSuccess('Настройка успешно изменена!');
    await changeCheckboxState(dispatch, module, false);
  } catch (error) {
    handleError(error);
  }
};

const personalData = createReducer(
  {
    [purgePersonalData]: () => ({ ...personalDataInitialState }),
    [setIsAllowJurisPages]: (state, payload) => ({
      ...state,
      isAllowJurisPages: payload,
    }),
    [setPersonalDataAll]: (state, payload) => ({
      ...state,
      personalDataAll: payload,
    }),
    [setIsAuthCaptchaEnabled]: (state, payload) => ({
      ...state,
      authCaptchaEnabled: payload,
    }),
    [setIsPassRecoveryCaptchaEnabled]: (state, payload) => ({
      ...state,
      passRecoveryCaptchaEnabled: payload,
    }),
    [setIsAuthCheckboxDisabled]: (state, payload) => ({
      ...state,
      authCheckboxDisabled: payload,
    }),
    [setIsPassRecoveryCheckboxDisabled]: (state, payload) => ({
      ...state,
      passRecoveryCheckboxDisabled: payload,
    }),
  },
  personalDataInitialState,
);

export default personalData;
