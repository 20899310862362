import { createReducer } from 'redux-act';
import { clearData, setLoading, setData } from 'redux/actions/Organization/structureActions';

const initialState = {
  data: [],
  isLoading: false,
};

const licensesReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setLoading]: (state, payload) => ({
      ...state,
      isLoading: payload,
    }),
    [setData]: (state, payload) => ({
      ...state,
      data: payload,
    }),
  },
  initialState,
);

export default licensesReducer;
