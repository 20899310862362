import { createReducer } from 'redux-act';
import {
  setRegulations,
  setRegulationsLoading,
  clearData,
  addRegulations,
  setRegulationsCreateLoading,
  setRegulationsRemoveLoading,
  removeRegulations,
} from 'redux/actions/Regulation/viewActions';

const initState = {
  createLoading: false,
  list: [],
  loading: false,
  removeLoading: false,
};

const viewReducer = createReducer(
  {
    [addRegulations]: (state, data) => ({
      ...state,
      createLoading: false,
      list: [...state.list, data],
    }),
    [clearData]: () => initState,
    [removeRegulations]: (state, regulationId) => ({
      ...state,
      list: state.list.filter(({ id }) => id !== regulationId),
      removeLoading: false,
    }),
    [setRegulations]: (state, list) => ({
      ...state,
      list,
      loading: false,
    }),
    [setRegulationsCreateLoading]: (state, createLoading) => ({
      ...state,
      createLoading,
    }),
    [setRegulationsLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
    [setRegulationsRemoveLoading]: (state, removeLoading) => ({
      ...state,
      removeLoading,
    }),
  },
  initState,
);

export default viewReducer;
