exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".preloader-2OMeU {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  color: #fff;\n  /* position: absolute;*/\n  /* top: 50%;*/\n  /* left: 50%;*/\n  /* transform: translateX(-50%) translateY(-50%);*/\n  height: 100%;\n  position: relative;\n}\n", ""]);

// exports
exports.locals = {
	"preloader": "preloader-2OMeU"
};