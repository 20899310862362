import React from 'react';
import { bool, func, node, string } from 'prop-types';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { Icon, Preloader } from 'components/ui';

import styles from './Modal.scss';

export default class Modal extends React.Component {
  static propTypes = {
    children: node,
    className: string,
    loading: bool,
    onClose: func,
    overlayClassName: string,
  };

  static defaultProps = {
    children: undefined,
    className: undefined,
    loading: false,
    onClose: undefined,
    overlayClassName: undefined,
  };

  constructor(props) {
    super(props);

    this.root = document.createElement('div');
    this.root.className = styles.modal;
    document.body.appendChild(this.root);
    document.body.style.overflowY = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'auto';

    if (this.root) {
      document.body.removeChild(this.root);
      this.root = null;
    }
  }

  // Чтобы клик по контенту не прохдил на оверлэй
  preventPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const { children, className, loading, onClose, overlayClassName } = this.props;

    return ReactDOM.createPortal(
      <div className={cn(styles.overlay, overlayClassName)}>
        <div className={cn(styles.content, { [className]: className })} onClick={this.preventPropagation}>
          {onClose && (
            <div className={styles['closer-container']}>
              <span className={styles.closer} onClick={onClose}>
                <Icon icon="times" size="2x" pack="fal" />
              </span>
            </div>
          )}
          <div className={styles.children}>{loading ? <Preloader size="8x" className={styles.modalPreloader} /> : children}</div>
        </div>
      </div>,
      this.root,
    );
  }
}
