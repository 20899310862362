/* eslint-disable no-console */
import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';
import { handleError } from 'magic/handlers';

const createAction = actionsFactory('teamActions/');

export const setTeamData = createAction('setTeamData');
export const setTeamLoading = createAction('setTeamLoading');

export const clearData = createAction('clearData');

export const getTeamData = (teamId) => async (dispatch) => {
  try {
    dispatch(setTeamLoading(true));

    const {
      data: { data: team },
    } = await axios.get(`/api/rest/organization/team/${teamId}`);

    dispatch(setTeamData(team));
  } catch (error) {
    handleError(error);
    dispatch(clearData());
  } finally {
    dispatch(setTeamLoading(false));
  }
};
