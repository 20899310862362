import vk from './vk.svg';
import tg from './tg.svg';
import yt from './yt.svg';
import ok from './ok.svg';
import fb from './fb.svg';
import whatsapp from './whatsapp.svg';
import pinterest from './pinterest.svg';
import twitter from './twitter.svg';
import instagram from './instagram.svg';
import defaultIcon from './default.svg';

export const socials = {
  vk,
  tg,
  yt,
  ok,
  fb,
  whatsapp,
  pinterest,
  twitter,
  instagram,
  defaultIcon,
};
