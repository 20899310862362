import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError } from 'magic/handlers';
import { uploadFile } from 'utils/uploadFiles';

const createAction = actionsFactory('printTemplates/current/');

export const clearData = createAction('clearData');
export const setPrintTemplateData = createAction('setPrintTemplateData');
export const setPrintTemplateLoading = createAction('setPrintTemplateLoading');

export const getPrintTemplate = (id) => async (dispatch) => {
  try {
    dispatch(setPrintTemplateLoading(true));

    const response = await axios.get(`/api/rest/report/template/history/${id}`);

    dispatch(setPrintTemplateData(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setPrintTemplateData({}));
  } finally {
    dispatch(setPrintTemplateLoading(false));
  }
};

export const savePrintTemplate = (data) => async (dispatch) => {
  try {
    const body = { ...data };
    body.fileInfo = await uploadFile(body.fileInfo);

    await axios.post('/api/rest/report/template/update', body);

    dispatch(getPrintTemplate(body.reportTemplateId));
  } catch (err) {
    handleError(err);
  }
};
