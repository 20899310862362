import { createReducer } from 'redux-act';
import { clearData, setRefereeCategoryData, setRefereeCategoryLoading } from 'redux/actions/Profile/refereeCategoryActions';

const initialState = {
  data: null,
  isLoading: false,
};

const refereeCategoryReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setRefereeCategoryData]: (state, data) => ({
      ...state,
      data,
    }),
    [setRefereeCategoryLoading]: (state, isLoading) => ({
      ...state,
      isLoading,
    }),
  },
  initialState,
);

export default refereeCategoryReducer;
