import { createReducer } from 'redux-act';
import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { get } from 'utils/lodash';

const createAction = actionsFactory('infrastructure/add/');

const initialState = {
  data: {},
  duplicates: {
    data: [],
    isLoading: false,
  },
};

export const updateData = createAction('updateData');
export const fillForm = createAction('fillForm');
export const setDuplicatesData = createAction('setDuplicatesData');
export const setDuplicatesLoading = createAction('setDuplicatesLoading');
export const clearData = createAction('clearData');

export const getDuplicates = (data) => async (dispatch) => {
  try {
    dispatch(setDuplicatesLoading(true));
    const fullAddress = get(data, 'address.value');
    if (fullAddress) {
      const params = { fullAddress };
      const response = await axios.get('/api/rest/infrastructureobject/findByAddress', { params });
      dispatch(setDuplicatesData(response.data.data.filter((item) => item.id !== data.id)));
    } else {
      dispatch(setDuplicatesData([]));
    }
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    dispatch(setDuplicatesData([]));
  } finally {
    dispatch(setDuplicatesLoading(false));
  }
};

const reducer = createReducer(
  {
    [clearData]: () => initialState,
    [fillForm]: (state, data) => ({
      ...state,
      data,
    }),
    [setDuplicatesData]: (state, data) => ({
      ...state,
      duplicates: {
        ...state.duplicates,
        data,
      },
    }),
    [setDuplicatesLoading]: (state, isLoading) => ({
      ...state,
      duplicates: {
        ...state.duplicates,
        isLoading,
      },
    }),
    [updateData]: (state, payload) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    }),
  },
  initialState,
);

export default reducer;
