import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * @type {React.FC<any>}
 */
const Icon = ({ pack, customIcon, icon, ...rest }) => {
  const fontIcon = useMemo(() => ((typeof icon === 'string' ? [`${pack}`, `${icon}`] : icon)), [pack, icon]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return customIcon ? <img {...rest} src={customIcon} alt="" /> : <FontAwesomeIcon {...rest} icon={fontIcon} />;
};

Icon.propTypes = {
  customIcon: PropTypes.string,
  icon: PropTypes.string,
  pack: PropTypes.string,
};

Icon.defaultProps = {
  customIcon: undefined,
  icon: undefined,
  pack: 'far',
};

export default Icon;
