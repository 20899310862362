import React from 'react';
import { H } from 'components/ui';
import Checkbox from 'components/rfs-ui/Checkbox/Checkbox';
import css from './AuthSettingsCard.styles.scss';

export const AUTH_SETTINGS_TYPES = {
  AUTHORIZATION: 'AUTHORIZATION',
  PASSWORD_RECOVERY: 'PASSWORD_RECOVERY',
};

export const AuthSettingsCard = ({
  authEnabled,
  passRevoveryEnabled,
  changeAuthSettings,
  disableAuthCheckbox,
  disablePassRecoveryCheckbox,
}) => {
  const onChange = (value, module) => {
    changeAuthSettings(module, value);
  };

  return (
    <div className={`${css.container} personal-data-card-container`}>
      <H size={3} className={css.title}>
        Управление настройками авторизации
      </H>
      <div className={css.contentContainer}>
        <Checkbox
          title="Включить капчу при авторизации"
          className={css.checkbox}
          checked={authEnabled}
          onChange={(value) => onChange(value, AUTH_SETTINGS_TYPES.AUTHORIZATION)}
          disabled={disableAuthCheckbox}
        />
        <Checkbox
          title="Включить капчу при смене пароля"
          className={css.checkbox}
          checked={passRevoveryEnabled}
          onChange={(value) => onChange(value, AUTH_SETTINGS_TYPES.PASSWORD_RECOVERY)}
          disabled={disablePassRecoveryCheckbox}
        />
      </div>
    </div>
  );
};
