exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".divider-f10Cq {\n  border-top: 1px solid rgba(255, 255, 255, 0.3);\n  margin: 20px 0;\n}\n\n  .divider-f10Cq.noMargin-1nDGB {\n    margin: 0;\n  }\n\n.theme-dark .divider-f10Cq {\n    border-color: rgba(255, 255, 255, 0.3);\n  }\n\n.theme-light .divider-f10Cq {\n    border-color: rgba(180, 191, 213, 0.5);\n  }\n", ""]);

// exports
exports.locals = {
	"divider": "divider-f10Cq",
	"noMargin": "noMargin-1nDGB"
};