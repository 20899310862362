import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import axios from 'axios';
import { omit } from 'lodash';

const createAction = actionsFactory('decisions/edit/');

export const cleanData = createAction('cleanData');

export const setDecision = createAction('setDecision');
export const setDecisionLoading = createAction('setDecisionLoading');

export const setAddedDecision = createAction('setAddedDecision');
export const setAddedDecisionLoading = createAction('setAddedDecisionLoading');

export const setDecisionActionLoading = createAction('setDecisionActionLoading');

export const getDecision = (decisionId) => async (dispatch) => {
  try {
    dispatch(setDecisionLoading(true));
    const url = `/api/rest/judiciary/decision/${decisionId}`;

    const {
      data: { data },
    } = await axios.get(url);
    dispatch(setDecision(data));
  } catch (err) {
    handleError(err);
    dispatch(setDecisionLoading(false));
  }
};

export const onPublish = (payload, successCallback) => async (dispatch) => {
  try {
    dispatch(setDecisionActionLoading(true));
    const url = '/api/rest/judiciary/decision/publish';

    await axios.post(url, omit(payload, 'status', 'appellateDecision'));
    dispatch(setDecisionActionLoading(false));
    handleSuccess('Решение успешно опубликовано!');
    if (successCallback) successCallback();
  } catch (err) {
    handleError(err);
    dispatch(setDecisionActionLoading(false));
  }
};

export const onRepay = (decisionId, successCallback) => async (dispatch) => {
  try {
    dispatch(setDecisionActionLoading(true));
    const url = `/api/rest/judiciary/decision/${decisionId}/repay`;

    await axios.post(url);
    dispatch(setDecisionActionLoading(false));
    handleSuccess('Решение успешно погашено!');
    if (successCallback) successCallback();
  } catch (err) {
    handleError(err);
    dispatch(setDecisionActionLoading(false));
  }
};

export const editDecision = (payload, successCallback) => async () => {
  try {
    const url = '/api/rest/judiciary/decision/edit';

    await axios.post(url, omit(payload, 'status'));
    handleSuccess('Изменения успешно применены!');
    if (successCallback) successCallback();
  } catch (err) {
    handleError(err);
  }
};

export const addAppellateDecision = (decisionId, payload, successCallback) => async () => {
  try {
    const url = `/api/rest/judiciary/decision/${decisionId}/add/appellate`;

    const {
      data: {
        data: { id },
      },
    } = await axios.post(url, payload);
    handleSuccess('Решение успешно добавлено!');
    if (successCallback) successCallback(id);
  } catch (err) {
    handleError(err);
  }
};

export const deleteDecision = (decisionId, successCallback) => async () => {
  try {
    const url = `/api/rest/judiciary/decision/${decisionId}`;

    await axios.delete(url);
    if (successCallback) successCallback();
  } catch (err) {
    handleError(err);
  }
};
