import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

class ScrollToTop extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  componentDidUpdate () {
    this.scrollToTop();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  render () {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
