import React from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker/DayPicker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css?external';
import moment from 'moment';

import { isValidDate } from 'magic/date';

import Calendar from './Calendar';

import css from './Datepicker.scss';

class Datepicker extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    format: PropTypes.string,
    disabledDays: PropTypes.shape({
      after: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
      before: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    }),
  }

  /**
   * Меняет дату через Datepicker, когда меняем месяц или год в Caption
   * @param day
   */
  handleChangeMonthYear = (date) => {
    const { format, onChange } = this.props;
    const momentDate = moment(date).format(format);
    onChange(momentDate);
  }

  /**
   * Меняет дату при клике на день в Datepicker
   * @param day
   */
  handleChangeDay = (day, { disabled }) => {
    if (!disabled) {
      const { format, onChange } = this.props;
      const momentDate = moment(day).format(format);
      onChange(momentDate, true);
    }
  };

  renderCalendar = ({ date, localeUtils }) => {
    const { minYear, maxYear } = this.props;

    return (
      <Calendar
        minYear={minYear}
        maxYear={maxYear}
        date={date}
        localeUtils={localeUtils}
        onChange={this.handleChangeMonthYear}
      />
    );
  };

  render() {
    const { format, value, disabledDays } = this.props;
    const selectedDate = isValidDate(value, format) ? new Date(moment(value, format)) : new Date();
    return (
      <div>
        <DayPicker
          onDayClick={this.handleChangeDay}
          locale="ru"
          localeUtils={MomentLocaleUtils}
          selectedDays={selectedDate}
          month={selectedDate}
          captionElement={this.renderCalendar}
          className={`${css.datePickerContainer} datepicker-container-themed`}
          disabledDays={disabledDays}
        />
      </div>
    );
  }
}

export default Datepicker;
