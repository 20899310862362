exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------ \nCommon\n--------------------------*/\n\n\n/* layouts width*/\n\n\n/* ------------------------ \nDark Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* input*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nLight Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nRating\n--------------------------*/\n\n\n.label-1NGWg {\n  color: #fff;\n  font-size: 1rem;\n}\n\n\n.label-1NGWg.required-3S96U {\n    color: #0a81ff;\n  }\n\n\n.label-1NGWg.disabled-siz5w {\n    color: #565e85;\n  }\n\n\n.theme-dark .label-1NGWg {\n    color: #fff;\n  }\n\n\n.theme-dark .label-1NGWg.required-3S96U {\n      color: #0a81ff;\n    }\n\n\n.theme-dark .label-1NGWg.disabled-siz5w {\n      color: #565e85;\n    }\n\n\n.theme-light .label-1NGWg {\n    color: #212121;\n  }\n\n\n.theme-light .label-1NGWg.required-3S96U {\n      color: #0a81ff;\n    }\n\n\n.theme-light .label-1NGWg.disabled-siz5w {\n      color: #565e85;\n    }\n", ""]);

// exports
exports.locals = {
	"label": "label-1NGWg",
	"required": "required-3S96U",
	"disabled": "disabled-siz5w"
};