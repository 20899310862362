exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".slick-dots {\n    position: unset;\n    margin: 1em 0;\n  }\n\n    .slick-dots li {\n      margin: 0;\n    }\n\n    .slick-dots .slick-active > div > div,\n    .slick-dots li:hover > div > div {\n      background-color: #9196af;\n    }\n  .slick-prev {\n    left: -120px;\n  }\n  .slick-next {\n    right: -120px;\n  }\n  .slick-disabled svg {\n    color: rgba(255, 255, 255, .5);\n    -webkit-transition: .3s;\n    transition: .3s;\n  }\n\n.arrow-38oAc {\n  font-size: 1rem;\n  margin-right: 0;\n  width: 120px;\n  display: unset;\n  height: 100%;\n  border-radius: 0;\n  position: absolute;\n}\n\n.arrow-38oAc:before {\n    content: none;\n  }\n\n.dotWrapper-1LDJf {\n  width: 100%;\n  height: 100%;\n}\n\n.dotWrapper-1LDJf .dot-3Ulce {\n    width: 10px;\n    height: 10px;\n    border: 1px solid #9196af;\n    border-radius: 50%;\n    position: relative;\n    top: 50%;\n    left: 50%;\n    -webkit-transform: translate(-50%, -50%);\n            transform: translate(-50%, -50%);\n    margin-right: 0;\n  }\n\n.slide-Tn0xQ {\n  position: relative;\n  outline: none;\n}\n\n.sliderModal-1Cyux {\n  background-color: transparent;\n  max-width: 100%;\n  width: 1280px;\n}\n\n.sliderOverlay-3xNJ2 {\n  background-color: rgba(0, 0, 0, .9);\n  padding: 40px 80px;\n}\n\n.modalContent-3vi7b {\n  padding-top: 40px;\n}\n", ""]);

// exports
exports.locals = {
	"arrow": "arrow-38oAc",
	"dotWrapper": "dotWrapper-1LDJf",
	"dot": "dot-3Ulce",
	"slide": "slide-Tn0xQ",
	"sliderModal": "sliderModal-1Cyux",
	"sliderOverlay": "sliderOverlay-3xNJ2",
	"modalContent": "modalContent-3vi7b"
};