import axios from 'axios';
import actionsFactory from 'magic/actionsFactory';
import { handleError } from 'magic/handlers';
import { uploadFile } from 'utils/uploadFiles';

import { selectFreeTemplatesPagination } from 'redux/selectors/printTemplates';

const createAction = actionsFactory('printTemplates/free/');

export const clearFreeTemplatesData = createAction('clearFreeTemplatesData');
export const setFreeTemplatesData = createAction('setFreeTemplatesData');
export const setFreeTemplatesPagination = createAction('setFreeTemplatesPagination');

export const getFreeTemplates = (type, pagination) => async (dispatch, getState) => {
  try {
    const currentPagination = selectFreeTemplatesPagination(getState());

    const params = {
      page: pagination ? pagination.page : currentPagination.page,
      perPage: pagination ? pagination.perPage : currentPagination.perPage,
    };

    const {
      data: { data, pageData },
    } = await axios.get(`/api/rest/report/template/free/list/${type.toUpperCase()}`, { params });

    dispatch(setFreeTemplatesData(data));
    dispatch(
      setFreeTemplatesPagination({
        factCount: pageData.factCountOnPage,
        itemsCount: pageData.size,
        page: pageData.numberPage,
        pageCount: pageData.countPage,
        perPage: pageData.sizePage,
      }),
    );
  } catch (err) {
    handleError(err);
  }
};

export const saveFreeTemplate = (data) => async (dispatch) => {
  try {
    const body = { ...data };

    if (body.fileInfo instanceof File) {
      body.fileInfo = await uploadFile(body.fileInfo);
    }

    await axios.post('/api/rest/report/template/free/save', body);
    await dispatch(getFreeTemplates(body.type.sysName));
  } catch (err) {
    handleError(err);
  }
};

export const deleteFreeTemplate = (id, type) => async (dispatch, getState) => {
  try {
    const pagination = selectFreeTemplatesPagination(getState());
    const nextPagination = {
      page: pagination.page > 0 && pagination.factCount === 1 ? pagination.page - 1 : pagination.page,
      perPage: pagination.perPage,
    };

    await axios.delete(`/api/rest/report/template/free/${id}/delete`);
    await dispatch(getFreeTemplates(type, nextPagination));
  } catch (err) {
    handleError(err);
  }
};
