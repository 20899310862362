import { createReducer } from 'redux-act';
import * as competitionsIntegrationActions from 'redux/actions/competitionsIntegrationActions';

const initialState = {
    address: '',
};

const competitionsIntegrationReducer = createReducer(
  {
    [competitionsIntegrationActions.setCurrentCompetitionsAddress]: (state, address) => ({
      ...state,
      address,
    }),
  },
  initialState,
);

export default competitionsIntegrationReducer;
