import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';

import css from './Searchbar.scss';

class Searchbar extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    refer: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    value: PropTypes.string,
  };

  static defaultProps = {
    className: undefined,
    disabled: false,
    onChange: undefined,
    onSearch: undefined,
    refer: undefined,
    value: '',
  };

  handleKeypressPress = (event) => {
    const { disabled, onSearch } = this.props;

    if (event.key === 'Enter' && onSearch && !disabled) {
      onSearch(event);
    }
  };

  render() {
    const { className, value, onChange, refer, onSearch, disabled, ...props } = this.props;

    return (
      <div className={cn(css.searchbar, className)}>
        <input ref={refer} className={css.input} value={value} onChange={onChange} onKeyPress={this.handleKeypressPress} {...props} />
        <Icon icon="search" className={css.icon} />
        <Button onClick={onSearch} className={css.button} disabled={disabled} type="text">
          Найти
        </Button>
      </div>
    );
  }
}

export default Searchbar;
