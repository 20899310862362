import { createReducer } from 'redux-act';
import { cleanData, setAccreditation, setLoading } from '../../actions/Organization/accreditationActions';

const initialState = {
  data: {
    dateEnd: null,
    dateStart: null,
    number: '',
  },
  loading: false,
};

const decisionsReducer = createReducer(
  {
    [cleanData]: () => ({ ...initialState }),
    [setLoading]: (state, payload) => ({
      ...state,
      isLoading: payload,
    }),
    [setAccreditation]: (state, payload) => ({
      ...state,
      data: payload,
    }),
  },
  initialState,
);

export default decisionsReducer;
