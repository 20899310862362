export const PERSONAL_DATA_TYPES = Object.freeze({
  PERSONAL_DATA_AGREEMENT: 'PERSONAL_DATA_AGREEMENT',
  USER_AGREEMENT: 'USER_AGREEMENT',
  METRIC_DATA_AGREEMENT: 'METRIC_DATA_AGREEMENT',
});

export const PERSONAL_DATA_TYPES_RU = Object.freeze({
  PERSONAL_DATA_AGREEMENT: 'Политика в отношении обработки персональных данных',
  USER_AGREEMENT: 'Пользовательское соглашение',
  METRIC_DATA_AGREEMENT: 'Условия обработки метрических данных',
});
