import actionsFactory from 'magic/actionsFactory';

const createAction = actionsFactory('transfers/admin');

export const clearData = createAction('clearData');
export const clearFormData = createAction('clearFormData');
export const setActionTypes = createAction('setActionTypes');
export const setGeneralData = createAction('setGeneralData');
export const setPlayer = createAction('setPlayer');
export const updateFormData = createAction('updateFormData');
export const updateGeneralData = createAction('updateGeneralData');
