import { combineReducers } from 'redux';
import constructor from './constructorReducer';
import creation from './creationReducer';
import processing from './processingReducer';
import view from './viewReducer';

const appealsReducer = combineReducers({
  constructor,
  creation,
  processing,
  view,
});

export default appealsReducer;
