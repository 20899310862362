import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { get } from 'utils/lodash';

import TableHeader from './Header';
import TableRow from './Row';

import styles from './Table.scss';

const cx = classNames.bind(styles);

const Table = ({ className, columns, data, fixed, type, light, valueKey, hideHeader }) => {
  const tableClassNames = useMemo(() => cx(styles.table, styles[type], className, { fixed, light }), [type, className, fixed, light]);

  return (
    <table className={tableClassNames}>
      {!hideHeader && (
        <thead>
          <TableHeader columns={columns} />
        </thead>
      )}
      <tbody>
        {data.map((item) => (
          <TableRow columns={columns} item={item} key={get(item, valueKey)} />
        ))}
      </tbody>
    </table>
  );
};

const PTColumn = {
  header: PropTypes.string,
  key: PropTypes.string,
  renderValue: PropTypes.func,
  width: PropTypes.string,
};

Table.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape(PTColumn)).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  fixed: PropTypes.bool,
  hideHeader: PropTypes.bool,
  light: PropTypes.bool,
  type: PropTypes.oneOf(['border', 'cards']),
  valueKey: PropTypes.string,
};

Table.defaultProps = {
  className: '',
  data: [],
  fixed: false,
  hideHeader: false,
  light: false,
  type: 'border',
  valueKey: 'id',
};

export default Table;
