exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".modal-1fazp {\n  width: 100%;\n  max-width: 720px;\n}\n\n  @media screen and (min-height: 1000px) {.modal-1fazp {\n    margin-top: 110px\n}\n  }\n\n.cropContainer-fekbx {\n  position: relative;\n  height: 400px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.btnContainer-2UOq5 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  margin: 40px 0 10px;\n}\n\n.btnContainer-2UOq5 button:first-child {\n      padding: 0 20px;\n    }\n\n.btnContainer-2UOq5 button:not(:last-child) {\n      margin-right: 40px\n    }\n\n.isMainCheckbox-HPPq3 {\n  text-align: right;\n  margin-top: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"modal": "modal-1fazp",
	"cropContainer": "cropContainer-fekbx",
	"btnContainer": "btnContainer-2UOq5",
	"isMainCheckbox": "isMainCheckbox-HPPq3"
};