export const DEFAULT_ACTION_TYPES = [
  'CHANGE_LEGAL_STATUS',
  'CHANGE_LEGAL_STATUS_DESCRIPTION',
  'ADD_ORGANIZATION_TO_LEGAL',
  'DELETE_ORGANIZATION',
  'CHANGE_CLUB_LEGAL',
  'BANK_DETAILS',
  'CLUB_NAME',
  'CHANGE_CLUB_CATEGORY',
  'CHANGE_SCHOOL_CATEGORY',
  'LEGAL_VERIFICATION',
  'CREATE_LEGAL',
  'CHANGE_CLUB_LICENSE',
  'CHANGE_EXTERNAL_LEGAL_STATUS',
  'CHANGE_ORGANIZATION_STATUS',
  'NEW_CLUB_LICENSE',
  'DOCUMENT',
];
