import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { selectDictionaryFilters } from 'redux/selectors/Dictionaries/recordsSelectors';

const createAction = actionsFactory('dictionaries/records/');

export const clearRecordsData = createAction('clearData');
export const setRecordsRequest = createAction('setRecordsRequest');
export const setRecordsSuccess = createAction('setRecordsSuccess');
export const setRecordsFailure = createAction('setRecordsFailure');

export const getDictionaryRecords = (filters) => async (dispatch) => {
  try {
    dispatch(setRecordsRequest(filters));
    const { dictionary, searchText } = filters;
    const params = { dictionary: dictionary.sysName, searchText: searchText || undefined };

    const {
      data: { data },
    } = await axios.get('/api/rest/dictionary/editable/records', { params });

    dispatch(setRecordsSuccess(data));

    return data;
  } catch (err) {
    handleError(err);
    dispatch(setRecordsFailure());

    return [];
  }
};

export const saveDictionaryRecord = (record) => async (dispatch, getState) => {
  try {
    const { dictionary, searchText } = selectDictionaryFilters(getState());

    const saveParams = { dictionary: dictionary.sysName };
    await axios.post('/api/rest/dictionary/editable/save', record, { params: saveParams });
    handleSuccess('Запись успешно сохранена!', 'file-check');

    const getParams = { dictionary: dictionary.sysName, searchText: searchText || undefined };

    const {
      data: { data },
    } = await axios.get('/api/rest/dictionary/editable/records', { params: getParams });
    dispatch(setRecordsSuccess(data));

    return true;
  } catch (err) {
    handleError(err);
  }
};

export const deleteDictionaryRecord = (recordId) => async (dispatch, getState) => {
  try {
    const { dictionary, searchText } = selectDictionaryFilters(getState());

    const deleteParams = { dictionary: dictionary.sysName, recordId };
    await axios.delete('/api/rest/dictionary/editable/delete', { params: deleteParams });
    handleSuccess('Запись успешно удалена!', 'trash');

    const getParams = { dictionary: dictionary.sysName, searchText: searchText || undefined };

    const {
      data: { data },
    } = await axios.get('/api/rest/dictionary/editable/records', { params: getParams });
    dispatch(setRecordsSuccess(data));

    return true;
  } catch (err) {
    handleError(err);
  }
};
