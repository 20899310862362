import { createReducer } from 'redux-act';
import { clearData, setIndicatorsData, setIndicatorsLoading, setIndicatorsCalculating } from 'redux/actions/controlIndicatorsActions';

const initialState = {
  actualDate: null,
  indicators: [],
  isCalculating: false,
  isLoading: false,
  population: 0,
};

const controlIndicatorsReducer = createReducer(
  {
    [clearData]: () => ({
      ...initialState,
    }),
    [setIndicatorsCalculating]: (state, isCalculating) => ({
      ...state,
      isCalculating,
    }),
    [setIndicatorsData]: (state, { actualDate, indicators, population }) => ({
      ...state,
      actualDate,
      indicators,
      population,
    }),
    [setIndicatorsLoading]: (state, isLoading) => ({
      ...state,
      isLoading,
    }),
  },
  initialState,
);

export default controlIndicatorsReducer;
