import { createSelector } from 'reselect';
import { get } from 'utils/lodash';

const contractsState = (state) => state.profile.contracts;
const selectContractIdFromProps = (_, props) => props.contract && props.contract.id;

export const selectContractsListData = createSelector(contractsState, (state) => state.contractsList.data);
export const selectContractsListLoading = createSelector(contractsState, (state) => state.contractsList.isLoading);

export const selectContractData = createSelector(contractsState, selectContractIdFromProps, (state, contractId) =>
  get(state, `contractData.${contractId}.data`, null),
);

export const selectContractLoading = createSelector(contractsState, selectContractIdFromProps, (state, contractId) =>
  get(state, `contractData.${contractId}.isLoading`, false),
);

export const selectContractTypes = createSelector(contractsState, (state) => state.contractTypes.data);
export const selectContractTypesLoading = createSelector(contractsState, (state) => state.contractTypes.isLoading);

export const selectContractFormData = createSelector(contractsState, (state) => state.contractForm.data);
export const selectContractFormLoading = createSelector(contractsState, (state) => state.contractForm.isLoading);
