import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import css from './Components.scss';

const cn = classnames.bind(css);

const WeekEventContainer = ({
  onClick,
  style = {},
  label,
  children,
  onSelect,
  event,
  type,
}) => {
  const { top, height, width, xOffset } = style;

  return (
    <div
      className={cn('rbc-event', css.eventWrapper, { time: type === 'time' })}
      style={style && {
        top: `${top}%`,
        height: `${height}%`,
        width: `${width}%`,
        left: `${xOffset}%`,
      }}
      onClick={onSelect ? () => onSelect(event) : onClick}
    >
      {children}
      <div className={css.timeRange}>{label}</div>
    </div>
  );
};

WeekEventContainer.propTypes = {
  event: PropTypes.object,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  style: PropTypes.shape({
    top: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    xOffset: PropTypes.number,
  }),
  label: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
};

export default WeekEventContainer;
