exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".linkButton-25Ni0 {\n  text-decoration: none !important;\n  display: -webkit-inline-box;\n  display: -ms-inline-flexbox;\n  display: inline-flex;\n  color: #fff;\n  margin-right: 40px;\n}\n\n  .linkButton-25Ni0:last-child {\n    margin-right: 0;\n  }\n\n", ""]);

// exports
exports.locals = {
	"linkButton": "linkButton-25Ni0"
};