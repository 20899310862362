const dateFormats = {
  APP_DATETIME_FORMAT: 'DD.MM.YYYY HH:mm',
  APP_DATETIME_FORMAT_ENG: 'DD.MM.YYYY HH:mm:ss',
  APP_DATETIME_FORMAT_RUS: 'дд.мм.гггг чч:мм:cc',
  APP_DATE_FORMAT: 'DD.MM.YYYY',
  APP_DATE_FORMAT_RUS: 'дд.мм.гггг',
  APP_TIME_FORMAT: 'HH:mm',
  FULL_DATE: 'D MMMM YYYY',
  SERVER_DATETIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
  SERVER_DATE_FORMAT: 'YYYY-MM-DD',
  YEAR_DATE_FORMAT: 'YYYY',
};

export default dateFormats;
