import { createReducer } from 'redux-act';
import {
  clearData,
  setContractsListLoading,
  setContractsListData,
  getContractRequest,
  getContractSuccess,
  clearContractData,
  setContractsTypesLoading,
  setContractsTypesData,
  setContractFormData,
  setContractFormLoading,
  updateContractFormData,
  clearContractFormData,
} from 'redux/actions/Profile/contractsActions';

const initialState = {
  contractData: {},
  contractForm: {
    data: null,
    isLoading: false,
  },
  contractTypes: {
    data: [],
    isLoading: false,
  },
  contractsList: {
    data: [],
    isLoading: false,
  },
};

const contractsReducer = createReducer(
  {
    [clearContractData]: ({ contractData, ...state }, contractId) => ({
      ...state,
      contractData: {
        ...contractData,
        [contractId]: undefined,
      },
    }),
    [clearContractFormData]: (state) => ({
      ...state,
      contractForm: { ...initialState.contractForm },
    }),
    [clearData]: () => ({ ...initialState }),
    [getContractRequest]: ({ contractData, ...state }, contractId) => ({
      ...state,
      contractData: {
        ...contractData,
        [contractId]: { isLoading: true },
      },
    }),
    [getContractSuccess]: ({ contractData, ...state }, { contractId, data }) => {
      const contractState = contractData[contractId] ? { data, isLoading: false } : undefined;

      return {
        ...state,
        contractData: {
          ...contractData,
          [contractId]: contractState,
        },
      };
    },
    [setContractFormData]: ({ contractForm, ...state }, data) => ({
      ...state,
      contractForm: {
        ...contractForm,
        data,
      },
    }),
    [setContractFormLoading]: ({ contractForm, ...state }, isLoading) => ({
      ...state,
      contractForm: {
        ...contractForm,
        isLoading,
      },
    }),
    [setContractsListData]: ({ contractsList, ...state }, data) => ({
      ...state,
      contractsList: {
        ...contractsList,
        data,
      },
    }),
    [setContractsListLoading]: ({ contractsList, ...state }, isLoading) => ({
      ...state,
      contractsList: {
        ...contractsList,
        isLoading,
      },
    }),
    [setContractsTypesData]: ({ contractTypes, ...state }, data) => ({
      ...state,
      contractTypes: {
        ...contractTypes,
        data,
      },
    }),
    [setContractsTypesLoading]: ({ contractTypes, ...state }, isLoading) => ({
      ...state,
      contractTypes: {
        ...contractTypes,
        isLoading,
      },
    }),
    [updateContractFormData]: ({ contractForm, ...state }, newData) => ({
      ...state,
      contractForm: {
        ...contractForm,
        data: {
          ...contractForm.data,
          ...newData,
        },
      },
    }),
  },
  initialState,
);

export default contractsReducer;
