import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';

import { Modal, Button } from 'components/ui';
import Checkbox from 'components/rfs-ui/Checkbox/Checkbox';
import { noop, throttle } from 'utils/lodash';

import { getCroppedImg, readFile } from './utils';
import css from './CropModal.scss';

const CROP_STEP = 5;
const ZOOM_STEP = 0.05;
const CROP_SIZE = 180;

const INITIAL_CROP = { x: 0, y: 0 };
const CROPPER_CROP_SIZE = { height: CROP_SIZE, width: CROP_SIZE };

const CropModal = ({
  open,
  onClose,
  onClear,
  onApply,
  onChangeFile,
  image,
  canDelete,
  showMainControl,
  disabledMainControl,
  defaultSelectedMain,
}) => {
  const [isMain, setIsMain] = useState(defaultSelectedMain);
  const [source, setSource] = useState();
  const [crop, setCrop] = useState(INITIAL_CROP);
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [imageSize, setImageSize] = useState({});
  const cropperRef = useRef();

  const minZoom = useMemo(() => {
    const maxSize = Math.max(imageSize.width, imageSize.height);
    return maxSize > 0 ? CROP_SIZE / maxSize : 0.5;
  }, [imageSize]);

  const maxCrop = useMemo(
    () => ({
      x: Math.abs((imageSize.width * zoom - CROP_SIZE) / 2),
      y: Math.abs((imageSize.height * zoom - CROP_SIZE) / 2),
    }),
    [imageSize, zoom],
  );

  const handleCropChange = useCallback(
    throttle((currentCrop) => {
      setCrop({
        x: currentCrop.x > 0 ? Math.min(currentCrop.x, maxCrop.x) : Math.max(currentCrop.x, -maxCrop.x),
        y: currentCrop.y > 0 ? Math.min(currentCrop.y, maxCrop.y) : Math.max(currentCrop.y, -maxCrop.y),
      });
    }, 25),
    [maxCrop],
  );

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const handleImageLoaded = useCallback((size) => {
    setImageSize(size);
  }, []);

  const handleApply = useCallback(async () => {
    onClose();

    if (image) {
      const result = await getCroppedImg(image, croppedArea);
      onApply({ file: image, isMain, result });
    } else {
      onApply();
    }
  }, [onApply, onClose, image, croppedArea, isMain]);

  const handleKeyDown = useCallback(
    (e) => {
      // eslint-disable-next-line default-case
      switch (e.key) {
        case 'ArrowLeft':
          setCrop((prevCrop) => ({ ...prevCrop, x: prevCrop.x - CROP_STEP > -maxCrop.x ? prevCrop.x - CROP_STEP : -maxCrop.x }));
          break;
        case 'ArrowRight':
          setCrop((prevCrop) => ({ ...prevCrop, x: prevCrop.x + CROP_STEP < maxCrop.x ? prevCrop.x + CROP_STEP : maxCrop.x }));
          break;
        case 'ArrowUp':
          setCrop((prevCrop) => ({ ...prevCrop, y: prevCrop.y + CROP_STEP < maxCrop.y ? prevCrop.y + CROP_STEP : maxCrop.y }));
          break;
        case 'ArrowDown':
          setCrop((prevCrop) => ({ ...prevCrop, y: prevCrop.y - CROP_STEP > -maxCrop.y ? prevCrop.y - CROP_STEP : -maxCrop.y }));
          break;
        case '+':
          setZoom((prevZoom) => prevZoom + ZOOM_STEP);
          break;
        case '-':
          setZoom((prevZoom) => (prevZoom - ZOOM_STEP > minZoom ? prevZoom - ZOOM_STEP : minZoom));
          break;
      }
      if (cropperRef.current) {
        cropperRef.current.recomputeCropPosition();
      }
    },
    [minZoom, maxCrop],
  );

  const updateImageSource = async () => {
    if (image) {
      const imageSource = await readFile(image);
      setIsMain(defaultSelectedMain);
      setSource(imageSource);
      setCrop(INITIAL_CROP);
      setCroppedArea(null);
      setZoom(1);
    } else {
      setSource();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    updateImageSource();
  }, [image]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) {
    return null;
  }

  return (
    <Modal className={css.modal} onClose={onClose}>
      <div className={css.cropContainer}>
        {image ? (
          <Cropper
            ref={cropperRef}
            image={source}
            crop={crop}
            zoom={zoom}
            cropShape="round"
            cropSize={CROPPER_CROP_SIZE}
            onCropChange={handleCropChange}
            onCropComplete={onCropComplete}
            onMediaLoaded={handleImageLoaded}
            onZoomChange={setZoom}
            showGrid={false}
            minZoom={minZoom}
            zoomSpeed={0.25}
            restrictPosition={false}
          />
        ) : (
          <Button onClick={onChangeFile} icon="folder" type="secondary">
            Выбрать файл
          </Button>
        )}
      </div>
      {!!image && showMainControl && (
        <div className={css.isMainCheckbox}>
          <Checkbox id="isMainPhoto" title="Основное фото" onChange={setIsMain} checked={isMain} disabled={disabledMainControl} />
        </div>
      )}
      <div className={css.btnContainer}>
        <Button onClick={handleApply} icon="check">
          Применить
        </Button>
        {canDelete && image ? (
          <Button onClick={onClear} icon="trash" type="secondary">
            Удалить
          </Button>
        ) : (
          <Button onClick={onClose} icon="times" type="secondary">
            Отмена
          </Button>
        )}
      </div>
    </Modal>
  );
};

CropModal.propTypes = {
  canDelete: PropTypes.bool,
  defaultSelectedMain: PropTypes.bool,
  disabledMainControl: PropTypes.bool,
  image: PropTypes.object,
  onApply: PropTypes.func,
  onChangeFile: PropTypes.func,
  onClear: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  showMainControl: PropTypes.bool,
};

CropModal.defaultProps = {
  canDelete: false,
  defaultSelectedMain: false,
  disabledMainControl: false,
  image: undefined,
  onApply: noop,
  onChangeFile: noop,
  onClear: noop,
  onClose: noop,
  open: false,
  showMainControl: false,
};

export default CropModal;
