import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import axios from 'axios';
import moment from 'moment';
import { ROUTES_WAYS } from 'constants/routesWays';
import { DEFAULT_PAGINATION } from 'constants/pagination';

const createAction = actionsFactory('meetings/edit/');

export const cleanData = createAction('cleanData');

export const setSaveMeetingLoading = createAction('setSaveMeetingLoading');

export const setFederations = createAction('setFederations');
export const setFederationsLoading = createAction('setFederationsLoading');

export const setMeetingLoading = createAction('setMeetingLoading');
export const setCommonInfo = createAction('setCommonInfo');

export const setCasesInfo = createAction('setCasesInfo');
export const setAttachCasesLoading = createAction('setAttachCasesLoading');

export const setCompositionCasesInfo = createAction('setCompositionCasesInfo');
export const setCompositionCasesLoading = createAction('setCompositionCasesInfo');
export const setDeAttachCasesLoading = createAction('setDeAttachCasesLoading');

export const setDecisionsInfo = createAction('setDecisionsInfo');
export const setDecisionsLoading = createAction('setDecisionsLoading');
export const setSaveDeletingBlockLoading = createAction('setSaveDeletingBlockLoading');

export const setMembersInfo = createAction('setMembersInfo');
export const setMembersLoading = createAction('setMembersLoading');
export const setMembersSaveLoading = createAction('setMembersSaveLoading');

export const setMailingInfo = createAction('setMailingInfo');
export const setMailingLoading = createAction('setMailingLoading');
export const setMailingSaveLoading = createAction('setMailingSaveLoading');
export const setMailSaveLoading = createAction('setMailSaveLoading');

export const setAgenda = createAction('setAgenda');
export const setAgendaLoading = createAction('setAgendaLoading');
export const setSaveAgendaLoading = createAction('setSaveAgendaLoading');

export const setAgendaMailing = createAction('setAgendaMailing');
export const setAgendaMailingLoading = createAction('setAgendaMailingLoading');
export const setSaveAgendaMailLoading = createAction('setSaveAgendaMailLoading');

export const setNotification = createAction('setNotification');
export const setNotificationLoading = createAction('setNotificationLoading');

export const setDocuments = createAction('setDocuments');
export const setDocumentsLoading = createAction('setDocumentsLoading');

export const getCommonInfo = (meetingId) => async (dispatch) => {
  try {
    dispatch(setMeetingLoading(true));

    const url = `/api/rest/judiciary/meeting/${meetingId}`;

    const {
      data: { data },
    } = await axios.get(url);
    dispatch(setCommonInfo(data));
  } catch (err) {
    handleError(err);
    dispatch(setMeetingLoading(false));
  }
};

export const getFederations = () => async (dispatch) => {
  try {
    dispatch(setFederationsLoading(true));
    const url = '/api/rest/judiciary/currentOrganizations';

    const {
      data: { data },
    } = await axios.get(url);
    dispatch(setFederations(data));
  } catch (err) {
    handleError(err);
    dispatch(setFederationsLoading(false));
  }
};

export const saveMeeting = (data, successCallback) => async (dispatch) => {
  try {
    dispatch(setSaveMeetingLoading(true));
    const requestBody = {
      ...data,
      committee: {
        ...data.committee,
        federationId: data.federation.federationId,
      },
      meetingTime: +moment(data.meetingTime).format('x'),
    };

    const url = '/api/rest/judiciary/meeting/save';

    await axios.post(url, { ...requestBody });
    handleSuccess('Заседание успешно изменено!');
    if (successCallback) successCallback(ROUTES_WAYS.MEETING);

    dispatch(setSaveMeetingLoading(false));
  } catch (err) {
    handleError(err);
    dispatch(setSaveMeetingLoading(false));
  }
};

export const getCasesInfo = (meetingId, status, pagination = DEFAULT_PAGINATION) => async (dispatch) => {
  try {
    dispatch(setMeetingLoading(true));

    const { page, perPage } = pagination;

    const url = '/api/rest/judiciary/meeting/cases';

    const {
      data: { data, pageData = {} },
    } = await axios.get(url, { params: { meetingId, status } });

    const newPagination = {
      itemsCount: pageData.size,
      page,
      pageCount: pageData.countPage,
      perPage,
    };

    dispatch(setCasesInfo({ cases: data, pagination: newPagination }));
  } catch (err) {
    handleError(err);
    dispatch(setMeetingLoading(false));
  }
};

export const attachCases = (meetingId, caseIds) => async (dispatch) => {
  try {
    dispatch(setAttachCasesLoading(true));

    const url = `/api/rest/judiciary/meeting/${meetingId}/cases/save`;

    await axios.post(url, [...caseIds]);
    dispatch(setAttachCasesLoading(false));
    handleSuccess('Выбранные дела перенесены во вкладку Состав дел!');
  } catch (err) {
    handleError(err);
    dispatch(setAttachCasesLoading(false));
  }
};

export const getCompositionCasesInfo = (meetingId) => async (dispatch) => {
  try {
    dispatch(setCompositionCasesLoading(true));

    const url = '/api/rest/judiciary/meeting/composition-cases';

    const {
      data: { data },
    } = await axios.get(url, { params: { meetingId } });
    dispatch(setCompositionCasesInfo(data));
  } catch (err) {
    handleError(err);
    dispatch(setCompositionCasesLoading(false));
  }
};

export const deAttachCases = (meetingId, caseId) => async (dispatch) => {
  try {
    dispatch(setDeAttachCasesLoading(true));

    const url = `/api/rest/judiciary/meeting/${meetingId}/cases/${caseId}`;

    await axios.delete(url);
    dispatch(setDeAttachCasesLoading(false));
  } catch (err) {
    handleError(err);
    dispatch(setDeAttachCasesLoading(false));
  }
};

export const getDecisionsInfo = (meetingId) => async (dispatch) => {
  try {
    dispatch(setDecisionsLoading(true));

    const url = `/api/rest/judiciary/meeting/${meetingId}/decisions`;

    const {
      data: { data },
    } = await axios.get(url);
    dispatch(setDecisionsInfo(data));
  } catch (err) {
    handleError(err);
    dispatch(setDecisionsLoading(false));
  }
};

export const saveDeletingBlock = (meetingId, deleting, successCallback) => async (dispatch) => {
  try {
    dispatch(setSaveDeletingBlockLoading(true));

    const url = `/api/rest/judiciary/meeting/${meetingId}/protocol`;

    await axios.post(url, deleting, { headers: { 'Content-Type': 'text/html; charset=utf-8' } });

    dispatch(setSaveDeletingBlockLoading(false));
    if (successCallback) successCallback();
    handleSuccess('Изменения успешно применены!');
  } catch (err) {
    handleError(err);
    dispatch(setSaveDeletingBlockLoading(false));
  }
};

export const getMembersInfo = (meetingId) => async (dispatch) => {
  try {
    dispatch(setMembersLoading(true));

    const url = `/api/rest/judiciary/mailing/protocol/${meetingId}/members`;

    const {
      data: { data },
    } = await axios.get(url);
    dispatch(setMembersInfo(data));
  } catch (err) {
    handleError(err);
    dispatch(setMembersLoading(false));
  }
};

export const saveMembers = (meetingId, members) => async (dispatch) => {
  try {
    dispatch(setMembersSaveLoading(true));

    const url = `/api/rest/judiciary/mailing/protocol/${meetingId}`;

    await axios.post(url, members);
    handleSuccess('Участники успешно сохранены!');
    dispatch(setMembersInfo(members));
    dispatch(setMembersSaveLoading(false));
  } catch (err) {
    handleError(err);
    dispatch(setMembersSaveLoading(false));
  }
};

export const getMailingInfo = (meetingId) => async (dispatch) => {
  try {
    dispatch(setMailingLoading(true));

    const url = `/api/rest/judiciary/mailing/protocol/${meetingId}`;

    const {
      data: { data },
    } = await axios.get(url);
    dispatch(setMailingInfo(data));
  } catch (err) {
    handleError(err);
    dispatch(setMailingLoading(false));
  }
};

export const saveEmail = (isProtocol, meetingId, personData, rfsEmail, successCallback) => async (dispatch) => {
  try {
    dispatch(setMailSaveLoading(true));

    const url = `/api/rest/judiciary/mailing/protocol/${meetingId}/change${rfsEmail ? '/rfs' : ''}/email`;

    await axios.post(url, personData, rfsEmail ? { headers: { 'Content-Type': 'text/html; charset=utf-8' } } : undefined);
    handleSuccess('Email успешно сохранен!');
    if (successCallback) successCallback();
    dispatch(setMailSaveLoading(false));
  } catch (err) {
    handleError(err);
    dispatch(setMailSaveLoading(false));
  }
};

export const sentProtocol = (meetingId, whom, data, successCallback) => async (dispatch) => {
  try {
    dispatch(setMailingSaveLoading(true));

    const url = `/api/rest/judiciary/mailing/protocol/${meetingId}/send/${whom}`;

    const {
      data: { data: responseData },
    } = await axios({
      data: data.data,
      method: 'post',
      url,
    });
    dispatch(setMailingInfo(responseData));
    handleSuccess('Протокол успешно отправлен!');
    if (successCallback) successCallback();
    dispatch(setMailingSaveLoading(false));
  } catch (err) {
    handleError(err);
    dispatch(setMailingSaveLoading(false));
  }
};

export const getAgendaInfo = (meetingId) => async (dispatch) => {
  try {
    dispatch(setAgendaLoading(true));

    const url = `/api/rest/judiciary/meeting/${meetingId}/agenda`;

    const {
      data: { data },
    } = await axios.get(url);
    dispatch(setAgenda(data));
  } catch (err) {
    handleError(err);
    dispatch(setAgendaLoading(false));
  }
};

export const saveAgenda = (meetingId, data, successCallback) => async (dispatch) => {
  try {
    dispatch(setSaveAgendaLoading(true));

    const url = `/api/rest/judiciary/meeting/${meetingId}/agenda`;

    await axios.post(url, data);
    dispatch(setSaveAgendaLoading(false));
    if (successCallback) successCallback()
    handleSuccess('Изменения успешно применены!');
  } catch (err) {
    handleError(err);
    dispatch(setSaveAgendaLoading(false));
  }
};

export const getAgendaMailing = (meetingId) => async (dispatch) => {
  try {
    dispatch(setAgendaMailingLoading(true));

    const url = `/api/rest/judiciary/mailing/agenda/${meetingId}`;

    const {
      data: { data },
    } = await axios.get(url);
    dispatch(setAgendaMailing(data));
  } catch (err) {
    handleError(err);
    dispatch(setAgendaMailingLoading(false));
  }
};

export const saveAgendaMail = (meetingId, data) => async (dispatch) => {
  try {
    dispatch(setSaveAgendaMailLoading(true));

    const url = `/api/rest/judiciary/mailing/agenda/${meetingId}/change/email`;

    await axios.post(url, data);
    dispatch(setSaveAgendaMailLoading(false));
    handleSuccess('Изменения успешно применены!');
  } catch (err) {
    handleError(err);
    dispatch(setSaveAgendaMailLoading(false));
  }
};

export const sentAgendaMailing = (meetingId, whom, data) => async (dispatch) => {
  try {
    dispatch(setMailingSaveLoading(true));

    const url = `/api/rest/judiciary/mailing/agenda/${meetingId}/send/${whom}`;

    const {
      data: { data: responseData },
    } = await axios({
      data: data.data,
      method: 'post',
      url,
    });
    dispatch(setAgendaMailing(responseData));
    handleSuccess('Повестка успешно отправлена!');
    dispatch(setMailingSaveLoading(false));
  } catch (err) {
    handleError(err);
    dispatch(setMailingSaveLoading(false));
  }
};

export const getNotifications = (payload) => async (dispatch) => {
  try {
    const { meetingId, ...params } = payload;
    dispatch(setNotificationLoading(true));

    const url = `/api/rest/judiciary/mailing/agenda/${meetingId}/get/notification`;

    const {
      data: { data },
    } = await axios.get(url, { params });
    dispatch(setNotification(data));
  } catch (err) {
    handleError(err);
    dispatch(setNotificationLoading(false));
  }
};

export const getDocuments = (payload) => async (dispatch) => {
  try {
    const { meetingId, ...params } = payload;
    dispatch(setDocumentsLoading(true));

    const url = `/api/rest/judiciary/mailing/agenda/${meetingId}/documents`;

    const {
      data: { data },
    } = await axios.get(url, { params });
    dispatch(setDocuments(data));
  } catch (err) {
    handleError(err);
    dispatch(setDocumentsLoading(false));
  }
};

export const onSaveComment = (meetingId, data) => async () => {
  try {
    const url = `/api/rest/judiciary/mailing/agenda/${meetingId}/change/notification`;

    await axios.post(url, data);
    handleSuccess('Комментарий успешно сохранен!');
  } catch (err) {
    handleError(err);
  }
};

export const onSaveDocuments = (meetingId, data) => async () => {
  try {
    const url = `/api/rest/judiciary/mailing/agenda/${meetingId}/documents`;

    await axios.post(url, data);
    handleSuccess('Документы успешно сохранены!');
  } catch (err) {
    handleError(err);
  }
};
