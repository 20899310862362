import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import { selectPhotoGalleryData } from 'redux/selectors/Organization/photoGallerySelectors';

const createAction = actionsFactory('organization/photoGallery/');

export const clearData = createAction('clearData');
export const setPhotoLoading = createAction('setPhotoLoading');
export const setPhotoData = createAction('setPhotoData');

export const deletePhoto = (fileId, organizationId) => async (dispatch) => {
  try {
    await axios.delete(`/api/rest/organization/${organizationId}/photo/${fileId}`);

    dispatch(setPhotoData([]));

    handleSuccess('Фото успешно удалено!', 'trash');
  } catch (error) {
    handleError(error);
  }
};

export const setMainPhoto = (file, organizationId) => async (dispatch) => {
  try {
    await axios.post(`/api/rest/organization/${organizationId}/photo/add?fileStorageId=${file.id}`, null);

    dispatch(setPhotoData([{ ...file, mainPhoto: true }]));
    handleSuccess('Фото успешно установлено как основное!');
  } catch (error) {
    handleError(error);
  }
};
