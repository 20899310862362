import { createReducer } from 'redux-act';
import { isEmpty, get } from 'utils/lodash';
import axios from 'axios';
import actionsFactory from 'magic/actionsFactory';
import { DEFAULT_PAGINATION } from 'constants/pagination';
import { handleError } from 'magic/handlers';

const createAction = actionsFactory('applications/certification/list/');

const initialState = {
  incoming: {
    all: null,
    loaded: false,
    new: null,
    pagination: DEFAULT_PAGINATION,
  },
  loading: true,
  outgoing: {
    all: null,
    loaded: false,
    pagination: DEFAULT_PAGINATION,
  },
};

export const setLoading = createAction('setLoading');
export const setIncoming = createAction('setIncoming');
export const setIncomingPagination = createAction('setIncomingPagination');
export const setOutgoing = createAction('setOutgoing');
export const setOutgoingPagination = createAction('setOutgoingPagination');
export const clearData = createAction('clearData');

const createPagination = (pagination, statePagination) => {
  const defaultPerPage = isEmpty(pagination) ? DEFAULT_PAGINATION.perPage : statePagination.perPage;
  return {
    page: get(pagination, 'page', DEFAULT_PAGINATION.page),
    perPage: get(pagination, 'perPage', defaultPerPage),
  };
};

export const getIncoming = (pagePagination = {}, filters = {}) => async (dispatch, getState) => {
  try {
    const { pagination, new: newApplications, loaded } = getState().certificationApplications.list.incoming;
    // при первой подгрузке запустим общий лоадер
    if (!loaded) {
      dispatch(setLoading(true));
    }

    const incoming = {};
    const { page, perPage } = createPagination(pagePagination, pagination);

    if (!newApplications) {
      const newParams = { page: 0, pagesize: 20 };
      const newBody = { type: 'new' };
      const responseNew = await axios.post('/api/rest/process/infrastructure/inbox/statements', newBody, { params: newParams });
      incoming.new = responseNew.data.data;
    }

    const params = { page, pagesize: perPage };
    const body = { ...filters, type: 'all' };
    const response = await axios.post('/api/rest/process/infrastructure/inbox/statements', body, { params });
    const { data, pageData } = response.data;
    incoming.all = data;

    dispatch(setIncoming(incoming));
    dispatch(
      setIncomingPagination({
        itemsCount: get(pageData, 'size', data.length),
        page: get(pageData, 'numberPage', page),
        pageCount: get(pageData, 'countPage', 1),
        perPage: get(pageData, 'sizePage', perPage),
      }),
    );
  } catch (e) {
    handleError(e);
    dispatch(setLoading(false));
  }
};

export const getOutgoing = (pagePagination = {}, filters = {}) => async (dispatch, getState) => {
  try {
    const { pagination, loaded } = getState().certificationApplications.list.outgoing;
    // при первой подгрузке запустим общий лоадер
    if (!loaded) {
      dispatch(setLoading(true));
    }

    const { page, perPage } = createPagination(pagePagination, pagination);
    const params = { page, pagesize: perPage };
    const body = { ...filters, type: 'all' };
    const response = await axios.post('/api/rest/process/infrastructure/outbox/statements', body, { params });
    const { data, pageData } = response.data;

    dispatch(setOutgoing(data));
    dispatch(
      setOutgoingPagination({
        itemsCount: get(pageData, 'size', data.length),
        page: get(pageData, 'numberPage', page),
        pageCount: get(pageData, 'countPage', 1),
        perPage: get(pageData, 'sizePage', perPage),
      }),
    );
  } catch (e) {
    handleError(e);
    dispatch(setLoading(false));
  }
};

const reducer = createReducer(
  {
    [clearData]: () => initialState,
    [setIncoming]: (state, incoming) => ({
      ...state,
      incoming: {
        ...state.incoming,
        ...incoming,
        loaded: true,
      },
      loading: false,
    }),
    [setIncomingPagination]: (state, pagination) => ({
      ...state,
      incoming: {
        ...state.incoming,
        pagination,
      },
    }),
    [setLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
    [setOutgoing]: (state, outgoing) => ({
      ...state,
      loading: false,
      outgoing: {
        ...state.outgoing,
        all: outgoing,
        loaded: true,
      },
    }),
    [setOutgoingPagination]: (state, pagination) => ({
      ...state,
      outgoing: {
        ...state.outgoing,
        pagination,
      },
    }),
  },
  initialState,
);

export default reducer;
