import React, { useMemo } from 'react';

import { oneOfType, string, node, number } from 'prop-types';

import Icon from 'components/ui/Icon/Icon';

import css from './TextWithDivider.styles.scss';

export const getDateWithDateRange = (first, last, divider = '-') => {
  switch (true) {
    case Boolean(first && last):
      return `${first} ${divider} ${last}`;
    case Boolean(first):
      return first;
    case Boolean(last):
      return last;

    default:
      return '';
  }
};

export const getTextWithDivider = (first, last, divider = <Icon icon="arrows-h" size="1x" pack="fal" />) => {
  const _last = (() => {
    if (last) {
      return <span>{last}</span>;
    }

    return null;
  })();

  const _first = (() => {
    if (first) {
      return (
        <span className={css.textWithDividerFirstText}>
          {first}&nbsp;{last && divider}&nbsp;
        </span>
      );
    }

    return null;
  })();

  if (last || first) {
    return (
      <div className={css.textWithDividerContainer}>
        {_first}
        {_last}
      </div>
    );
  }

  return null;
};

export function TextWithDivider(props) {
  const { divider, first, last } = props;

  const textWithDivider = useMemo(() => getTextWithDivider(first, last, divider), [divider, first, last]);

  return textWithDivider;
}

TextWithDivider.defaultProps = {
  divider: <Icon icon="arrows-h" size="1x" pack="fal" />,
  first: '',
  last: '',
};

TextWithDivider.propTypes = {
  divider: oneOfType([string, node]),
  first: oneOfType([string, node, number]),
  last: oneOfType([string, node, number]),
};
