import { createReducer } from 'redux-act';
import {
  setCaseData,
  setCaseLoading,
  clearData,
  setDecisionsData,
  setDecisionsLoading,
  clearDecisions,
  setSaveDecisionLoading,
  addDecisions,
  setRemoveDecisionLoading,
  onRemoveDecision,
  setDocuments,
  setUploadDocumentLoading,
  setRemoveDocumentLoading,
  setSaveViolationLoading,
  setDocument,
} from 'redux/actions/Cases/editActions';

const decisionsInitState = {
  data: [],
  loading: false,
  removeLoading: false,
  saveLoading: false,
};

const initState = {
  data: {},
  decisions: decisionsInitState,
  loading: false,
  removeDocumentLoading: false,
  saveViolationLoading: false,
  uploadLoading: false,
};

export const getKeyValue = (key) => (key.person ? 'person' : key.organization ? 'organization' : undefined);
export const getKeyLabel = (value) => (value.person ? 'fio' : 'fullName');

const editReducer = createReducer(
  {
    [addDecisions]: (state, data) => ({
      ...state,
      decisions: {
        ...state.decisions,
        data: [...(state.decisions.data || []), ...data],
        saveLoading: false,
      },
    }),
    [clearData]: () => initState,
    [clearDecisions]: (state) => ({ ...state, decisions: decisionsInitState }),
    [onRemoveDecision]: (state, decisionId) => ({
      ...state,
      decisions: {
        ...state.decisions,
        data: [...state.decisions.data].filter(({ id }) => id !== decisionId),
        removeLoading: false,
      },
    }),
    [setCaseData]: (state, data) => {
      const { disciplinaryCaseDefendant, participants } = data.disciplinaryViolating || {};

      return {
        ...state,
        data: {
          ...data,
          disciplinaryViolating: data.disciplinaryViolating
            ? {
                ...data.disciplinaryViolating,
                disciplinaryCaseDefendant: disciplinaryCaseDefendant
                  ? {
                      ...disciplinaryCaseDefendant,
                      [getKeyValue(disciplinaryCaseDefendant)]: {
                        ...disciplinaryCaseDefendant[getKeyValue(disciplinaryCaseDefendant)],
                        [getKeyLabel(disciplinaryCaseDefendant)]:
                          getKeyLabel(disciplinaryCaseDefendant) === 'fio'
                            ? `
                      ${disciplinaryCaseDefendant.person.lastName || ''}
                      ${disciplinaryCaseDefendant.person.firstName || ''}
                      ${disciplinaryCaseDefendant.person.middleName || ''}`
                            : disciplinaryCaseDefendant.organization?.fullName,
                      },
                    }
                  : undefined,
                participants: participants?.map((item) => ({
                  ...item,
                  [getKeyValue(item)]: {
                    ...item[getKeyValue(item)],
                    [getKeyLabel(item)]: `${
                      getKeyLabel(item) === 'fullName'
                        ? item.organization?.fullName
                        : `${item.person?.lastName || ''} ${item.person?.firstName || ''} ${item.person?.middleName || ''}`
                    }`,
                  },
                })),
              }
            : undefined,
        },
        loading: false,
      };
    },
    [setCaseLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
    [setDecisionsData]: (state, data) => ({
      ...state,
      decisions: {
        ...state.decisions,
        data,
        loading: false,
      },
    }),
    [setDecisionsLoading]: (state, loading) => ({
      ...state,
      decisions: {
        ...state.decisions,
        loading,
      },
    }),
    [setDocument]: (state, documentId) => ({
      ...state,
      data: {
        ...state.data,
        documents: state.data.documents.filter((item) => item.id !== documentId),
      },
      removeDocumentLoading: false,
    }),
    [setDocuments]: (state, document) => ({
      ...state,
      data: {
        ...state.data,
        documents: [...state.data.documents, document],
      },
      uploadLoading: false,
    }),
    [setRemoveDecisionLoading]: (state, removeLoading) => ({
      ...state,
      decisions: {
        ...state.decisions,
        removeLoading,
      },
    }),
    [setRemoveDocumentLoading]: (state, removeDocumentLoading) => ({
      ...state,
      removeDocumentLoading,
    }),
    [setSaveDecisionLoading]: (state, saveLoading) => ({
      ...state,
      decisions: {
        ...state.decisions,
        saveLoading,
      },
    }),
    [setSaveViolationLoading]: (state, saveViolationLoading) => ({
      ...state,
      saveViolationLoading,
    }),
    [setUploadDocumentLoading]: (state, uploadLoading) => ({
      ...state,
      uploadLoading,
    }),
  },
  initState,
);

export default editReducer;
