import React from 'react';
import PT from 'prop-types';
import css from './index.scss';

class Required extends React.PureComponent {
  static propTypes = {
    children: PT.node,
    required: PT.bool,
  }
  static defaultProps = {
    required: false,
  }
  render () {
    const { children, required } = this.props;
    return required ?
      (
        <React.Fragment>
          <div className={css.label}>обязательное поле</div>
          <div className={css.container}>
            {children}
          </div>
        </React.Fragment>
      )
      : children;

  }
}

export default Required;

