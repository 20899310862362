import React from 'react';
import ReactDOM from 'react-dom';
import { library, dom } from '@fortawesome/fontawesome-svg-core';

import qs from 'qs';
import axios from 'axios';

import { faIconsLib } from './utils/faIconsLib';
import 'styles/index.scss';

// eslint-disable-next-line import/extensions
import 'normalize.css/normalize.css?external';
import AppProvider from './AppProvider';
import './buildInfo';

// eslint-disable-next-line
require('@babel/register');

library.add(...faIconsLib);
dom.watch();
axios.defaults.paramsSerializer = (params) => qs.stringify(params); // RFSEIAS-8344

const container = document.getElementById('container');

function renderApp(Component) {
  ReactDOM.render(<Component />, container);
}

renderApp(AppProvider);

if (module.hot) {
  module.hot.accept('./AppProvider', async () => {
    const NewAppProvider = await import('./AppProvider');
    renderApp(NewAppProvider.default);
  });
}
