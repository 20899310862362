import React from 'react';
import classnames from 'classnames/bind';

import { BlockTable } from 'components/rfs-ui';
import { Icon } from 'components/ui';

import css from './index.scss';

const cn = classnames.bind(css);

const CollapsibleTable = ({ children: data, columns, subComponent, className }) => (
  <BlockTable
    columns={[
      {
        Expander: ({ isExpanded }) =>
          <Icon icon="angle-down" className={cn(css.expanderIcon, { reverse: isExpanded })} size="2x" pack="fal" />,
        expander: true,
        className: cn(css.cell, css.expanderCell),
        headerClassName: css.expanderHeader,
        width: 41,
      },
      ...columns,
    ]}
    SubComponent={({ original }) => <div className={css.subContainer}>{subComponent(original)}</div>}
    className={className}
  >
    {data}
  </BlockTable>
);

export default CollapsibleTable;
