import addUser from './add-user.svg';
import addOrganization from './add-organization.svg';
import addObject from './add-object.svg';
import findUser from './find-user.svg';
import findOrganization from './find-organization.svg';
import findObject from './find-object.svg';
import transferIncoming from './transfer-incoming.png';
import transferOutgoing from './transfer-outgoing.png';
import minSport from './min-sport.png';
import transferNew from './transfer-new.svg';
import transferSubmit from './transfer-submit.svg';
import transferForm from './transfer-form.svg';
import competitions from './competitions.svg';
import matches from './matches.svg';
import participants from './participants.svg';
import organizations from './organizations.svg';
import infrastructures from './infrastructures.svg';
import filterBan from './filter-ban.svg';
import emptyOrgLogo from './empty-organization.png';
import administration from './administration.svg';
import training from './training.svg';
import jurisdiction from './jurisdiction.png';
import man from './man.svg';
import woman from './woman.svg';
import keyGray from './key-gray.svg';
import keyGreen from './key-green.svg';
import keyRed from './key-red.svg';
import pieChart from './pie-chart.svg';
import invalid from './invalid.svg';
import valid from './valid.svg';
import changeHistory from './change-history.svg';
import legionnaire from './legionnaire.svg';
import contracts from './contracts.svg';
import unarchive from './unarchive.svg'

import { socials } from './socials';

const icons = {
  addObject,
  addOrganization,
  addUser,
  administration,
  competitions,
  emptyOrgLogo,
  filterBan,
  findObject,
  findOrganization,
  findUser,
  infrastructures,
  invalid,
  jurisdiction,
  keyGray,
  keyGreen,
  keyRed,
  man,
  matches,
  minSport,
  organizations,
  participants,
  pieChart,
  training,
  transferForm,
  transferIncoming,
  transferNew,
  transferOutgoing,
  transferSubmit,
  valid,
  woman,
  changeHistory,
  socials,
  legionnaire,
  contracts,
  unarchive
};

export default icons;
