import { createReducer } from 'redux-act';
import { DEFAULT_PAGINATION } from 'constants/pagination';
import { clearData, setMeetingsData, setMeetingsLoading } from '../../actions/Meetings/viewActions';

const initState = {
  data: [],
  filters: {},
  loading: false,
  pagination: DEFAULT_PAGINATION,
};

const viewReducer = createReducer(
  {
    [clearData]: () => initState,
    [setMeetingsData]: (state, { data, pagination, filters }) => ({
      ...state,
      data,
      filters,
      loading: false,
      pagination,
    }),
    [setMeetingsLoading]: (state, loading) => ({
      ...state,
      loading,
    }),
  },
  initState,
);

export default viewReducer;
