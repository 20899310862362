import axios from 'axios';
import { get } from 'utils/lodash';

import { DADATA_TOKEN } from 'constants/tokens';
import dadataEndpoints from 'constants/dadataEndpoints';

export const getAddressInfo = async (query, locations) => {
  const {
    data: { suggestions },
  } = await axios({
    data: { count: 1, locations, query },
    headers: {
      Accept: 'application/json',
      Authorization: DADATA_TOKEN,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    url: dadataEndpoints.address,
  });

  return suggestions[0];
};

export const getAddressLocations = (dadata) => {
  const location = {
    city: get(dadata, 'data.city'),
    house: get(dadata, 'data.house'),
    region: get(dadata, 'data.region'),
    settlement: get(dadata, 'data.settlement'),
    street: get(dadata, 'data.street'),
  };

  return [location];
};

export const addressRestructure = (dadata) => {
  const dadataTimeZone = dadata.timezone || '';
  const timezoneMatch = dadataTimeZone.match(/UTC[+-]?\d{1,2}/);
  const timezone = timezoneMatch && timezoneMatch[0] ? timezoneMatch[0].substring(3) : undefined;

  return {
    city: get(dadata, 'data.city'),
    district: get(dadata, 'data.region_with_type'),
    geo_lat: get(dadata, 'data.geo_lat'),
    geo_lon: get(dadata, 'data.geo_lon'),
    house: get(dadata, 'data.house'),
    housing: get(dadata, 'data.block'),
    region: get(dadata, 'data.region'),
    region_kladr_id: get(dadata, 'data.region_kladr_id'),
    settlementWithType: get(dadata, 'data.settlement_with_type'),
    street: get(dadata, 'data.street_with_type'),
    timezone,
    value: get(dadata, 'value'),
    zipCode: get(dadata, 'data.postal_code'),
  };
};


export const legalRestructureNew = (data) => ({
  dateRegistr: data.state?.registrationDate  || null,
  dateUpdate: data.state?.actualityDate  || null, 
  forma: data.opf.full  || '',
  fullName: data.name.fullWithOpf  || '',
  inn: data.inn  || '',
  kpp: data.kpp  || '',
  legaladdress: addressRestructure(data.address || {}),
  ogrn: data.ogrn  || '',
  okpo: data.okpo  || '',
  shortName: data.name.shortWithOpf  || '',
  status: data.state?.status  || '',
});

export const legalRestructure = (dadata) => ({
  dateRegistr: get(dadata, 'data.state.registration_date'),
  dateUpdate: get(dadata, 'data.state.actuality_date'),
  forma: get(dadata, 'data.opf.full'),
  fullName: get(dadata, 'data.name.full_with_opf'),
  inn: get(dadata, 'data.inn'),
  kpp: get(dadata, 'data.kpp'),
  legaladdress: addressRestructure(get(dadata, 'data.address')),
  ogrn: get(dadata, 'data.ogrn'),
  okpo: get(dadata, 'data.okpo'),
  shortName: get(dadata, 'data.name.short_with_opf'),
  status: get(dadata, 'data.state.status'),
});

export const bankRestructure = (dadata) => ({
  address: addressRestructure(get(dadata, 'data.address')),
  bic: get(dadata, 'data.bic'),
  inn: get(dadata, 'data.inn'),
  kpp: get(dadata, 'data.kpp'),
  name: get(dadata, 'data.name.payment') || get(dadata, 'data.name.full') || get(dadata, 'data.name.short'),
  swift: get(dadata, 'data.swift'),
  value: get(dadata, 'value'),
});
