/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames/bind';

import Label from 'components/ui/Label/Label';
import { Input } from 'components/rfs-ui';
import { Icon, Button } from 'components/ui';
import dateFormats from 'constants/dateFormats';
import { makeMaskByFormat, isValidDate, makeDateString } from 'magic/date';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import Datepicker from './Datepicker';
import Timepicker from './Timepicker';

import css from './index.scss';

const cn = classnames.bind(css);
const MIN_YEAR = 1800;
const MAX_YEAR = 2500;
const DEFAULT_TIME = '00:00';

class DateTimePicker extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    classNames: PropTypes.object,
    dateFormat: PropTypes.string,
    disabled: PropTypes.bool,
    disabledDays: PropTypes.shape({
      after: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
      before: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    }),
    doValidate: PropTypes.bool,
    error: PropTypes.string,
    iconBefore: PropTypes.oneOfType([PropTypes.node]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isInvalid: PropTypes.bool,
    light: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    timeFormat: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    withTime: PropTypes.bool,
  };

  static defaultProps = {
    classNames: {},
    dateFormat: dateFormats.APP_DATE_FORMAT,
    doValidate: false,
    error: '',
    isInvalid: false,
    timeFormat: dateFormats.APP_TIME_FORMAT,
  };

  state = {
    date: '',
    showDatepicker: false,
    showTimepicker: false,
    time: '',
  };

  dateTimePickerRef = React.createRef();

  datePickerRef = React.createRef();

  componentDidMount() {
    this.setValueToState();
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if ((prevProps.value || value) && prevProps.value !== value) {
      this.setValueToState();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.focusTimeout);
  }

  setValueToState = () => {
    const { value, dateFormat, timeFormat, withTime } = this.props;

    if (value || value === 0) {
      const date = makeDateString(value, withTime || value instanceof Date, dateFormat);
      const time = withTime ? makeDateString(value, true, timeFormat) : '';
      this.setState({ date, time });
    } else {
      this.setState({ date: '', time: '' });
    }
  };

  makeFormat = () => {
    const { withTime, dateFormat, timeFormat } = this.props;

    return withTime ? `${dateFormat} ${timeFormat}` : dateFormat;
  };

  showDatepicker = () => {
    const { showDatepicker } = this.state;
    // делаем фокус на инпут, чтобы сработал handleInputFocus
    this.inputRef.focus();
    if (!showDatepicker) {
      this.setState({ showDatepicker: true, showTimepicker: false });
    }
  };

  showTimepicker = () => {
    const { showTimepicker } = this.state;
    // делаем фокус на инпут, чтобы сработал handleInputFocus
    this.inputRef.focus();
    if (!showTimepicker) {
      this.setState({ showDatepicker: false, showTimepicker: true });
    }
  };

  checkValueOnBlur = () => {
    const { date, time } = this.state;
    const dateTime = `${date} ${time}`.trim();
    const format = this.makeFormat();

    if (isValidDate(dateTime, format)) {
      this.handleChangeValue(dateTime);
    } else {
      const hasNumbers = /\d/.test(dateTime);

      this.setState({ date: '', time: '' });
      if (!hasNumbers) {
        this.handleChangeValue('');
      }
    }
  };

  handleClickOutside = (event) => {
    const { current } = this.dateTimePickerRef;
    const { showTimepicker } = this.state;

    if (current && !current.contains(event.target)) {
      document.removeEventListener('mousedown', this.handleClickOutside, { capture: true });
      this.checkValueOnBlur();
      this.setState({ showDatepicker: false, showTimepicker: false });
    }

    // закроем дейтпикер после выбора даты
    if (showTimepicker) {
      this.setState({ showDatepicker: false });
    }
  };

  handleChangeValue = (value) => {
    const { onChange, withTime } = this.props;
    if (!value) {
      onChange(value);
    } else {
      const format = this.makeFormat();
      const momentDate = withTime ? moment(value, format, true) : moment.utc(value, format, true);
      onChange(new Date(momentDate).getTime());
    }
  };

  handleInputFocus = () => {
    const { date, time } = this.state;
    const dateTime = `${date} ${time}`.trim();

    // Помещаем курсор в начало инпута, если его значение пустое
    if (!dateTime) {
      this.focusTimeout = setTimeout(() => {
        this.inputRef.selectionStart = 0;
        this.inputRef.selectionEnd = 0;
      }, 0);
    }

    // https://gost-jira.atlassian.net/browse/RFSEIAS-3680
    // capture: true -  для того, чтобы сначала ловить клики на document, иначе клики по кнопкам срабатывают раньше,
    // чем вызовется handleChangeValue
    document.addEventListener('mousedown', this.handleClickOutside, { capture: true });
    this.setState({ showDatepicker: true, showTimepicker: false });
  };

  handleInputChange = (value) => {
    const [date, time = ''] = value.split(' ');

    this.setState({ date, time }, () => {
      const { showDatepicker, showTimepicker } = this.state;

      // своего рода костыль:
      // после выбора даты, дальнейшеего ее выделения с помощью мыши происходит blur и дейтпикер закрывается
      // (поскольку выделение даты включает в себя mousedown и в конце mouseup)
      // костыль иммитирует проверку значения, когда выделенную дату стерли после blur
      if (!showDatepicker && !showTimepicker) {
        this.checkValueOnBlur();
      }
    });
  };

  handleDateChange = (date, closeDatepicker = false) => {
    if (this.props.chageOnDayPick) {
      this.handleChangeValue(date);
    }
    const { withTime } = this.props;

    if (withTime) {
      this.setState(
        ({ time }) => ({
          date,
          time: time || DEFAULT_TIME,
          showDatepicker: false,
          showTimepicker: closeDatepicker,
        }),
        () => this.checkValueOnBlur(),
      );
    } else {
      this.setState({ date, showDatepicker: !closeDatepicker }, () => this.checkValueOnBlur());
    }
  };

  handleTimeChange = (time) => {
    this.setState(
      ({ date }) => ({ date, time }),
      () => this.checkValueOnBlur(),
    );
  };

  handleEnterPress = (event) => {
    if (event.key === 'Enter' || event.charCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ showDatepicker: false, showTimepicker: false }, this.checkValueOnBlur);
      document.removeEventListener('mousedown', this.handleClickOutside, { capture: true });
      this.inputRef.blur();
    }
  };

  renderCalendarIcon = () => {
    const { withTime, dateFormat } = this.props;
    const { date, showDatepicker, showTimepicker } = this.state;
    const datepickerIconClass = cn(css.buttonIcon, {
      activeIcon: showDatepicker,
    });
    const timepickerIconClass = cn(css.buttonIcon, {
      activeIcon: showTimepicker,
    });

    return (
      <div className={css.buttonsContainer}>
        <Button type="text" onClick={this.showDatepicker}>
          <Icon icon="calendar-alt" pack="fal" className={datepickerIconClass} />
        </Button>
        {withTime && (
          <Button type="text" onClick={this.showTimepicker} disabled={!isValidDate(date, dateFormat)}>
            <Icon icon="clock" pack="fal" className={timepickerIconClass} />
          </Button>
        )}
      </div>
    );
  };

  render() {
    const {
      id,
      title,
      required,
      disabled,
      placeholder,
      dateFormat,
      timeFormat,
      className,
      disabledDays,
      classNames,
      iconBefore,
      light,
      doValidate,
      isInvalid,
      error,
    } = this.props;
    const { date, time, showDatepicker, showTimepicker } = this.state;
    const format = this.makeFormat();
    const value = `${date} ${time}`;
    const isRequired = required && !disabled;
    const inputPlaceholder = placeholder || dateFormats.APP_DATE_FORMAT_RUS;
    const inputClassName = required && css.required;
    const inputContainerClassName = cn(css.inputContainer, classNames.inputContainerClassName);

    return (
      <div className={cn(css.container, className)}>
        {title && (
          <Label className={css.datepickerLabel} disabled={disabled} required={isRequired} htmlFor={id}>
            {title}
          </Label>
        )}
        <div className={css.dateTimePickerContainer} ref={this.dateTimePickerRef}>
          <Input
            mask={makeMaskByFormat(format)}
            pipe={createAutoCorrectedDatePipe(format.toLowerCase(), {
              maxYear: MAX_YEAR,
              minYear: MIN_YEAR,
            })}
            keepCharPositions
            value={value.trim() || undefined}
            onChange={this.handleInputChange}
            actionIcon={!disabled && this.renderCalendarIcon()}
            iconBefore={iconBefore}
            placeholder={inputPlaceholder}
            inputClassName={cn(inputClassName, classNames.inputClassName)}
            className={inputContainerClassName}
            id={id}
            onFocus={this.handleInputFocus}
            onKeyPress={this.handleEnterPress}
            doValidate={doValidate}
            refer={(input) => {
              this.inputRef = input;
            }}
            disabled={disabled}
            light={light}
            valid={!isInvalid}
            required={isRequired}
            error={error}
          />
          {showDatepicker && (
            <Datepicker
              disabled={disabled}
              value={date}
              minYear={MIN_YEAR}
              maxYear={MAX_YEAR}
              format={dateFormat}
              onChange={this.handleDateChange}
              disabledDays={disabledDays}
            />
          )}
          {showTimepicker && <Timepicker value={time} format={timeFormat} onChange={this.handleTimeChange} />}
        </div>
      </div>
    );
  }
}

export default DateTimePicker;
