import actionsFactory from 'magic/actionsFactory';
import axios from 'axios';

const createAction = actionsFactory('competitionIntegration/');

export const setCurrentCompetitionsAddress = createAction('setCurrentCompetitionsAddress');

export const getCurrentCompetitionsAddress = () => async (dispatch) => {
    try {
      const response = await axios.get('/api/rest/integration/competition/host');
      dispatch(setCurrentCompetitionsAddress(response.data.data));
    } catch (err) {
      console.log(err);
    }
  };