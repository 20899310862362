exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* ------------------------ \nCommon\n--------------------------*/\n\n\n/* layouts width*/\n\n\n/* ------------------------ \nDark Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* input*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nLight Theme \n--------------------------*/\n\n\n/* colors*/\n\n\n/* modal content padding*/\n\n\n/* ------------------------ \nRating\n--------------------------*/\n\n\n.valuesContainer-Hzm2q {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-top: 13px;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-column-gap: 10px;\n     -moz-column-gap: 10px;\n          column-gap: 10px;\n  row-gap: 10px;\n}\n\n\n.selectedItem-1A1mo {\n  padding: 8px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  background-color: #eaf6ff;\n  -webkit-column-gap: 10px;\n     -moz-column-gap: 10px;\n          column-gap: 10px;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n\n\n.selectedItem-1A1mo > svg {\n    cursor: pointer;\n    font-size: 20px;\n  }\n\n\n.customOption-2x2JI {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-column-gap: 12px;\n     -moz-column-gap: 12px;\n          column-gap: 12px;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  padding-left: 14px;\n}\n", ""]);

// exports
exports.locals = {
	"valuesContainer": "valuesContainer-Hzm2q",
	"selectedItem": "selectedItem-1A1mo",
	"customOption": "customOption-2x2JI"
};