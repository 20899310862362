import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError } from 'magic/handlers';

const createAction = actionsFactory('permissions');

export const clearData = createAction('clearData');
export const setUserOrganizations = createAction('setUserOrganizations');

export const getUserOrganizations = (permissionSysName, orgType) => async (dispatch) => {
  try {
    const params = { orgType, permissionSysName };
    const response = await axios.get('/api/rest/permission/organizations', { params });

    dispatch(setUserOrganizations(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setUserOrganizations([]));
  }
};
