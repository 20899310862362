import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import { handleError } from 'magic/handlers';
import { DEFAULT_PAGINATION } from 'constants/pagination';
import get from 'lodash/get';
import axios from 'axios';

const createAction = actionsFactory('duplication/');

const initialState = {
  accuracies: {},
  comment: '',
  duplicates: [],
  filters: {},
  inProgress: false,
  loaded: false,
  original: null,
  pagination: DEFAULT_PAGINATION,
  results: null,
  withRemoved: false,
};

// actions
export const setParams = createAction('setParams');
export const setFilters = createAction('setFilters');
export const clearFilters = createAction('clearFilters');
export const clearData = createAction('clearData');
export const startLoading = createAction('startLoading');
export const setResults = createAction('setResults');
export const setAccuracy = createAction('setAccuracy');
export const clearAccuracies = createAction('clearAccuracies');
export const clearResults = createAction('clearResults');
export const setOriginal = createAction('setOriginal');
export const setDuplicates = createAction('setDuplicates');
export const setComment = createAction('setComment');
export const setPagination = createAction('setPagination');
export const stopLoadingWithError = createAction('stopLoadingWithError');

export const findByCharacter = (objectType, pagePagination = {}) => async (dispatch, getState) => {
  dispatch(clearResults());
  dispatch(startLoading());
  try {
    const { pagination = {}, filters, accuracies, withRemoved } = getState().duplication;
    const { page = DEFAULT_PAGINATION.page, perPage = pagination.perPage } = pagePagination;

    const res = await axios.post(`/api/rest/${objectType}/find/duplicate?page=${page}&pagesize=${perPage}`, {
      withRemoved,
      ...filters,
      ...accuracies,
    });

    const { data, pageData } = res.data;

    dispatch(setResults(data));
    dispatch(
      setPagination({
        itemsCount: get(pageData, 'size', data.length),
        page: get(pageData, 'numberPage', page),
        pageCount: get(pageData, 'countPage', 1),
        perPage: get(pageData, 'sizePage', perPage),
      }),
    );
  } catch (e) {
    handleError(e);
    dispatch(stopLoadingWithError());
  }
};

export const findOrganizations = (pagePagination) => async (dispatch, getState) => {
  dispatch(clearResults());
  dispatch(startLoading());
  try {
    const { pagination = {}, filters = {}, withRemoved } = getState().duplication;
    const { organizationName, organizationType = {} } = filters;
    const { page = pagination.page, perPage = pagination.perPage } = pagePagination;

    const res = await axios.post(`/api/rest/organization/find/org?page=${page}&pagesize=${perPage}`, {
      isDuplicate: withRemoved,
      searchText: organizationName,
      type: organizationType.id,
    });

    const { data, pageData } = res.data;

    dispatch(setResults(data));
    dispatch(
      setPagination({
        itemsCount: get(pageData, 'size', data.length),
        page: get(pageData, 'numberPage', page),
        pageCount: get(pageData, 'countPage', 1),
        perPage: get(pageData, 'sizePage', perPage),
      }),
    );
  } catch (e) {
    handleError(e);
    dispatch(stopLoadingWithError());
  }
};

export const findPersonsByCharacter = (pagePagination) => findByCharacter('person', pagePagination);

export const findOrganizationsByCharacter = (pagePagination) => findByCharacter('organization', pagePagination);

export const mergeDuplicates = (objectType) => async (dispatch, getState) => {
  try {
    const { original, duplicates, comment } = getState().duplication;

    await axios.post(`/api/rest/${objectType}/merge/duplicate`, {
      comment,
      duplicateIds: duplicates.map((d) => d.id),
      standardId: original.id,
    });

    dispatch(setOriginal(null));
    dispatch(setDuplicates([]));
  } catch (e) {
    handleError(e);
  }
};

// reducer
const reducer = createReducer(
  {
    [clearAccuracies]: (state) => ({
      ...state,
      accuracies: initialState.accuracies,
    }),
    [clearData]: () => initialState,
    [clearFilters]: (state) => ({
      ...state,
      filters: initialState.filters,
    }),
    [clearResults]: (state) => ({
      ...state,
      loaded: false,
      results: initialState.results,
    }),
    [setAccuracy]: (state, accuracies) => ({
      ...state,
      accuracies: {
        ...state.accuracies,
        ...accuracies,
      },
    }),
    [setComment]: (state, comment) => ({
      ...state,
      comment,
    }),
    [setDuplicates]: (state, duplicates) => ({
      ...state,
      duplicates,
    }),
    [setFilters]: (state, filters) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }),
    [setOriginal]: (state, original) => ({
      ...state,
      original,
    }),
    [setPagination]: (state, pagination) => ({
      ...state,
      pagination,
    }),
    [setParams]: (state, params) => ({
      ...state,
      ...params,
    }),
    [setResults]: (state, results) => ({
      ...state,
      inProgress: false,
      loaded: true,
      results,
    }),
    [startLoading]: (state) => ({
      ...state,
      inProgress: true,
    }),
    [stopLoadingWithError]: (state) => ({
      ...state,
      inProgress: false,
      loaded: false,
    }),
  },
  initialState,
);

export default reducer;
