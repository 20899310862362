import { number, string } from 'prop-types';

export const PTTransferReasonView = {
  /**
   * @description Описание
   */
  description: string,
  /**
   * @description Идентификатор
   */
  id: number,
  /**
   * @description Наименование
   */
  name: string.isRequired,
  /**
   * @description Системеное наименование
   */
  sysName: string,
  /**
   * @description Идентификатор с фронта
   */
  tempId: string,
};
