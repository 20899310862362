import { createReducer } from 'redux-act';
import { clearData, setPhotoData, setPhotoLoading } from 'redux/actions/Profile/photoGalleryActions';

const initialState = {
  data: [],
  isLoading: false,
};

const photoGalleryReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setPhotoData]: (state, data) => ({
      ...state,
      data,
    }),
    [setPhotoLoading]: (state, isLoading) => ({
      ...state,
      isLoading,
    }),
  },
  initialState,
);

export default photoGalleryReducer;
