import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ru';
import range from 'lodash/range';

import { addZero } from 'magic/date';

class Calendar extends React.PureComponent {
  static propTypes = {
    date: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    minYear: PropTypes.number,
    maxYear: PropTypes.number,
  };

  years = range(this.props.minYear, this.props.maxYear + 1);

  handleChange = (e) => {
    const { date, onChange } = this.props;
    const { year, month } = e.target.form;
    // Добавляем нолики, иначе падает. Ставим день первый, если он не существует в другом месяце
    onChange(new Date(year.value, addZero(month.value), addZero(date.getDay()) || 1));
  };

  render () {
    const { date } = this.props;

    return (
      <form className="DayPicker-Caption">
        <select name="year" onChange={this.handleChange} value={date.getFullYear()}>
          {this.years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <select name="month" onChange={this.handleChange} value={date.getMonth()}>
          {moment.months().map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
      </form>
    );
  }
}

export default Calendar;

