import { Preloader } from 'components/ui';
import PropTypes from 'prop-types';
import React from 'react';

const AsyncWrapper = ({ loading, children }) => (loading ? <Preloader size="8x" /> : <>{children}</>);

AsyncWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default AsyncWrapper;
