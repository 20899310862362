import { createReducer } from 'redux-act';

import { setTriggers, setEvents, setPayments } from 'redux/actions/PayConstructor/dictionaries';

const initialState = {
  events: [],
  payments: [],
  triggers: [],
};

const dictionariesReducer = createReducer(
  {
    [setEvents]: (state, events) => ({
      ...state,
      events,
    }),
    [setPayments]: (state, payments) => ({
      ...state,
      payments,
    }),
    [setTriggers]: (state, triggers) => ({
      ...state,
      triggers,
    }),
  },
  initialState,
);

export default dictionariesReducer;
