import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import { Button, Link } from 'components/ui';

import css from './TopBarItem.scss';

const cn = classnames.bind(css);

const TopBarItem = ({ title, link, active }) => {
  const titleClassName = useMemo(() => cn(css.title, { active }), [active]);

  const button = (
    <Button type="text">
      <span className={titleClassName}>{title}</span>
    </Button>
  );

  if (active) {
    return <div className={css.link}>{button}</div>;
  }

  return (
    <Link className={css.link} to={link}>
      {button}
    </Link>
  );
};

TopBarItem.propTypes = {
  active: PropTypes.bool,
  link: PropTypes.string,
  title: PropTypes.string.isRequired,
};

TopBarItem.defaultProps = {
  active: false,
  link: undefined,
};

export default React.memo(TopBarItem);
