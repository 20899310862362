import React, { Fragment, useCallback, useMemo } from 'react';
import { bool, func, node, number, oneOfType, string } from 'prop-types';
import cn from 'classnames';
import Icon from 'components/ui/Icon/Icon';

import styles from './Avatar.scss';

export default function Avatar(props) {
  const {
    alt,
    circle,
    className,
    customIcon,
    icon,
    image,
    imageBase64,
    isLoading,
    isLogo,
    onClick,
    overlayIcon,
    placeholder,
    size,
  } = props;

  const backgroundSize = isLogo ? 'contain' : 'cover';

  const renderAvatarFromBase64 = useCallback(
    () => (
      <img
        style={{
          height: '100%',
          objectFit: 'cover',
          width: '100%',
        }}
        src={imageBase64.indexOf('data:image/jpeg;base64') !== -1 ? imageBase64 : `data:image/jpeg;base64,${imageBase64}`}
        alt={alt}
      />
    ),
    [alt, imageBase64],
  );

  const renderAvatarFromImage = useCallback(
    () => (
      <Fragment>
        {image && <div className={styles.imageContainer} style={{ backgroundImage: `url("${image}")`, backgroundSize }} />}
        {!image &&
          (customIcon || (
            <div className={styles.iconContainer}>
              <Icon icon={icon || placeholder} size="4x" />
            </div>
          ))}
      </Fragment>
    ),
    [image, backgroundSize, icon, placeholder, customIcon],
  );

  const renderAvatar = () => (imageBase64 ? renderAvatarFromBase64() : renderAvatarFromImage());

  const style = useMemo(
    () => ({
      backgroundColor: image ? '#fff' : '#d8d8d8',
      borderRadius: circle ? '50%' : 0,
      cursor: onClick ? 'pointer' : 'inherit',
      fontSize: `${size / 10 / 14}rem`, // Супер хак чтобы иконка всегда была пропорциональна контейнеру
      height: `${size}px`,
      width: `${size}px`,
    }),
    [image, circle, onClick, size],
  );

  return (
    <div className={cn(styles.avatar, className)} style={style} onClick={onClick} role="button">
      {renderAvatar()}
      {overlayIcon && (
        <div className={styles.overlay}>
          <Icon icon={overlayIcon} size="4x" />
        </div>
      )}
      {isLoading && (
        <div className={styles.loading}>
          <Icon icon="spinner-third" spin size="4x" />
        </div>
      )}
    </div>
  );
}

Avatar.propTypes = {
  alt: string,
  circle: bool,
  className: string,
  customIcon: oneOfType([string, node]),
  icon: string,
  image: string,
  imageBase64: string,
  isLoading: bool,
  isLogo: bool,
  onClick: func,
  overlayIcon: string,
  placeholder: string,
  size: number,
};

Avatar.defaultProps = {
  alt: '',
  circle: false,
  className: undefined,
  customIcon: undefined,
  icon: '',
  image: '',
  imageBase64: '',
  isLoading: false,
  isLogo: false,
  onClick: undefined,
  overlayIcon: '',
  placeholder: 'camera',
  size: 140,
};
