import React, { useState } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import Async from '../Async';
import css from './styles.scss';
import { Icon } from '../../../ui';
import CustomOption from './CustomOption';
import { makeStyles } from './styles';
import Reference from '../Reference';

const MultiAsync = ({ onChange, values, labelKey, reference, valueKey, onlyValue, ...otherProps }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const isLabelKeyFn = typeof labelKey === 'function';

  const onInputChange = (query, { action }) => {
    if (action !== 'set-value') setInputValue(query);
  };

  const onDelete = (option) => {
    const filtered = values.filter((el) => {
      const value = onlyValue ? el : el[valueKey];
      return option !== value;
    });
    onChange(filtered);
  };

  const getOptions = (value) => {
    const optionValues = options.map((el) => el[valueKey]);
    const filtered = value.filter((el) => !optionValues.includes(el[valueKey]));
    setOptions([...options, ...filtered]);
  };

  const filteredOptions = onlyValue && options.length ? options.filter((el) => values.includes(el[valueKey])) : values;

  const selectedOptions = onlyValue ? filteredOptions : (values || []).flatMap((item) => {
    if(!options.length) return [item];
    if (item[labelKey]) return [item];
    return [{
      ...item,
      [labelKey]: options.find((option) => option[valueKey] === item[valueKey])[labelKey],
    }];
  });

  const Component = reference ? Reference : Async;

  return (
    <div>
      <Component
        styles={makeStyles}
        isMulti
        value={values}
        components={{ Option: CustomOption }}
        labelKey={labelKey}
        onChange={onChange}
        inputValue={inputValue}
        onInputChange={onInputChange}
        getOptions={getOptions}
        valueKey={valueKey}
        {...otherProps}
        autoLoad={false}
        autoBlur={false}
        clearable={false}
        closeOnSelect={false}
        removeSelected={false}
        closeMenuOnSelect={false}
        onSelectResetsInput={false}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
      />
      <div className={css.valuesContainer}>
        {selectedOptions?.filter((el) => el).map((el) => {
          const value = onlyValue ? el : el[valueKey];
          const label = onlyValue ? el : el[labelKey];
          return (
            <div key={value} className={css.selectedItem}>
              {isLabelKeyFn ? labelKey(el) : label}
              <Icon onClick={() => onDelete(value)} icon="times" size="1x" pack="fal" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

MultiAsync.propTypes = {
  values: PropTypes.array,
  onChange: PropTypes.func,
  labelKey: oneOfType([PropTypes.func, PropTypes.string]),
  reference: PropTypes.bool,
  onlyValue: PropTypes.bool,
  valueKey: PropTypes.string,
};

MultiAsync.defaultProps = {
  values: [],
  onChange: () => {},
  labelKey: '',
  valueKey: '',
  reference: false,
  onlyValue: false,
};

export default MultiAsync;
