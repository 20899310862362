import axios from 'axios';
import { handleError } from 'magic/handlers';
import { setRatingCalculateTotalRequest, setRatingCalculateTotalComplete } from './actions';

export const calculateTotalRating = (ratings) => async (dispatch) => {
  const hash = `ratings_${Date.now()}`;

  try {
    dispatch(setRatingCalculateTotalRequest(hash));

    const {
      data: { data },
    } = await axios.post('/api/rest/rating/calculate-total', ratings);

    return data;
  } catch (err) {
    handleError(err);

    return undefined;
  } finally {
    dispatch(setRatingCalculateTotalComplete(hash));
  }
};
