import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PermissionsContext from './PermissionsContext';

const PermissionsProvider = ({ children, user }) => {
  const permissions = useMemo(() => {
    if (user) {
      const global = user.authorities;
      const orgs = user.oranizations;
      return { global, orgs };
    }

    return {};
  }, [user]);

  return <PermissionsContext.Provider value={permissions}>{children}</PermissionsContext.Provider>;
};

PermissionsProvider.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
};

PermissionsProvider.defaultProps = {
  children: null,
  user: null,
};

const mapStateToProps = (state) => ({
  user: state.core.session.user,
});

export default connect(mapStateToProps)(PermissionsProvider);
