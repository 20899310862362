import { createReducer } from 'redux-act';

import { addAppealType, clearData, setAppealsTypes, setAppealsGroups } from 'redux/actions/Appeals/constructorActions';

const initialState = {
  groups: [],
  types: [],
};

const constructorReducer = createReducer(
  {
    [addAppealType]: (state, newType) => ({
      ...state,
      types: [...state.types, newType],
    }),
    [clearData]: () => ({ ...initialState }),
    [setAppealsGroups]: (state, groups) => ({
      ...state,
      groups,
    }),
    [setAppealsTypes]: (state, types) => ({
      ...state,
      types,
    }),
  },
  initialState,
);

export default constructorReducer;
