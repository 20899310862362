import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import Input from '../Input/Input';
import css from './EmailInput.scss';

const EmailInput = ({ className, onChange, refer, ...props }) => {
  const [isActive, setIsActive] = useState(false);
  const validationTimeoutRef = useRef();
  const inputRef = useRef();

  const containerClassName = useMemo(() => cn(css.container, className), [className]);
  const messageClassName = useMemo(() => cn(css.message, { [css.active]: isActive }), [isActive]);

  const handleChange = useCallback(
    (value) => {
      const val = value && String(value).toLocaleLowerCase();
      if (value !== val) {
        setIsActive(true);
        const pos = inputRef.current.selectionStart;
        clearTimeout(validationTimeoutRef.current);
        validationTimeoutRef.current = setTimeout(() => setIsActive(false), 1000);
        setTimeout(() => inputRef.current.setSelectionRange && inputRef.current.setSelectionRange(pos, pos), 0);
      }
      onChange(val);
    },
    [onChange],
  );

  const handleRefer = useCallback(
    (ref) => {
      inputRef.current = ref;
      if (refer) {
        refer(ref);
      }
    },
    [refer],
  );

  useEffect(() => () => clearTimeout(validationTimeoutRef.current), []);

  return (
    <div className={containerClassName}>
      <Input {...props} className={css.inputContainer} onChange={handleChange} refer={handleRefer} type="text" />
      <div className={messageClassName}>применение заглавных букв запрещено</div>
    </div>
  );
};

EmailInput.propTypes = Input.propTypes;

export default EmailInput;
