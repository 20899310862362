export const restructureData = (data) => {
  const {
    country,
    externalStatus,
    district,
    value,
    zipCode,
    email,
    phone,
    url,
    legShortName,
    legShortNameEn,
    shortNameEn,
    fullNameEn,
    ...deprecatedData
  } = data;
  const hasContacts = data.district || data.value || data.zipCode || data.email || data.phone || data.url;
  return {
    ...deprecatedData,
    isForeign: true,
    countryId: data.country?.id,
    legalStatusId: data.externalStatus?.id,
    legalId: data.legal?.id,
    legShortName: legShortName ? legShortName : deprecatedData?.shortName || deprecatedData?.fullName || '',
    legShortNameEn: legShortNameEn ? legShortNameEn : shortNameEn || fullNameEn || '',
    sportNameEn: fullNameEn,
    contactsOrganization: [
      hasContacts && {
        address: {
          district: data.district,
          value: data.value,
          zipCode: data.zipCode,
        },
        email: data.email,
        phone: data.phone,
        url: data.url,
        isMainInfo: true,
        title: 'Основные контакты',
      },
    ],
  };
};

export const restructureDataForEdit = (data) => {
  const { country, externalStatus, district, value, zipCode, email, phone, url, ...deprecatedData } = data;
  return {
    ...deprecatedData,
    countryId: data.country?.id,
    legalStatusId: data.externalStatus?.id,
    legalId: data.legal?.id,
  };
};
