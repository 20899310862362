import { createSelector } from 'reselect';

const selectRecordsState = (state) => state.dictionaries.records;

export const selectDictionaryRecordsData = createSelector(selectRecordsState, (state) => state.data);
export const selectDictionaryRecordsStatus = createSelector(selectRecordsState, (state) => state.status);
export const selectDictionaryFilters = createSelector(selectRecordsState, (state) => state.filters);
export const selectDictionaryFields = createSelector(selectDictionaryFilters, (filters) => filters.dictionary?.fields || []);

const makeFieldValue = (value, field) => {
  if (!value) {
    return undefined;
  }

  if (field.url) {
    const { id, name } = value;

    return id && name ? { id, name } : undefined;
  }

  return value.content || undefined;
};

const normalizeRecord = (record) =>
  record.fieldValues.reduce((acc, { field, value }) => {
    const fieldValue = makeFieldValue(value, field);

    return { ...acc, [field.sysName]: fieldValue };
  }, {});

export const selectNormalizedRecordsData = createSelector(selectDictionaryRecordsData, (records) => records.map(normalizeRecord));
