export const LEGAL_STATUSES = {
  ACTIVE: 'ACTIVE',
  LIQUIDATED: 'LIQUIDATED',
  LIQUIDATING: 'LIQUIDATING',
  NOT_ACTIVE: 'NOT_ACTIVE',
};

export const LEGAL_STATUSES_RU = {
  [LEGAL_STATUSES.ACTIVE]: 'Действует',
  [LEGAL_STATUSES.LIQUIDATED]: 'Ликвидировано',
  [LEGAL_STATUSES.LIQUIDATING]: 'Ликвидируется',
  [LEGAL_STATUSES.NOT_ACTIVE]: 'Не активно',
};

export const ORGANIZATION_STATUSES = {
  ACTIVE: 'ACTIVE',
  DELETE: 'DELETE',
  DUPLICATE: 'DUPLICATE',
};
