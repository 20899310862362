var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".logo-3ZFPB {\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n  width: 68px;\n  height: 80px;\n  background-image: url(" + escape(require("./logo.svg")) + ");\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n\n.smallLogo-1BPfZ {\n  width: 35px;\n  height: 40px;\n}", ""]);

// exports
exports.locals = {
	"logo": "logo-3ZFPB",
	"smallLogo": "smallLogo-1BPfZ"
};