import { number, arrayOf, string, object, shape } from 'prop-types';
import { PTFileInfo } from './fileInfoPropTypes';
import { PTContractStatus } from './contractStatusPropTypes';
import { PTPosition } from './positionPropTypes';

/**
 * @description Контракт
 */
export const PTContract = {
  /**
   * @description Дата расторжения договора
   */
  dateAvoided: number,

  /**
   * @description Дата заключения договора
   */
  dateConclusion: number,

  /**
   * @description Дата окончания контракта
   */
  dateEnd: number,
  /**
   * @description Дата регистрации договора
   */
  dateRegister: number,
  /**
   * @description Дата начала в контракта
   */
  dateStart: number,
  /**
   * @description Дата приостановления договора
   */
  dateSuspended: number,

  documents: arrayOf(PTFileInfo),
  /**
   * @description Идентификатор
   */
  id: number,

  /**
   * @description Примечание
   */
  note: string,

  /**
   * @description Номер контракта
   */
  number: string,

  organization: object,
  orgposition: shape(PTPosition),
  status: shape(PTContractStatus),
  /**
   * @description Идентификатор с фронта
   */
  tempId: string,
};
