import { createReducer } from 'redux-act';

import { clearData, setRatingCalculateTotalComplete, setRatingCalculateTotalRequest } from 'redux/actions/Rating';

const initialState = {
  hash: '',
  isLoading: false,
};

const calculatingTotalReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setRatingCalculateTotalComplete]: (state, hash) => {
      if (state.hash !== hash) {
        return state;
      }

      return {
        ...state,
        hash: '',
        isLoading: false,
      };
    },
    [setRatingCalculateTotalRequest]: (state, hash) => ({
      ...state,
      hash,
      isLoading: true,
    }),
  },
  initialState,
);

export default calculatingTotalReducer;
