import axios from 'axios';
import { handleError } from 'magic/handlers';

/**
 *
 * @param {object} file - Данные .
 */
export const uploadFile = async (file, altFetchUrl, type, id) => {
  const data = new FormData();
  data.append('file', file);
  const fetchUrl = (() => {
    switch (typeof altFetchUrl) {
      case 'undefined':
        return `/api/rest/files/upload?name=${file.name}${id ? `&objectId=${id}` : ''}${type ? `&objectType=${type}` : ''}`;
      case 'function':
        return altFetchUrl(file, id, type);
      case 'string':
        return altFetchUrl;
      default:
        return `/api/rest/files/upload?name=${file.name}`;
    }
  })();
  const fileFetching = await fetch(fetchUrl, {
    body: data,
    credentials: 'include',
    headers: {},
    method: 'post',
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Failed to upload file');
      }

      return response.json();
    })
    .then((response) => response.data)
    .catch(() => handleError({ text: 'Произошла ошибка при загрузке файла' }));

  return fileFetching;
};

export const uploadImage = (image) => {
  if (!image || image.id) {
    return image;
  }

  const fileToUpload = image.result || image.file || image;
  return uploadFile(fileToUpload, false);
};

export const uploadFiles = (files) =>
  Promise.all(files)
    .then((newFiles) => {
      const _newFiles = newFiles.map((newFile) => {
        if ('fileInfo' in newFile) {
          return newFile.fileInfo;
        }
        return newFile;
      });
      return _newFiles;
    })
    .catch(() => []);

export const uploadFilesMultiple = async (files) => {
  if (!Array.isArray(files)) {
    return undefined;
  }

  const toUpload = [];
  const uploadedFiles = [];

  files.forEach((file) => (file instanceof File ? toUpload.push(file) : uploadedFiles.push(file)));
  const result = await Promise.all(toUpload.map((file) => uploadFile(file)));
  return [...uploadedFiles, ...result];
};

export const uploadFileToCollection = async (file, type, id, isScan, param) => {
  const fileData = await uploadFile(file, false);

  const body = {
    file_storage_id: fileData.storageId,
    isscan: isScan,
    obj_id: id,
    obj_type: type,
    param,
  };

  await axios.post('/api/rest/link_file', body);
};

export const linkFileToCollection = (fileStorageId, objId, objType) =>
  axios({
    method: 'post',
    url: '/api/rest/link_file',
    data: {
      file_storage_id: fileStorageId,
      obj_id: objId,
      obj_type: objType,
    },
  }).then((response) => response.data);

export const linkFilesToCollection = (fileStorageIds = [], objId, objType) =>
  Promise.all(fileStorageIds.map((fileStorageId) => linkFileToCollection(fileStorageId, objId, objType)));

export const uploadFileToFileStorage = async (file) => {
  const formData = new FormData();
  formData.append('file', new Blob([await file.arrayBuffer()]), file.name);
  return axios({
    method: 'post',
    url: '/api/rest/files/upload',
    params: { name: file.name },
    data: formData,
  }).then((response) => response.data.data);
};

export const uploadFilesToFileStorage = (files = []) => Promise.all(files.map((file) => uploadFileToFileStorage(file)));

export const uploadListToCollection = (filesList, type, id, isScan, param) => {
  if (!Array.isArray(filesList)) {
    return [];
  }

  return Promise.all(filesList.map((item) => uploadFileToCollection(item, type, id, isScan, param)));
};

export const removeFromCollection = async (fileId, type) => {
  const data = fileId;
  const params = { obj_type: type };
  const response = await axios.delete('/api/rest/link_file', { data, params });
  return response.status === 200;
};

export const removeListFromCollection = (filesList, type) => {
  if (!Array.isArray(filesList)) {
    return [];
  }

  return Promise.all(filesList.map((item) => removeFromCollection(item.storageGuid, type)));
};

export const getFilesCollection = async (objId, objType, isScan) => {
  const params = { objId, objType };
  const {
    data: { data: scansData },
  } = await axios.get('/api/rest/link_file/list', { params });

  if (typeof isScan === 'boolean') {
    return scansData.filter((scan) => scan.isscan === isScan);
  }

  return scansData;
};
