export const APPEALS_GROUPS = {
  INFRASTRUCTURES: 'Infr_Objects',
  MEMBERS: 'Members',
  ORGANIZATIONS: 'Organizations',
};

export const APPEALS_STATUSES = {
  IN_WORK: 'In_work',
  SENT: 'Sent',
  SOLVE_NO: 'Solve_no',
  SOLVE_YES: 'Solve_yes',
  TO_REVISION: 'To_revision',
};

export const APPEALS_STATUSES_RU = {
  [APPEALS_STATUSES.IN_WORK]: 'На рассмотрении',
  [APPEALS_STATUSES.SENT]: 'Отправлено',
  [APPEALS_STATUSES.SOLVE_NO]: 'Отклонено',
  [APPEALS_STATUSES.SOLVE_YES]: 'Завершено',
  [APPEALS_STATUSES.TO_REVISION]: 'На доработку',
};

export const CREATOR_TYPES = {
  MEMBER: 1,
  RFS: 2,
};
