import Notification from 'components/rfs-ui/Notification/Notification';
/**
 * @param {string} text текст ошибки, который отображается в нотификашке
 */
export const notificationError = (text = 'Непредвиденная ошибка сервиса') =>
  Notification.error({
    children: {
      icon: 'minus-circle',
      message: text,
    },
  });

export const notificationSuccess = (message = '', icon = '') =>
  Notification.success({
    children: {
      icon,
      message,
    },
  });
