import { createReducer } from 'redux-act';

import { clearData, setRatingCalculateComplete, setRatingCalculateRequest } from 'redux/actions/Rating';

const initialState = {};

const calculatingReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setRatingCalculateComplete]: (state, { criterionId, hash }) => {
      if (!state[criterionId] || state[criterionId].hash !== hash) {
        return state;
      }

      const nextState = { ...state };
      delete nextState[criterionId];

      return nextState;
    },
    [setRatingCalculateRequest]: (state, { criterionId, hash }) => ({
      ...state,
      [criterionId]: {
        hash,
        isLoading: true,
      },
    }),
  },
  initialState,
);

export default calculatingReducer;
