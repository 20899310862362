import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError } from 'magic/handlers';

const createAction = actionsFactory('printTemplates/view/');

export const clearData = createAction('clearData');
export const setPrintTemplatesData = createAction('setPrintTemplatesData');
export const setPrintTemplatesLoading = createAction('setPrintTemplatesLoading');

export const getPrintTemplates = (type) => async (dispatch) => {
  try {
    dispatch(setPrintTemplatesLoading(true));

    const response = await axios.get(`/api/rest/report/template/list/${String(type).toUpperCase()}`);
    dispatch(setPrintTemplatesData(response.data.data));
  } catch (err) {
    handleError(err);
    dispatch(setPrintTemplatesData([]));
  } finally {
    dispatch(setPrintTemplatesLoading(false));
  }
};
