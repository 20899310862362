import React from 'react';
import { isEmpty, map } from 'utils/lodash';

import TableHeader from './Header';
import TableRow from './Row';

const Table = ({ className, headers, headerClassName, columnRenderer, children: data }) => {
  return (
    <table className={className}>
      {!isEmpty(headers) && <TableHeader className={headerClassName}>{headers}</TableHeader>}
      {
        !isEmpty(data) &&
        map(data, ((item, key) => <TableRow columnRenderer={columnRenderer} key={key}>{item}</TableRow>))
      }
    </table>
  );
};

export default Table;
