import React from 'react';

interface Item {
  date: string;
  version: string;
  data: string;
}

export function useReleasesData(): Item[] {
  const [data, setData] = React.useState<Item[]>(() => {
    const storedData = localStorage.getItem('releases');

    if (!storedData) {
      return [];
    }

    try {
      return JSON.parse(storedData);
    } catch (e) {
      return [];
    }
  });

  React.useEffect(() => {
    (async () => {
      const res = await fetch('/releases.json');
      const newData = await res.json();

      localStorage.setItem('releases', JSON.stringify(newData));

      setData(newData);
    })();
  }, []);

  return data;
}
