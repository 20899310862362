exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".container-33OnR {\n  margin-bottom: 40px;\n}\n\n  .container-33OnR:last-child {\n    margin-bottom: 0;\n  }\n\n.header-EDyE5 {\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  cursor: pointer;\n  width: 100%;\n}\n\n.header-EDyE5 > div {\n    margin-bottom: 0;\n    -webkit-box-flex: 1;\n        -ms-flex-positive: 1;\n            flex-grow: 1;\n  }\n\n.arrow-f1Yje {\n  margin-left: 10px;\n  margin-right: -10px;\n  width: 20px !important;\n  font-size: 24px !important;\n  -webkit-transition: .3s;\n  transition: .3s;\n  position: relative;\n  top: 2px;\n}\n\n.arrow-f1Yje.reverse-2jHVC {\n    -webkit-transform: rotateX(180deg);\n            transform: rotateX(180deg);\n    -webkit-transition: .3s;\n    transition: .3s;\n  }\n\n.arrow-f1Yje.left-1i9ol {\n    margin-left: 0;\n  }\n\n.arrow-f1Yje.left-1i9ol, .arrow-f1Yje.right-3YfzR {\n    margin-right: 10px;\n  }\n\n", ""]);

// exports
exports.locals = {
	"container": "container-33OnR",
	"header": "header-EDyE5",
	"arrow": "arrow-f1Yje",
	"reverse": "reverse-2jHVC",
	"left": "left-1i9ol",
	"right": "right-3YfzR"
};