import { createReducer } from 'redux-act';

import {
  clearData,
  setAppealsCategoriesData,
  setAppealsCategoriesLoading,
  setAppealsData,
  setIncomingAppealPagination,
  setAppealsLoading,
  setAppealsFilters,
  updateAppealsFilters,
} from 'redux/actions/Appeals/viewActions';
import { DEFAULT_PAGINATION } from 'constants/pagination';

const initialState = {
  categories: {
    data: [],
    isLoading: false,
  },
  list: {
    data: [],
    filters: {},
    isLoading: false,
    pagination: DEFAULT_PAGINATION,
  },
};

const viewReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setAppealsCategoriesData]: ({ categories, ...state }, data) => ({
      ...state,
      categories: {
        ...categories,
        data,
      },
    }),
    [setAppealsCategoriesLoading]: ({ categories, ...state }, isLoading) => ({
      ...state,
      categories: {
        ...categories,
        isLoading,
      },
    }),
    [setAppealsData]: ({ list, ...state }, data) => ({
      ...state,
      list: {
        ...list,
        data,
      },
    }),
    [setAppealsFilters]: ({ list, ...state }, filters) => ({
      ...state,
      list: {
        ...list,
        filters,
      },
    }),
    [setAppealsLoading]: ({ list, ...state }, isLoading) => ({
      ...state,
      list: {
        ...list,
        isLoading,
      },
    }),
    [setIncomingAppealPagination]: ({ list, ...state }, pagination) => ({
      ...state,
      list: {
        ...list,
        pagination,
      },
    }),
    [updateAppealsFilters]: ({ list, ...state }, filters) => ({
      ...state,
      list: {
        ...list,
        filters: {
          ...list.filters,
          ...filters,
        },
      },
    }),
  },
  initialState,
);

export default viewReducer;
