import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import axios from 'axios';

import { Async, Select, FormElement } from 'components/rfs-ui';

import ValuesList from './ValuesList';
import css from './Multiselect.scss';

const cn = classnames.bind(css);

class Multiselect extends React.Component {
  static propTypes = {
    allowCustomOptions: PropTypes.bool,
    async: PropTypes.bool,
    buttonText: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    externalOptions: PropTypes.func,
    filterOption: PropTypes.func,
    labelKey: PropTypes.string,
    light: PropTypes.bool,
    loadingValues: PropTypes.array,
    minCount: PropTypes.number,
    noDataMessage: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    options: PropTypes.array,
    optionsPath: PropTypes.string,
    readonly: PropTypes.bool,
    required: PropTypes.bool,
    reverse: PropTypes.bool,
    selected: PropTypes.array,
    selectedKey: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    valueKey: PropTypes.string,
  };

  static defaultProps = {
    allowCustomOptions: false,
    async: false,
    buttonText: 'Добавить',
    className: undefined,
    containerClassName: undefined,
    dark: false,
    disabled: false,
    externalOptions: undefined,
    filterOption: undefined,
    labelKey: undefined,
    light: false,
    loadingValues: [],
    minCount: 1,
    noDataMessage: false,
    onDelete: undefined,
    options: undefined,
    optionsPath: undefined,
    readonly: false,
    required: false,
    reverse: false,
    selected: [],
    selectedKey: 'name',
    title: undefined,
    value: undefined,
    valueKey: 'id',
  };

  state = {
    inProgress: false,
    loadingOptions: [],
    startValidation: false,
    value: '',
  };

  componentDidMount() {
    const { async, optionsPath } = this.props;

    if (!async && optionsPath) {
      this.getOptions();
    }
  }

  componentDidUpdate(prevProps) {
    const { optionsPath } = this.props;
    if (prevProps.optionsPath !== optionsPath) {
      this.getOptions();
    }
  }

  getOptions = () => {
    const { optionsPath } = this.props;
    this.setState({ inProgress: true });

    axios
      .get(optionsPath)
      .then((res) => this.setState({ inProgress: false, loadingOptions: res.data.data }))
      .catch(() => this.setState({ inProgress: false }));
  };

  addOption = (option) => {
    const { selected = [], onChange } = this.props;
    const options = [...selected];

    options.push(option);
    this.setState({ value: '' });
    onChange(options, option);
  };

  removeOption = (idx) => () => {
    const { selected = [], onChange, onDelete } = this.props;
    const options = [...selected];

    options.splice(idx, 1);

    if (isFunction(onDelete)) {
      onDelete(options, selected[idx]);
    } else {
      onChange(options, selected[idx]);
    }
  };

  /**
   * Фильтрует options с учетом уже выбранных
   */
  filterOption = (option) => {
    const { selected, valueKey, filterOption } = this.props;
    const selectedEmpty = isEmpty(selected);
    const amongSelected = selected.some((selectedOption) => selectedOption[valueKey] === option.data[valueKey]);
    const checkOuterFilter = !filterOption || filterOption(option);

    return (selectedEmpty || !amongSelected) && checkOuterFilter;
  };

  handleSelectChange = (e) => {
    const { startValidation, value } = this.state;
    if (!isEmpty(e)) {
      this.addOption(e);
      this.setState({
        startValidation: !startValidation ? true : startValidation,
        value: value ? '' : value,
      });
    }
  };

  handleInputChange = (value, { action }) => {
    if (action === 'input-change') {
      const { startValidation } = this.state;
      const newState = { value };

      if (!startValidation) {
        newState.startValidation = true;
      }

      this.setState(newState);
    }
  };

  handleKeyDown = (e) => {
    const { loadingOptions } = this.state;
    const { allowCustomOptions } = this.props;

    if (e.key === 'Enter' && allowCustomOptions) {
      const { value, options = loadingOptions, labelKey } = this.props;
      const haveMatches = options.some((option) => (typeof option === 'object' ? option[labelKey] === value : option === value));

      if (!haveMatches) {
        this.addButton.click();
      }
    }

    return undefined;
  };

  handleAdd = (e) => {
    e.preventDefault();
    const { value, loadingOptions } = this.state;
    const { selected, labelKey, options = loadingOptions, allowCustomOptions } = this.props;
    const selectedStrings = selected.map((option) => (typeof option === 'object' ? option[labelKey] : option));

    if (value && !selectedStrings.includes(value) && (!isEmpty(options) || allowCustomOptions)) {
      const valueFromOptions = options.find((option) => option[labelKey] === value);
      this.addOption(valueFromOptions || value);
    }
  };

  render() {
    const { startValidation, loadingOptions, inProgress, value } = this.state;
    const {
      async,
      selected,
      selectedKey,
      allowCustomOptions,
      required,
      minCount,
      noDataMessage,
      options = loadingOptions,
      optionsPath,
      onChange,
      containerClassName,
      className,
      reverse,
      readonly,
      externalOptions,
      light,
      title,
      dark,
      loadingValues,
      valueKey,
      buttonText,
      disabled,
      filterOption,
      ...restProps
    } = this.props;

    const hasError = startValidation && required && minCount && selected.length < minCount;
    const borderStyles = required ? '1px solid #0a81ff' : undefined;

    const commonProps = {
      className: css.select,
      customStyles: {
        control: {
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
        },
        menu: {
          border: hasError ? '1px solid #eb0a26' : borderStyles,
          marginTop: required ? 0 : undefined,
        },
      },
      dark,
      disabled,
      inputValue: allowCustomOptions ? value : undefined,
      light,
      noBorder: true,
      onChange: this.handleSelectChange,
      onInputChange: this.handleInputChange,
      onKeyDown: this.handleKeyDown,
      required,
      value,
      valueKey,
      ...restProps,
    };

    if (light || dark) {
      commonProps.customStyles.control.border = '1px transparent !important';
    }

    return (
      <div className={cn(css.container, containerClassName, { reverse })}>
        {!isEmpty(selected) && (
          <ValuesList
            values={selected}
            labelKey={selectedKey}
            valueKey={valueKey}
            onRemove={this.removeOption}
            valuesComponent={externalOptions}
            className={cn({ dark, light })}
            reverse={reverse}
            loadingValues={loadingValues}
            disabled={disabled}
          />
        )}
        {isEmpty(selected) && noDataMessage && <p>Информация пока не добавлена</p>}
        {!readonly && hasError && <div className={css.errorTooltip}>{`Необходимо выбрать минимум ${minCount}`}</div>}
        {!readonly && (
          <FormElement title={title} required={required} className={className}>
            <div className={cn(css.bar, { required, [css.error]: hasError })}>
              {async ? (
                <Async {...commonProps} optionsPath={optionsPath} filterOption={this.filterOption} />
              ) : (
                <Select {...commonProps} isLoading={inProgress} options={options.filter((option) => this.filterOption({ data: option }))} />
              )}
              <button
                className={cn(css.addButton, { dark, light })}
                onClick={this.handleAdd}
                type="button"
                disabled={disabled}
                ref={(button) => {
                  this.addButton = button;
                }}
              >
                <span className={css.buttonText}>{buttonText}</span>
              </button>
            </div>
          </FormElement>
        )}
      </div>
    );
  }
}

export default Multiselect;
