import axios from 'axios';
import { handleError, handleSuccess } from '../../../magic/handlers';
import actionsFactory from '../../../magic/actionsFactory';

const createAction = actionsFactory('organization/uniform/');

export const clearData = createAction('clearData');
export const setIsLoading = createAction('setIsLoading');
export const setUniformData = createAction('setUniformData');
export const setColorTypes = createAction('setColorTypes');

export const getColorTypesList = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/rest/dictionary/colorType');
    dispatch(setColorTypes(response.data.data));
  } catch (err) {
    handleError(err);
  }
};
export const getUniformList = (orgId) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await axios.get(`/api/rest/organization/form/find/${orgId}`);
    dispatch(setUniformData(response.data.data));
  } catch (err) {
    handleError(err);
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const createUniform = (body) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    await axios.post('/api/rest/organization/form/new', body);
    dispatch(getUniformList(body.organizationId));
    handleSuccess('Форма успешно добавлена!');
    return true;
  } catch (err) {
    handleError(err);
  } finally {
    dispatch(setIsLoading(false));
  }
  return false;
};

export const editUniform = (body) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    await axios.post('/api/rest/organization/form/edit', body);
    dispatch(getUniformList(body.organizationId));
    handleSuccess('Форма успешно изменена!');
    return true;
  } catch (err) {
    handleError(err);
  } finally {
    dispatch(setIsLoading(false));
  }
  return false;
};

export const deleteUniform = (orgId, formId) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    await axios.delete(`/api/rest/organization/form?formId=${formId}`);
    dispatch(getUniformList(orgId));
    handleSuccess('Форма успешно удалена!');
    return true;
  } catch (err) {
    handleError(err);
  } finally {
    dispatch(setIsLoading(false));
  }
  return false;
};
