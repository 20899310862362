import * as React from 'react';
import cn from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { PermissionsConsumer } from 'components/composed/Permissions';
import styles from './CheckboxGroup.scss';
import Checkbox from '../Checkbox/Checkbox';

export default class CheckboxGroup extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    options: PropTypes.array,
    checkboxClassName: PropTypes.string,
    checkedKey: PropTypes.string,
    labelIdPrefix: PropTypes.string,
    labelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onChange: PropTypes.func,
    permissions: PropTypes.object,
  };

  handleBoxCheck = (index, value) => {
    const { options = [], onChange, checkedKey = 'checked' } = this.props;

    if (options.length && onChange) {
      const newOptions = cloneDeep(options);

      newOptions[index][checkedKey] = value;
      onChange(newOptions, newOptions[index]);
    }
  };

  getOptionTitle = (labelKey, option) => {
    if (typeof labelKey === 'string') {
      return option[labelKey];
    }
    if (typeof labelKey === 'function') {
      return labelKey(option);
    }

    return null;
  };

  render () {
    const {
      options = [],
      className,
      checkboxClassName,
      checkedKey = 'checked',
      labelKey = 'title',
      labelIdPrefix,
      iconBeforeRenderer,
      id,
      ...checkboxProps
    } = this.props;

    // TODO
    // Убрать отсюда нафиг PermissionConsumer когда переделают права на бэке.

    return (
      <div className={cn(styles.checkboxGroup, className)}>
        {
          options.map((option, index) => {
            const groupId = id || 'group';
            const optionId = parseInt(option.id) >= 0 ?
              `${groupId}-${option.id}` :
              `${groupId}-${index}`;

            return (
              <PermissionsConsumer
                key={option.id}
                permissionKey={id && [id]}
                permissions={option.sysName && [option.sysName]}
              >
                <div className={styles.checkboxContainer}>
                  {iconBeforeRenderer && iconBeforeRenderer(option)}
                  <Checkbox
                    {...checkboxProps}
                    title={this.getOptionTitle(labelKey, option)}
                    className={cn(styles.checkboxItem, checkboxClassName)}
                    checked={option[checkedKey]}
                    onChange={value => this.handleBoxCheck(index, value)}
                    id={labelIdPrefix ? `${optionId}-${labelIdPrefix}` : optionId}
                    disabled={option.disabled}
                  />
                </div>
              </PermissionsConsumer>
            );
          })
        }
      </div>
    );
  }
}
