import { createReducer } from 'redux-act';
import { clearData, setFootballerRankData, setFootballerRankLoading } from 'redux/actions/Profile/footballerRankActions';

const initialState = {
  data: null,
  isLoading: false,
};

const footballerRankReducer = createReducer(
  {
    [clearData]: () => ({ ...initialState }),
    [setFootballerRankData]: (state, data) => ({
      ...state,
      data,
    }),
    [setFootballerRankLoading]: (state, isLoading) => ({
      ...state,
      isLoading,
    }),
  },
  initialState,
);

export default footballerRankReducer;
