import { any, bool, func, number, object, shape, string } from 'prop-types';

export const PTLocation = {
  hash: string.isRequired,
  key: string,
  pathname: string.isRequired,
  search: string.isRequired,
  state: any,
};

export const PTHistory = {
  action: string.isRequired,
  block: func.isRequired,
  createHref: func.isRequired,
  go: func.isRequired,
  goBack: func.isRequired,
  goForward: func.isRequired,
  length: number.isRequired,
  listen: func.isRequired,
  location: shape(PTLocation).isRequired,
  push: func.isRequired,
  replace: func.isRequired,
};

export const PTMatch = {
  isExact: bool.isRequired,
  params: object,
  path: string.isRequired,
  url: string.isRequired,
};

export const PTStaticContext = any;

export const PTReactRouter = {
  history: shape(PTHistory).isRequired,
  location: shape(PTLocation).isRequired,
  match: shape(PTMatch).isRequired,
  staticContext: PTStaticContext,
};
