import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';
import axios from 'axios';
import { ROUTES_WAYS } from 'constants/routesWays';
import moment from 'moment';
import { MeetingEditTabs } from 'components/pages/Meetings/Edit/static';

const createAction = actionsFactory('meetings/creation/');

export const setSaveMeetingLoading = createAction('setSaveMeetingLoading');
export const setFederations = createAction('setFederations');
export const setFederationsLoading = createAction('setFederationsLoading');

export const saveMeeting = (data, successCallback) => async (dispatch) => {
  try {
    dispatch(setSaveMeetingLoading(true));
    const requestBody = {
      ...data,
      committee: {
        ...data.committee,
        federationId: data.federation.federationId,
      },
      meetingTime: +moment(data.meetingTime).format('x'),
      tournament: {
        ...data.tournament,
        ekp: data.tournament?.tournamentNumEkp,
      },
    };

    const url = '/api/rest/judiciary/meeting/save';

    const {
      data: {
        data: { id },
      },
    } = await axios.post(url, { ...requestBody });
    handleSuccess('Заседание успешно создано!');
    if (successCallback) successCallback(ROUTES_WAYS.MEETING_EDIT.replace(':id', id).replace(':tab', MeetingEditTabs[0].section));

    dispatch(setSaveMeetingLoading(false));
  } catch (err) {
    handleError(err);
    dispatch(setSaveMeetingLoading(false));
  }
};
