import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';
import isEmpty from 'lodash/isEmpty';

const createAction = actionsFactory('training/plan/equipment/');

const initialState = {};

export const fillEquipment = createAction('fillEquipment');
export const updateEquipment = createAction('updateEquipment');
export const clearEquipment = createAction('clearEquipment');

export const fillInitialEquipment = (data = {}) => (dispatch) => {
  const { equipments = [], ...restData } = data;

  dispatch(fillEquipment({
    ...restData,
    equipments: !isEmpty(equipments) ? equipments : [{}],
  }));
};

const reducer = createReducer({
  [fillEquipment]: (state, equipmentData) => equipmentData,
  [updateEquipment]: (state, data) => ({
    ...state,
    ...data,
  }),
  [clearEquipment]: () => initialState,
}, initialState);

export default reducer;
