import { lazy } from 'react';

/**
 * lazy позволяет подгружать компоненты асинхронно.
 * и экономит время и канал пользователя.
 */

// Роуты для неавторизованного пользователя
/* Авторизация */
export const Login = lazy(() => import('components/pages/Login'));
/* Ввод кода подтверждения для двухфакторной авторизации */
export const ConfirmLogin = lazy(() => import('components/pages/ConfirmLogin'));
/* Регистрация */
export const Registration = lazy(() => import('components/pages/Registration'));
/* Восстановление пароля */
export const Recovery = lazy(() => import('components/pages/Password/Recovery'));
/* Смена пароля */
export const Change = lazy(() => import('components/pages/Password/Change'));
/* Смена email */
export const EmailChange = lazy(() => import('components/pages/EmailChange'));

// Роуты для авторизованного пользователя
export const Management = lazy(() => import('components/pages/Management'));

// Просмотр заседаний
export const Meetings = lazy(() => import('components/pages/Meetings/List'));

// Добавление заседаний
export const MeetingCreate = lazy(() => import('components/pages/Meetings/Create'));

// Добавление заседаний
export const Employers = lazy(() => import('components/pages/Employees/List'));

// Редактирования заседаний
export const MeetingEdit = lazy(() => import('components/pages/Meetings/Edit'));

// Просмотр дисциплинарных дел
export const Cases = lazy(() => import('components/pages/Cases/List'));

// Создание дисциплинарного дела
export const CaseCreate = lazy(() => import('components/pages/Cases/Create'));

// Редактирования дисциплинарного дела
export const CaseEdit = lazy(() => import('components/pages/Cases/Edit'));

// Просмотр решения
export const EditDecision = lazy(() => import('components/pages/Decisions/Edit'));

// Реестр решений
export const Decisions = lazy(() => import('components/pages/Decisions/List'));

// Роуты для авторизованного пользователя
export const Applications = lazy(() => import('components/pages/Applications/Applications'));

/* Поиск дублей */
export const Duplication = lazy(() => import('components/pages/Duplication'));

// Создание физ. лица
export const ProfileCreate = lazy(() => import('components/pages/Profile/Create'));

// История переходов
export const TransfersHistory = lazy(() => import('components/pages/Profile/View/TransfersHistory'));

/* История блокировок */
export const LockHistory = lazy(() => import('components/pages/LockHistory'));

// Просмотр физиков
export const ProfileView = lazy(() => import('components/pages/Profile/View'));

// Просмотр физиков
export const ProfileDuplicates = lazy(() => import('components/pages/ProfileDuplicates'));

// Страница управление правами доступа
export const AccessRights = lazy(() => import('components/pages/AccessRights'));

/* Роли доступа */
export const PermissionFiltersView = lazy(() => import('components/pages/PermissionFilters/View'));
export const PermissionFiltersEdit = lazy(() => import('components/pages/PermissionFilters/Edit'));

/* Добавление организации */
export const OrganizationAdd = lazy(() => import('components/pages/Organization/Add'));

/* Просмотр организации */
export const OrganizationView = lazy(() => import('components/pages/Organization/View'));

/* Создание сборной */
export const NationalTeamAdd = lazy(() => import('components/pages/NationalTeam/Add'));

/* Просмотр сборной */
export const NationalTeamView = lazy(() => import('components/pages/NationalTeam/View'));

/* Редактирование сборной */
export const NationalTeamEdit = lazy(() => import('components/pages/NationalTeam/Edit'));

/* Просмотр команды */
export const TeamView = lazy(() => import('components/pages/Team'));

/* Просмотр организации */
export const OrganizationEdit = lazy(() => import('components/pages/Organization/Edit'));

/* Создание юр лица */
export const LegalAdd = lazy(() => import('components/pages/Legal/Add'));

/* Создание филиала юр. лица */
export const LegalCreateBranch = lazy(() => import('components/pages/Legal/CreateBranch'));

/* Создание документа юр лица */
export const DocumentAdd = lazy(() => import('components/pages/Document/Add'));

/* Просмотр документа */
export const DocumentView = lazy(() => import('components/pages/Document/View'));

/* Просмотр юр лица */
export const LegalView = lazy(() => import('components/pages/MergedLegal'));

/* Поиск организации */
export const LegalOrgSearch = lazy(() => import('components/pages/Search/LegalOrg'));

/* История изменений */
export const ChangeHistory = lazy(() => import('components/pages/ChangeHistory'));

/* История изменений v2 */
export const ChangeHistoryV2 = lazy(() => import('components/pages/ChangeHistoryV2'));

/* Создание обращений */
export const Appeals = lazy(() => import('components/pages/Appeals'));

/* Поиск физ лица */
export const ProfileSearch = lazy(() => import('components/pages/Search/Profile'));

/* Входящие и исходящие заявки */
export const Requests = lazy(() => import('components/pages/TransferApplications/List'));

/* Страница регламеторв */
export const Regulation = lazy(() => import('components/pages/Regulation/List'));

/* Страница просмотра регламента */
export const RegulationEdit = lazy(() => import('components/pages/Regulation/Edit'));

/* Страница заявки */
export const RequestPage = lazy(() => import('components/pages/TransferApplications/View'));

/* Страница уведомление Фифа */
export const NotificationFifa = lazy(() => import('components/pages/NotificationFifa'));

/* Создание учетной записи пользователя */
export const AccountCreation = lazy(() => import('components/pages/AccountCreation'));

/* Редактировать  учетную запись пользователя */
export const AccountEdit = lazy(() => import('components/pages/AccountEdit'));

/* Создание пароля для учетки */
export const PasswordCreate = lazy(() => import('components/pages/Password/Create'));

/* Поиск ОФИ */
export const InfrastructureSearch = lazy(() => import('components/pages/Search/Infrastructure'));

/* Создание/редактирование инфраструктуры */
export const InfrastructureCreate = lazy(() => import('components/pages/Infrastructure/Edit'));

/* Просмотр инфраструктуры */
export const InfrastructureView = lazy(() => import('components/pages/Infrastructure/View'));

/* Создание заявления на сертификацию */
export const CertificationCreation = lazy(() => import('components/pages/CertificationApplications/Draft'));

/* Заявления на сертификацию объектов */
export const CertificationList = lazy(() => import('components/pages/CertificationApplications/List'));

/* Страница заявления на сертификацию */
export const CertificationView = lazy(() => import('components/pages/CertificationApplications/View'));

/* Мои организации */
export const PersonOrganizations = lazy(() => import('components/pages/PersonOrganizations'));

/* Управление переходами для администратора */
export const TransferAdmin = lazy(() => import('components/pages/TransferAdmin'));

/* Управление переходами любителей */
export const TransferUser = lazy(() => import('components/pages/TransferUser'));

/* Управление переходами (массовые действия) */
export const TransferGroup = lazy(() => import('components/pages/TransferGroup'));

/* Конструктор лицензий */
// Функционал скрыт в рамках RFSEIAS-7427
// export const LicenseConstructor = lazy(() => import('components/pages/LicenseConstructor'));

/* Настройки */
export const Settings = lazy(() => import('components/pages/Settings'));

/* Создание/редактирование группы подготовки */
export const TrainingGroupEdit = lazy(() => import('components/pages/TrainingGroup/Edit'));

export const TrainingGroupEditByGroupId = lazy(() => import('components/pages/TrainingGroup/TrainingGroupEditByGroupId'));

/* Просмотр группы подготовки */
export const TrainingGroupView = lazy(() => import('components/pages/TrainingGroup/View'));

/* Формирование расписания тренировок */
export const TrainingSchedule = lazy(() => import('components/pages/Training/Schedule'));

/* Карточка тренировки */
export const TrainingView = lazy(() => import('components/pages/Training/View'));

/* Карточка тренировки */
export const TrainingEdit = lazy(() => import('components/pages/Training/Edit'));

/* План тренировки */
export const TrainingPlan = lazy(() => import('components/pages/Training/Plan'));

/* Подведение итогов тренировки */
export const TrainingSummarize = lazy(() => import('components/pages/Training/Summarize'));

/* Мое расписание */
export const PersonSchedule = lazy(() => import('components/pages/PersonSchedule'));

/* Реестр тестов */
export const TestList = lazy(() => import('components/pages/Test/List'));

/* Просмотр теста */
export const TestView = lazy(() => import('components/pages/Test/View'));

/* Создание теста */
export const TestAdd = lazy(() => import('components/pages/Test/Add'));

/* Редактирование теста */
export const TestEdit = lazy(() => import('components/pages/Test/Edit'));

/* Реестр упражнений */
export const ExerciseList = lazy(() => import('components/pages/Exercise/List'));

/* Просмотр упражнения */
export const ExerciseView = lazy(() => import('components/pages/Exercise/View'));

/* Создание упражнения */
export const ExerciseAdd = lazy(() => import('components/pages/Exercise/Edit'));

/* Редактирование упражнения */
export const ExerciseEdit = lazy(() => import('components/pages/Exercise/Edit'));

/* Управление категориями клубов и школ */
export const Categories = lazy(() => import('components/pages/Categories'));

/* Политика обработки перс. данных и пользовательское соглашение */
export const PersonalData = lazy(() => import('components/pages/PersonalData'));

/* Расчет контрольных индикаторов */
export const ControlIndicators = lazy(() => import('components/pages/ControlIndicators'));

/* Конструктор типов обращений в РФС */
export const AppealsConstructor = lazy(() => import('components/pages/AppealsConstructor'));

/* Конструктор документов */
export const DocumentConstructor = lazy(() => import('components/pages/DocumentConstructor/View'));

/* Форма конструктора документов физ. лица */
export const PersonDocumentConstructorForm = lazy(() => import('components/pages/DocumentConstructor/PersonEdit'));

/* Форма конструктора документов организации */
export const OrgDocumentConstructorForm = lazy(() => import('components/pages/DocumentConstructor/OrgEdit'));

/* Форма конструктора документов ОФИ */
export const InfrastructureDocumentConstructorForm = lazy(() => import('components/pages/DocumentConstructor/Infrastructure'));

/* Экспорт паспортов футболистов (массовое действие) */
export const PassportsGroup = lazy(() => import('components/pages/PassportsGroup'));

/* Присвоение званий/разрядов (массовое действие) */
export const RanksGroup = lazy(() => import('components/pages/RanksGroup'));

/* Регистрация контрактов футболистов */
export const ContractRegistration = lazy(() => import('components/pages/ContractRegistration'));

/* Отчет по статистике переходов */
export const OrganizationTransferStatistic = lazy(() => import('components/pages/Organization/TransferStatistic'));

/* Просмотр шаблонов печатных форм */
export const PrintTemplatesView = lazy(() => import('components/pages/PrintTemplates/View'));

/* Управление шаблоном печатных форм */
export const PrintTemplatesEdit = lazy(() => import('components/pages/PrintTemplates/Edit'));

/* Конструктор калькулятора выплат */
export const PayConstructor = lazy(() => import('components/pages/PayConstructor'));

/* Калькулятор выплат за подготовку спортсмена */
export const PayCalculator = lazy(() => import('components/pages/PayCalculator'));

/* Список инструкций */
export const ContractsList = lazy(() => import('components/pages/Contracts/List'));

/* Просмотр инструкции */
export const ContractView = lazy(() => import('components/pages/Contracts/View'));

/* Работа с мигрированными данными */
export const MigratedData = lazy(() => import('components/pages/MigratedData'));

/* Управление справочниками */
export const Dictionaries = lazy(() => import('components/pages/Dictionaries'));

/* Оценка соревновательной деятельности */
export const RatingView = lazy(() => import('components/pages/Rating/view'));

/* Редактирование оценки соревновательной деятельности */
export const RatingEdit = lazy(() => import('components/pages/Rating/edit'));
