import { createReducer } from 'redux-act';
import actionsFactory from 'magic/actionsFactory';

const createAction = actionsFactory('training/summarize/mainInfo/');

const initialState = {};

export const fillMainInfo = createAction('fillMainInfo');
export const updateMainInfo = createAction('updateMainInfo');
export const clearMainInfo = createAction('clearMainInfo');

const reducer = createReducer(
  {
    [clearMainInfo]: () => initialState,
    [fillMainInfo]: (_state, mainInfo) => mainInfo,
    [updateMainInfo]: (state, data) => ({
      ...state,
      ...data,
    }),
  },
  initialState,
);

export default reducer;
