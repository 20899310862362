import React, { useState, useCallback } from 'react';
import { shape } from 'prop-types';

import { PTPersonalData } from 'propTypes/personalDataPropTypes';
import { Button } from 'components/ui';

import { makePersonalDataUrl } from 'utils/createUrl';

import css from './Disclaimer.styles.scss';

const initialState = window.localStorage.getItem('cookieConfirmation');

export const Disclaimer = ({ metricDataAgreement }) => {
  const DisclaimerText = (
    <div>
      <p>
        Мы обрабатываем Cookies для улучшения работы сайта, анализа трафика, персонализации сервисов и удобства пользователей. Используя сайт
        или кликая «Я согласен», Вы соглашаетесь с Условиями обработки метрических данных на Цифровой платформе Российского Футбольного Союза.
        Вы можете запретить обработку Cookies в настройках браузера. Пожалуйста, ознакомьтесь с{' '}
        <a href={makePersonalDataUrl(metricDataAgreement?.fileInfo)}>
          Условиями обработки метрических данных в Цифровой платформе Российского Футбольного Союза
        </a>
      </p>
    </div>
  );

  const [isConfirmed, setConfirmed] = useState(initialState);
  const handleConfirmationClick = useCallback(() => {
    setConfirmed('true');
    window.localStorage.setItem('cookieConfirmation', 'true');
  }, [setConfirmed]);

  return isConfirmed ? null : (
    <div className={css.disclaimer}>
      <div className={css.content}>
        <div className={css.item}>{DisclaimerText}</div>
        <div className={css.item}>
          <Button onClick={handleConfirmationClick} className={css.button}>
            Я согласен
          </Button>
        </div>
      </div>
    </div>
  );
};

Disclaimer.propTypes = {
  personalDataAgreement: shape(PTPersonalData),
};

export default Disclaimer;
