import axios from 'axios';

import actionsFactory from 'magic/actionsFactory';
import { handleError, handleSuccess } from 'magic/handlers';

const createAction = actionsFactory('profile/fifaDuplicates/');

export const clearData = createAction('clearData');
export const setFifaDuplicatesData = createAction('setFifaDuplicatesData');
export const setFifaDuplicatesLoading = createAction('setFifaDuplicatesLoading');

export const getFifaDuplicates = (personId) => async (dispatch) => {
  try {
    dispatch(setFifaDuplicatesLoading(true));

    const params = { personId };
    const {
      data: { data },
    } = await axios.get('/api/rest/person/fifa/duplicate', { params });

    dispatch(setFifaDuplicatesData(data));
  } catch (error) {
    handleError(error);
    dispatch(setFifaDuplicatesData([]));
  } finally {
    dispatch(setFifaDuplicatesLoading(false));
  }
};

export const createFifaId = (personId) => async () => {
  try {
    const params = { personId };

    await axios.post('/api/rest/person/fifa/create', null, { params });
    handleSuccess('Участнику будет присвоен новый FIFA ID');
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const confirmFifaId = (personId, fifaId) => async () => {
  try {
    const params = { fifaId, personId };

    await axios.post('/api/rest/person/fifa/confirm', null, { params });
    handleSuccess(`Участнику предварительно присвоен FIFA ID: ${fifaId}`);
  } catch (error) {
    handleError(error);
    throw error;
  }
};
