import { createReducer } from 'redux-act';
import { LOADING_STATUSES } from 'constants/loadingStatuses';

import { clearRecordsData, setRecordsRequest, setRecordsSuccess, setRecordsFailure } from 'redux/actions/Dictionaries/recordsActions';

const initialState = {
  data: [],
  filters: {},
  status: LOADING_STATUSES.INITIAL,
};

const recordsReducer = createReducer(
  {
    [clearRecordsData]: () => ({}),
    [setRecordsFailure]: (state) => ({
      ...state,
      status: LOADING_STATUSES.FAILURE,
    }),
    [setRecordsRequest]: (state, filters) => ({
      ...state,
      data: [],
      filters,
      status: LOADING_STATUSES.LOADING,
    }),
    [setRecordsSuccess]: (state, data) => ({
      ...state,
      data,
      status: LOADING_STATUSES.SUCCESS,
    }),
  },
  initialState,
);

export default recordsReducer;
